import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Moment, MomentInput } from 'moment';
import * as moment_ from 'moment';
import { Router } from '@angular/router';
import { dateFormat, dateTimeFormat, sleep, toDateString, toDateTimeString, toTimeString } from 'src/app/shared/utils/utils';
const moment: any = (<any>moment_).default || moment_;

export const DateInputFormatDate = dateFormat;
export const DateInputFormatDateTime = dateTimeFormat;

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrl: './date-input.component.scss'
})
export class DateInputComponent  implements OnInit, OnChanges {

	public toDateString = toDateString;
	public toTimeString = toTimeString;
	public toDateTimeString = toDateTimeString;

	@Input() private min: MomentInput;
	@Input() private max: MomentInput;
	@Input() public mode: 'date' | 'time' | 'date-time' = 'date';

	private date_: MomentInput;
	@Input() public set date(d: MomentInput) {
		this.date_ = d;
	}
	@Output() private dateChange = new EventEmitter<Date>();
	@Output() private change = new EventEmitter<Date>();

	@ViewChild('modal') private modal: TemplateRef<any>;

	//#region Internal fields

	private _min: Moment;
	private _max: Moment;

	private _modalRef: NgbModalRef;

	_isEditing: boolean;
	_isDateEnabled: boolean;
	_isTimeEnabled: boolean;

	_curDate: Date;

	_year: number;
	_month: number;
	_day: number;
	_hour: number;
	_minute: number;

  _initialYear: number;
  _initialMonth: number;
  _initialDay: number;
  _initialHour: number;
  _initialMinute: number;

	_years: number[];
	_months: number[];
	_days: number[];
	_hours: number[];
	_minutes: number[];

	_monthNames: string[] = [
		'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
		'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
	];

	//#endregion

	constructor(private modalService: NgbModal, private router: Router) { }

	ngOnInit() {
		this.reset();
	}

	ngOnChanges(changes: SimpleChanges) {
		for (const change in changes) {
			if (change === 'min' || change === 'max' || change === 'date' || change === 'mode') {
				this.reset();
			}
		}
	}
	//#region Reset

	reset() {
		this._isDateEnabled = this.mode === 'date-time' || this.mode === 'date';
		this._isTimeEnabled = this.mode === 'date-time' || this.mode === 'time';

		if (this.min == null || !(moment(this.min, dateFormat).isValid)) {
			this._min = moment('1901-01-01', DateInputFormatDate);
		} else {
			this._min = moment(this.min, dateFormat);
		}
		if (this.max == null || !(moment(this.max, dateFormat).isValid)) {
			this._max = moment().add(20, 'years');
		} else {
			this._max = moment(this.max, DateInputFormatDate);
		}

		if (this._max < this._min) {
			this._max = this._min.add(1, 'days');
		}

		let date: Moment;
		if (this.date_ == null || !(moment(this.date_, dateFormat).isValid)) {
			date = moment();
		} else {
			date = moment(this.date_, DateInputFormatDate);
		}
		if (date < this._min) {
			date = this._min;
		}
		if (date > this._max) {
			date = this._max;
		}

		this._year = date.get('year');
		this._month = date.get('month') + 1;
		this._day = date.get('date');
		this._hour = date.get('hour');
		this._minute = date.get('minute');
    this.updateDate();
	}

	open() {
		this._modalRef = this.modalService.open(this.modal, { keyboard: false, backdrop: 'static' });
	}

	public close() {
		if (this._modalRef != null) {
			this._modalRef.dismiss();
			this._modalRef = null;
		}
	}

	//#endregion

	//#region Update parts

  updateDate(){
    this.updateYears();
		this.updateMonths();
		this.updateDays();
		this.updateHours();
		this.updateMinutes();
		sleep(50).then(() => {
			this.onDateChange();
			this.dateChange.emit(this._curDate);
			this.change.emit(this._curDate);
		});
  }

	updateYears() {
		const years: number[] = [];
		const minYear = this._min.get('year');
		const maxYear = this._max.get('year');
		for (let i = maxYear; i >= minYear; i--) {
			years.push(i);
		}
		this._years = years;
		if (this._year < minYear) {
			this._year = minYear;
		}
		if (this._year > maxYear) {
			this._year = maxYear;
		}

	}

	updateMonths() {
		const months: number[] = [];
		let minMonth = 1;
		let maxMonth = 12;

		if (this._year === this._min.get('year')) {
			minMonth = this._min.get('month') + 1;
		}
		if (this._year === this._max.get('year')) {
			maxMonth = this._max.get('month') + 1;
		}

		for (let i = minMonth; i <= maxMonth; i++) {
			months.push(i);
		}
		this._months = months;
		if (this._month < minMonth) {
			this._month = minMonth;
		}
		if (this._month > maxMonth) {
			this._month = maxMonth;
		}
	}

	updateDays() {
		const days: number[] = [];
		let minDay = 1;
		let maxDay = this.makeMoment(this._year, this._month).daysInMonth();

		if (
			this._year === this._min.get('year') &&
			this._month === this._min.get('month') + 1
		) {
			minDay = this._min.get('date');
		}
		if (
			this._year === this._max.get('year') &&
			this._month === this._max.get('month') + 1
		) {
			maxDay = this._max.get('date');
		}

		for (let i = minDay; i <= maxDay; i++) {
			days.push(i);
		}
		this._days = days;
		if (this._day < minDay) {
			this._day = maxDay;
		}
		if (this._day > maxDay) {
			this._day = maxDay;
		}
	}

	updateHours() {
		const hours: number[] = [];
		let minHour = 0;
		let maxHour = 23;

		if (
			this._year === this._min.get('year') &&
			this._month === this._min.get('month') + 1 &&
			this._day === this._min.get('day')
		) {
			minHour = this._min.get('hour');
		}
		if (
			this._year === this._max.get('year') &&
			this._month === this._max.get('month') + 1 &&
			this._day === this._max.get('day')
		) {
			maxHour = this._max.get('hour');
		}

		for (let i = minHour; i <= maxHour; i++) {
			hours.push(i);
		}
		this._hours = hours;
		if (this._hour < minHour) {
			this._hour = maxHour;
		}
		if (this._hour > maxHour) {
			this._hour = maxHour;
		}
	}

	updateMinutes() {
		const minutes: number[] = [];
		let minMinute = 0;
		let maxMinute = 59;

		if (
			this._year === this._min.get('year') &&
			this._month === this._min.get('month') + 1 &&
			this._day === this._min.get('day') &&
			this._hour === this._min.get('hour')
		) {
			minMinute = this._min.get('minute');
		}
		if (
			this._year === this._max.get('year') &&
			this._month === this._max.get('month') + 1 &&
			this._day === this._max.get('day') &&
			this._minute === this._max.get('minute')
		) {
			maxMinute = this._max.get('minute');
		}

		for (let i = minMinute; i <= maxMinute; i++) {
			minutes.push(i);
		}
		this._minutes = minutes;
		if (this._minute < minMinute) {
			this._minute = maxMinute;
		}
		if (this._minute > maxMinute) {
			this._minute = maxMinute;
		}
	}

	//#endregion

	//#region Reaction to input

	onYearChanged() {
		this.updateMonths();
		this.updateDays();
		this.updateHours();
		this.updateMinutes();
		this.onDateChange();
	}

	onMonthChanged() {
		this.updateDays();
		this.updateHours();
		this.updateMinutes();
		this.onDateChange();
	}

	onDayChanged() {
		this.updateHours();
		this.updateMinutes();
		this.onDateChange();
	}

	onHourChanged() {
		this.updateMinutes();
		this.onDateChange();
	}

	onMinuteChanged() {
		this.onDateChange();
	}

	onDateChange() {
		this._curDate = this.getCurrentDate();
	}

	onStartEdit() {
		this._isEditing = true;
		this.open();
	}

	onFinishEdit() {
		this._isEditing = false;
		this.close();
		this.dateChange.emit(this._curDate);
		this.change.emit(this._curDate);
	}
	//#endregion

	//#region Util

	zeroPrefix(input: number, places: number): string {
		let output = '';
		if (input != null) {
			output += input;
		}
		while (output.length < places) {
			output = '0' + output;
		}
		return output;
	}

	makeDate(
		year: number, month?: number, day?: number,
		hour?: number, minute?: number
	): Date {
		const output = this.makeMoment(year, month, day, hour, minute);
		if (output == null) {
			return null;
		}
		return output.toDate();
	}

	makeMoment(
		year: number, month?: number, day?: number,
		hour?: number, minute?: number
	): Moment {
		if (year == null) {
			return null;
		}
		let input = '';
		let format = '';
		if (year != null) {
			input += '' + this.zeroPrefix(year, 4);
			format += 'YYYY';
		}
		if (month != null) {
			input += '-' + this.zeroPrefix(month, 2);
			format += '-MM';
		}
		if (day != null) {
			input += '-' + this.zeroPrefix(day, 2);
			format += '-DD';
		}
		if (hour != null) {
			input += ' ' + this.zeroPrefix(hour, 2);
			format += ' HH';
		}
		if (minute != null) {
			input += ':' + this.zeroPrefix(minute, 2);
			format += ':mm';
		}
		const output = moment(input, format);
		return output;
	}

	getCurrentDate(): Date {
		return this.makeDate(this._year, this._month, this._day, this._hour, this._minute);
	}

	//#endregion

}

