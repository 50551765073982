import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from '../../utils/utils';

@Component({
	selector: 'sac-pager',
	templateUrl: './pager.component.html',
	styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnInit {

	private _page: number;

	@Input() public set page(value: number) {
		this._page = value;
		this.pageOut = value + 1;
	}

	public get page(): number {
		return this._page;
	}

	@Input() public totalPagesCount = 0;
	@Input() public totalItemsCount = 0;
	@Input() public itemsPerPage: number;

	public pageOut = 1;
	public perPageItemOut = 10;
  public showSelect = false;

	@Output() public pageChanged = new EventEmitter<number>();
	@Output() public itemsPerPageChanged = new EventEmitter<number>();
	private notificationCanceltionHandler: Function;

	constructor() { }

	ngOnInit() {

    if(!isNullOrUndefined(this.itemsPerPage)){
      this.perPageItemOut = this.itemsPerPage;
      this.showSelect = true;
    }

	}

	public setPage(page: number) {
		if (page < 0) {
			page = 0;
		}
		if (page > this.totalPagesCount - 1) {
			page = this.totalPagesCount - 1;
		}
		if (page === this.page) {
			return;
		}
		this.pageOut = page + 1;
		this.notifyPageChanged(page);
	}

	public onPageOutChanged() {
		this.setPage(this.pageOut - 1);
	}

  public onPerPageItemChanged() {
    this.notifyPerPageItemChanged(this.perPageItemOut);
	}

	public notifyPageChanged(page: number) {
		if (this.notificationCanceltionHandler != null) {
			this.notificationCanceltionHandler();
			this.notificationCanceltionHandler = null;
		}
		let shouldCancel = false;
		this.notificationCanceltionHandler = () => {
			shouldCancel = true;
		};
		setTimeout(() => {
			if (shouldCancel) {
				return;
			}
			this.notificationCanceltionHandler = null;
			this.pageChanged.emit(page);
		}, 300);
	}

  public notifyPerPageItemChanged(item: number) {
		if (this.notificationCanceltionHandler != null) {
			this.notificationCanceltionHandler();
			this.notificationCanceltionHandler = null;
		}
		let shouldCancel = false;
		this.notificationCanceltionHandler = () => {
			shouldCancel = true;
		};
		setTimeout(() => {
			if (shouldCancel) {
				return;
			}
			this.notificationCanceltionHandler = null;
			this.itemsPerPageChanged.emit(item);
		}, 300);
	}

}
