import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderService {

	private jobs = 0;
	public get isVisible(): boolean {
		return this.jobs > 0;
	}

	constructor() { }

	public show() {
		this.jobs++;
	}

	public hide() {
		this.jobs--;
	}

}
