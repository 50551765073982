import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from 'src/app/models/api/protocol/api-response';
import { ApiService } from 'src/app/services/api.service';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { ChangeSubjectType } from 'src/app/models/api/enums/change-subject-type';
import { Country } from 'src/app/models/api/entities/country';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { PersonRequest } from 'src/app/models/api/protocol/person-request';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss']
})
export class PhotoUploadComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() country: Country;
  @Input() managingPhotoType: string;

  @Input() personId: 0;

  @Output() saved = new EventEmitter<PersonInfo>();
  @Output() closed = new EventEmitter<PersonInfo>();

  @ViewChild('photoModal', { static: true }) private photoModal: TemplateRef<any>;
  private photoModalRef: NgbModalRef;

  person: PersonInfo;
  personRequest: PersonRequest;
  imageChangedEvent: string = '';
  croppedImage: string = '';
  viewingMode: boolean = false;

  isNew: boolean;
  isVisiblle: boolean;
  isReadOnly: boolean;

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private changeNotificationsService: ChangeNotificationsService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.country.code);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }

    this.loadPerson();
  }

  private async loadPerson() {
    if (this.isVisiblle) {
      this.close();
    }

    this.isNew = false;

    this.personRequest = {
      personId: this.personId,
      withPhotos: true
    }

    const person = await this.apiService.getPerson(this.personRequest);

    if (person == null) {
      this.close();
      return;
    }

    this.person = person;

    switch (this.managingPhotoType) {
      case 'passport':
        if (this.person.passportFile !== null) {
          this.croppedImage = this.person.passportFile;
        }
        break;
      default:
        if (this.person.photoFile !== null) {
          this.croppedImage = this.person.photoFile;
        }
        break;
    }


    this.changeNotificationsService.removeNotificationsByQuery(ChangeSubjectType.Person, [person.id.toString()]);

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.photoModalRef != null) {
      this.photoModalRef.close();
      this.photoModalRef = null;
    }
    this.photoModalRef = this.modalService.open(this.photoModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.photoModalRef != null) {
      this.photoModalRef.close();
      this.photoModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.person);
  }

  private async save() {

    this.person.fromDocuments = true;

    let request: UpdateRequest<PersonInfo>;

    let result: ApiResponse<number>;

      if(this.viewingMode === false){
        this.messageService.setToastMessage(false, `Please select file!`, 5);
        return;
      }

    switch (this.managingPhotoType) {
      case 'passport':
        this.person.photoFile = null;

        if (this.person.passportFile === null)
          this.messageService.setToastMessage(false, `Please select file!`, 5);

        if (this.person.passportFile !== null) {
          request = { isNew: this.isNew, subject: this.person };
          result = await this.apiService.updatePerson(request);

          if (result.isSuccess) {
            this.saved.emit(this.person);
            this.messageService.setToastMessage(true, `${this.person.name} documents updated`, 10);
            this.onClose();
          } else {
            this.messageService.setToastMessage(false, result.errorMessage, 10);
          }
        }

        break;
      default:
        this.person.passportFile = null;

        if (this.person.photoFile === null)
          this.messageService.setToastMessage(false, `Please select file!`, 5);

        if (this.person.photoFile !== null) {
          request = { isNew: this.isNew, subject: this.person };
          result = await this.apiService.updatePerson(request);

          if (result.isSuccess) {
            this.saved.emit(this.person);
            this.messageService.setToastMessage(true, `${this.person.name} documents updated`, 10);
            this.onClose();
          } else {
            this.messageService.setToastMessage(false, result.errorMessage, 10);
          }
        }
        break;
    }




  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.viewingMode = true;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    switch (this.managingPhotoType) {
      case 'passport':
        this.person.passportFile = event.base64;
        break;
      default:
        this.person.photoFile = event.base64;
        break;
    }
  }
  imageLoaded() {

  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  //#endregion


}
