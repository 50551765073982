// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #17a2b8;
}

a.top-fix {
  margin-top: 3px;
}

a.smartscoring > img {
  height: 32px;
}

a.ueg {
  padding-top: 0;
  padding-bottom: 0;
}

a.ueg > span {
  display: block;
  float: left;
  margin-right: 5px;
  margin-top: 12px;
}

a.ueg > img {
  height: 48px;
}

.terms-of-use {
  color: #15a3b8;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,6BAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,cAAA;EACA,iBAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACE,cAAA;EACA,gBAAA;AACF","sourcesContent":[".navbar {\r\n    background-color: rgba(0,0,0,.03);\r\n    border-top: 1px solid #17a2b8;\r\n}\r\n\r\na.top-fix {\r\n    margin-top: 3px;\r\n}\r\n\r\na.smartscoring > img {\r\n    height: 32px;\r\n}\r\n\r\na.ueg {\r\n    padding-top: 0;\r\n    padding-bottom: 0;\r\n}\r\n\r\na.ueg > span {\r\n    display: block;\r\n    float: left;\r\n    margin-right: 5px;\r\n    margin-top: 12px;\r\n}\r\n\r\na.ueg > img {\r\n    height: 48px;\r\n}\r\n\r\n.terms-of-use {\r\n  color: #15a3b8;\r\n  font-weight: 500;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
