import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { FlightInfo } from 'src/app/models/api/entities/flight-info';
import { FlightReservationInfo } from 'src/app/models/api/entities/flight-reservation-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { PersonShortInfo } from 'src/app/models/api/entities/person-short-info';
import { PersonsListRequest } from 'src/app/models/api/protocol/persons-list-request';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { isConvertableToNumber, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-flight-passengers-list',
  templateUrl: './flight-passengers-list.component.html',
  styleUrls: ['./flight-passengers-list.component.scss']
})
export class FlightPassengersListComponent implements OnInit {

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public reservations: FlightReservationInfo;
  public reservationPassengers: PersonShortInfo[];

  public currentFlightId: number;
  public addingPassengers = false;

  public canManageDelegation = false;

  public messageService: MessageService;

  @Output() passengerUpdated = new EventEmitter<boolean>();

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }
    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;

      this.updateHeaderItems();

      this.route.paramMap.subscribe(async (paramMap) => {
        const flightIdStr = paramMap.get('flightId');

        let flightId = 0;
        if (isConvertableToNumber(flightIdStr)) {
          flightId = Number(flightIdStr);
        }

        if (flightId === 0) {
          return;
        }

        this.currentFlightId = flightId;

      });

      this.reloadList();

    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private updateHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    if (this.canManageDelegation) {
     headerItems.push({ title: 'Add Passenger', iconClass: 'fa-plus', callback: () => this.onAddPassenger() });
    }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    this.reservations = await this.apiService.getFlightReservation(this.currentFlightId);
    this.reservationPassengers = this.reservations.passengers;
    this.passengerUpdated.emit(true);
  }

  private addPassenger(flightId: number) {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/flight-reservations/${flightId}/passenger`;

    if(this.evt.features.isClubExist === true)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flight-reservations/${flightId}/passenger`;

    this.location.go(path);
    this.currentFlightId = flightId;
    this.addingPassengers = true;
  }

  private closePassenger() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/flight-reservations/${this.currentFlightId}`;

    if(this.evt.features.isClubExist === true)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flight-reservations/${this.currentFlightId}`;

    this.location.go(path);
    this.addingPassengers = false;
  }

  private async deletePassenger(person: PersonShortInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, async confirmed => {

      if (confirmed) {

        const currentReservations = this.reservations.passengers.filter(p => p.id !== person.id);

        this.reservations.passengers = currentReservations;

        const request: UpdateRequest<FlightReservationInfo> = {
          isNew: false,
          subject: this.reservations
        };

        const result = await this.apiService.updateFlightPassenger(request);

        if (result.isSuccess) {
          this.messageService.setToastMessage(true, `Person ${person.fullName} Deleted`, 10);
          this.reloadList();
        } else {
          this.messageService.setToastMessage(false, result.errorMessage, 10);
        }

      }
    });
  }
  //#endregion

  //#region Actions

  onPageChanged(page: number) {
    // this.flight.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onAddPassenger() {
    this.addPassenger(this.currentFlightId);
  }

  onDeletePassenger(person: PersonShortInfo) {
    this.deletePassenger(person);
  }

  onPassengerUpdated() {
    this.reloadList();
  }

  onPassengerClosed() {
    this.closePassenger();
  }

  //#endregion


}
