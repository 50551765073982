import { DelegationInfo } from './../../../models/api/entities/delegation-info';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, Input, EventEmitter, Output, ElementRef, Renderer2 } from '@angular/core';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpEventType } from '@angular/common/http';

import * as Plyr from 'plyr';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-anthem-upload',
  templateUrl: './anthem-upload.component.html',
  styleUrls: ['./anthem-upload.component.scss'],
  providers: [NgbProgressbarConfig]
})
export class AnthemUploadComponent implements OnInit {

  public anthemPlayer: Plyr;

  public anthemSource: Plyr.Source[];

  public anthemPlayerControls: Plyr.Options = { controls: ['play', 'progress', 'current-time', 'mute', 'seekTime', 'volume'] };

  public progress: number = 0;

  public isUploadProgress: boolean = false;

  public buttonTitle: string = "Upload & Change";

  @Input() delegation: DelegationInfo;
  @Input() isNfUser: boolean;
  @Output() public onUploadFinished = new EventEmitter();

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private el: ElementRef,
    private renderer: Renderer2,
    progressBar: NgbProgressbarConfig
  ) {
    progressBar.max = 100;
    progressBar.striped = true;
    progressBar.animated = true;
    progressBar.type = 'info';
    progressBar.height = '23px';

    this.messageService = new MessageService();
  }

  ngOnInit(): void {

    this.anthemSource = [{ src: this.apiService.getAnthemAddress(this.delegation.countryCode), type: 'audio/mp3', }];

    if(this.delegation.isAnthemExist == false){
      this.buttonTitle = "Upload anthem";
    }

  }

  uploadAnthem(files) {

    if (files.length === 0) {
      return;
    }

    const message = 'Upload the Anthem? ';
    this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        let fileToUpload = <File>files[0];

        const formData = new FormData();

        formData.append('file', fileToUpload, fileToUpload.name);

        this.apiService.uploadAnthemMusic(formData, this.delegation).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
            this.isUploadProgress = true;
          } else if (event.type === HttpEventType.Response) {

            if (event.body.isSuccess) {
              let convertPlaylist = event.body.data;

              this.anthemSource = [{
                src: this.apiService.getAnthemAddress(convertPlaylist.scr),
                type: convertPlaylist.type
              }]

              this.messageService.setToastMessage(true, `Anthem has successfully uploaded!`);

              this.onUploadFinished.emit(event.body);
              this.delegation.isAnthemExist = true;
              this.buttonTitle = "Upload & Change";
              this.updatePlayer()
            } else {
              this.messageService.setToastMessage(false, `Anthem not uploaded!`);
            }

            this.progress = 0;
            this.isUploadProgress = false;

          }
        });

      }
    });

  }

  private updatePlayer() {
    const parentElement = document.getElementById('player-parent');
    if (parentElement) {
      const audioContainer = parentElement?.getElementsByClassName("audio-container")[0];
      const player = parentElement?.getElementsByTagName("audio")[0];
      if (player && audioContainer) {
        player.remove();
        const audioPlayer = this.renderer.createElement("audio");
        this.renderer.setAttribute(audioPlayer, "id", "player");
        this.renderer.setAttribute(audioPlayer, "controls", "");
        this.renderer.setAttribute(audioPlayer, "playsinline", "");
        this.renderer.setAttribute(audioPlayer, "class", "player");

        const sourceElement = this.renderer.createElement("source");
        this.renderer.setAttribute(sourceElement, "type", "audio/mp3");
        this.renderer.setAttribute(
          sourceElement,
          "src",
          this.anthemSource[0].src
        );
        this.renderer.appendChild(audioPlayer, sourceElement);
        this.renderer.appendChild(audioContainer, audioPlayer);
      }
    }
}
}