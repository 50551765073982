import { MusicInfo } from './../../../models/api/entities/music-info';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MimeTypes } from 'src/app/utils/mime-types';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { isConvertableToNumber, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-music-list',
  templateUrl: './music-list.component.html',
  styleUrls: ['./music-list.component.scss']
})
export class MusicListComponent implements OnInit {

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public musics: ListResponse<MusicInfo> = new LocalListResponse<MusicInfo>();

  public currentMusicId: number;
  public viewingMusics = false;

  public canManageDelegation = false;

  public isObserver: boolean = false;

  public messageService: MessageService;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      if ( curUser.role === 3 ){
        this.isObserver = true;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;

      const breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }

      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      if (this.isObserver) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      // eslint-disable-next-line max-len
      if(evt.features.isClubExist === false)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` });
        breadcrumbs.push({ key: 'Music', value: '', link:''})
      }

      if(evt.features.isClubExist === true)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}` });
        breadcrumbs.push({ key: 'Music', value: '', link:''})
      }

      this.breadcrumbsService.breadcrumbs = breadcrumbs;

      this.updateHeaderItems();

      if (params.has('musicId')) {
        const musicIdStr = params.get('musicId');

        let musicId = 0;
        if (isConvertableToNumber(musicIdStr)) {
          musicId = Number(musicIdStr);
        }

        if (musicId !== 0) {
          this.editMusic(musicId);
        } else {
          this.addMusic();
        }

      };

      this.reloadList();

    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private updateHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    if (this.canManageDelegation) {
      headerItems.push({ title: 'Download musics', iconClass: 'fa-file-zip-o', callback: () => this.onDownloadAll() });
      headerItems.push({ title: 'Add music', iconClass: 'fa-plus', callback: () => this.onAddMusic() });
    }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    // eslint-disable-next-line max-len
    this.musics = await this.apiService.getMusicsList(true, this.itemsPerPage, this.musics.page, null, this.delegation.eventId, this.delegation.countryCode, this.delegation.clubShortName);
  }

  private editMusic(musicId: number) {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/music/${musicId}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/music/${musicId}`;

    this.location.go(path);
    this.currentMusicId = musicId;
    this.viewingMusics = true;
  }

  private closeMusic() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/music`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/music`;

    this.location.go(path);
    this.viewingMusics = false;
    this.currentMusicId = 0;
    this.onMusicUpdated();
  }

  private addMusic() {
    this.editMusic(0);
  }

  private downloadAll() {
    let path = `/Music/DownloadAllMusic?eventId=${this.evt.id}&country=${this.delegation.countryCode}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `/Music/DownloadAllMusic?eventId=${this.evt.id}&country=${this.delegation.countryCode}&club=${this.delegation.clubShortName}`;

    const mimeType = MimeTypes.zip;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private downloadMusic(music: MusicInfo) {
    const path = `/Music/GetMusic?musicId=${music.id}&isDownload=true`;
    const mimeType = MimeTypes.mp3;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }


  private deleteMusic(music: MusicInfo) {
    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        this.apiService.deleteMusic(music.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `Music has successfully deleted`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  //   //#endregion

  // #region Actions;

  onPageChanged(page: number) {
    this.musics.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onAddMusic() {
    this.addMusic();
  }

  onEditMusic(music: MusicInfo) {
    this.editMusic(music.id);
  }

  onDeleteMusic(music: MusicInfo) {
    this.deleteMusic(music);
  }

  onMusicUpdated() {
    this.reloadList();
  }

  onMusicClosed() {
    this.closeMusic();
  }

  onDownloadAll() {
    this.downloadAll();
  }

  onDownloadMusic(music: MusicInfo) {
    this.downloadMusic(music);
  }

  //#endregion

}
