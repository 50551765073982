import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { FlightReservationInfo } from 'src/app/models/api/entities/flight-reservation-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-flight-reservations-list',
  templateUrl: './flight-reservations-list.component.html',
  styleUrls: ['./flight-reservations-list.component.scss']
})
export class FlightReservationsListComponent implements OnInit {

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;

  public flights: ListResponse<FlightReservationInfo> = new LocalListResponse<FlightReservationInfo>();

  public canManageDelegation = false;

  public isObserver: boolean = false;

  public messageService: MessageService;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      if ( curUser.role === 3 ){
        this.isObserver = true;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;


      const breadcrumbs: Breadcrumb[] = [];

      if (curUser.role === 1){
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }

      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      if (this.isObserver) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      // eslint-disable-next-line max-len
      if(evt.features.isClubExist === false)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` });
        breadcrumbs.push({ key: 'Travel details', value: '', link:''})
      }

      if(evt.features.isClubExist === true)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}` });
        breadcrumbs.push({ key: 'Travel details', value: '', link:''})
      }

      this.breadcrumbsService.breadcrumbs = breadcrumbs;
      this.reloadList();

    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private async reloadList() {
    // eslint-disable-next-line max-len
    this.flights = await this.apiService.getFlightReservationsList(true, this.itemsPerPage, this.flights.page, null, this.delegation.eventId, this.delegation.countryCode, null, this.delegation.clubShortName);
  }

  private viewFlightReservation(flightId: number) {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/flight-reservations/${flightId}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flight-reservations/${flightId}`;

    this.router.navigate([path]);
  }

  private closeFlight() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/flight-reservations`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flight-reservations`;

    this.router.navigate([path]);
  }

  //#endregion

  //#region Actions

  onPageChanged(page: number) {
    this.flights.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onViewFlightReservationDetail(flight: FlightReservationInfo) {
    if (flight === null) {
      return;
    }
    this.viewFlightReservation(flight.id);
  }

  onFlightClosed() {
    this.closeFlight();
  }

  //#endregion

}
