export class LocalApiResponse<T> {
    isSuccess: boolean;
    successMessage: string;
    errorMessage: string;
    data: T;

    public constructor()
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    public constructor(errorMessage: string)
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    public constructor(successMessage: string, data: T)
    public constructor(errorMessage?: string, successMessage?: string, data?: T) {
        if (errorMessage == null && successMessage == null && data == null) {
            return;
        }
        if (errorMessage != null) {
            this.isSuccess = false;
            this.errorMessage = errorMessage;
            this.successMessage = null;
            this.data = null;
        } else {
            this.isSuccess = true;
            this.errorMessage = null;
            this.successMessage = successMessage;
            this.data = data;
        }
    }
}
