import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Breadcrumb } from '../models/breadcrumb';
import { ShortEventInfo } from '../models/api/entities/short-event-info';
import { Country } from '../models/api/entities/country';
import { Router, NavigationStart } from '@angular/router';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  //#region Properties
  private _title: string = '';
  private _breadcrumbs: Breadcrumb[] = [];

  public get breadcrumbs(): Breadcrumb[]
  {
    return this._breadcrumbs;
  }
  public set breadcrumbs(breadcrumbs: Breadcrumb[])
  {
    this._breadcrumbs = breadcrumbs;
    this.breadcrumbsChanged.emit(this.breadcrumbs);
  }

  public get title(): string
  {
    return this._title;
  }

  public set title(title: string)
  {
    this._title = title;
    this.titleChanged.emit(this.title);
  }

  @Output() public breadcrumbsChanged = new EventEmitter<Breadcrumb[]>();
  @Output() public titleChanged = new EventEmitter<string>();
  //#endregion

  constructor(private router: Router) {
    this.load();
  }

  private load() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart)
      {
        this.reset();
      }
    });
  }

  private reset()
  {
    this._breadcrumbs = [];
    this._title = '';
    this.titleChanged.emit(this.title);
    this.breadcrumbsChanged.emit(this.breadcrumbs);
  }

}
