import { ApiService } from 'src/app/services/api.service';
import { AccommodationDetailsInfo } from 'src/app/models/api/entities/special-export/accommodation/accommodation-details-info';
import { Component, Input, OnInit } from '@angular/core';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

const moment = extendMoment(Moment);


@Component({
  selector: 'app-special-export-detail-accomodation',
  templateUrl: './special-export-detail-accomodation.component.html',
  styleUrls: ['./special-export-detail-accomodation.component.scss']
})
export class SpecialExportDetailAccomodationComponent implements OnInit {

  @Input() eventId: number;
  @Input() accommodationDetail: AccommodationDetailsInfo;

  public datesCol: number = 1;
  public dateRange: Array<string>;
  public dateRangeSpec: Array<string> = [];
  public roomTypesCol: number = 1;
  public roomTypesFor = [];
  public selectedRemark: string;
  public selectedDelegation: string;
  public hotelId: number;
  public inx = 0;
  public countryCode: string;


  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    config: NgbModalConfig
  ) {
    config.size = 'lg';
    config.backdrop = 'static';
  }

  ngOnInit(): void {

    this.datesCol = this.accommodationDetail.delegations[0].detailByDate.length;

    const minCheckInStartDate = moment(this.accommodationDetail.minCheckInStartDate);
    const maxCheckOutEndDate = moment(this.accommodationDetail.maxCheckOutEndDate);
    const dateRangeMoment = moment.range(minCheckInStartDate, maxCheckOutEndDate);
    const dateRangeMomentSpec = moment.range(minCheckInStartDate.add(-1, 'days'), maxCheckOutEndDate);
    const dateRange = Array.from(dateRangeMoment.by('days')).map(m => m.format('DD/MM'));
    const dateRangeSpec = Array.from(dateRangeMomentSpec.by('days')).map(m => m.format('DD/MM'));

    this.dateRange = dateRange;

    for (let index = 0; index < dateRange.length; index++) {
      this.dateRangeSpec.push(`${dateRangeSpec[index]} - ${dateRange[index]}`);

    }

    this.roomTypesCol = this.accommodationDetail.roomTypes.length;

    for (let index = 0; index < this.datesCol / this.roomTypesCol; index++) {
      this.roomTypesFor[index] = index;
    }

  }

  showRemark(showRemarkModal, inx: number) {
    this.inx = inx;
      this.selectedRemark = this.accommodationDetail.delegations[inx].remark;
      this.modalService.open(showRemarkModal);
  }

  addEditRemark(addEditRemarkModal, inx: number, delegation: string){
    this.inx = inx;
    this.countryCode = delegation;
    this.selectedDelegation = delegation;
    this.selectedRemark = this.accommodationDetail.delegations[inx].remark;
    this.modalService.open(addEditRemarkModal);
  }

  async saveRemark() {

   const request = {
      eventId: this.eventId,
      detailId: this.accommodationDetail.exportId,
      hotelId: this.accommodationDetail.hotelId,
      countryCode: this.selectedDelegation,
      remark: this.selectedRemark
  }

    const res = await this.apiService.addEditRemark(request);

    if (res){
      this.accommodationDetail.delegations[this.inx].remark = stringIsNullOrEmpty(this.selectedRemark) ? null : this.selectedRemark;
      this.accommodationDetail.delegations[this.inx].remarkAvailable = stringIsNullOrEmpty(this.selectedRemark) ? false : true;
    }

    this.modalService.dismissAll();
  }

}
