import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MealReservationInfo } from 'src/app/models/api/entities/meal-reservation-info';
import { MimeTypes } from 'src/app/utils/mime-types';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserService } from 'src/app/services/user.service';
import { isConvertableToNumber, stringIsNullOrEmpty, toDateString } from 'src/app/shared/utils/utils';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-meal-reservations-list',
  templateUrl: './meal-reservations-list.component.html',
  styleUrls: ['./meal-reservations-list.component.scss']
})
export class MealReservationsListComponent implements OnInit {

  public toDateString = toDateString;

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public reservations: ListResponse<MealReservationInfo> = new LocalListResponse<MealReservationInfo>();

  public currentReservationId: number;
  public viewingMealReservation = false;

  public canManageDelegation = false;

  public isLocOrAdmin: boolean = false;

  public isObserver: boolean = false;

  public isPenaltyFunctionEnabled: boolean = false;

  public messageService: MessageService;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    if (curUser.role === 1 || curUser.role === 2) {
      this.isLocOrAdmin = true;
    }
    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      if (curUser.role === 3) {
        this.isObserver = true;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.isPenaltyFunctionEnabled = evt.features.isPenaltyFunctionEnabled;

      this.evt = evt;
      this.delegation = delegation;

      const breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }
      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      // eslint-disable-next-line max-len
      if(evt.features.isClubExist === false)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` });
        breadcrumbs.push({ key: 'Meal Reservations', value: '', link:''})
      }

      if(evt.features.isClubExist === true)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}` });
        breadcrumbs.push({ key: 'Meal Reservations', value: '', link:''})
      }

      this.breadcrumbsService.breadcrumbs = breadcrumbs;

      this.updateHeaderItems();

      if (params.has('mealReservationId')) {

        const mealReservationIdStr = params.get('mealReservationId');

        if (mealReservationIdStr == null || !isConvertableToNumber(mealReservationIdStr)) {
          this.close();
          return;
        }

        const reservationId = Number(mealReservationIdStr);

        this.currentReservationId = reservationId;

        if (reservationId !== 0) {
          this.viewReservation(reservationId);
        } else {
          this.addReservation();
        }

        this.viewReservation(reservationId);
      };

      this.reloadList();

    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }


  private updateHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    if (this.canManageDelegation) {
      headerItems.push({ title: 'Add reservation', iconClass: 'fa-plus', callback: () => this.onAddReservation() });
    }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    // eslint-disable-next-line max-len
    this.reservations = await this.apiService.getMealReservations(this.evt.features.isPenaltyFunctionEnabled, this.itemsPerPage, this.reservations.page, null, this.delegation.eventId, this.delegation.countryCode, this.delegation.clubShortName);
  }

  private viewReservation(reservationId: number) {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/meal-reservations/${reservationId}`;

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/meal-reservations/${reservationId}`;

    this.location.go(path);
    this.currentReservationId = reservationId;
    this.viewingMealReservation = true;
  }

  private closeReservation() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/meal-reservations`;

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.clubShortName}/meal-reservations`;

    this.location.go(path);
    this.viewingMealReservation = false;
    this.currentReservationId = 0;
  }

  private addReservation() {
    this.viewReservation(0);
  }

  private downloadReservation() {
    let path = `/Export/MealReservations?eventId=${this.evt.id}&country=${this.delegation.countryCode}`;

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      path = `/Export/MealReservations?eventId=${this.evt.id}&country=${this.delegation.countryCode}&club=${this.delegation.clubShortName}`;

    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private deleteReservation(reservation: MealReservationInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        this.apiService.deleteMealReservation(reservation.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `Meal reservation '${reservation.name} has successfully deleted`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });

      }
    });
  }

  private disableReservation(reservation: MealReservationInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        this.apiService.disableMealReservation(reservation.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            const toastMessage = `Meal reservation '${reservation.name} disabled`;
            this.messageService.setToastMessage(true, toastMessage);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  //#endregion

  //#region Actions

  onPageChanged(page: number) {
    this.reservations.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onDownloadReservation() {
    this.downloadReservation();
  }

  onAddReservation() {
    this.addReservation();
  }

  onViewReservation(reservation: MealReservationInfo) {
    this.viewReservation(reservation.id);
  }

  onDisableReservation(reservation: MealReservationInfo) {
    this.disableReservation(reservation);
  }

  onDeleteReservation(reservation: MealReservationInfo) {
    this.deleteReservation(reservation);
  }

  onReservationUpdated() {
    this.reloadList();
  }

  onReservationClosed() {
    this.closeReservation();
  }

  //#endregion

}
