import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Airport } from 'src/app/models/api/entities/airport';
import { ApiService } from 'src/app/services/api.service';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { Country } from 'src/app/models/api/entities/country';
import { CurrencyType } from 'src/app/models/api/enums/currency-type';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { FlightInfo } from 'src/app/models/api/entities/flight-info';
import { FlightTypeInfo } from 'src/app/models/api/entities/flight-type-info';
import { MealReservationInfo } from 'src/app/models/api/entities/meal-reservation-info';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { isNullOrUndefined, toMoment } from 'src/app/utils/utils';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { MessageService } from 'src/app/shared/services/message.service';
import { stringIsNullOrEmpty, toDateString, toDateTimeString } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-flights',
  templateUrl: './flights.component.html',
  styleUrls: ['./flights.component.scss']
})
export class FlightsComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() country: Country;

  @Input() flightId: 0;
  @Input() flights: ListResponse<FlightInfo>;

  @Input() clubShortName: string = null;

  @Output() saved = new EventEmitter<FlightInfo>();
  @Output() closed = new EventEmitter<FlightInfo>();

  @ViewChild('flightModal', { static: true }) private flightModal: TemplateRef<any>;
  private flightModalRef: NgbModalRef;

  allFlights: FlightInfo[];

  flight: FlightInfo;

  allCounties: Country[];
  allAirports: Airport[];
  allFlightTypes: FlightTypeInfo[];

  minDate: string;
  maxDate: string;
  flightDate: Date;

  maxQuantity: number = 0;
  personsQuantity: number = 0;


  isNew: boolean;

  isVisiblle: boolean;
  isReadOnly: boolean;

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    this.allCounties = this.entityMapService.allCountries;
    this.allFlightTypes = this.entityMapService.getAllFlightTypes();

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.country.code);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }

    this.minDate = toDateString(toMoment(this.evt.startDate).add(-20, 'month').toDate());
    this.maxDate = toDateString(toMoment(this.evt.startDate).add(20, 'month').toDate());

    this.personsQuantity = (await this.apiService.getPersonsList(false,-1,0,null,this.evt?.id,this.country.code,this.clubShortName)).items.length;


    if (this.flightId === 0) {
      this.newFlight();
    } else {
      this.loadFlight();
    }
  }

  private async newFlight() {

    this.isNew = true;

    this.flight = {
      id: 0,
      eventId: this.evt.id,
      countryCode: this.country.code,
      flightDate: toDateTimeString(this.flightDate),
      flightNumber: null,
      flightTypeCode: null,
      quantity: 0,
      airport: null,
      flightCountryCode: null
    };

    if(this.clubShortName !== null){
      this.flight.clubShortName = this.clubShortName;
    }

    this.open();
  }

  private async loadFlight() {
    if (this.isVisiblle) {
      this.close();
    }

    this.isNew = false;

    const flight = await this.apiService.getFlight(this.flightId);
    this.flightDate = new Date(flight.flightDate);

    if (this.flight === null) {
      this.close();
      return;
    }

    const countryName = this.entityMapService.getCountryByCode(flight.flightCountryCode);

    this.allAirports = null;
    this.allAirports = this.entityMapService.getAirportsByCountryName(countryName.name);

    this.flight = flight;

    let total = 0;

    this.flights.items.filter(t => this.entityMapService.getFlightByCode(t.flightTypeCode) === this.flight.flightType)
                      .forEach(flt => { total += flt.quantity });


    const over = this.personsQuantity - total;

    this.maxQuantity = this.flight.quantity + over;
    // this.changeNotificationsService.removeNotificationsByQuery();

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      return;
    }

    if (this.flightModalRef != null) {
      this.flightModalRef.close();
      this.flightModalRef = null;
    }

    if(this.personsQuantity === 0){
      this.messageService.setToastMessage(false, 'Persons does not exist! <br> Please add from Persons section.');
      this.close();
      this.flightModalRef.close();
      this.flightModalRef = null;
      this.isVisiblle = false;
      this.closed.emit(this.flight);
    }

    this.flightModalRef = this.modalService.open(this.flightModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.flightModalRef != null) {
      this.flightModalRef.close();
      this.flightModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.flight);
  }

  private async save() {

    if (isNullOrUndefined(this.flight.flightType)) {
      this.messageService.setToastMessage(false, 'Please select type.');
      return;
    }

    if (stringIsNullOrEmpty(this.flight.flightNumber)) {
      this.messageService.setToastMessage(false, 'Please add travel number.');
      return;
    }

    if (stringIsNullOrEmpty(this.flight.countryCode)) {
      this.messageService.setToastMessage(false, 'Please select country.');
      return;
    }

    if (stringIsNullOrEmpty(this.flight.airport)) {
      this.messageService.setToastMessage(false, 'Please select airport.');
      return;
    }

    if (this.flight.quantity === 0) {
      this.messageService.setToastMessage(false, 'Please add quantity.');
      return;
    }

    if(this.maxQuantity < this.flight.quantity){
      this.messageService.setToastMessage(false, `Please add correct quantity. Only ${this.flight.quantity} flights are available`);
      return;
    }


    this.flight.flightDate = toDateTimeString(this.flightDate);
    this.flight.flightTypeCode = this.flight.flightType.toString();

    if(this.clubShortName !== null){
      this.flight.clubShortName = this.clubShortName;
    }

    const request: UpdateRequest<FlightInfo> = {
      isNew: this.isNew,
      subject: this.flight
    };

    const result = await this.apiService.updateFlight(request);

    if (result.isSuccess) {
      this.saved.emit(this.flight);
      this.messageService.setToastMessage(true, this.isNew ? 'Travel created' : 'Travel updated', 10);
      this.onClose();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage, 10);
    }

  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  onFlightCountryChange() {
    if (this.flight.flightCountryCode === null) {
      return;
    }

    const countryName = this.entityMapService.getCountryByCode(this.flight.flightCountryCode);
    this.flight.airport = null;
    this.allAirports = [];
    this.allAirports = this.entityMapService.getAirportsByCountryName(countryName.name);

  }

  onFlightTypeChange() {
    let total = 0;

    this.flights.items
      .filter(t => this.entityMapService
      .getFlightByCode(t.flightTypeCode) === this.flight.flightType)
      .forEach(flt => { total += flt.quantity });

    this.maxQuantity = this.personsQuantity - total;

    if(this.maxQuantity === 0)
    this.messageService.setToastMessage(false, 'All persons for this type are ended.<br>If you want add new flight, please add person or change other flights.');

  }
  //#endregion
}
