import { Pipe, PipeTransform } from '@angular/core';

import { RoomType } from '../models/api/enums/room-type';

@Pipe({
  name: 'hotelPriceType'
})
export class HotelPriceTypePipe implements PipeTransform {

  transform(price: number, roomType: RoomType, quantity = null): unknown {

    switch (roomType) {
      case RoomType.QD:
        price *= 4;
        break;
      case RoomType.TR:
        price *= 3;
        break;
      case RoomType.TW:
        price *= 2;
        break;
      case RoomType.TW_SEP_B:
        price *= 2;
        break;
      case RoomType.TW_SH_B:
        price *= 2;
        break;
      case RoomType.Twin_BB:
        price *= 2;
        break;
      case RoomType.Twin_HB:
        price *= 2;
        break;
      case RoomType.Twin_FB:
        price *= 2;
        break;
      case RoomType.Triple_BB:
        price *= 3;
        break;
      case RoomType.Triple_HB:
        price *= 3;
        break;
      case RoomType.Triple_FB:
        price *= 3;
        break;
      case RoomType.Multiple_4_beds_BB:
        price *= 4;
        break;
      case RoomType.Multiple_4_beds_HB:
        price *= 4;
        break;
      case RoomType.Multiple_4_beds_FB:
        price *= 4;
        break;
      case RoomType.Multiple_6_beds_BB:
        price *= 6;
        break;
      case RoomType.Multiple_6_beds_HB:
        price *= 6;
        break;
      case RoomType.Multiple_6_beds_FB:
        price *= 6;
        break;
    }

    if (quantity !== null) {
      price *= quantity;
    }
    return price;

  }

}
