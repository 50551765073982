// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group-prepend {
  text-align: center;
}

.input-group-prepend .input-group-text {
  min-width: 30px;
  text-align: center;
}

.popover {
  max-width: unset !important;
}

.width-100-pr {
  width: 70%;
}

.date-group:hover {
  border: black solid 1px;
}

.date-group {
  border: #ededed solid 1px;
  border-radius: 12px;
}
.date-group button {
  border: none;
}
.date-group input {
  pointer-events: none;
  border: none;
}

@media (min-width: 768px) {
  [class^=col-]:not(.first) {
    padding-left: 5px;
  }
  [class^=col-]:not(.last) {
    padding-right: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/date-input/date-input.component.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,2BAAA;AAAJ;;AAGA;EACE,UAAA;AAAF;;AAGC;EACC,uBAAA;AAAF;;AAGC;EACC,yBAAA;EACA,mBAAA;AAAF;AACE;EACE,YAAA;AACJ;AACE;EACE,oBAAA;EACA,YAAA;AACJ;;AAIA;EAEI;IACI,iBAAA;EAFN;EAKE;IACI,kBAAA;EAHN;AACF","sourcesContent":["\r\n.input-group-prepend {\r\n    text-align: center;\r\n}\r\n\r\n.input-group-prepend .input-group-text {\r\n    min-width: 30px;\r\n    text-align: center;\r\n}\r\n\r\n.popover {\r\n    max-width: unset !important;\r\n}\r\n\r\n.width-100-pr {\r\n  width: 70%;\r\n }\r\n\r\n .date-group:hover{\r\n  border: black solid 1px;\r\n }\r\n\r\n .date-group{\r\n  border: #ededed solid 1px;\r\n  border-radius: 12px;\r\n  button{\r\n    border: none;\r\n  }\r\n  input{\r\n    pointer-events: none;\r\n    border: none;\r\n  }\r\n }\r\n\r\n\r\n@media (min-width: 768px) {\r\n\r\n    [class^=\"col-\"]:not(.first) {\r\n        padding-left: 5px;\r\n    }\r\n\r\n    [class^=\"col-\"]:not(.last) {\r\n        padding-right: 5px;\r\n    }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
