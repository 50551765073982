// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.num-group {
  border: 1px solid #ededed;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
.num-group select {
  position: relative;
  height: 100%;
  border: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.num-group input {
  border: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  border-left: 1px solid #ededed;
}`, "",{"version":3,"sources":["webpack://./src/app/components/delegations/phone-number-input/phone-number-input.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EAEA,8BAAA;AACJ","sourcesContent":[".num-group {\r\n  border: 1px solid #ededed;\r\n  font-size: 1.6rem;\r\n  line-height: 2.4rem;\r\n  font-weight: 500;\r\n  border-radius: 12px;\r\n  position: relative;\r\n  overflow: hidden;\r\n  select{\r\n    position: relative;\r\n    height: 100%;\r\n    border: none;\r\n    font-size: 1.6rem;\r\n    line-height: 2.4rem;\r\n    font-weight: 500;\r\n  }\r\n  input{\r\n    border: none;\r\n    font-size: 1.6rem;\r\n    line-height: 2.4rem;\r\n    font-weight: 500;\r\n    // eger xetti silmek istesen bunu silersen\r\n    border-left: 1px solid #ededed;\r\n\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
