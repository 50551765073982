import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrl: './table-settings.component.scss'
})
export class TableSettingsComponent {
  @Input() buttonText: string;
  @Input() isDisabled: boolean;
  @Output() viewClicked = new EventEmitter<void>();
  @Output() changeStatus = new EventEmitter<void>();

  onViewClick() {
    this.viewClicked.emit();
  }

  onChangeStatus() {
    this.changeStatus.emit();
  }

}
