// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picker {
  display: flex;
  width: 20rem;
  border: none;
  position: absolute;
}
.picker .picker-input {
  padding: 15px 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-right: none;
  border-radius: 4px 0 0 4px;
  font-size: 17px;
}
.picker .btn-picker {
  padding: 15px 20px;
  border-left: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  color: #333333;
  text-align: center;
  font-size: 17px;
}
.picker .btn-picker:hover {
  background-color: #e9e9ea;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/color-picker/color-picker.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,eAAA;EACD,WAAA;EACC,kBAAA;EACA,kBAAA;EACA,0BAAA;EACA,eAAA;AAEJ;AAAE;EACE,kBAAA;EACA,iBAAA;EACA,0BAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;AAEJ;AADI;EACE,yBAAA;AAGN","sourcesContent":[".picker {\n  display: flex;\n  width: 20rem;\n  border: none;;\n  position: absolute;\n  .picker-input {\n    padding: 15px 20px;\n    cursor: pointer;\n   width: 100%;\n    text-align: center;\n    border-right: none;\n    border-radius: 4px 0 0 4px;\n    font-size: 17px;\n  }\n  .btn-picker{\n    padding: 15px 20px;\n    border-left: none;\n    border-radius: 0 4px 4px 0;\n    cursor: pointer;\n    color:#333333;\n    text-align: center;\n    font-size: 17px;\n    &:hover{\n      background-color: #e9e9ea;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
