import { orderBy } from 'lodash';
import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { dateTimeFormat, sleep } from 'src/app/utils/utils';

import { ChangeNotificationInfo } from 'src/app/models/api/entities/change-notification-info';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { ChangeSubjectType } from 'src/app/models/api/enums/change-subject-type';
import { ChangeType } from 'src/app/models/api/enums/change-type';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/models/api/enums/user-role';
import { toDateTimeString } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-change-notifications-menu',
  templateUrl: './change-notifications-menu.component.html',
  styleUrls: ['./change-notifications-menu.component.scss']
})
export class ChangeNotificationsMenuComponent implements OnInit {

  isVisible: boolean;
  notifications: ChangeNotificationInfo[] = [];
  dates: string[] = [];

  constructor(
    private changeNotificationsService: ChangeNotificationsService,
    private entityMapService: EntityMapService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.changeNotificationsService.notificationsChange.subscribe(value => {
      value = orderBy(value, t => t.createdOn, "desc");
      this.notifications = value;
      this.resetDates();
    })

    this.changeNotificationsService.isNotificationsAvailableChange.subscribe(value => {
      this.isVisible = value;
    })
  }

  private resetDates() {
    const notifications = this.notifications;
    let dates: string[] = [];

    if (notifications != null || notifications.length > 0) {
      dates = notifications.map(t => moment(t.createdOn, dateTimeFormat).fromNow(true));
    }

    this.dates = dates;
  }

  //#region Data

  getPrettyDate(notification: ChangeNotificationInfo): string {
    const notifications = this.notifications;
    if (notification == null || notifications == null || notifications.length === 0) {
      return '';
    }

    const index = this.notifications.indexOf(notification);
    if (index < 0) {
      return '';
    }

    const date = this.dates[index];

    if (date == null) {
      return '';
    }

    return date;
  }

  getDate(notification: ChangeNotificationInfo): string {

    toDateTimeString(notification.createdOn);

    const notifications = this.notifications;
    if (notification == null || notifications == null || notifications.length === 0) {
      return '';
    }

    const index = this.notifications.indexOf(notification);
    if (index < 0) {
      return '';
    }

    const date = this.dates[index];

    if (date == null) {
      return '';
    }

    return toDateTimeString(notification.createdOn);;
  }

  getIconClass(notification: ChangeNotificationInfo): string {
    if (notification == null) {
      return '';
    }

    switch (notification.changeSubjectType) {
      case ChangeSubjectType.User:
        return 'fa-user-circle';

      case ChangeSubjectType.Person:
        return 'fa-user';

      case ChangeSubjectType.Event:
        return 'fa-calendar';
    }

    return '';
  }

  getChangeType(notification: ChangeNotificationInfo): string {
    if (notification == null) {
      return '';
    }
    return ChangeType[notification.changeType];
  }

  getChangeSubjectType(notification: ChangeNotificationInfo): string {
    if (notification == null) {
      return '';
    }
    return ChangeSubjectType[notification.changeSubjectType];
  }

  canNavigateToSubject(notification: ChangeNotificationInfo): boolean {
    return notification != null && notification.changeType !== ChangeType.Delete;
  }

  canHaveFlag(notification: ChangeNotificationInfo): boolean {
    if (notification == null || notification.sourceUser === null) {
      return false;
    }

    return notification.sourceUser.countryCode != null;
  }

  getFlagAddress(notification: ChangeNotificationInfo): string {
    if (!this.canHaveFlag(notification)) {
      return null;
    }
    const address = this.entityMapService.getCountryFlagAddress(notification.sourceUser.countryCode);
    return address;
  }

  getSourceUserDescription(notification: ChangeNotificationInfo): string {
    if (notification == null || notification.sourceUser === null) {
      return '';
    }

    const str = '[' + UserRole[notification.sourceUser.role] + '] ' + notification.sourceUser.surname + ' ' + notification.sourceUser.name;

    return str;
  }

  getSourceUserTooltip(notification: ChangeNotificationInfo): string {
    if (notification == null || notification.sourceUser === null) {
      return '';
    }

    let str = '';

    if (this.canHaveFlag(notification)) {
      str += '[' + notification.sourceUser.countryCode + '] ';
    }

    str += notification.sourceUser.username;

    return str;
  }

  //#endregion

  //#region Actions

  onHide() {
    this.changeNotificationsService.hideView();
  }

  onNavigateToSubject(notification: ChangeNotificationInfo) {
    if (notification == null) {
      return;
    }

    if (notification.changeType === ChangeType.Delete) {
      return;
    }

    const ids = notification.subjectId;

    this.onHide();

    let path: string = null;

    switch (notification.changeSubjectType) {
      case ChangeSubjectType.User:
        path = `/events/${ids[0]}/users/${ids[1]}`;
        break;

      case ChangeSubjectType.Event:
        path = `/events/${ids[0]}`;
        break;
      case ChangeSubjectType.Person:
        path = `/events/${ids[0]}/delegations/${ids[1]}/persons/${ids[2]}`;
        break;
      case ChangeSubjectType.Hotel:
        path = `/events/${ids[0]}/hotels/${ids[1]}`;
      case ChangeSubjectType.HotelRoom:
        path = `/events/${ids[0]}/hotels/${ids[1]}/rooms/${ids[2]}`;
        break;
      case ChangeSubjectType.HotelRoomReservation:
        path = `/events/${ids[0]}/delegations/${ids[1]}/hotel-reservations/${ids[4]}`;
        break;
      case ChangeSubjectType.MealVenue:
        path = `/events/${ids[0]}/meal-venues/${ids[1]}`;
        break;
      case ChangeSubjectType.MealReservation:
        path = `/events/${ids[0]}/delegations/${ids[1]}/meal-reservations/${ids[2]}`;
        break;
      case ChangeSubjectType.Flight:
        path = `/events/${ids[0]}/delegations/${ids[1]}/flights/${ids[2]}`;
        break;
      case ChangeSubjectType.FlightDetail:
        path = `/events/${ids[0]}/delegations/${ids[1]}/flight-reservations/${ids[2]}`;
        break;
    }

    if (path != null) {
      this.router.navigate([path]);
    }

  }

  onRemoveNotification(notification: ChangeNotificationInfo) {
    if (notification == null) {
      return;
    }

    this.changeNotificationsService.removeNotifications([notification]);
  }

  onRemoveAllNotifications() {
    if (this.notifications == null || this.notifications.length === 0) {
      return;
    }

    this.onHide();

    this.changeNotificationsService.removeNotifications(this.notifications);
  }

  //#endregion

}
