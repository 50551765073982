import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgOption, NgSelectModule } from '@ng-select/ng-select';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isNullOrUndefined, toMoment } from 'src/app/utils/utils';

import { ApiService } from 'src/app/services/api.service';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { ChangeSubjectType } from 'src/app/models/api/enums/change-subject-type';
import { Country } from 'src/app/models/api/entities/country';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelInfo } from 'src/app/models/api/entities/hotel-info';
import { HotelNightsCounterPipe } from './../../../pipes/hotel-nights-counter.pipe';
import { HotelRoomInfo } from 'src/app/models/api/entities/hotel-room-info';
import { HotelRoomReservationInfo } from 'src/app/models/api/entities/hotel-room-reservation-info';
import { PenaltyType } from './../../../models/api/enums/penalty-type';
import { RoomType } from 'src/app/models/api/enums/room-type';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { deepClone, toDate, toDateString } from 'src/app/shared/utils/utils';

export interface RangeCheck {
  IsOutOfRangeCheckIn: boolean;
  IsAggreeWithRangeCheckIn: boolean;
  IsOutOfRangeCheckOut: boolean;
  IsAggreeWithRangeCheckOut: boolean;
}

export enum AggreeType {
  CheckIn,
  CheckOut
}

@Component({
  selector: 'app-hotel-reservation',
  templateUrl: './hotel-reservation.component.html',
  styleUrls: ['./hotel-reservation.component.scss']
})
export class HotelReservationComponent implements AfterViewInit {

  RoomType = RoomType;

  @Input() evt: EventInfo;
  @Input() country: Country;

  @Input() reservationId: 0;
  @Input() isLocOrAdmin: boolean = false;

  @Input() clubShortName: string = null;

  @Output() saved = new EventEmitter<HotelRoomReservationInfo>();
  @Output() closed = new EventEmitter<HotelRoomReservationInfo>();

  @ViewChild('reservationModal', { static: true }) private reservationModal: TemplateRef<any>;
  private reservationModalRef: NgbModalRef;

  allHotels: HotelInfo[];
  allRooms: HotelRoomInfo[];

  hotel: HotelInfo;
  room: HotelRoomInfo;

  reservation: HotelRoomReservationInfo;

  isLateBookingStarted: boolean = false;

  minDate: string;
  maxDate: string;

  checkInDate: string;
  checkOutDate: string;

  isNew: boolean;

  isVisiblle: boolean;
  isReadOnly: boolean;

  penaltyType: PenaltyType = PenaltyType.Accommodation;
  isAgreeWithCancellationPolicy: boolean = false;

  availableQuantity: number = 0;
  minQuantity: number = 0;

  messageService: MessageService;

  rangeCheck: RangeCheck = {
    IsOutOfRangeCheckIn: false,
    IsAggreeWithRangeCheckIn: false,
    IsOutOfRangeCheckOut: false,
    IsAggreeWithRangeCheckOut: false,
  }

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private changeNotificationsService: ChangeNotificationsService,
    private alertService: AlertService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.country.code);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }

    this.minDate = toDateString(toMoment(this.evt.startDate).add(-10, 'month').toDate());
    this.maxDate = toDateString(toMoment(this.evt.endDate).add(10, 'month').toDate());


    if (this.evt.features.isHotelRoomLatePerNightPriceEnabled) {

      const lateStartDate = toDate(this.evt.deadlines.accommodationLateBookingStartDate);

      const today = new Date();

      if (today > lateStartDate) {
        this.isLateBookingStarted = true;
      }

    }

    if (this.evt.features.isPenaltyFunctionEnabled)
      this.isAgreeWithCancellationPolicy = false;

    if (this.reservationId === 0) {
      this.newReservation();
    } else {
      this.loadReservation();
    }
  }

  private async newReservation() {

    const hotels = await this.apiService.getHotels(false, -1, -1, null, this.evt.id);
    this.allHotels = hotels.items;

    if (this.allHotels.length === 0) {
      this.messageService.setToastMessage(false, 'There is no hotel available in this event.', 3);
      this.close();
      return;
    }

    const hotel = this.allHotels[0];

    const rooms = await this.apiService.getHotelRooms(false, -1, -1, null, hotel.id);

    this.allRooms = rooms.items;

    if (this.allRooms.length === 0) {
      this.messageService.setToastMessage(false, 'There is no room available in this hotel.', 3);
      this.close();
      return;
    }

    const room = this.allRooms[0];

    this.hotel = hotel;
    this.room = room;

    this.isNew = true;

    this.checkInDate = this.evt.deadlines.accomodationRangeStartDate;
    this.checkOutDate = this.evt.deadlines.accommodationRangeEndDate;

    //console.log('this.evt.deadlines.accomodationRangeStartDate :>> ', this.evt.deadlines.accomodationRangeStartDate);
    //console.log('this.checkInDate :>> ', this.checkInDate);
    //console.log('this.evt.deadlines.accommodationRangeEndDate :>> ', this.evt.deadlines.accommodationRangeEndDate);
    //console.log('this.checkOutDate :>> ', this.checkOutDate);

    if (this.room.totalQuantity === 0)
      this.availableQuantity = 66666;

    if (this.room.totalQuantity !== 0)
      this.availableQuantity = this.room.availableQuantity;

    this.minQuantity = 0;

    this.reservation = {
      id: 0,
      roomId: this.room.id,
      eventId: this.evt.id,
      countryCode: this.country.code,
      checkInDate: toDateString(this.checkInDate),
      checkOutDate: toDateString(this.checkOutDate),
      quantity: 0,
      discount: 0,
      isLateRegistration: this.isLateBookingStarted,
      isEarlyCheckIn: false,
      isLateCheckOut: false,
      personIds: [],
      updatedDate: null,
      isAgreeWithCancellationPolicy: this.isAgreeWithCancellationPolicy
    };

    if(this.clubShortName !== null)
      this.reservation.clubShortName = this.clubShortName;

    this.open();
  }

  private async loadReservation() {
    if (this.isVisiblle) {
      this.close();
    }

    if (!this.isLocOrAdmin && this.isLateBookingStarted) {
      this.close();
      return;
    }

    this.isNew = false;

    const reservation = await this.apiService.getHotelRoomReservation(this.reservationId);

    if (reservation == null) {
      this.close();
      return;
    }

    const hotels = await this.apiService.getHotels(false, -1, -1, null, this.evt.id);

    let allHotels = hotels.items.filter(t => t.id !== reservation.room.hotel.id);

    allHotels = [reservation.room.hotel, ...allHotels];

    const rooms = await this.apiService.getHotelRooms(false, -1, -1, null, reservation.room.hotel.id);

    let allRooms = rooms.items.filter(t => t.id !== reservation.room.id);

    allRooms = [reservation.room, ...allRooms];

    this.allHotels = allHotels;
    this.allRooms = allRooms;

    this.hotel = reservation.room.hotel;
    this.room = reservation.room;

    if (isNullOrUndefined(reservation.discount)) {
      reservation.discount = 0;
    }

    this.reservation = reservation;

    this.availableQuantity = reservation.room.availableQuantity;
    this.minQuantity = 0;

    if (reservation.room.totalQuantity === 0)
      this.availableQuantity = 66666;

    if (reservation.room.totalQuantity !== 0)
      this.availableQuantity = reservation.room.availableQuantity;

    this.checkInDate = this.reservation.checkInDate;
    this.checkOutDate = this.reservation.checkOutDate;
    this.isAgreeWithCancellationPolicy = this.reservation.isAgreeWithCancellationPolicy;

    this.changeNotificationsService.removeNotificationsByQuery(ChangeSubjectType.HotelRoomReservation, [reservation.id.toString()]);

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.reservationModalRef != null) {
      this.reservationModalRef.close();
      this.reservationModalRef = null;
    }
    this.reservationModalRef = this.modalService.open(this.reservationModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.reservationModalRef != null) {
      this.reservationModalRef.close();
      this.reservationModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.reservation);
  }

  private async save() {

    const reservation: HotelRoomReservationInfo = deepClone(this.reservation);

    if (!this.reservation.quantity)
    {
      this.reservation.quantity = 0;
    }
    reservation.roomId = this.room.id;
    reservation.checkInDate = toDateString(this.checkInDate);
    reservation.checkOutDate = toDateString(this.checkOutDate);

    this.nightsRangeCheck(reservation.checkInDate, reservation.checkOutDate);

    if (this.rangeCheck.IsOutOfRangeCheckIn && !this.rangeCheck.IsAggreeWithRangeCheckIn){
      this.rangeAlert(AggreeType.CheckIn, "You are out of check-in range date.");
      return;
    }

    if (this.rangeCheck.IsOutOfRangeCheckOut && !this.rangeCheck.IsAggreeWithRangeCheckOut){
      this.rangeAlert(AggreeType.CheckOut, "You are out of check-out range date.");
      return;
    }

//#region quantity

    // if (this.availableQuantity !== 66666 && this.room.totalQuantity !== 0 && this.room.availableQuantity === 0) {
    //   this.messageService.setToastMessage(false, "This hotel room are fully booked. Please Select another.");
    //   return;
    // }

    // console.log(this.room.totalQuantity);
    // console.log(this.room.availableQuantity);
    // console.log(this.availableQuantity);
    // console.log(this.minQuantity);
    // console.log(this.reservation.quantity);

    if (this.room.totalQuantity !== 0 && this.room.availableQuantity <= 0) {
      this.messageService.setToastMessage(false, "This hotel room are fully booked. Please Select another.");
      return;
    }

    if (this.reservation.quantity > this.room.availableQuantity) {
      this.messageService.setToastMessage(false, `Please fill correct quantity. Only ${this.room.availableQuantity} rooms are available` );
      return;
    }

//#endregion

    if (this.evt.features.isPenaltyFunctionEnabled && !this.isAgreeWithCancellationPolicy) {
      this.messageService.setToastMessage(false, "Please agree with cancellation policy.");
      return;
    }

    if (this.reservation.quantity <= 0) {
      this.reservation.quantity = 0;
      this.messageService.setToastMessage(false, "Please fill correct quantity.");
      return;
    }

    if (this.evt.features.isPenaltyFunctionEnabled) {
      reservation.isAgreeWithCancellationPolicy = this.isAgreeWithCancellationPolicy;
    }

    if(this.clubShortName !== null)
      reservation.clubShortName = this.clubShortName;

    const request: UpdateRequest<HotelRoomReservationInfo> = {
      isNew: this.isNew,
      subject: reservation
    };

    const result = await this.apiService.updateHotelRoomReservation(request);
    if (result.isSuccess) {
      this.saved.emit(this.reservation);
      this.messageService.setToastMessage(true, this.isNew ? 'Room reserved' : 'Rooming list updated', 7);
      this.close();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage);
    }
  }

  private async updateRooms() {
    const rooms = await this.apiService.getHotelRooms(false, -1, -1, null, this.hotel.id);
    let allRooms = rooms.items;
    if (allRooms.some(t => t.id === this.room.id)) {
      allRooms = allRooms.filter(t => t.availableQuantity > 0 && t.id !== this.room.id);
      allRooms = [this.room, ...allRooms];
    } else if (allRooms.length > 0) {
      this.room = allRooms[0];
    }
    this.allRooms = allRooms;
    this.updateReservation();
  }

  private updateReservation() {
    if (this.room.totalQuantity === 0)
      this.availableQuantity = 66666;

    if (this.room.totalQuantity !== 0)
      this.availableQuantity = this.room.availableQuantity;

    this.minQuantity = 0;

    this.reservation.quantity = 0;

    if (this.reservation.isEarlyCheckIn) {
      if (
        !this.evt.features.isHotelRoomEarlyCheckInAddPriceEnabled ||
        this.room.earlyCheckInAddPrice === 0
      ) {
        this.reservation.isEarlyCheckIn = false;
      }
    }
    if (this.reservation.isLateCheckOut) {
      if (
        !this.evt.features.isHotelRoomLateCheckOutAddPriceEnabled ||
        this.room.lateCheckOutAddPrice === 0
      ) {
        this.reservation.isLateCheckOut = false;
      }
    }

    // if (this.room.totalQuantity === 0)
    //   this.availableQuantity = 66666;

    if (this.room.totalQuantity !== 0)
      this.availableQuantity = this.room.availableQuantity;

    this.minQuantity = 0;
  }

  nightsRangeCheck(
    startDate: string,
    endDate: string
  ) {

    const hotelNightsCounterPipe = new HotelNightsCounterPipe();

    const countCheckIn: number = hotelNightsCounterPipe.transform(this.evt.deadlines.accomodationRangeStartDate, startDate);
    const countCheckOut: number = hotelNightsCounterPipe.transform(endDate, this.evt.deadlines.accommodationRangeEndDate);

    if (countCheckIn < 0)
      this.rangeCheck.IsOutOfRangeCheckIn = true;

    if (countCheckOut < 0)
      this.rangeCheck.IsOutOfRangeCheckOut = true;

  }

  rangeAlert(aggreeType: AggreeType, message: string): void {
    this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        switch (aggreeType) {
          case AggreeType.CheckIn:
            this.rangeCheck.IsAggreeWithRangeCheckIn = true;
            break;
          case AggreeType.CheckOut:
            this.rangeCheck.IsAggreeWithRangeCheckOut = true;
            break;
        }
      }
    });
  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  onHotelChanged() {
    this.updateRooms();
  }

  onRoomChanged() {
    this.updateReservation();
  }

  onEarlyCheckInChange(event: any) {
    this.reservation.isEarlyCheckIn = event;
  }

  onLateCheckOutChange(event: any) {

    this.reservation.isLateCheckOut = event;
  }


  //#endregion

}
