import { UserService } from './services/user.service';

import { BrowserModule, Title } from '@angular/platform-browser';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatTabsModule} from '@angular/material/tabs';
import { AccountComponent } from './components/account/account.component';
import { ApiService } from './services/api.service';
import { AppComponent } from './components/app/app.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { BreadcrumbsService } from './services/breadcrumbs.service';
import { ChangeNotificationsMenuComponent } from './components/change-notifications-menu/change-notifications-menu.component';
import { ChangeNotificationsService } from './services/change-notifications.service';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { DelegationComponent } from './components/delegations/delegation/delegation.component';
import { DelegationOverviewComponent } from './components/delegations/delegation-overview/delegation-overview.component';
import { DelegationPipesModule } from './pipes/delegation-pipes.module';
import { DelegationSectionButtonComponent } from './components/delegations/delegation-section-button/delegation-section-button.component';
import { DelegationsListComponent } from './components/delegations/delegations-list/delegations-list.component';
import { DisciplineComponent } from './components/disciplines/discipline/discipline.component';
import { DisciplinesListComponent } from './components/disciplines/disciplines-list/disciplines-list.component';
import { DocumentsComponent } from './components/documents/documents/documents.component';
import { DocumentsListComponent } from './components/documents/documents-list/documents-list.component';
import { EntityMapService } from './services/entity-map.service';
import { EventComponent } from './components/events/event/event.component';
import { EventNewComponent } from './components/events/event-new/event-new.component';
import { EventSettingsComponent } from './components/events/event-settings/event-settings.component';
import { EventsListComponent } from './components/events/events-list/events-list.component';
import { ExportComponent } from './components/events/export/export.component';
import { FlightPassengerComponent } from './components/flights/flight-passenger/flight-passenger.component';
import { FlightPassengersListComponent } from './components/flights/flight-passengers-list/flight-passengers-list.component';
import { FlightReservationsComponent } from './components/flights/flight-reservations/flight-reservations.component';
import { FlightReservationsListComponent } from './components/flights/flight-reservations-list/flight-reservations-list.component';
import { FlightsComponent } from './components/flights/flights/flights.component';
import { FlightsListComponent } from './components/flights/flights-list/flights-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { HotelComponent } from './components/hotels/hotel/hotel.component';
import { HotelReservationComponent } from './components/hotels/hotel-reservation/hotel-reservation.component';
import { HotelReservationsListComponent } from './components/hotels/hotel-reservations-list/hotel-reservations-list.component';
import { HotelRoomComponent } from './components/hotels/hotel-room/hotel-room.component';
import { HotelRoomReservationComponent } from './components/hotels/hotel-room-reservation/hotel-room-reservation.component';
import { HotelRoomReservationsListComponent } from './components/hotels/hotel-room-reservations-list/hotel-room-reservations-list.component';
import { HotelRoomsListComponent } from './components/hotels/hotel-rooms-list/hotel-rooms-list.component';
import { HotelsListComponent } from './components/hotels/hotels-list/hotels-list.component';
import { HttpClientModule } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoginComponent } from './components/login/login.component';
import { MailboxComponent } from './components/events/mail/mailbox/mailbox.component';
import { MealReservationsComponent } from './components/meals/meal-reservations/meal-reservations.component';
import { MealReservationsListComponent } from './components/meals/meal-reservations-list/meal-reservations-list.component';
import { MealVenueComponent } from './components/meals/meal-venue/meal-venue.component';
import { MealVenueListComponent } from './components/meals/meal-venue-list/meal-venue-list.component';
import { MusicComponent } from './components/music/music/music.component';
import { MusicListComponent } from './components/music/music-list/music-list.component';
import { APP_ID, NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { OverviewComponent } from './components/events/overview/overview.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PersonsComponent } from './components/persons/persons/persons.component';
import { PersonsListComponent } from './components/persons/persons-list/persons-list.component';
import { PhotoUploadComponent } from './components/documents/photo-upload/photo-upload.component';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { SettingsComponent } from './components/settings/settings.component';
import { UnitMemberComponent } from './components/units/unit-member/unit-member.component';
import { UnitsComponent } from './components/units/units/units.component';
import { UnitsListComponent } from './components/units/units-list/units-list.component';
import { UserComponent } from './components/users/user/user.component';
import { UsersListComponent } from './components/users/users-list/users-list.component';
import { AnthemUploadComponent } from './components/delegations/anthem-upload/anthem-upload.component';
import { MusicPlayerComponent } from './components/music/music-player/music-player.component';
import { SpecialExportComponent } from './components/events/special-export/special-export.component';
import { PaymentTrackerComponent } from './components/events/payment-tracker/payment-tracker.component';
import { MissedInformationsComponent } from './components/events/missed-informations/missed-informations.component';
import { PenaltiesListComponent } from './components/events/penalties/penalties-list.component';
import { PenaltyComponent } from './components/events/penalties/penalty/penalty.component';
import { ManagePaymentComponent } from './components/events/payment-tracker/manage-payment/manage-payment.component';
import { SpecialExportDetailComponent } from './components/events/special-export/special-export-detail/special-export-detail.component';
import { SpecialExportDetailAccomodationComponent } from './components/events/special-export/special-export-detail-accomodation/special-export-detail-accomodation.component';
import { SpecialExportDetailMealComponent } from './components/events/special-export/special-export-detail-meal/special-export-detail-meal.component';
import { SpecialExportDetailRoomingComponent } from './components/events/special-export/special-export-detail-rooming/special-export-detail-rooming.component';
import { AccomodationsListComponent } from './components/events/special-export/accomodations-list/accomodations-list.component';
import { RoomingsListComponent } from './components/events/special-export/roomings-list/roomings-list.component';
import { MealVenuesListComponent } from './components/events/special-export/meal-venues-list/meal-venues-list.component';
import { GroupComponent } from './components/persons/group/group.component';
import { GroupsManageComponent } from './components/persons/groups-manage/groups-manage.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { Covid19Component } from './components/covid19/covid19.component';
import { SpecialDeadlinesComponent } from './components/delegations/special-deadlines/special-deadlines.component';
import { AgreementComponent } from './components/events/penalties/agreement/agreement.component';
import { PersonEntryFeeComponent } from './components/person-entry-fee/person-entry-fee.component';
import { AuthLayoutComponent } from './components/auth/auth-layout/auth-layout.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { AuthContactFormComponent } from './components/auth/auth-contact-form/auth-contact-form.component';
import { AuthHomeComponent } from './components/auth/auth-home/auth-home.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { HomeDefaultComponent } from './components/home/home-default/home-default.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { EventContainerComponent } from './components/events/event-container/event-container.component';
import { TableSettingsComponent } from './components/table-settings/table-settings.component';
import { PhoneNumberInputComponent } from './components/delegations/phone-number-input/phone-number-input.component';
import { AlertComponent } from './components/alert/alert.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { LoaderService } from './shared/services/loader.service';
import { AlertService } from './shared/services/alert.service';
import { MessageService } from './shared/services/message.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
// eslint-disable-next-line max-len
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    BreadcrumbsComponent,
    NavigationComponent,

    //Authentication
    AuthLayoutComponent,
    AuthContactFormComponent,
    AuthHomeComponent,

    PageNotFoundComponent,
    LoginComponent,
    AccountComponent,

    DisciplinesListComponent,
    DisciplineComponent,

    ChangeNotificationsMenuComponent,
    ContactFormComponent,
    SettingsComponent,
    TermsOfUseComponent,

    // Event related

    EventsListComponent,
    EventNewComponent,
    EventComponent,
    EventSettingsComponent,
    ExportComponent,
    SpecialExportComponent,
    SpecialExportDetailComponent,
    SpecialExportDetailAccomodationComponent,
    SpecialExportDetailMealComponent,
    SpecialExportDetailRoomingComponent,
    AccomodationsListComponent,
    RoomingsListComponent,
    MealVenuesListComponent,
    OverviewComponent,
    PaymentTrackerComponent,
    ManagePaymentComponent,
    MissedInformationsComponent,
    PenaltiesListComponent,
    PenaltyComponent,

    UsersListComponent,
    UserComponent,

    HotelsListComponent,
    HotelComponent,
    HotelRoomsListComponent,
    HotelRoomComponent,

    MealVenueListComponent,
    MealVenueComponent,

    // Delegation related

    DelegationsListComponent,
    DelegationComponent,
    DelegationSectionButtonComponent,
    DelegationOverviewComponent,
    SpecialDeadlinesComponent,

    HotelReservationsListComponent,
    HotelReservationComponent,
    MealReservationsComponent,
    MealReservationsListComponent,
    PersonsComponent,
    PersonsListComponent,
    HotelRoomReservationsListComponent,
    HotelRoomReservationComponent,
    DocumentsComponent,
    DocumentsListComponent,
    PhotoUploadComponent,

    // Flights

    FlightsComponent,
    FlightsListComponent,

    FlightReservationsComponent,
    FlightReservationsListComponent,
    FlightPassengerComponent,
    FlightPassengersListComponent,

    // Units

    UnitsComponent,
    UnitsListComponent,
    UnitMemberComponent,

    // Music

    MusicListComponent,
    MusicComponent,
    MusicPlayerComponent,
    AnthemUploadComponent,


    //Mail
    MailboxComponent,
    GroupComponent,
    GroupsManageComponent,
    Covid19Component,
    PersonEntryFeeComponent,
    AgreementComponent,
    NotificationsComponent,
    HomeDefaultComponent,
    PaginationComponent,
    DateInputComponent,
    EventContainerComponent,
    TableSettingsComponent,
    PhoneNumberInputComponent,
    AlertComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    EditorModule,
    CommonModule,
    RouterModule,
    MatTabsModule,
    FormsModule,
    NgbModule,
    NgbModalModule,
    FontAwesomeModule,
    ImageCropperModule,
    NgSelectModule,
    DelegationPipesModule,
    SharedModule,
    ToastrModule.forRoot(),
    CdkDrag,
    CdkDragHandle,
    RouterModule.forRoot(
      [
      { path: 'auth', component: AuthLayoutComponent,
        children: [
          { path: '', component: AuthHomeComponent },
          { path: 'login', component: LoginComponent},
          { path: 'terms-of-use', component: TermsOfUseComponent},
          { path: 'contact', component: AuthContactFormComponent}
        ]
      },

      { path: '', component: HomeComponent,
        children: [
          {path: '', pathMatch: 'full', component: HomeDefaultComponent},
          { path: 'contact', component: ContactFormComponent },
          { path: 'account', component: AccountComponent },
          { path: 'disciplines', component: DisciplinesListComponent },
          { path: 'settings', component: SettingsComponent },
          { path: 'notifications', component: NotificationsComponent},
          { path: 'terms-of-use', component: TermsOfUseComponent },

          //Discipline related
          { path: 'disciplines/:disciplineType', component: DisciplineComponent },


          // Event related
          { path: 'events', component: EventContainerComponent ,
            children: [
              {path: '', component: EventsListComponent, pathMatch: 'full'},
              { path: 'new', component: EventNewComponent },
              { path: ':eventId', component: EventComponent,
                children:[
                  { path: '', redirectTo: 'delegations', pathMatch: 'full'},
                  { path: 'settings', component: EventSettingsComponent },

                  { path: 'users', component: UsersListComponent },
                  { path: 'users/:username', component: UsersListComponent },

                  { path: 'hotels', component: HotelsListComponent },
                  { path: 'hotels/new', component: HotelComponent, data: { isNewHotel: true } },
                  { path: 'hotels/:hotelId', component: HotelComponent },
                  { path: 'hotels/:hotelId/rooms/:roomId', component: HotelComponent },

                  { path: 'meal-venues', component: MealVenueListComponent },
                  { path: 'meal-venues/:venueId', component: MealVenueListComponent },

                  { path: 'export', component: ExportComponent },
                  { path: 'special-export', component: SpecialExportComponent },
                  { path: 'special-export/:detailId/detail', component: SpecialExportDetailComponent },
                  { path: 'special-export/:detailId/detail/:typeDetail', component: SpecialExportDetailComponent },
                  { path: 'payment-tracker', component: PaymentTrackerComponent },
                  { path: 'payment-tracker/:trackId', component: PaymentTrackerComponent },
                  { path: 'missed-informations', component: MissedInformationsComponent },
                  { path: 'penalties', component: PenaltiesListComponent },
                  { path: 'penalties/:penaltyId', component: PenaltiesListComponent },
                  { path: 'overview', component: OverviewComponent },

                  { path: 'mailbox', component: MailboxComponent },

                  // Delegation related
                  { path: 'delegations', component: DelegationsListComponent },
                  { path: 'delegations/special-deadlines/:countryCode', component: DelegationsListComponent },
                  { path: 'delegations/:countryCode', component: DelegationComponent },

                  { path: 'delegations/:countryCode/overview', component: DelegationOverviewComponent },

                  { path: 'delegations/:countryCode/hotel-reservations', component: HotelReservationsListComponent },
                  { path: 'delegations/:countryCode/hotel-reservations/:hotelReservationId', component: HotelReservationsListComponent },
                  { path: 'delegations/:countryCode/hotel-reservations/rooming/:hotelReservationId', component: HotelReservationsListComponent },

                  { path: 'delegations/:countryCode/room-reservations', component: HotelRoomReservationsListComponent },
                  { path: 'delegations/:countryCode/room-reservations/:roomReservationId', component: HotelRoomReservationsListComponent },

                  { path: 'delegations/:countryCode/meal-reservations', component: MealReservationsListComponent },
                  { path: 'delegations/:countryCode/meal-reservations/:mealReservationId', component: MealReservationsListComponent },

                  { path: 'delegations/:countryCode/persons', component: PersonsListComponent },
                  { path: 'delegations/:countryCode/persons/:PersonId', component: PersonsListComponent },
                  { path: 'delegations/:countryCode/persons/group/:groupId', component: PersonsListComponent },
                  { path: 'delegations/:countryCode/persons/manage/group', component: GroupsManageComponent },

                  { path: 'delegations/:countryCode/documents', component: DocumentsListComponent },
                  { path: 'delegations/:countryCode/documents/:documentId', component: DocumentsListComponent },
                  { path: 'delegations/:countryCode/documents/:documentId/photo-upload', component: DocumentsListComponent },

                  { path: 'delegations/:countryCode/flights', component: FlightsListComponent },
                  { path: 'delegations/:countryCode/flights/:flightId', component: FlightsListComponent },

                  { path: 'delegations/:countryCode/flight-reservations', component: FlightReservationsListComponent },
                  { path: 'delegations/:countryCode/flight-reservations/:flightId', component: FlightReservationsComponent },
                  { path: 'delegations/:countryCode/flight-reservations/:flightId/passenger', component: FlightPassengerComponent },

                  { path: 'delegations/:countryCode/music', component: MusicListComponent },
                  { path: 'delegations/:countryCode/music/:musicId', component: MusicListComponent },


                  // With club
                  { path: 'delegations/:countryCode/:clubShortName', component: DelegationComponent },

                  { path: 'delegations/:countryCode/:clubShortName/overview', component: DelegationOverviewComponent },

                  { path: 'delegations/:countryCode/:clubShortName/hotel-reservations', component: HotelReservationsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/hotel-reservations/:hotelReservationId', component: HotelReservationsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/hotel-reservations/rooming/:hotelReservationId', component: HotelReservationsListComponent },

                  { path: 'delegations/:countryCode/:clubShortName/room-reservations', component: HotelRoomReservationsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/room-reservations/:roomReservationId', component: HotelRoomReservationsListComponent },

                  { path: 'delegations/:countryCode/:clubShortName/meal-reservations', component: MealReservationsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/meal-reservations/:mealReservationId', component: MealReservationsListComponent },

                  { path: 'delegations/:countryCode/:clubShortName/persons', component: PersonsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/persons/:PersonId', component: PersonsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/persons/group/:groupId', component: PersonsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/persons/manage/group', component: GroupsManageComponent },

                  { path: 'delegations/:countryCode/:clubShortName/documents', component: DocumentsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/documents/:documentId', component: DocumentsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/documents/:documentId/photo-upload', component: DocumentsListComponent },

                  { path: 'delegations/:countryCode/:clubShortName/flights', component: FlightsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/flights/:flightId', component: FlightsListComponent },

                  { path: 'delegations/:countryCode/:clubShortName/flight-reservations', component: FlightReservationsListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/flight-reservations/:flightId', component: FlightReservationsComponent },
                  { path: 'delegations/:countryCode/:clubShortName/flight-reservations/:flightId/passenger', component: FlightPassengerComponent },

                  { path: 'delegations/:countryCode/:clubShortName/music', component: MusicListComponent },
                  { path: 'delegations/:countryCode/:clubShortName/music/:musicId', component: MusicListComponent },
                ]
              },
            ]
          },
        ]
      },
      { path: '**', component: PageNotFoundComponent }
    ]),
    BrowserAnimationsModule
  ],
  providers: [
    Title,
    LoaderService,
    EntityMapService,
    ApiService,
    UserService,
    ChangeNotificationsService,
    BreadcrumbsService,
    AlertService,
    MessageService,
    { provide: APP_ID, useValue: 'ng-cli-universal' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
