import { MomentInput } from "moment";
import { toDate, toMoment } from "../shared/utils/utils";

export class DateStatus {

  public startDate: MomentInput;
  public endDate: MomentInput;

  public isFeatureEnabled: boolean = false;

  public featureStartDate: MomentInput;
  public featureEndDate: MomentInput;

  public specialStartDate: MomentInput;
  public specialEndDate: MomentInput;
  public isSpecialDateEnabled: boolean;

  public bufferDaysForStart: number = 1;
  public bufferDaysForEnd: number = 1;

  today = new Date();

  getStatus(): DateStatuses {

    const startDate = toDate(this.startDate);
    const endDate = toDate(this.endDate);

    const bufferBeforeStart = toDate(toMoment(startDate).add(-this.bufferDaysForStart, 'days'));
    const bufferBeforeEnd = toDate(toMoment(endDate).add(-this.bufferDaysForEnd, 'days'));

    const specialStartDate = toDate(this.specialStartDate);
    const specialEndDate = toDate(this.specialEndDate);

    let specialBufferBeforeStart: Date;
    let specialBufferBeforeEnd: Date;

    if(specialStartDate !== null && specialEndDate !== null){
      specialBufferBeforeStart = toDate(toMoment(specialStartDate).add(-this.bufferDaysForStart, 'days'));
      specialBufferBeforeEnd = toDate(toMoment(specialEndDate).add(-this.bufferDaysForEnd, 'days'));
    }


    const today = this.today;
    let returnStatus: DateStatuses;

    switch (this.isFeatureEnabled) {
      case true:

        const featureStartDate = toDate(this.featureStartDate);
        const featureEndDate = toDate(this.featureEndDate);

        const featureBufferBeforeStart = toDate(toMoment(featureStartDate).add(-this.bufferDaysForStart, 'days'));
        const featureBufferBeforeEnd = toDate(toMoment(featureEndDate).add(-this.bufferDaysForEnd, 'days'));

        if (today > endDate || today > featureEndDate) {
          returnStatus = DateStatuses.Ended;
          if (today > featureEndDate) {
            returnStatus = DateStatuses.FeatureEnded;
          }
        }

        if (today < startDate || endDate < featureStartDate && today < featureStartDate) {
          returnStatus = DateStatuses.NotStarted;

          if (endDate < featureStartDate && today < featureStartDate) {
            returnStatus = DateStatuses.FeatureNotStarted;
          }

          if (today > bufferBeforeStart && today < startDate || endDate <= featureStartDate && today > featureBufferBeforeStart && today < featureStartDate) {
            returnStatus = DateStatuses.StartsSoon;

            if (endDate <= featureStartDate && today > featureBufferBeforeStart && today < featureStartDate) {
              returnStatus = DateStatuses.FeatureStartsSoon;
            }
          }
        }

        if (today >= startDate && today <= endDate || today > endDate && today >= featureStartDate && today <= featureEndDate) {
          returnStatus = DateStatuses.OnGoing;

          if (today > endDate && today >= featureStartDate && today <= featureEndDate) {
            returnStatus = DateStatuses.FeatureOnGoing;
          }

          if (today > bufferBeforeEnd && today < endDate || today > endDate && today > featureBufferBeforeEnd && today < featureEndDate) {
            returnStatus = DateStatuses.EndsSoon;

            if (today > endDate && today > featureBufferBeforeEnd && today < featureEndDate) {
              returnStatus = DateStatuses.FeatureEndsSoon;
            }
          }
        }

        if (returnStatus === DateStatuses.Ended || DateStatuses.FeatureEnded) {

          if (specialEndDate !== null && specialEndDate.getFullYear() !== 1 && this.isSpecialDateEnabled) {

            if (today > specialEndDate) {
              returnStatus = DateStatuses.SpecialDateEnded
            }

            if (today < specialStartDate) {
              returnStatus = DateStatuses.SpecialDateNotStarted;
            }

            if (today >= specialStartDate && today <= specialEndDate) {
              returnStatus = DateStatuses.SpecialDateOnGoing;

              if (today > specialBufferBeforeEnd && today < specialEndDate) {
                returnStatus = DateStatuses.SpecialDateEndsSoon;
              }
            }

          }

        }

        break;

      default:

        if (today > endDate) {
          returnStatus = DateStatuses.Ended;
        }

        if (today < startDate) {
          returnStatus = DateStatuses.NotStarted;
          if (today > bufferBeforeStart && today < startDate) {
            returnStatus = DateStatuses.StartsSoon;
          }
        }

        if (today >= startDate && today <= endDate) {
          returnStatus = DateStatuses.OnGoing;
          if (today > bufferBeforeEnd && today < endDate) {
            returnStatus = DateStatuses.EndsSoon;
          }
        }

        if (returnStatus === DateStatuses.Ended) {

          if (specialEndDate !== null && specialEndDate.getFullYear() !== 1 && this.isSpecialDateEnabled) {

            if (today > specialEndDate) {
              returnStatus = DateStatuses.SpecialDateEnded
            }

            if (today < specialStartDate) {
              returnStatus = DateStatuses.SpecialDateNotStarted;
            }

            if (today >= specialStartDate && today <= specialEndDate) {
              returnStatus = DateStatuses.SpecialDateOnGoing;

              if (today > specialBufferBeforeEnd && today < specialEndDate) {
                returnStatus = DateStatuses.SpecialDateEndsSoon;
              }
            }

          }

        }

        break;
    }

    return returnStatus;
  }

}


export enum DateStatuses {
  NotStarted = 0,
  FeatureNotStarted,
  StartsSoon,
  FeatureStartsSoon,
  OnGoing,
  FeatureOnGoing,
  EndsSoon,
  FeatureEndsSoon,
  SpecialDateNotStarted,
  SpecialDateOnGoing,
  SpecialDateEndsSoon,
  SpecialDateEnded,
  Ended,
  FeatureEnded,
}
