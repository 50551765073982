// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th,
td {
  text-align: center;
  white-space: nowrap;
}

th {
  border-right: 1px solid #dee2e6;
}

th:last-child {
  border-right: 0;
}

.bd-top {
  border-top: 1.6px solid #dee2e6 !important;
}

.bd-right {
  border-right: 1.6px solid #dee2e6 !important;
}

.bd-bottom {
  border-bottom: 2px solid #dee2e6 !important;
}

.tr-top-white td,
.tr-top-white th {
  border-top: none !important;
}

.tr-white th,
.tr-white td {
  border: none;
}

.info-btn {
  border: none;
  background: none;
  padding: 0 10px;
  color: black;
}

.info-btn:focus, .btn-remark:focus {
  outline: none;
}

.btn-remark {
  border: none;
  background: none;
  padding: 0 10px;
  color: black;
}

td > p {
  margin: 5px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/events/special-export/special-export-detail-rooming/special-export-detail-rooming.component.scss"],"names":[],"mappings":"AAAA;;EAEE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AACA;EACE,eAAA;AAEF;;AACA;EACE,0CAAA;AAEF;;AACA;EACE,4CAAA;AAEF;;AACA;EACE,2CAAA;AAEF;;AACA;;EAEE,2BAAA;AAEF;;AACA;;EAEE,YAAA;AAEF;;AACA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AAEF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AAEF;;AACA;EACE,sBAAA;AAEF","sourcesContent":["th,\r\ntd {\r\n  text-align: center;\r\n  white-space: nowrap;\r\n}\r\n\r\nth {\r\n  border-right: 1px solid #dee2e6;\r\n}\r\nth:last-child {\r\n  border-right: 0;\r\n}\r\n\r\n.bd-top {\r\n  border-top: 1.6px solid #dee2e6 !important;\r\n}\r\n\r\n.bd-right {\r\n  border-right: 1.6px solid #dee2e6 !important;\r\n}\r\n\r\n.bd-bottom {\r\n  border-bottom: 2px solid #dee2e6 !important;\r\n}\r\n\r\n.tr-top-white td,\r\n.tr-top-white th {\r\n  border-top: none !important;\r\n}\r\n\r\n.tr-white th,\r\n.tr-white td {\r\n  border: none;\r\n}\r\n\r\n.info-btn {\r\n  border: none;\r\n  background: none;\r\n  padding: 0 10px;\r\n  color: black;\r\n}\r\n\r\n.info-btn:focus, .btn-remark:focus {\r\n  outline: none;\r\n}\r\n\r\n.btn-remark {\r\n  border: none;\r\n  background: none;\r\n  padding: 0 10px;\r\n  color: black;\r\n}\r\n\r\ntd > p {\r\n  margin: 5px!important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
