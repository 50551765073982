import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent implements OnInit {

	@ViewChild('modal') private modal: TemplateRef<any>;
	private modalRef: NgbModalRef;

	constructor(public alertService: AlertService, private modalService: NgbModal) { }

	ngOnInit() {
		this.alertService.visibilityChanged.subscribe((next, error, complete) => {
			if (this.alertService.isVisible) {
				this.show();
			} else {
				this.hide();
			}
		});
	}

	public onConfirm() {
		this.hide();
		if (this.alertService.isConfirmation) {
			this.alertService.confirm();
		}
	}

	public onCancel() {
		this.hide();
		if (this.alertService.isConfirmation) {
			this.alertService.cancel();
		}
	}

	private show() {
		if (this.modalRef != null) {
			this.hide();
		}
		this.modalRef = this.modalService.open(this.modal, { backdrop: 'static', keyboard: false });
	}

	private hide() {
		if (this.modalRef != null) {
			this.modalRef.dismiss();
			this.modalRef = null;
		}
	}

}
