// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewable-overlay {
    color: #f8f9fa;
    position: relative;
    right: 30px;
    bottom: 3px;
    width: 30px;
    height: 30px;
    background: #17a2b8;
    border-radius: 85% 0 0 0;
    padding: 9px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/image-view/image-view.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,wBAAwB;IACxB,YAAY;AAChB","sourcesContent":[".previewable-overlay {\r\n    color: #f8f9fa;\r\n    position: relative;\r\n    right: 30px;\r\n    bottom: 3px;\r\n    width: 30px;\r\n    height: 30px;\r\n    background: #17a2b8;\r\n    border-radius: 85% 0 0 0;\r\n    padding: 9px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
