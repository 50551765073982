import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from 'src/app/services/api.service';
import { Country } from 'src/app/models/api/entities/country';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { FlightReservationInfo } from 'src/app/models/api/entities/flight-reservation-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { PersonShortInfo } from 'src/app/models/api/entities/person-short-info';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-flight-passenger',
  templateUrl: './flight-passenger.component.html',
  styleUrls: ['./flight-passenger.component.scss']
})
export class FlightPassengerComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() country: Country;

  @Input() flightId: 0;

  @Input() clubShortName: string = null;

  @Output() saved = new EventEmitter<FlightReservationInfo>();
  @Output() closed = new EventEmitter<FlightReservationInfo>();

  @ViewChild('flightPassengersModal', { static: true }) private flightPassengersModal: TemplateRef<any>;
  private flightPassengersModalRef: NgbModalRef;

  isNew: boolean;
  isVisiblle: boolean;
  isReadOnly: boolean;

  messageService: MessageService;

  totalSeat = 0;

  personsList: ListResponse<PersonShortInfo> = new LocalListResponse<PersonShortInfo>();
  personDropdown: PersonShortInfo[];
  selectedPersons: PersonShortInfo[];
  reservation: FlightReservationInfo;

  freeSeat: number = 0;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    this.route.paramMap.subscribe(async params => {
      const evtIdStr = params.get('eventId');
      const countryCode = params.get('countryCode');
    

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === Number(evtIdStr)) ||
      (canViewDelegation && curUser.eventId === Number(evtIdStr) && curUser.countryCode === countryCode);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }

  });

    if ( this.flightId !== 0 || this.flightId !== null ) {
      this.loadPassengers();
    } else {
      this.close();
    }

  }

  private async loadPassengers() {
    if (this.isVisiblle) {
      this.close();
    }

    this.isNew = false;
    this.personsList = await this.apiService.getShortPersons(false, -1, 0, null, this.evt.id, this.country.iocCode, this.clubShortName);

    this.personDropdown = this.personsList.items;

    const getFlightPassengers = await this.apiService.getFlightReservation(this.flightId);

    this.selectedPersons = getFlightPassengers.passengers;


    this.reservation = {
      id: getFlightPassengers.id,
      eventId: getFlightPassengers.eventId,
      countryCode: getFlightPassengers.countryCode,
      flightTypeCode: getFlightPassengers.flightTypeCode,
      flightNumber: getFlightPassengers.flightNumber,
      flightDate: getFlightPassengers.flightDate,
      flightCountryCode: getFlightPassengers.flightCountryCode,
      airport: getFlightPassengers.airport,
      freeSeat : getFlightPassengers.freeSeat
    };

    if(this.clubShortName !== null)
    this.reservation.clubShortName = this.clubShortName;

    this.totalSeat = getFlightPassengers.freeSeat + getFlightPassengers.passengers.length;

    this.freeSeat = (this.selectedPersons.length - this.totalSeat) + this.totalSeat;
    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.flightPassengersModalRef != null) {
      this.flightPassengersModalRef.close();
      this.flightPassengersModalRef = null;
    }

    this.flightPassengersModalRef = this.modalService.open(this.flightPassengersModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.flightPassengersModalRef != null) {
      this.flightPassengersModalRef.close();
      this.flightPassengersModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.reservation);
  }

  private async save() {

    this.reservation.passengers = this.selectedPersons;

    const request: UpdateRequest<FlightReservationInfo> = {
      isNew: this.isNew,
      subject: this.reservation
    };

    const result = await this.apiService.updateFlightPassenger(request);

    if (result.isSuccess) {
      this.saved.emit(this.reservation);
      this.messageService.setToastMessage(true, 'Travel details updated', 10);
      this.onClose();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage, 10);
    }

  }

  onClose() {
    this.close();
  }

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onItemSelect(item: any) {
    return;
  }
  onSelectAll(items: any) {
    return;
  }

  onChangeSelectedPerson() {
    this.freeSeat = (this.selectedPersons.length - this.totalSeat) + this.totalSeat;
  }

}
