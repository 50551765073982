import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'sac-image-view',
	templateUrl: './image-view.component.html',
	styleUrls: ['./image-view.component.css']
})
export class ImageViewComponent implements OnInit {

	//#region Fields

	@Input() title: string;
	@Input() address: string;

	@ViewChild('imageView') private imageViewModal: TemplateRef<any>;
	private imageViewModalRef: NgbModalRef;

	//#endregion

	//#region Constructor

	constructor(
		private modalService: NgbModal,
	) { }

	//#endregion

	ngOnInit() {
	}

	//#region Actions

	public onShowImage() {
		this.imageViewModalRef = this.modalService.
			open(this.imageViewModal, { keyboard: false, backdrop: 'static' });
	}

	public onCloseImageView() {
		if (this.imageViewModalRef != null) {
			this.imageViewModalRef.close();
			this.imageViewModalRef = null;
		}
	}

	//#endregion


}
