import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserRole } from 'src/app/models/api/enums/user-role';

@Component({
  selector: 'app-home-default',
  templateUrl: './home-default.component.html',
  styleUrl: './home-default.component.scss'
})
export class HomeDefaultComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.apiService.currentUser$.subscribe(() => {
      this.redirectIfNeeded();
    })
    this.redirectIfNeeded();
  }

  async redirectIfNeeded() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    const isBoundToEvent = this.entityMapService.roleCan(curUser.role, UserPrivilege.BoundToEvent);
    const isBoundToCountry = this.entityMapService.roleCan(curUser.role, UserPrivilege.BoundToCountry);
    const canManagePayment = this.entityMapService.roleCan(curUser.role, UserPrivilege.ManagePayment);
    const canManageEvent = this.entityMapService.roleCan(curUser.role, UserPrivilege.ManageEvent);

    const isAccountant = curUser.role === UserRole.Accountant;

    if (isBoundToEvent && isBoundToCountry && curUser.eventId != null && curUser.countryCode != null) {
      if(curUser.clubShortName !== null)
      {
        this.router.navigate([`events/${curUser.eventId}/delegations/${curUser.countryCode}/${curUser.clubShortName}`]);
      }

      if(curUser.clubShortName === null)
      {
        let navigationPath = `events/${curUser.eventId}/delegations/${curUser.countryCode}`
        this.router.navigate([navigationPath]);
      }
    } else if (isBoundToEvent && curUser.eventId != null && !isAccountant) {
      this.router.navigate([`events/${curUser.eventId}`]);
    } else if (isBoundToEvent && curUser.eventId != null && isAccountant) {
      this.router.navigate([`events/${curUser.eventId}/payment-tracker`]);
    }


  }
}
