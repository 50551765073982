import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelInfo } from 'src/app/models/api/entities/hotel-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { MimeTypes } from 'src/app/utils/mime-types';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-hotels-list',
  templateUrl: './hotels-list.component.html',
  styleUrls: ['./hotels-list.component.scss']
})
export class HotelsListComponent implements OnInit {

  public itemsPerPage = 10;
  public filter = '';

  private _includeDisabled = false;
  public get includeDisabled(): boolean {
    return this._includeDisabled;
  }
  public set includeDisabled(value: boolean) {
    this._includeDisabled = value;
    this.updateHeaderItems();
  }

  public headerItems: PageHeaderItem[];

  public evt: EventInfo;
  public hotels: ListResponse<HotelInfo> = new LocalListResponse<HotelInfo>();

  public canManageEvent = false;

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  //#region Routines

  async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);

    if (!canViewEvent) {
      this.close();
      return;
    }

    this.route.parent.paramMap.subscribe(async (paramMap) => {

      const evtIdStr = paramMap.get('eventId');

      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      this.evt = evt;
      this.canManageEvent = canManageEvent;

      this.updateHeaderItems();

      const breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }
      breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
      breadcrumbs.push({ key: 'Hotels', value: ``, link: `` });

      this.breadcrumbsService.breadcrumbs = breadcrumbs;
      this.breadcrumbsService.title = this.evt.name;
      this.reloadList();

    });
  }

  private updateHeaderItems() {
    let headerItems: PageHeaderItem[] = [];
    if (this.canManageEvent) {
      headerItems.push({ title: 'Download hotels', iconClass: 'fa-file-excel-o', callback: () => this.onDownloadHotel() });
      headerItems.push({ title: 'Add hotel', iconClass: 'fa-plus', callback: () => this.onAddHotel() });
    }
    if (this.includeDisabled) {
      headerItems.push({ title: 'Hide disabled', iconClass: 'fa-lock', callback: () => this.onToggleHidden() });
    } else {
      headerItems.push({ title: 'Show all', iconClass: 'fa-unlock', callback: () => this.onToggleHidden() });
    }
    this.headerItems = headerItems;
  }

  private close() {
    const curUser = this.apiService.currentUser;
    if (curUser === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);
    const canViewThisEvent = this.evt != null && canViewEvent && curUser.eventId === this.evt.id;

    if (canViewThisEvent) {
      this.router.navigate(['/events/' + this.evt.id]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private downloadHotel() {
    const path = `/Export/Hotel?eventId=${this.evt.id}`;
    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private downloadHotelSingle(id: number) {
    const path = `/Export/Hotel?eventId=${this.evt.id}&hotelId=${id}`;
    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  public toggleHidden() {
    this.includeDisabled = !this.includeDisabled;
    this.reloadList();
  }

  public async reloadList() {
    this.hotels = await this.apiService.getHotels(this.includeDisabled, this.itemsPerPage, this.hotels.page, this.filter, this.evt.id);
  }


  //#endregion

  //#region Actions

  public onFilterChange() {
    this.reloadList();
  }

  public onToggleHidden() {
    this.toggleHidden();
  }

  public onPageChanged(page: number) {
    this.hotels.page = page;
    this.reloadList();
  }

  public onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  public onAddHotel() {
    this.router.navigate([`/events/${this.evt.id}/hotels/new`]);
  }

  public onDownloadHotel() {
    this.downloadHotel();
  }

  public onDownloadHotelSingle(id: number) {
    this.downloadHotelSingle(id);
  }

  public onViewHotel(hotel: HotelInfo) {
    this.router.navigate([`/events/${this.evt.id}/hotels/${hotel.id}`]);
  }

  public onDisableHotel(hotel: HotelInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        if (hotel.isDisabled) {
          return;
        }
        this.apiService.disableHotel(hotel.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `Hotel '${hotel.name}' disabled`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });

      }

    });

  }

  public onEnableHotel(hotel: HotelInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        if (!hotel.isDisabled) {
          return;
        }
        this.apiService.enableHotel(hotel.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `Hotel '${hotel.id}' enabled`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }

    });
  }

  onFilterKeyUp(key: any) {
		if (key.which === 13) {
			this.onFilterChange();
		}
	}
}
  //#endregion


