// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t-o-u-content {
  height: 60vh;
  padding: 25px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}`, "",{"version":3,"sources":["webpack://./src/app/components/terms-of-use/terms-of-use.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,gBAAA;AACF;;AACA;EACE,UAAA;EACA,uBAAA,EAAA,+BAAA;AAEF","sourcesContent":[".t-o-u-content {\r\n  height: 60vh;\r\n  padding: 25px;\r\n  overflow-y: auto;\r\n}\r\n::-webkit-scrollbar {\r\n  width: 0px;\r\n  background: transparent; /* make scrollbar transparent */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
