import { MusicInfo } from './../../../models/api/entities/music-info';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { FlightReservationInfo } from 'src/app/models/api/entities/flight-reservation-info';
import { HotelRoomReservationInfo } from 'src/app/models/api/entities/hotel-room-reservation-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { MealReservationInfo } from 'src/app/models/api/entities/meal-reservation-info';
import { MimeTypes } from 'src/app/utils/mime-types';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { UserInfo } from 'src/app/models/api/entities/user-info';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  public itemsContactsPerPage = 10;
  public itemsPersonsPerPage = 10;
  public itemsHotelsPerPage = 10;
  public itemsRoomsPerPage = 10;
  public itemsMealsPerPage = 10;
  public itemsFlightArraivalsPerPage = 10;
  public itemsFlightDeparturesPerPage = 10;
  public itemsMusicsPerPage = 10;

  public filterContacts = '';
  public filterPersons = '';
  public filterHotels = '';
  public filterRooms = '';
  public filterMeals = '';
  public filterFlightArrivals = '';
  public filterFlightDepartures = '';
  public filterMusics = '';


  public headerContactsItems: PageHeaderItem[] = [];
  public headerPersonsItems: PageHeaderItem[] = [];
  public headerHotelItems: PageHeaderItem[] = [];
  public headerRoomItems: PageHeaderItem[] = [];
  public headerMealItems: PageHeaderItem[] = [];
  public headerArrivalsItems: PageHeaderItem[] = [];
  public headerDeparturesItems: PageHeaderItem[] = [];
  public headerMusicsItems: PageHeaderItem[] = [];

  public contacts: ListResponse<UserInfo> = new LocalListResponse<UserInfo>();
  public persons: ListResponse<PersonInfo> = new LocalListResponse<PersonInfo>();
  public hotels: ListResponse<HotelRoomReservationInfo> = new LocalListResponse<HotelRoomReservationInfo>();
  public rooms: ListResponse<HotelRoomReservationInfo> = new LocalListResponse<HotelRoomReservationInfo>();
  public meals: ListResponse<MealReservationInfo> = new LocalListResponse<MealReservationInfo>();
  public flightArrivals: ListResponse<FlightReservationInfo> = new LocalListResponse<FlightReservationInfo>();
  public flightDepartures: ListResponse<FlightReservationInfo> = new LocalListResponse<FlightReservationInfo>();
  public musics: ListResponse<MusicInfo> = new LocalListResponse<MusicInfo>();


  public evt: EventInfo;
  public canManageEvent = false;

  public messageService: MessageService;


  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit(): void {
    this.load();
  }

  private async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);

    if (!canViewEvent) {
      this.router.navigate(['/']);
      return;
    }

    this.route.parent.paramMap.subscribe(async params => {

      const evtIdStr = params.get('eventId');

      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.router.navigate(['/']);
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.router.navigate(['/']);
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.router.navigate(['/']);
        return;
      }

      this.evt = evt;
      this.canManageEvent = canManageEvent;

      if (curUser.role === 1) {
        this.breadcrumbsService.breadcrumbs = [
          { key: 'Events', link: `/events` },
        ];
      }

      if (canViewThisEvent) {
        this.breadcrumbsService.breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` })
        this.breadcrumbsService.breadcrumbs.push({ key: 'Overview', link: `` })
        this.breadcrumbsService.title = evt.name;
      }

      if (canManageEvent) {

        this.headerContactsItems = [
          ...this.headerContactsItems,
        ];

        this.headerPersonsItems = [
          ...this.headerPersonsItems,
        ];


        this.headerHotelItems = [
          ...this.headerHotelItems,
        ];

        this.headerRoomItems = [
          ...this.headerRoomItems,
        ];


        this.headerMealItems = [
          ...this.headerMealItems,
        ];

        this.headerArrivalsItems = [
          ...this.headerArrivalsItems,
        ];

        this.headerDeparturesItems = [
          ...this.headerDeparturesItems,
        ];

        this.headerMusicsItems = [
          ...this.headerMusicsItems,
        ];

      }

      this.reloadDelegationList();
      this.reloadPersonList();
      this.reloadHotelsList();
      this.reloadRoomsList();
      this.reloadMealsList();
      this.reloadFlightArrivalsList();
      this.reloadFlightDeparturesList();
      this.reloadMusicsList();
    });

  }

  private async reloadDelegationList() {
    this.contacts = await this.apiService.getUsers(false, this.itemsContactsPerPage, this.contacts.page, this.filterContacts, this.evt.id);
  }

  private async reloadPersonList() {
    // eslint-disable-next-line max-len
    this.persons = await this.apiService.getPersonsList(false, this.itemsPersonsPerPage, this.persons.page, this.filterPersons, this.evt.id, null);
  }

  private async reloadHotelsList() {
    // eslint-disable-next-line max-len
    this.hotels = await this.apiService.getHotelRoomReservations(this.evt.features.isPenaltyFunctionEnabled, this.itemsHotelsPerPage, this.hotels.page, this.filterHotels, this.evt.id, null);
  }

  private async reloadRoomsList() {
    // eslint-disable-next-line max-len
    this.rooms = await this.apiService.getHotelRoomReservations(false, this.itemsRoomsPerPage, this.rooms.page, this.filterRooms, this.evt.id, null);
  }

  private async reloadMealsList() {
    // eslint-disable-next-line max-len
    this.meals = await this.apiService.getMealReservations(this.evt.features.isPenaltyFunctionEnabled, this.itemsMealsPerPage, this.meals.page, this.filterMeals, this.evt.id, null);
  }

  private async reloadFlightArrivalsList() {
    // eslint-disable-next-line max-len
    this.flightArrivals = await this.apiService.getFlightReservationsList(false, this.itemsFlightArraivalsPerPage, this.flightArrivals.page, this.filterFlightArrivals, this.evt.id, null, 'A');
  }

  private async reloadFlightDeparturesList() {
    // eslint-disable-next-line max-len
    this.flightDepartures = await this.apiService.getFlightReservationsList(false, this.itemsFlightDeparturesPerPage, this.flightDepartures.page, this.filterFlightDepartures, this.evt.id, null, 'D');
  }

  private async reloadMusicsList() {
    // eslint-disable-next-line max-len
    this.musics = await this.apiService.getMusicsList(true, this.itemsMusicsPerPage, this.musics.page, this.filterMusics, this.evt.id, null);
  }



  private download(type: string) {
    let path: string;
    let mimeType: string;
    switch (type) {
      case 'contacts-xlsx':
        path = `/Export/Contacts?eventId=${this.evt.id}`;
        mimeType = MimeTypes.xls;
        break;
      case 'hotels-xlsx':
        path = `/Export/HotelReservations?eventId=${this.evt.id}`;
        mimeType = MimeTypes.xls;
        break;
      case 'hotels-zip':
        path = `/Export/HotelReservations?eventId=${this.evt.id}&isZip=true`;
        mimeType = MimeTypes.zip;
        break;
      case 'meals-zip':
        path = '/Export/MealReservations?eventId=' + this.evt.id + '&isZip=true';
        mimeType = MimeTypes.xls;
        break;
      case 'meals-xlsx':
        path = '/Export/MealReservations?eventId=' + this.evt.id;
        mimeType = MimeTypes.xls;
        break;
      case 'arrivals-xlsx':
        path = `/Export/FlightReservations?eventId=${this.evt.id}&arrivals=true`;
        mimeType = MimeTypes.xls;
        break;
      case 'departures-xlsx':
        path = `/Export/FlightReservations?eventId=${this.evt.id}&departures=true`;
        mimeType = MimeTypes.xls;
        break;
      case 'musics-zip':
        path = `/Music/DownloadAllMusic?eventId=${this.evt.id}`;
        mimeType = MimeTypes.zip;
        break;
      case 'person-info-zip':
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=false`;
        mimeType = MimeTypes.xls;
        break;
      case 'person-document-zip':
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=true&detailType=documents`;
        mimeType = MimeTypes.xls;
        break;
      case 'persons-photo-zip':
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=true&detailType=photos`;
        mimeType = MimeTypes.zip;
        break;
      case 'persons-passport-zip':
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=true&detailType=passports`;
        mimeType = MimeTypes.zip;
        break;
      default:
        break;
    }
    this.downloadFile(path, mimeType);
  }

  private downloadSinglePhoto(type: string, personInfo: PersonInfo) {
    const path = `/Persons/DownloadPhoto?eventId=${this.evt.id}&person=${personInfo.id}&type=${type}`;
    const mimeType = MimeTypes.jpeg;
    this.downloadFile(path, mimeType);
  }

  private downloadFile(path: string, mimeType: string) {
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private downloadMusic(music: MusicInfo) {
    const path = `/Music/GetMusic?musicId=${music.id}&isDownload=true`;
    const mimeType = MimeTypes.mp3;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  //#region Actions

  onDownload(type: string) {
    this.download(type);
  }

  onDownloadSinglePhoto(type: string, personInfo: PersonInfo) {
    this.downloadSinglePhoto(type, personInfo)
  }

  //#endregion

  //#region Contacts actions

  onContactsFilterChange() {
    this.reloadDelegationList();
  }

  onContactsPageChanged(page: number) {
    this.contacts.page = page;
    this.reloadDelegationList();
  }

  onContactsItemPerPageChanged(item: number) {
    this.itemsContactsPerPage = item;
    this.reloadDelegationList();
  }

  //#endregion

  //#region Person actions

  onPersonsFilterChange() {
    this.reloadPersonList();
  }

  onPersonsPageChanged(page: number) {
    this.persons.page = page;
    this.reloadPersonList();
  }

  onPersonsItemPerPageChanged(item: number) {
    this.itemsPersonsPerPage = item;
    this.reloadPersonList();
  }

  //#endregion

  //#region Hotel reservations actions

  onHotelFilterChange() {
    this.reloadHotelsList();
  }

  onHotelsPageChanged(page: number) {
    this.hotels.page = page;
    this.reloadHotelsList();
  }

  onHotelItemPerPageChanged(item: number) {
    this.itemsHotelsPerPage = item;
    this.reloadHotelsList();
  }

  //#endregion

  //#region Rooms actions

  onRoomFilterChange() {
    this.reloadRoomsList();
  }

  onRoomsPageChanged(page: number) {
    this.rooms.page = page;
    this.reloadRoomsList();
  }

  onRoomsItemPerPageChanged(item: number) {
    this.itemsRoomsPerPage = item;
    this.reloadRoomsList();
  }

  //#endregion

  //#region Meals actions

  onMealFilterChange() {
    this.reloadMealsList();
  }

  onMealsPageChanged(page: number) {
    this.meals.page = page;
    this.reloadMealsList();
  }

  onMealsItemPerPageChanged(item: number) {
    this.itemsMealsPerPage = item;
    this.reloadMealsList();
  }

  //#endregion

  //#region Flight Arrival actions

  onArrivalsFilterChange() {
    this.reloadFlightArrivalsList();
  }

  onFlightArrivalPageChanged(page: number) {
    this.flightArrivals.page = page;
    this.reloadFlightArrivalsList();
  }

  onFlightArrivalPerPageChanged(item: number) {
    this.itemsFlightArraivalsPerPage = item;
    this.reloadFlightArrivalsList();
  }

  //#endregion

  //#region Flight Departure actions

  onFlightDeparturePageChanged(page: number) {
    this.flightDepartures.page = page;
    this.reloadFlightDeparturesList();
  }

  onDeparturesFilterChange() {
    this.reloadFlightDeparturesList();
  }

  onDeparturesFilterPerPageChanged(item: number) {
    this.itemsFlightDeparturesPerPage = item;
    this.reloadFlightDeparturesList();
  }

  //#endregion

  //#region Musics Arrival actions

  onMusicFilterChange() {
    this.reloadMusicsList();
  }

  onMusicsPageChanged(page: number) {
    this.musics.page = page;
    this.reloadMusicsList();
  }

  onDownloadMusic(music: MusicInfo) {
    this.downloadMusic(music);
  }

  onMusicsPerPageChanged(item: number) {
    this.itemsMusicsPerPage = item;
    this.reloadMusicsList();
  }

  //#endregion

}
