import { EventInfo } from './../../../../models/api/entities/event-info';
import { PenaltyType } from './../../../../models/api/enums/penalty-type';
import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { PenaltyInfo } from 'src/app/models/api/entities/penalty-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {

  @Input() evt: EventInfo;
  @Input() penaltyType: PenaltyType;

  public allPenalties: ListResponse<PenaltyInfo> = new LocalListResponse<PenaltyInfo>();
  public penalties: PenaltyInfo[] = [];


  constructor(private apiService: ApiService) { }

  async ngOnInit(): Promise<void> {

    this.allPenalties = await this.apiService.getPenalties(false, -1, 0, null, this.evt.id);
    this.penalties = this.allPenalties.items.filter(t => t.penaltyType == this.penaltyType);

  }

}

