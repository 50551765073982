import { Pipe, PipeTransform } from '@angular/core';

import { EntityMapService } from '../services/entity-map.service';
import { FlightType } from '../models/api/enums/flight-type';

@Pipe({
  name: 'flightType'
})
export class FlightTypePipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  async transform(flightType: FlightType): Promise<string> {
    if (flightType === null) {
      return null;
    }
    return this.entityMapService.getFlightByType(flightType).name;
  }

}
