export enum FunctionType {
    Gymnast = 0,
    Judge,
    Doctor,
    ParamedicalStaff,
    Coach,
    Choreographer,
    HeadOfDelegation,
    TeamLeader,
    TeamManager,
    VIPGuest,
    Guest,
    Participant,



    Other = 1000000
}
