import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedRoutingModule } from "./shared-routing.module";
import { LoaderComponent } from "./components/loader/loader.component";
import { AlertComponent } from "./components/alert/alert.component";
import { MessageComponent } from "./components/message/message.component";
import { CheckBoxComponent } from "./components/check-box/check-box.component";
import { CheckListComponent } from "./components/check-list/check-list.component";
import { PagerComponent } from "./components/pager/pager.component";
import { PhoneNumberInputComponent } from "./components/phone-number-input/phone-number-input.component";
import { ImageViewComponent } from "./components/image-view/image-view.component";
import { DateZeroPrefixPipe } from "./pipes/date-zero-prefix.pipe";
import { DateMonthNamePipe } from "./pipes/date-month-name.pipe";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { DateInputComponent } from "./components/date-input/date-input.component";
import { provideToastr, ToastrModule } from "ngx-toastr";
import { provideAnimations } from "@angular/platform-browser/animations";
import { FilterComponent } from './modals/filter/filter.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
@NgModule({
  declarations: [
    LoaderComponent,
    AlertComponent,
    MessageComponent,
    CheckBoxComponent,
    CheckListComponent,
    DateInputComponent,
    PagerComponent,
    PhoneNumberInputComponent,
    ImageViewComponent,
    DateZeroPrefixPipe,
    DateMonthNamePipe,
    PageHeaderComponent,
    FilterComponent,
    ColorPickerComponent,
  ],
  imports: [CommonModule, SharedRoutingModule,ColorPickerModule,ToastrModule.forRoot()],
  exports: [
    LoaderComponent,
    AlertComponent,
    MessageComponent,
    CheckBoxComponent,
    CheckListComponent,
    DateInputComponent,
    PagerComponent,
    PhoneNumberInputComponent,
    ImageViewComponent,
    DateZeroPrefixPipe,
    DateMonthNamePipe,
    PageHeaderComponent,
    FilterComponent,
    ColorPickerComponent
  ],
  providers:[
    provideAnimations(), 
    provideToastr(),
  ]
})
export class SharedModule {}
