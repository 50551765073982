import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss'
})
export class ColorPickerComponent implements OnInit{
  
  @Output() savedColor: EventEmitter<string> = new EventEmitter<string>();
  @Input()textColor:string;
  pickerColor:string;
  ngOnInit(): void {
    this.pickerColor=this.textColor?this.textColor:"ff00";
  }
  SaveColor(){
     
    if(this.pickerColor.startsWith('#'))
    {
        this.pickerColor=this.pickerColor.slice(1)
    }
    this.savedColor.emit(this.pickerColor);
  }
}
