import { isUndefined } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { TermsOfUseRequest } from 'src/app/models/api/protocol/terms-of-use-request';
import { ApiService } from 'src/app/services/api.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { UserRole } from 'src/app/models/api/enums/user-role';
import { UserInfo } from 'src/app/models/api/entities/user-info';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { Router } from '@angular/router';
import { deepClone } from 'src/app/shared/utils/utils';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  termsOfUseRequest: TermsOfUseRequest;
  termsOfUseContent: string;
  isUserLoggedIn: boolean = false;
  isAgreeWithTerms: boolean = false;
  curUser: UserInfo;

  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.apiService.currentUser$.subscribe(user => {
      this.curUser = user;
    })
    await this.load();
  }

  private async load() {
    const termsOfUse = await this.apiService.getTermsOfUse();
    if (termsOfUse.data !== null) {
      this.termsOfUseContent = termsOfUse.data.content;
    } else {
      this.termsOfUseContent = '';
    }
    await this.apiService.ready();
    const curUser = this.apiService.currentUser;
    if (curUser !== null) {
      this.curUser = curUser;
      this.isUserLoggedIn = true;
      this.isAgreeWithTerms = curUser.isAgreeWithTerms;
      if (curUser.role == UserRole.Admin)
        this.isAgreeWithTerms = true;
    }
  }

  private async agree() {
    let user: UserInfo = deepClone(this.curUser);
    user.isAgreeWithTerms = true;
    const request: UpdateRequest<UserInfo> = {
      isNew: false,
      subject: user
    };

    const result = await this.apiService.updateUser(request);
    if (result.isSuccess && this.curUser.isAgreeWithTerms && this.curUser.role !== UserRole.Admin) {
      this.messageService.setToastMessage(true, 'Thank you for your agreement!', 7);
      this.router.navigate(['/events']);
    } else {
      this.messageService.setToastMessage(false, result.errorMessage);
    }
  }

  onAgree() {
    this.agree();
  }

}
