import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { BreadcrumbsService } from "src/app/services/breadcrumbs.service";
import { EntityMapService } from "src/app/services/entity-map.service";
import { EventInfo } from "src/app/models/api/entities/event-info";
import { MimeTypes } from "src/app/utils/mime-types";
import { UserPrivilege } from "src/app/models/api/enums/user-privilege";
import { UserService } from "src/app/services/user.service";
import { MessageService } from "src/app/shared/services/message.service";
import { isConvertableToNumber } from "src/app/shared/utils/utils";
import { AlertService } from "src/app/shared/services/alert.service";

@Component({
  selector: "app-export",
  templateUrl: "./export.component.html",
  styleUrls: ["./export.component.scss"],
})
export class ExportComponent implements OnInit {
  public evt: EventInfo;
  public canManageEvent = false;
  public apiUrl = "";

  public showEarlyCheckInPrice: boolean = false;
  public showLateCheckOutPrice: boolean = false;

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    public alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageSystem
    );
    const canManageEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageEvent
    );
    const canViewEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ViewEvent
    );

    if (!canViewEvent) {
      this.close();
      return;
    }

    this.route.parent.paramMap.subscribe(async (params) => {
      const evtIdStr = params.get("eventId");
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      this.canManageEvent = canManageEvent;

      this.evt = evt;
      this.apiUrl = this.apiService.apiUrl;

      if (curUser.role === 1) {
        this.breadcrumbsService.breadcrumbs = [
          { key: "Events", link: `/events` },
        ];
      }

      if (canManageEvent) {
        this.breadcrumbsService.breadcrumbs.push({
          key: "",
          value: `${evt.name}`,
          link: `/events/${evt.id}`,
        });
        this.breadcrumbsService.breadcrumbs.push({
          key: "Export",
          link: ``,
        });
        this.breadcrumbsService.title = evt.name;
      }
    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(["/auth"]);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      user,
      UserPrivilege.ManageSystem
    );

    if (canManageSystem) {
      this.router.navigate([`/events/${this.evt.id}`]);
    } else {
      this.router.navigate(["/"]);
    }
  }

  //#region Actions

  onClose() {
    this.close();
  }

  private downloadFile(path: string, mimeType: string) {
    this.apiService.downloadFile(path, mimeType).then((error) => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  onGenerateUpdates() {
    this.generateUpdates();
  }

  private async generateUpdates() {
    const result = await this.apiService.generateUpdatesForExport(this.evt.id);
    this.messageService.setToastMessage(
      result.isSuccess,
      result.successMessage,
      7
    );
  }

  onGenerateHotel(type: string) {
    let path: string;
    let mimeType: string;
    switch (type) {
      case "hotel-xlsx":
        path = `/Export/Hotel?eventId=${this.evt.id}`;
        mimeType = MimeTypes.xls;
        break;
      case "room-xlsx":
        path = `/Export/Room?eventId=${this.evt.id}`;
        mimeType = MimeTypes.xls;
        break;
      default:
        break;
    }
    this.downloadFile(path, mimeType);
  }

  onGenerateHotelReservations(type: string) {
    let path: string;
    let mimeType: string;
    switch (type) {
      case "hotel-xlsx":
        path = `/Export/HotelReservations?eventId=${this.evt.id}&earlyCheckInShow=${this.showEarlyCheckInPrice}&lateCheckOutShow=${this.showLateCheckOutPrice}`;
        mimeType = MimeTypes.xls;
        break;
      case "room-xlsx":
        path = `/Export/RoomReservations?eventId=${this.evt.id}`;
        mimeType = MimeTypes.xls;
        break;
      case "split-by-delegations-zip":
        path = `/Export/HotelReservations?eventId=${this.evt.id}&splitBy=delegations&isZip=true&earlyCheckInShow=${this.showEarlyCheckInPrice}&lateCheckOutShow=${this.showLateCheckOutPrice}`;
        mimeType = MimeTypes.zip;
        break;
      case "split-by-clubs-zip":
        path = `/Export/HotelReservations?eventId=${this.evt.id}&splitBy=clubs&isZip=true&earlyCheckInShow=${this.showEarlyCheckInPrice}&lateCheckOutShow=${this.showLateCheckOutPrice}`;
        mimeType = MimeTypes.zip;
        break;
      case "split-by-hotel-zip":
        path = `/Export/HotelReservations?eventId=${this.evt.id}&splitBy=hotels&isZip=true&earlyCheckInShow=${this.showEarlyCheckInPrice}&lateCheckOutShow=${this.showLateCheckOutPrice}`;
        mimeType = MimeTypes.zip;
        break;
      case "room-zip":
        path = `/Export/RoomReservations?eventId=${this.evt.id}&isZip=true`;
        mimeType = MimeTypes.zip;
        break;
      default:
        break;
    }
    this.downloadFile(path, mimeType);
  }

  onGenerateMeal(type: string) {
    let path: string;
    let mimeType: string;
    switch (type) {
      case "meal-xlsx":
        path = "/Export/Meals?eventId=" + this.evt.id;
        mimeType = MimeTypes.xls;
        break;
      default:
        break;
    }
    this.downloadFile(path, mimeType);
  }

  onGenerateMealReservations(type: string) {
    let path: string;
    let mimeType: string;
    switch (type) {
      case "meal-xlsx":
        path = "/Export/MealReservations?eventId=" + this.evt.id;
        mimeType = MimeTypes.xls;
        break;
      case "split-by-delegations-zip":
        path =
          "/Export/MealReservations?eventId=" +
          this.evt.id +
          "&splitBy=delegations&isZip=true";
        mimeType = MimeTypes.xls;
        break;
      case "split-by-clubs-zip":
        path =
          "/Export/MealReservations?eventId=" +
          this.evt.id +
          "&splitBy=clubs&isZip=true";
        mimeType = MimeTypes.xls;
        break;
      case "split-by-venues-zip":
        path =
          "/Export/MealReservations?eventId=" +
          this.evt.id +
          "&splitBy=meals&isZip=true";
        mimeType = MimeTypes.xls;
        break;
      default:
        break;
    }
    this.downloadFile(path, mimeType);
  }

  onGeneratePerson(type: string) {
    let path: string;
    let mimeType: string;
    switch (type) {
      case "persons-xlsx":
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=false&isDetailed=false`;
        mimeType = MimeTypes.xls;
        break;
      case "document-xlsx":
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=false&isDetailed=true`;
        mimeType = MimeTypes.xls;
        break;
      case "persons-zip":
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=false`;
        mimeType = MimeTypes.xls;
        break;
      case "document-zip":
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=true`;
        mimeType = MimeTypes.xls;
        break;
      case "photo-zip":
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=false&image=photos`;
        mimeType = MimeTypes.zip;
        break;
      case "passports-zip":
        path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=false&image=passports`;
        mimeType = MimeTypes.zip;
        break;
      default:
        break;
    }
    this.downloadFile(path, mimeType);
  }

  onGenerateTravel(type: string) {
    let path: string;
    let mimeType: string;
    switch (type) {
      case "general-xlsx":
        path = `/Export/FlightReservations?eventId=${this.evt.id}`;
        mimeType = MimeTypes.xls;
        break;
      case "arrivals-xlsx":
        path = `/Export/FlightReservations?eventId=${this.evt.id}&isZip=false&splitBy=arrivals`;
        mimeType = MimeTypes.xls;
        break;
      case "departures-xlsx":
        path = `/Export/FlightReservations?eventId=${this.evt.id}&isZip=false&splitBy=departures`;
        mimeType = MimeTypes.xls;
        break;
      case "general-zip":
        path = `/Export/FlightReservations?eventId=${this.evt.id}&isZip=true&splitBy=delegations`;
        mimeType = MimeTypes.zip;
        break;
      case "arrivals-zip":
        path = `/Export/FlightReservations?eventId=${this.evt.id}&isZip=true&splitBy=arrivals`;
        mimeType = MimeTypes.zip;
        break;
      case "departures-zip":
        path = `/Export/FlightReservations?eventId=${this.evt.id}&isZip=true&splitBy=departures`;
        mimeType = MimeTypes.zip;
        break;
      default:
        break;
    }
    this.downloadFile(path, mimeType);
  }

  onGenerateContacts() {
    const path = `/Export/Contacts?eventId=${this.evt.id}`;
    const mimeType = MimeTypes.xls;
    this.downloadFile(path, mimeType);
  }

  onGenerateMusic() {
    const path = `/Music/DownloadAllMusic?eventId=${this.evt.id}`;
    window.open(`${this.apiUrl}${path}`);

    // const mimeType = MimeTypes.zip;
    // this.downloadFile(path, mimeType);
  }

  onGenerateAnthems() {
    const path = `/Music/DownloadAllAnthems?eventId=${this.evt.id}`;
    window.open(`${this.apiUrl}${path}`);

    // const mimeType = MimeTypes.zip;
    // this.downloadFile(path, mimeType);
  }

  onShowEarlyCheckInReservationPrice() {
    this.showEarlyCheckInPrice = !this.showEarlyCheckInPrice;
  }

  onShowLateCheckOutReservationPrice() {
    this.showLateCheckOutPrice = !this.showLateCheckOutPrice;
  }
  //#endregion
}
