import { Component, OnInit, Inject, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { stringIsNullOrEmpty } from "../../utils/utils";
import { MessageService } from "../../shared/services/message.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public username: string;
  public password: string;
  public isPasswordVisible = false;


  constructor(
    private apiService: ApiService,
    private router: Router,
    public messageService: MessageService
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {}

  onSubmit() {
    if (stringIsNullOrEmpty(this.username)) {
      this.messageService.setToastMessage(false, "Username is empty");
      return;
    }
    if (stringIsNullOrEmpty(this.password)) {
      this.messageService.setToastMessage(false, "Password is empty");
      return;
    }

    this.apiService.login(this.username, this.password).then((response) => {
      if (response.isSuccess) {
       this.messageService.setToastMessage(true, response.successMessage);
        if (this.router.url === "/auth/login") {
          this.router.navigate(["/"]);
        }
      } else {
       this.messageService.setToastMessage(false, response.errorMessage);
      }
    });
  }

  onCancel() {
    this.router.navigate(["/"]);
  }

  contact() {
    this.router.navigate(["/auth/contact"]);
  }
  get isInMaintenanceMode(): boolean {
    return this.apiService.isInMaintenanceMode;
  }

  togglePasswordVisibility() {
    var passwordInput = document.getElementById("password") as HTMLInputElement;
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      this.isPasswordVisible = true;
    } else {
      passwordInput.type = "password";
      this.isPasswordVisible = false;
    }
  }
}
