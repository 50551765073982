// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-other-badge {
  margin-top: 0.6rem;
  margin-left: 0.7rem;
  margin-right: 0.6rem;
}

.detail-other-badge-bottom {
  margin-bottom: 0.6rem;
}

.plyr--audio .plyr__control:focus-visible, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: black !important;
}

::ng-deep .plyr--audio .plyr__control:focus-visible, ::ng-deep .plyr--audio .plyr__control:hover, ::ng-deep .plyr--audio .plyr__control[aria-expanded=true] :hover {
  background: black !important;
}
::ng-deep .plyr--full-ui input[type=range] {
  color: black !important;
}

div {
  margin: auto !important;
}

.player {
  width: 114% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/delegations/anthem-upload/anthem-upload.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACE,4BAAA;AACF;;AAIE;EACE,4BAAA;AADJ;AAIE;EAEE,uBAAA;AAHJ;;AAOA;EACE,uBAAA;AAJF;;AAOA;EACE,sBAAA;AAJF","sourcesContent":[".detail-other-badge {\r\n    margin-top: 0.6rem;\r\n    margin-left: 0.7rem;\r\n    margin-right: 0.6rem;\r\n}\r\n\r\n.detail-other-badge-bottom {\r\n    margin-bottom: 0.6rem;\r\n}\r\n\r\n.plyr--audio .plyr__control:focus-visible, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true]{\r\n  background: black !important;\r\n}\r\n\r\n\r\n::ng-deep{\r\n  .plyr--audio  .plyr__control:focus-visible, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] :hover{\r\n    background: black !important;\r\n  }\r\n\r\n  .plyr--full-ui input[type=range]\r\n  {\r\n    color: black !important;\r\n  }\r\n}\r\n\r\ndiv{\r\n  margin: auto !important;\r\n}\r\n\r\n.player{\r\n  width: 114% !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
