// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th,
td {
  text-align: center;
  white-space: nowrap;
}

th {
  border-right: 1px solid #dee2e6;
}

th:last-child {
  border-right: 0;
}

.td-top-colored th, .td-top-colored td {
  border-top: 1px solid #dee2e6 !important;
}

.td-right-step1-colored th, .td-right-step1-colored td {
  border-right: 1px solid #dee2e6 !important;
}

.td-right-step2-colored th, .td-right-step2-colored td {
  border-right: 1px solid #dee2e6 !important;
}

.td-right-step2-colored th:last-child, .td-right-step2-colored td:last-child,
.td-right-step1-colored th:last-child, .td-right-step1-colored td:last-child {
  border-right: none !important;
}

.info-btn {
  border: none;
  background: none;
  padding: 0 10px;
  color: black;
}

.info-btn:focus, .btn-remark:focus {
  outline: none;
}

.btn-remark {
  border: none;
  background: none;
  padding: 0 10px;
  color: black;
}

.table-responsive {
  overflow-x: scroll !important;
}

.table-container {
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/events/special-export/special-export-detail-accomodation/special-export-detail-accomodation.component.scss"],"names":[],"mappings":"AAAA;;EAEE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AACA;EACE,eAAA;AAEF;;AACA;EACE,wCAAA;AAEF;;AACA;EACE,0CAAA;AAEF;;AACA;EACE,0CAAA;AAEF;;AACA;;EAEE,6BAAA;AAEF;;AACA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AAEF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AAEF;;AACA;EACE,6BAAA;AAEF;;AACA;EACE,cAAA;AAEF","sourcesContent":["th,\r\ntd {\r\n  text-align: center;\r\n  white-space: nowrap;\r\n}\r\n\r\nth {\r\n  border-right: 1px solid #dee2e6;\r\n}\r\nth:last-child {\r\n  border-right: 0;\r\n}\r\n\r\n.td-top-colored th, .td-top-colored td {\r\n  border-top: 1px solid #dee2e6!important;\r\n}\r\n\r\n.td-right-step1-colored th, .td-right-step1-colored td {\r\n  border-right: 1px solid #dee2e6!important;\r\n}\r\n\r\n.td-right-step2-colored th, .td-right-step2-colored td {\r\n  border-right: 1px solid #dee2e6!important;\r\n}\r\n\r\n.td-right-step2-colored th:last-child, .td-right-step2-colored td:last-child,\r\n.td-right-step1-colored th:last-child, .td-right-step1-colored td:last-child{\r\n  border-right: none!important;\r\n}\r\n\r\n.info-btn {\r\n  border: none;\r\n  background: none;\r\n  padding: 0 10px;\r\n  color: black;\r\n}\r\n\r\n.info-btn:focus, .btn-remark:focus {\r\n  outline: none;\r\n}\r\n\r\n.btn-remark {\r\n  border: none;\r\n  background: none;\r\n  padding: 0 10px;\r\n  color: black;\r\n}\r\n\r\n.table-responsive{\r\n  overflow-x: scroll!important;\r\n}\r\n\r\n.table-container{\r\n  overflow: auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
