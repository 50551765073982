import { Pipe, PipeTransform } from '@angular/core';

import { EntityMapService } from '../services/entity-map.service';
import { RoomType } from '../models/api/enums/room-type';

@Pipe({
  name: 'roomType'
})
export class RoomTypePipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  async transform( roomType: RoomType ): Promise<string> {
    if (roomType === null) {
      return null;
    }

    await this.entityMapService.ready();

    return this.entityMapService.getRoomTypeName(roomType);

  }

}
