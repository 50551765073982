import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserInfo } from '../../models/api/entities/user-info';
import { environment } from '../../../environments/environment';
import { UserRole } from '../../models/api/enums/user-role';
import { UserPrivilege } from '../../models/api/enums/user-privilege';
import { EntityMapService } from '../../services/entity-map.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent implements OnInit{
  user: UserInfo;
  isLoggedIn = false;

  UserPrivilege = UserPrivilege;
  UserRole = UserRole;

  public isProduction = false;
  public isNavbarCollapsed = true;

  constructor(
    private apiService: ApiService,
    public entityMapService: EntityMapService,
    public changeNotificationsService: ChangeNotificationsService,
    public router: Router
  ) {
    this.isProduction = environment.production === true;
    this.apiService.currentUser$.subscribe(user => {
      this.user = user;
      this.isLoggedIn = this.user != null;
    })
  }

  ngOnInit() {
  }

}
