import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PageHeaderItem } from '../../models/page-header-item';

@Component({
	selector: 'sac-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {

	public isNavbarCollapsed = true;

	@Input() public title: string;
	@Input() public items: PageHeaderItem[];
	@Input() public filter: string;
	@Input() public titleClass: string = 'text-info';
	@Output() public filterChange = new EventEmitter<string>();

	constructor() { }

	ngOnInit() {
	}

	onToggelNavbar() {
		this.isNavbarCollapsed = !this.isNavbarCollapsed;
	}

	onItemClick(item: PageHeaderItem) {
		if (item == null) {
			return;
		}
		if (item.callback == null) {
			return;
		}

		item.callback();
	}

	onFilterChange() {
		this.filterChange.emit(this.filter);
	}

	onFilterKeyUp(key: any) {
		if (key.which === 13) {
			this.onFilterChange();
		}
	}

	get hasItems(): boolean {
		return this.items != null && this.items.length > 0;
	}

	get hasFilterBar(): boolean {
		return this.filterChange.observers.length > 0;
	}

	get hasItemsOrFilterBar(): boolean {
		return this.hasItems || this.hasFilterBar;
	}

}
