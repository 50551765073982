import { CategoryType } from './../models/api/enums/category-type';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityMapService } from '../services/entity-map.service';
import { CategoryTypeInfo } from '../models/api/entities/category-type-info';

@Pipe({
  name: 'categoryType'
})
export class CategoryTypePipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  async transform(categoryType: CategoryType, withShortCode: boolean = false): Promise<string> {
    if (categoryType === null) {
      return null;
    }
    await this.entityMapService.ready();

    let getTypeInfo: CategoryTypeInfo = this.entityMapService.getCategoryByType(categoryType);
    
    let returnName: string = getTypeInfo.name;

    if(withShortCode){
      returnName = `[${getTypeInfo.code}] ${getTypeInfo.name}`;
    }
    
    return returnName
  }

}
