// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.up-button {
  top: 35px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/components/music/music/music.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,kBAAA;AACJ","sourcesContent":[".up-button {\r\n    top: 35px;\r\n    position: relative;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
