import { PersonGroupInfo } from './../../../models/api/entities/person-group-info';
import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Country } from 'src/app/models/api/entities/country';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { ChangeSubjectType } from 'src/app/models/api/enums/change-subject-type';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { MessageService } from 'src/app/shared/services/message.service';
import { deepClone, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() country: Country;

  @Input() groupId: 0;

  @Output() saved = new EventEmitter<PersonGroupInfo>();
  @Output() closed = new EventEmitter<PersonGroupInfo>();

  @ViewChild('groupPersonModal', { static: true }) private groupPersonModal: TemplateRef<any>;
  private groupPersonModalRef: NgbModalRef;

  group: PersonGroupInfo;

  isNew: boolean;

  isVisiblle: boolean;
  isReadOnly: boolean;

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private changeNotificationsService: ChangeNotificationsService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.country.code);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }

    if (this.groupId === 0) {
      this.newGroup();
    } else {
      this.loadGroup();
    }
  }

  private async newGroup() {
    this.isNew = true;
    this.group = {
      id: 0,
      eventId: this.evt.id,
      countryCode: this.country.code,
      name: null,
    };
    this.open();
  }

  private async loadGroup() {
    if (this.isVisiblle) {
      this.close();
    }

    this.isNew = false;

    const group = await this.apiService.getPersonGroup(this.groupId);

    if (group == null) {
      this.close();
      return;
    }

    this.group = group;

    this.changeNotificationsService.removeNotificationsByQuery(ChangeSubjectType.Person, [group.id.toString()]);

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.groupPersonModalRef != null) {
      this.groupPersonModalRef.close();
      this.groupPersonModalRef = null;
    }
    this.groupPersonModalRef = this.modalService.open(this.groupPersonModal, { size: 'sm', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.groupPersonModalRef != null) {
      this.groupPersonModalRef.close();
      this.groupPersonModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.group);
  }

  private async save() {

    const group: PersonGroupInfo = deepClone(this.group);

    if (stringIsNullOrEmpty(group.name)) {
      this.messageService.setToastMessage(false, 'Please add name.');
      return;
    }

    const request: UpdateRequest<PersonGroupInfo> = {
      isNew: this.isNew,
      subject: group
    };

    const result = await this.apiService.updatePersonGroup(request);

    if (result.isSuccess) {
      this.saved.emit(group);
      this.messageService.setToastMessage(true, this.isNew ? 'Person group created' : 'Person group updated', 10);
      this.onClose();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage, 10);
    }

  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  //#endregion


}
