import { ListResponse } from './../../../models/api/protocol/list-response';
import { LocalListResponse } from './../../../models/api/protocol/local-list-response';
import { PenaltyInfo } from './../../../models/api/entities/penalty-info';
import { PenaltyType } from './../../../models/api/enums/penalty-type';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-penalties-list',
  templateUrl: './penalties-list.component.html',
  styleUrls: ['./penalties-list.component.scss']
})
export class PenaltiesListComponent implements OnInit {

  private _includeAccommodationDisabled = false;
  public get includeAccommodationDisabled(): boolean {
    return this._includeAccommodationDisabled;
  }

  public set includeAccommodationDisabled(value: boolean) {
    this._includeAccommodationDisabled = value;
    this.updateAccommodationHeaderItems();
  }

  private _includeMealDisabled = false;
  public get includeMealDisabled(): boolean {
    return this._includeMealDisabled;
  }

  public set includeMealDisabled(value: boolean) {
    this._includeMealDisabled = value;
    this.updateMealHeaderItems();
  }

  public accommodationHeaderItems: PageHeaderItem[] = [];
  public mealHeaderItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public canManageEvent = false;

  public allPenalties: ListResponse<PenaltyInfo> = new LocalListResponse<PenaltyInfo>();
  public allAccommodations: PenaltyInfo[] = [];
  public allMeals: PenaltyInfo[] = [];

  public currentPenaltyId: number;
  public currentPenaltyType: PenaltyType;
  public viewingPenalty: boolean;

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private breadcrumbsService: BreadcrumbsService,
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit(): void {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);

    if (!canViewEvent) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      this.canManageEvent = canManageEvent;

      this.evt = evt;

      if (curUser.role === 1) {
        this.breadcrumbsService.breadcrumbs = [
          { key: 'Events', link: `/events` },
          { key: '', value: `${evt.name}`, link: `/events/${evt.id}` },
          { key: 'Cancellation policy', link:''}
        ];

        this.breadcrumbsService.title = evt.name;
      }

      this.updateAccommodationHeaderItems();
      this.updateMealHeaderItems();

      if (params.has('penaltyId')) {

        const penaltyIdAndTypeStr = params.get('penaltyId');

        if (penaltyIdAndTypeStr == null) {
          this.closePolicy();
          return;
        }

        const penaltyIdAndTypeSplit = penaltyIdAndTypeStr.split(',');

        const penaltyId: number = Number(penaltyIdAndTypeSplit[0]);
        const penaltyType: PenaltyType = Number(penaltyIdAndTypeSplit[1]);

        if (penaltyId === 0)
          this.addPolicy(0, penaltyType);

        if (penaltyId !== 0)
          this.viewPolicy(penaltyId, penaltyType);

      }

      this.reloadList(null);

    });
  }

  private updateAccommodationHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    if (this.canManageEvent) {
      headerItems.push({ title: 'Add policy', iconClass: 'fa-plus', callback: () => this.onAddPolicy(0, PenaltyType.Accommodation) });
    }
    if (this.includeAccommodationDisabled) {
      headerItems.push({ title: 'Hide disabled', iconClass: 'fa-lock', callback: () => this.onToggleHidden(PenaltyType.Accommodation) });
    } else {
      headerItems.push({ title: 'Show all', iconClass: 'fa-unlock', callback: () => this.onToggleHidden(PenaltyType.Accommodation) });
    }
    this.accommodationHeaderItems = headerItems;
  }

  private updateMealHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    if (this.canManageEvent) {
      headerItems.push({ title: 'Add policy', iconClass: 'fa-plus', callback: () => this.onAddPolicy(0, PenaltyType.Meal) });
    }
    if (this.includeMealDisabled) {
      headerItems.push({ title: 'Hide disabled', iconClass: 'fa-lock', callback: () => this.onToggleHidden(PenaltyType.Meal) });
    } else {
      headerItems.push({ title: 'Show all', iconClass: 'fa-unlock', callback: () => this.onToggleHidden(PenaltyType.Meal) });
    }
    this.mealHeaderItems = headerItems;
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate([`/events/${this.evt.id}`]);
    } else {
      this.router.navigate(['/']);
    }
  }


  private async reloadList(penaltyType: PenaltyType) {
    switch (penaltyType) {
      case PenaltyType.Accommodation:
        this.allPenalties = await this.apiService.getPenalties(this.includeAccommodationDisabled, -1, 0, null, this.evt.id);
        this.allAccommodations = this.allPenalties.items.filter(t => t.penaltyType == PenaltyType.Accommodation);
        break;
      case PenaltyType.Meal:
        this.allPenalties = await this.apiService.getPenalties(this.includeAccommodationDisabled, -1, 0, null, this.evt.id);
        this.allMeals = this.allPenalties.items.filter(t => t.penaltyType == PenaltyType.Meal);
        break;
      default:
        this.allPenalties = await this.apiService.getPenalties(false, -1, 0, null, this.evt.id);
        this.allAccommodations = this.allPenalties.items.filter(t => t.penaltyType == PenaltyType.Accommodation);
        this.allMeals = this.allPenalties.items.filter(t => t.penaltyType == PenaltyType.Meal);
        break;
    }
  }

  public toggleHidden(penaltyType: PenaltyType) {
    switch (penaltyType) {
      case PenaltyType.Accommodation:
        this.includeAccommodationDisabled = !this.includeAccommodationDisabled;
        this.updateAccommodationHeaderItems();
        break;
      case PenaltyType.Meal:
        this.includeMealDisabled = !this.includeMealDisabled;
        this.updateMealHeaderItems();
        break;
    }
    this.reloadList(penaltyType);
  }

  private addPolicy(penaltyId: number, penaltyType: PenaltyType): void {
    this.viewPolicy(penaltyId, penaltyType);
  }

  private viewPolicy(penaltyId: number, penaltyType: PenaltyType): void {
    this.location.go(`/events/${this.evt.id}/penalties/${penaltyId},${penaltyType}`);
    this.currentPenaltyId = penaltyId;
    this.currentPenaltyType = penaltyType;
    this.viewingPenalty = true;
  }

  private closePolicy() {
    this.location.go(`/events/${this.evt.id}/penalties/`);
    this.viewingPenalty = false;
  }

  //#region Actions

  public onToggleHidden(penaltyType: PenaltyType) {
    this.toggleHidden(penaltyType);
  }

  public onToggleHiddenAccomodation(){
    this.toggleHidden(PenaltyType.Accommodation);
  }

  public onToggleHiddenMeal(){
    this.toggleHidden(PenaltyType.Meal)
  }

  onClose() {
    this.close();
  }

  onAddPolicy(penaltyId: number, penaltyType: PenaltyType): void {
    this.addPolicy(penaltyId, penaltyType);
  }

  onAddPolicyAccomodation(){
    this.addPolicy(0, PenaltyType.Accommodation);
  }

  onAddPolicyMeal(){
    this.addPolicy(0, PenaltyType.Meal);
  }

  onViewPolicy(penaltyInfo: PenaltyInfo) {
    this.viewPolicy(penaltyInfo.id, penaltyInfo.penaltyType);
  }

  onDisablePolicy(penaltyId: number) {
    const message = 'Are you sure? ';

    this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        this.apiService.disablePenalty(penaltyId).then(result => {
          if (result.isSuccess) {
            this.reloadList(null);
            const toastMessage = `Policy is disabled`;
            this.messageService.setToastMessage(true, toastMessage);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  onEnablePolicy(penaltyId: number) {
    const message = 'Are you sure? ';

    this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        this.apiService.enablePenalty(penaltyId).then(result => {
          if (result.isSuccess) {
            this.reloadList(null);
            const toastMessage = `Policy is enabled`;
            this.messageService.setToastMessage(true, toastMessage);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }


  onPolicyClosed() {
    this.closePolicy();
  }

  onPolicyUpdated(penaltyType: PenaltyType) {
    this.reloadList(null);
  }

}

