import { Pipe, PipeTransform } from '@angular/core';

import { EntityMapService } from '../services/entity-map.service';
import { HotelClassType } from '../models/api/enums/hotel-class-type';

@Pipe({
  name: 'hotelClass'
})
export class HotelClassPipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  async transform(hotelClass: HotelClassType ): Promise<string> {
    if (hotelClass === null) {
      return null;
    }

    await this.entityMapService.ready();

    return this.entityMapService.getHotelClassTypeName(hotelClass);
  }

}
