import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MimeTypes } from 'src/app/utils/mime-types';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { PersonsListRequest } from 'src/app/models/api/protocol/persons-list-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { VisaType } from 'src/app/models/api/enums/visa-type';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit {

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public documents: ListResponse<PersonInfo> = new LocalListResponse<PersonInfo>();

  public currentPersonId: number;

  public viewingDocument = false;

  public managingPhoto = false;

  public managingPhotoType = 'photo';

  public canManageDelegation = false;

  public isObserver: boolean = false;

  public visaType: VisaType;

  public messageService: MessageService;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      if ( curUser.role === 3 ){
        this.isObserver = true;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;

      const breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }
      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      if (this.isObserver) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      // eslint-disable-next-line max-len
      if(evt.features.isClubExist === false)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` });
        breadcrumbs.push({ key: 'Documents', value: '', link:''})
      }

      if(evt.features.isClubExist === true)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}` });
        breadcrumbs.push({ key: 'Documents', value: '', link:''})
      }

      this.breadcrumbsService.breadcrumbs = breadcrumbs;

      this.updateHeaderItems();

      this.route.paramMap.subscribe(async (paramMap) => {
        const personIdStr = paramMap.get('documentId');

        let personId = 0;
        if (isConvertableToNumber(personIdStr)) {
          personId = Number(personIdStr);
        }

        if (personId === 0) {
          return;
        }

        this.editDocument(personId);
      });

      if (params.has('documentId')) {

        const documentIdStr = params.get('documentId');

        if (documentIdStr == null || !isConvertableToNumber(documentIdStr)) {
          this.close();
          return;
        }

        const documentId = Number(documentIdStr);

        this.currentPersonId = documentId;

        if(documentId !== 0){
          this.editDocument(documentId);
        }

      };

      this.reloadList();

    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private updateHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    // if (this.canManageDelegation) {
    //  headerItems.push({ title: 'Download visa details', iconClass: 'fa-file-excel-o', callback: () => this.onDownloadDocuments() });
    //  headerItems.push({ title: 'Download passport photos', iconClass: 'fa-file-zip-o', callback: () => this.onDownloadPassports() });
    // }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    // eslint-disable-next-line max-len
    this.documents = await this.apiService.getPersonsList(true, this.itemsPerPage, this.documents.page, null, this.delegation.eventId, this.delegation.countryCode, this.delegation.clubShortName);
  }

  private editDocument(personId: number) {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/documents/${personId}`;

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/documents/${personId}`;

    this.location.go(path);
    this.currentPersonId = personId;
    this.viewingDocument = true;
  }

  private closeDocument() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/documents`;

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/documents`;

    this.location.go(path);
    this.viewingDocument = false;
    this.currentPersonId = 0;
  }

  private photoUpload(photoType: string, document: PersonInfo){
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/documents/${document.id}/photo-upload`;

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/documents/${document.id}/photo-upload`;

    this.location.go(path);
    this.currentPersonId = document.id;
    this.managingPhoto = true;
    this.managingPhotoType = photoType;
  }

  private closePhotoUpload(){
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/documents`;

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/documents`;

    this.location.go(path);
    this.managingPhoto = false;
    this.managingPhotoType = '';
    this.currentPersonId = 0;
  }

  private downloadDocuments() {
    let path = `/Export/PersonInfo?eventId=${this.evt.id}&isDetailed=true&detailType=documents&country=${this.delegation.countryCode}`;

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      path = `/Export/PersonInfo?eventId=${this.evt.id}&isDetailed=true&detailType=documents&country=${this.delegation.countryCode}&club=${this.delegation.clubShortName}`;

    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private downloadPassports() {
    let path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=true&detailType=passports&country=${this.delegation.countryCode}`;

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=true&detailType=passports&country=${this.delegation.countryCode}&club=${this.delegation.countryCode}`;

      const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  //#endregion

  //#region Actions

  onPageChanged(page: number) {
    this.documents.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onEditDocument(person: PersonInfo) {
    this.editDocument(person.id);
  }

  onDocumentUpdated() {
    this.reloadList();
  }

  onDocumentClosed() {
    this.closeDocument();
  }

  onPhotoUpload(photoType: string, document: PersonInfo) {
    this.photoUpload(photoType, document);
  }

  onPhotoUploadClosed() {
    this.closePhotoUpload();
  }

  onPhotoUploadUpdated() {
    this.reloadList();
  }

  onDownloadDocuments() {
    this.downloadDocuments();
  }

  onDownloadPassports() {
    this.downloadPassports();
  }

  getFunctionTypeName(person: PersonInfo) {
    if (person === null || person.function === null) {
      return null;
    }
    return this.entityMapService.getFunctionTypeName(person.function);
  }

  getGenderName(person: PersonInfo){
    if (person === null || person.function === null) {
      return null;
    }
    return this.entityMapService.getGenderByType(person.gender).name;
  }


  //#endregion


}
