import { PersonGroupInfo } from "./../models/api/entities/person-group-info";
import { MissedInformationsInfo } from "./../models/api/entities/missed-informations/missed-informations-info";
import { Country } from "src/app/models/api/entities/country";
import { MusicInfo } from "./../models/api/entities/music-info";
import { PlayerSourceInfo } from "./../models/api/entities/player-source-info";
import { ApiResponse } from "./../models/api/protocol/api-response";
import { DelegationInfo } from "./../models/api/entities/delegation-info";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Observer, Observable, ReplaySubject } from "rxjs";
import { ChangeNotificationsService } from "./change-notifications.service";
import { saveAs } from "file-saver";
import { AuthRequest } from "../models/api/protocol/auth-request";
import { UserInfo } from "../models/api/entities/user-info";
import { Sha256 } from "../utils/sha256";
import { Router } from "@angular/router";
import { LocalApiResponse } from "../models/api/protocol/local-api-response";
import { LocalListResponse } from "../models/api/protocol/local-list-response";
import { EventPropertyListRequest } from "../models/api/protocol/event-property-list-request";
import { HotelRoomListRequest } from "../models/api/protocol/hotel-room-list-request";
import { ListResponse } from "../models/api/protocol/list-response";
import { EntityMapService } from "./entity-map.service";
import { PersonInfo } from "../models/api/entities/person-info";
import { EventsListRequest } from "../models/api/protocol/events-list-request";
import { EventInfo } from "../models/api/entities/event-info";
import { sleep } from "../utils/utils";
import { ContactRequest } from "../models/api/protocol/contact-request";
import { ServerState } from "../models/api/protocol/server-state";
import { StaticData } from "../models/api/entities/static-data";
import { PersonsListRequest } from "../models/api/protocol/persons-list-request";
import { ShortEventInfo } from "../models/api/entities/short-event-info";
import { HotelInfo } from "../models/api/entities/hotel-info";
import { UpdateRequest } from "../models/api/protocol/update-request";
import { HotelRoomInfo } from "../models/api/entities/hotel-room-info";
import { MealVenueInfo } from "../models/api/entities/meal-venue-info";
import { DelegationRequest } from "../models/api/entities/delegation-request";
import * as signalR from "@microsoft/signalr";
import { HotelRoomReservationInfo } from "../models/api/entities/hotel-room-reservation-info";
import { DelegationPropertyListRequest } from "../models/api/protocol/delegation-property-list-request";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MealReservationInfo } from "../models/api/entities/meal-reservation-info";
import { City } from "../models/api/entities/city";
import { FlightInfo } from "../models/api/entities/flight-info";
import { FlightReservationInfo } from "../models/api/entities/flight-reservation-info";
import { PersonShortInfo } from "../models/api/entities/person-short-info";
import { FlightType } from "../models/api/enums/flight-type";
import { DelegationFlightPropertyListRequest } from "../models/api/protocol/delegation-flight-property-list-request";
import { PersonRequest } from "../models/api/protocol/person-request";
import { MailInfo } from "../models/api/entities/mail-info";
import { PaymentTrackerInfo } from "../models/api/entities/payment-tracker/payment-tracker-info";
import { InstalmentsInfo } from "../models/api/entities/payment-tracker/instalments-info";
import { List, result } from "lodash";
import { SpecialExportInfo } from "../models/api/entities/special-export/special-export-info";
import { TermsOfUseRequest } from "../models/api/protocol/terms-of-use-request";
import { PenaltyInfo } from "../models/api/entities/penalty-info";
import { LoaderService } from "../shared/services/loader.service";
import { environment } from "src/environments/environment";
import { EDiplomaTypes } from "../models/api/enums/diplomas-types";
import { resolve } from "path";

@Injectable({ providedIn: "root" })
export class ApiService {
  public apiUrl: string;

  public currentUser: UserInfo;
  private _currentUser$ = new ReplaySubject<UserInfo>(1);
  public readonly currentUser$ = this._currentUser$.asObservable();

  public isInMaintenanceMode: boolean;
  public isInWeakRulesMode: boolean;
  public userRestoreFinished: boolean;

  private connection: signalR.HubConnection = null;

  //#region Constructor
  constructor(
    private http: HttpClient,
    private entityMapService: EntityMapService,
    private loaderService: LoaderService,
    private changeNotificationsService: ChangeNotificationsService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.apiUrl = environment.apiUrl;

    changeNotificationsService.setApiService(this);
    entityMapService.setApiServise(this);

    this.updateState();
    this.tryRestoreLogin();
  }

  //#endregion
  updateUserMethod(user: UserInfo) {
    this._currentUser$.next(user);

    this.currentUser = user;
  }

  //#region Routines

  private async makeRequest<T>(
    path: string,
    params: any,
    skipLoader: boolean = false
  ): Promise<ApiResponse<T>> {

    if (skipLoader !== true) {
      this.loaderService.show();
    }
    return new Promise<ApiResponse<T>>((resolve) => {
      const url = this.apiUrl + path;
      const headers = new HttpHeaders()
        .set("Access-Control-Allow-Origin", this.apiUrl)
        .set("Access-Control-Allow-Methods", "GET, POST")
        .set("Access-Control-Allow-Headers", "X-Requested-With,content-type")
        .set("Access-Control-Allow-Credentials", "true")
        .set("Content-Type", "application/json");

      const json = params == null ? null : JSON.stringify(params);
      const isAuthenticated = this.currentUser != null;
      this.http
        .post<any>(url, json, {
          observe: "response",
          withCredentials: true,
          headers: headers,
        })
        .subscribe(
          (response) => {
            if (
              isAuthenticated &&
              response.headers.has("IsAuthenticated") &&
              response.headers.get("IsAuthenticated") === "false"
            ) {
              sleep(1000).then(() => {
                this.logout().then(() => {
                  this.router.navigate(["/auth/login"]);
                });
              });
            }
            const result = response.body;
            if (skipLoader !== true) {
              this.loaderService.hide();
            }
            resolve(result);
          },
          (error) => {
            console.error(error);
            const result = new LocalApiResponse<T>(error.statusText);
            if (skipLoader !== true) {
              this.loaderService.hide();
            }
            resolve(result);
          }
        );
    });
  }

 fetchImage(imageUrl:any): Promise<any> {
    return new Promise<any>((resolve)=>{
      const headers= new HttpHeaders()
      .set("Access-Control-Allow-Origin", this.apiUrl)
      .set("Access-Control-Allow-Methods", "GET, POST")
      .set("Access-Control-Allow-Headers", "X-Requested-With,content-type")
      .set("Access-Control-Allow-Credentials", "true")
      .set("Accept", "image/*");
      this.http.get(imageUrl, {
    observe: "response",
    withCredentials:true,
    headers: headers,
    responseType: "blob",
  }).subscribe((result)=>{
    resolve(result)
  })
    })
      
  }

  private async makeUploadRequest<T>(
    path: string,
    params: any,
    filePropName: string,
    file: File
  ): Promise<ApiResponse<T>> {
    this.loaderService.show();
    return new Promise<ApiResponse<T>>((resolve) => {
      const url = this.apiUrl + path;

      const formData = new FormData();
      if (params != null) {
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const val = params[key];
            formData.append(key, val);
          }
        }
      }
      formData.append(filePropName, file);
      
      const options = {
        withCredentials: true,
        headers: new HttpHeaders()
          .set("Access-Control-Allow-Origin", this.apiUrl)
          .set("Access-Control-Allow-Methods", "GET, POST")
          .set("Access-Control-Allow-Headers", "X-Requested-With,content-type")
          .set("Access-Control-Allow-Credentials", "true")
          .set("Accept", "application/json"),
      };
      this.http.post<ApiResponse<T>>(url, formData, options).subscribe(
        (result) => {
          this.loaderService.hide();
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<T>(error.statusText);
          this.loaderService.hide();
          resolve(result);
        }
      );
    });
  }

  
  public downloadFile(path: string, mime: string): Promise<string> {
    return new Promise<string>((resolve) => {
      this.loaderService.show();

      const url = this.apiUrl + path;
      const headers = new HttpHeaders()
        .set("Content-Type", mime)
        .set("Accept", mime)
        .set("Access-Control-Allow-Headers", "Content-Disposition");

      // Process the file downloaded
      this.http
        .get(url, {
          observe: "response",
          headers: headers,
          responseType: "blob",
        })
        .subscribe(
          (res) => {
            this.loaderService.hide();
            if (res != null) {
              const contentDisposition =
                res.headers.get("Content-Disposition") || "";
              const matches = /filename=([^;]+)/gi.exec(contentDisposition);
              const fileName =
                matches == null || matches.length < 1
                  ? null
                  : matches[1].trim();

              const blob = new Blob([res.body], {
                type: "application/octet-stream",
              });
              if (fileName != null && blob != null && blob.size > 0) {
                saveAs(blob, fileName);
                resolve(null);
              } else {
                resolve("No file received");
              }
            }
          },
          (error) => {
            this.loaderService.hide();
            resolve(error.message);
          }
        );
    });
  }

  private async updateState() {
    const state = await this.getServerState();
    if (state != null) {
      this.isInMaintenanceMode = state.isInMaintenanceMode;
      this.isInWeakRulesMode = state.isInWeakRulesMode;
    }
  }

  defaultCoordinate(eventId: number | string): Observable<any> {
    return this.http.get(`${this.apiUrl}/Events/GetDiplomaDetail?eventId=${eventId}`);
  }
  

  public ready(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (this.userRestoreFinished) {
        resolve();
        return;
      }

      this._currentUser$.subscribe(() => {
        resolve();
      });
    });
  }

  //#endregion

  //#region Communication
  private async connect(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if (this.currentUser === null) {
        return;
      }
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${this.apiUrl}/hubs/Communication`)
        .withAutomaticReconnect([
          50, 2000, 5000, 20000, 20000, 20000, 30000, 30000, 30000, 30000,
          45000, 60000,
        ])
        .build();

      connection.onclose(async () => await this.onDisconnected());
      connection.on(
        "CloseConnection",
        async () => await this.onCloseConnection()
      );
      connection.on("SetNotifications", (notifications) =>
        this.changeNotificationsService.setNotifications(notifications)
      );
      let connected = true;
      await connection.start().catch(() => {
        connected = false;
      });

      if (connected) {
        this.connection = connection;
      }

      resolve(connected);
    });
  }

  private async disconnect() {
    if (this.connection != null) {
      await this.connection.stop();
      this.connection = null;
    }
  }

  private async onCloseConnection() {
    await this.logout();
    await this.disconnect();
  }

  private async onDisconnected() {
    let result = false;
    result = await this.connect();
    if (!result) {
      await this.disconnect();
      await this.logout();
    }
  }

  //#endregion

  //#region Log In/Out

  private async setCurrentUser(user: UserInfo) {
    if (user == null) {
      sessionStorage.removeItem("currentUsername");
      this.updateUserMethod(null);
      this.disconnect();
      this.changeNotificationsService.setNotifications([]);
    } else {
      sessionStorage.setItem("currentUsername", user.username);
      this.updateUserMethod(user);
      this.connect();
    }
    this.userRestoreFinished = true;
  }

  public async login(
    username: string,
    password: string
  ): Promise<ApiResponse<void>> {
    this.updateState();

    const passwordHash = new Sha256(false, false).update(password).toString();
    const request: AuthRequest = {
      username: username,
      passwordHash: passwordHash,
    };

    await this.setCurrentUser(null);

    return new Promise<ApiResponse<void>>(async (resolve) => {
      const result = await this.makeRequest<void>("/Account/Login", request);
      if (result.isSuccess) {
        const user = await this.getUser(username);
        if (user == null) {
          const resp = new LocalApiResponse<void>(
            "No such account or incorrect password."
          );
          resolve(resp);
        } else {
          await this.setCurrentUser(user);
          resolve(result);
        }
      } else {
        resolve(result);
      }
    });
  }

  public async logout(): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Account/Logout", null).then(async (result) => {
        await this.setCurrentUser(null);
        this.modalService.dismissAll();
        this.router.navigate(["/auth"]);
        resolve(result);
      });
    });
  }

  private async tryRestoreLogin() {
    const username = sessionStorage.getItem("currentUsername");
    if (username != null) {
      const user = await this.getUser(username);
      if (user != null) {
        await this.setCurrentUser(user);
      } else {
        this.router.navigate(["/auth"]);
      }
    } else {
      this.router.navigate(["/auth"]);
    }
  }

  public reloadCurrentUser() {
    if (this.currentUser === null) {
      return;
    }
    this.getUser(this.currentUser.username).then(async (user) => {
      if (user != null) {
        await this.setCurrentUser(user);
      } else {
        this.router.navigate(["/auth"]);
      }
    });
  }

  //#endregion

  //#region StaticData

  public getStaticData(): Promise<StaticData> {
    return new Promise<StaticData>((resolve) => {
      this.makeRequest<StaticData>("/StaticData/GetStaticData", null).then(
        (result) => {
          let staticData: StaticData = null;
          if (result.isSuccess) {
            staticData = <StaticData>result.data;
          }
          resolve(staticData);
        }
      );
    });
  }

  //#endregion

  //#region StaticData

  public getCityData(): Promise<City> {
    return new Promise<City>((resolve) => {
      this.makeRequest<City>("/StaticData/GetCityData", null).then((result) => {
        let cityData: City = null;
        if (result.isSuccess) {
          cityData = <City>result.data;
        }
        resolve(cityData);
      });
    });
  }

  public async sendMail(
    request: UpdateRequest<MailInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Events/SendMail", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Persons

  public async getPersonsList(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number,
    countryCode: string,
    clubShortName: string = null
  ): Promise<ListResponse<PersonInfo>> {
    return new Promise<ListResponse<PersonInfo>>((resolve) => {
      const request: DelegationPropertyListRequest = {
        eventId: eventId,
        countryCode: countryCode,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };

      if (clubShortName) {
        request.clubShortName = clubShortName;
      }

      this.makeRequest<ListResponse<PersonInfo>>(
        "/Persons/GetPersons",
        request
      ).then(
        (result) => {
          let persons: ListResponse<PersonInfo> =
            result.data as ListResponse<PersonInfo>;
          if (persons == null) {
            persons = new LocalListResponse();
          } else if (persons.items == null) {
            persons.items = [];
          }
          persons.items.forEach((person) => {
            if (person.countryCode != null) {
              person.country = this.entityMapService.getCountryByCode(
                person.countryCode
              );
            }

            person.fullName = `${person.surname} ${person.name}`;
          });
          resolve(persons);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse<PersonInfo>());
        }
      );
    });
  }

  private async makeUploadRequestFormdata<T>(
    path: string,
    params: any,
    formData:FormData
  ): Promise<ApiResponse<T>> {
    this.loaderService.show();
    return new Promise<ApiResponse<T>>((resolve) => {
      const url = this.apiUrl + path;
      const options = {
        withCredentials: true,
        headers: new HttpHeaders()
          .set("Access-Control-Allow-Origin", this.apiUrl)
          .set("Access-Control-Allow-Methods", "GET, POST")
          .set("Access-Control-Allow-Headers", "X-Requested-With,content-type")
          .set("Access-Control-Allow-Credentials", "true")
          .set("Accept", "application/json"),
      };
      this.http.post<ApiResponse<T>>(url, formData, options).subscribe(
        (result) => {
          this.loaderService.hide();
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<T>(error.statusText);
          this.loaderService.hide();
          resolve(result);
        }
      );
    });
  }
  getFormData(object: Object): FormData {
    const formData = new FormData;
    Object.keys(object).forEach(key => {
      const data = object[key as keyof object];
      if (Array.isArray(data)) {
        const newData = Array.from(data);
        newData.forEach(value => {
          if (typeof value == 'string' || value instanceof File) {
            formData.append(key + [], value)
          } else {
            formData.append(key + [], JSON.stringify(value as keyof object))
          }

        }

        );
      } else if (data == null) {
        formData.delete(key)
      }
      else {
        formData.append(key, object[key as keyof object]);
      };
    });
    return formData;
  }
  public uploadDelegationDiploma(file: File, eventId: number, diplomaOrientationType: EDiplomaTypes, hexColor: string, positionDTO: { top: number, left: number, key: string }[]): Promise<ApiResponse<void>> {
    console.log('positions', positionDTO);
    let a = {
      'file': file,
      'positions': positionDTO
    }
    let formdata = this.getFormData(a);
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeUploadRequestFormdata<void>(
        `/Events/UploadDelegationDiploma?eventId=${eventId}&diplomaOrientationType=${diplomaOrientationType}&hexColor=${hexColor}`,
        {},
        formdata
      ).then((result) => {
        resolve(result)
      },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        });
    });
  }
  public async getShortPersons(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number,
    countryCode: string,
    clubShortName: string = null
  ): Promise<ListResponse<PersonShortInfo>> {
    return new Promise<ListResponse<PersonShortInfo>>((resolve) => {
      const request: DelegationPropertyListRequest = {
        eventId: eventId,
        countryCode: countryCode,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };

      if (clubShortName !== null) request.clubShortName = clubShortName;

      this.makeRequest<ListResponse<PersonShortInfo>>(
        "/Persons/GetShortPersons",
        request
      ).then(
        (result) => {
          let persons: ListResponse<PersonShortInfo> =
            result.data as ListResponse<PersonShortInfo>;
          if (persons == null) {
            persons = new LocalListResponse();
          } else if (persons.items == null) {
            persons.items = [];
          }
          resolve(persons);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse<PersonShortInfo>());
        }
      );
    });
  }

  public async getPersons(
    request: PersonsListRequest
  ): Promise<ListResponse<PersonInfo>> {
    return new Promise<ListResponse<PersonInfo>>((resolve) => {
      this.makeRequest<ListResponse<PersonInfo>>(
        "/Persons/GetPersons",
        request
      ).then(
        (result) => {
          let persons: ListResponse<PersonInfo> =
            result.data as ListResponse<PersonInfo>;
          if (persons == null) {
            persons = new LocalListResponse();
          } else if (persons.items == null) {
            persons.items = [];
          }
          persons.items.forEach((person) => {
            if (person.countryCode != null) {
              person.country = this.entityMapService.getCountryByCode(
                person.countryCode
              );
            }
          });
          resolve(persons);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse<PersonInfo>());
        }
      );
    });
  }

  public async getPerson(request: PersonRequest): Promise<PersonInfo> {
    return new Promise<PersonInfo>((resolve) => {
      if (request.personId == null || request.personId < 1) {
        resolve(null);
        return;
      }
      this.makeRequest<PersonInfo>("/Persons/GetPerson", request).then(
        (result) => {
          const person: PersonInfo = result.data as PersonInfo;
          if (person != null) {
            if (person.countryCode != null) {
              person.country = this.entityMapService.getCountryByCode(
                person.countryCode
              );
            }
          }
          resolve(person);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async updatePerson(
    request: UpdateRequest<PersonInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Persons/UpdatePerson", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async deletePerson(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Persons/DeletePerson", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public getPersonPhotoAddress(id: number): string {
    return this.apiUrl + "/Persons/GetPersonPhoto?id=" + id;
  }

  public uploadPersonPhoto(id: number, file: File): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeUploadRequest<void>(
        "/Persons/UploadPersonPhoto",
        { id: id },
        "photo",
        file
      ).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async getPersonGroups(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number,
    countryCode: string
  ): Promise<ListResponse<PersonGroupInfo>> {
    return new Promise<ListResponse<PersonGroupInfo>>((resolve) => {
      const request: DelegationPropertyListRequest = {
        eventId: eventId,
        countryCode: countryCode,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };

      this.makeRequest<ListResponse<PersonGroupInfo>>(
        "/Persons/GetPersonGroups",
        request
      ).then(
        (result) => {
          let groups: ListResponse<PersonGroupInfo> =
            result.data as ListResponse<PersonGroupInfo>;
          if (groups == null) {
            groups = new LocalListResponse();
          } else if (groups.items == null) {
            groups.items = [];
          }
          resolve(groups);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse<PersonGroupInfo>());
        }
      );
    });
  }

  public async getPersonGroup(id: number): Promise<PersonGroupInfo> {
    return new Promise<PersonGroupInfo>((resolve) => {
      this.makeRequest<PersonGroupInfo>("/Persons/GetPersonGroup", id).then(
        (result) => {
          const group: PersonGroupInfo = result.data as PersonGroupInfo;
          resolve(group);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async updatePersonGroup(
    request: UpdateRequest<PersonGroupInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Persons/UpdatePersonGroup", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async deletePersonGroup(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Persons/DeletePersonGroup", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Music

  public uploadAnthemMusic(
    formData: FormData,
    delegation: DelegationInfo
  ): Observable<HttpEvent<ApiResponse<PlayerSourceInfo>>> {
    const options = {
      headers: new HttpHeaders()
        .set("Access-Control-Allow-Origin", this.apiUrl)
        .set("Access-Control-Allow-Methods", "GET, POST")
        .set("Access-Control-Allow-Headers", "X-Requested-With,content-type")
        .set("Access-Control-Allow-Credentials", "true")
        .set("Accept", "application/json"),
    };

    return this.http.post<ApiResponse<PlayerSourceInfo>>(
      `${this.apiUrl}/Music/UploadAnthem?eventId=${delegation.eventId}&country=${delegation.countryCode}`,
      formData,
      {
        withCredentials: true,
        headers: options.headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public getAnthemAddress(country: string): string {
    return (
      this.apiUrl +
      `/Music/GetAnthem?country=${country}&rand=${Math.floor(
        Math.random() * 2000
      )}`
    );
  }

  public getMusicAddress(musicId: number): string {
    return `${this.apiUrl}/Music/GetMusic?musicId=${musicId}&${
      Math.floor(Math.random() * 100) + 1
    }`;
  }

  public async getMusicsList(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number,
    countryCode: string,
    clubShortName: string = null
  ): Promise<ListResponse<MusicInfo>> {
    return new Promise<ListResponse<MusicInfo>>((resolve) => {
      const request: DelegationPropertyListRequest = {
        eventId: eventId,
        countryCode: countryCode,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };

      if (clubShortName !== null) {
        request.clubShortName = clubShortName;
      }

      this.makeRequest<ListResponse<MusicInfo>>(
        "/Music/GetMusics",
        request
      ).then(
        (result) => {
          let musics: ListResponse<MusicInfo> =
            result.data as ListResponse<MusicInfo>;
          if (musics == null) {
            musics = new LocalListResponse();
          } else if (musics.items == null) {
            musics.items = [];
          }
          musics.items.forEach((musics) => {
            if (musics.countryCode != null) {
              musics.country = this.entityMapService.getCountryByCode(
                musics.countryCode
              );
            }
          });
          resolve(musics);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse<MusicInfo>());
        }
      );
    });
  }

  public async getMusic(id: number): Promise<MusicInfo> {
    return new Promise<MusicInfo>((resolve) => {
      if (id == null || id < 1) {
        resolve(null);
        return;
      }
      this.makeRequest<MusicInfo>("/Music/GetMusicInfo", id).then(
        (result) => {
          let music: MusicInfo = result.data as MusicInfo;

          if (music.members !== null) {
            music.members.forEach((person) => {
              if (person.name !== null) {
                person.fullName = `${person.surname} ${person.name}`;
              }
            });
          }
          resolve(music);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async updateMusic(
    request: UpdateRequest<MusicInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Music/UpdateMusic", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public uploadMusic(
    formData: FormData,
    eventId: number,
    country: Country,
    musicId: number,
    clubShortName: string = null
  ): Observable<HttpEvent<ApiResponse<PlayerSourceInfo>>> {
    const options = {
      headers: new HttpHeaders()
        .set("Access-Control-Allow-Origin", this.apiUrl)
        .set("Access-Control-Allow-Methods", "GET, POST")
        .set("Access-Control-Allow-Headers", "X-Requested-With,content-type")
        .set("Access-Control-Allow-Credentials", "true")
        .set("Accept", "application/json"),
    };

    let path = `${this.apiUrl}/Music/UploadMusic?eventId=${eventId}&country=${country.code}&musicId=${musicId}`;

    if (clubShortName !== null) {
      path = `${this.apiUrl}/Music/UploadMusic?eventId=${eventId}&country=${country.code}&club=${clubShortName}&musicId=${musicId}`;
    }

    return this.http.post<ApiResponse<PlayerSourceInfo>>(path, formData, {
      withCredentials: true,
      headers: options.headers,
      reportProgress: true,
      observe: "events",
    });
  }

  public async deleteMusic(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Music/DeleteMusic", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Flights

  public async getFlightsList(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number,
    countryCode: string,
    clubShortName: string = null
  ): Promise<ListResponse<FlightInfo>> {
    return new Promise<ListResponse<FlightInfo>>((resolve) => {
      const request: DelegationPropertyListRequest = {
        eventId: eventId,
        countryCode: countryCode,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };

      if (clubShortName) request.clubShortName = clubShortName;

      this.makeRequest<ListResponse<FlightInfo>>(
        "/Flights/GetFlights",
        request
      ).then(
        (result) => {
          let flights: ListResponse<FlightInfo> =
            result.data as ListResponse<FlightInfo>;
          if (flights == null) {
            flights = new LocalListResponse();
          } else if (flights.items == null) {
            flights.items = [];
          }
          flights.items.forEach((flight) => {
            if (flight.flightCountryCode != null) {
              flight.country = this.entityMapService.getCountryByCode(
                flight.flightCountryCode
              );
            }

            if (flight.airport != null) {
              flight.airportDetail = this.entityMapService.getAirportByIataCode(
                flight.airport
              );
            }
          });
          resolve(flights);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse<FlightInfo>());
        }
      );
    });
  }

  public async getFlight(id: number): Promise<FlightInfo> {
    return new Promise<FlightInfo>((resolve) => {
      if (id == null || id < 1) {
        resolve(null);
        return;
      }
      this.makeRequest<FlightInfo>("/Flights/GetFlight", id).then(
        (result) => {
          const flight: FlightInfo = result.data as FlightInfo;
          if (flight.flightTypeCode !== null) {
            flight.flightType = this.entityMapService.getFlightByCode(
              flight.flightTypeCode
            );
          }
          resolve(flight);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async updateFlight(
    request: UpdateRequest<FlightInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Flights/UpdateFlight", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async updateFlightPassenger(
    request: UpdateRequest<FlightReservationInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Flights/UpdateFlightPassenger", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async deleteFlight(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Flights/DeleteFlight", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Flight reservations

  public async getFlightReservationsList(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number,
    countryCode: string,
    flightType: string = null,
    clubShortName: string = null
  ): Promise<ListResponse<FlightReservationInfo>> {
    return new Promise<ListResponse<FlightReservationInfo>>((resolve) => {
      const request: DelegationFlightPropertyListRequest = {
        eventId: eventId,
        countryCode: countryCode,
        flightType: flightType,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };

      if (clubShortName !== null) {
        request.clubShortName = clubShortName;
      }

      this.makeRequest<ListResponse<FlightReservationInfo>>(
        "/Flights/GetFlightReservations",
        request
      ).then(
        (result) => {
          let flights: ListResponse<FlightReservationInfo> =
            result.data as ListResponse<FlightReservationInfo>;
          if (flights == null) {
            flights = new LocalListResponse();
          } else if (flights.items == null) {
            flights.items = [];
          }
          flights.items.forEach((flight) => {
            if (flight.countryCode != null) {
              flight.country = this.entityMapService.getCountryByCode(
                flight.countryCode
              );
            }
            if (flight.flightCountryCode != null) {
              flight.flightCountry = this.entityMapService.getCountryByCode(
                flight.flightCountryCode
              );
            }

            if (flight.airport != null) {
              flight.airportDetail = this.entityMapService.getAirportByIataCode(
                flight.airport
              );
            }
          });
          resolve(flights);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse<FlightReservationInfo>());
        }
      );
    });
  }

  public async getFlightReservation(
    id: number
  ): Promise<FlightReservationInfo> {
    return new Promise<FlightReservationInfo>((resolve) => {
      if (id == null || id < 1) {
        resolve(null);
        return;
      }
      this.makeRequest<FlightReservationInfo>(
        "/Flights/GetFlightReservation",
        id
      ).then(
        (result) => {
          const flight: FlightReservationInfo =
            result.data as FlightReservationInfo;
          resolve(flight);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  //#endregion

  //#region Event

  public async getEvents(
    request: EventsListRequest
  ): Promise<ListResponse<EventInfo>> {
    return new Promise<ListResponse<EventInfo>>((resolve) => {
      this.makeRequest<ListResponse<EventInfo>>(
        "/Events/GetEvents",
        request
      ).then(
        (result) => {
          let events: ListResponse<EventInfo> =
            result.data as ListResponse<EventInfo>;
          if (events == null) {
            events = new LocalListResponse<EventInfo>();
          } else if (events.items == null) {
            events.items = [];
          }
          events.items.forEach((event) => {
            if (event.countryCode != null) {
              event.country = this.entityMapService.getCountryByCode(
                event.countryCode
              );
            }
          });
          resolve(events);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async getShortEvents(
    request: EventsListRequest
  ): Promise<ListResponse<ShortEventInfo>> {
    return new Promise<ListResponse<ShortEventInfo>>((resolve) => {
      this.makeRequest<ListResponse<ShortEventInfo>>(
        "/Events/GetShortEvents",
        request
      ).then(
        (result) => {
          let events: ListResponse<ShortEventInfo> =
            result.data as ListResponse<ShortEventInfo>;
          if (events == null) {
            events = new LocalListResponse<ShortEventInfo>();
          } else if (events.items == null) {
            events.items = [];
          }
          resolve(events);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async getEvent(id: number): Promise<EventInfo> {
    return new Promise<EventInfo>((resolve) => {
      if (id == null || id < 1) {
        resolve(null);
        return;
      }
      this.makeRequest<EventInfo>("/Events/GetEvent", id).then(
        (result) => {
          const event: EventInfo = result.data as EventInfo;
          if (event != null) {
            if (event.countryCode != null) {
              event.country = this.entityMapService.getCountryByCode(
                event.countryCode
              );
            }
          }
          resolve(event);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getShortEvent(id: number): Promise<ShortEventInfo> {
    return new Promise<ShortEventInfo>((resolve) => {
      if (id == null || id < 1) {
        resolve(null);
        return;
      }
      this.makeRequest<ShortEventInfo>("/Events/GetShortEvent", id).then(
        (result) => {
          const event: ShortEventInfo = result.data as ShortEventInfo;
          resolve(event);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async createEvent(event: EventInfo): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Events/CreateEvent", event).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async updateEvent(event: EventInfo): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Events/UpdateEvent", event).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async enableEvent(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Events/EnableEvent", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async disableEvent(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Events/DisableEvent", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async deleteEvent(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Events/DeleteEvent", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Users

  public async getUser(username: string): Promise<UserInfo> {
    return new Promise<UserInfo>((resolve) => {
      this.makeRequest<UserInfo>("/Users/GetUser", username).then(
        (result) => {
          const user: UserInfo = result.data as UserInfo;
          if (user != null && user.countryCode != null) {
            user.country = this.entityMapService.getCountryByCode(
              user.countryCode
            );
          }
          resolve(user);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getUsers(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId?: number,
    includeUsernames?: string[]
  ): Promise<ListResponse<UserInfo>> {
    return new Promise<ListResponse<UserInfo>>((resolve) => {
      const request: EventPropertyListRequest = {
        eventId: eventId == null ? 0 : eventId,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
        includeCodes: includeUsernames,
      };
      this.makeRequest<ListResponse<UserInfo>>("/Users/GetUsers", request).then(
        (result) => {
          let users: ListResponse<UserInfo> =
            result.data as ListResponse<UserInfo>;
          if (users == null) {
            users = new LocalListResponse();
          } else if (users.items == null) {
            users.items = [];
          }
          users.items.forEach((user) => {
            if (user.countryCode != null) {
              user.country = this.entityMapService.getCountryByCode(
                user.countryCode
              );
            }
          });
          resolve(users);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async updateUser(
    request: UpdateRequest<UserInfo>
  ): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Users/UpdateUser", request).then(
        (result) => {
          if(this.currentUser.username == request.subject.username)
          {
            this.updateUserMethod(request.subject)
          }

          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async enableUser(username: string): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Users/EnableUser", username).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async disableUser(username: string): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Users/DisableUser", username).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Hotels

  public async getHotel(id: number): Promise<HotelInfo> {
    return new Promise<HotelInfo>((resolve) => {
      this.makeRequest<HotelInfo>("/Hotels/GetHotel", id).then(
        (result) => {
          const hotel: HotelInfo = result.data as HotelInfo;
          resolve(hotel);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getHotels(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number
  ): Promise<ListResponse<HotelInfo>> {
    return new Promise<ListResponse<HotelInfo>>((resolve) => {
      const request: EventPropertyListRequest = {
        eventId: eventId,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };
      this.makeRequest<ListResponse<HotelInfo>>(
        "/Hotels/GetHotels",
        request
      ).then(
        (result) => {
          let hotels: ListResponse<HotelInfo> =
            result.data as ListResponse<HotelInfo>;
          if (hotels == null) {
            hotels = new LocalListResponse();
          } else if (hotels.items == null) {
            hotels.items = [];
          }
          resolve(hotels);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async updateHotel(
    request: UpdateRequest<HotelInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Hotels/UpdateHotel", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async enableHotel(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Hotels/EnableHotel", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async disableHotel(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Hotels/DisableHotel", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Hotel Rooms

  public async getHotelRoom(id: number): Promise<HotelRoomInfo> {
    return new Promise<HotelRoomInfo>((resolve) => {
      this.makeRequest<HotelRoomInfo>("/Hotels/GetRoom", id).then(
        (result) => {
          const room: HotelRoomInfo = result.data as HotelRoomInfo;
          resolve(room);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getHotelRooms(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    hotelId: number
  ): Promise<ListResponse<HotelRoomInfo>> {
    return new Promise<ListResponse<HotelRoomInfo>>((resolve) => {
      const request: HotelRoomListRequest = {
        hotelId: hotelId,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };
      this.makeRequest<ListResponse<HotelRoomInfo>>(
        "/Hotels/GetRooms",
        request
      ).then(
        (result) => {
          let rooms: ListResponse<HotelRoomInfo> =
            result.data as ListResponse<HotelRoomInfo>;
          if (rooms == null) {
            rooms = new LocalListResponse();
          } else if (rooms.items == null) {
            rooms.items = [];
          }
          resolve(rooms);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async updateHotelRoom(
    request: UpdateRequest<HotelRoomInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Hotels/UpdateRoom", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async enableHotelRoom(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Hotels/EnableRoom", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async disableHotelRoom(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Hotels/DisableRoom", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Hotel Room Reservations

  public async getHotelRoomReservation(
    id: number
  ): Promise<HotelRoomReservationInfo> {
    return new Promise<HotelRoomReservationInfo>((resolve) => {
      this.makeRequest<HotelRoomReservationInfo>(
        "/Hotels/GetRoomReservation",
        id
      ).then(
        (result) => {
          const reservation: HotelRoomReservationInfo =
            result.data as HotelRoomReservationInfo;
          resolve(reservation);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getHotelRoomReservations(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number,
    countryCode: string,
    clubShortName: string = null
  ): Promise<ListResponse<HotelRoomReservationInfo>> {
    return new Promise<ListResponse<HotelRoomReservationInfo>>((resolve) => {
      const request: DelegationPropertyListRequest = {
        eventId: eventId,
        countryCode: countryCode,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };

      if (clubShortName !== null) request.clubShortName = clubShortName;

      this.makeRequest<ListResponse<HotelRoomReservationInfo>>(
        "/Hotels/GetRoomReservations",
        request
      ).then(
        (result) => {
          let rooms: ListResponse<HotelRoomReservationInfo> =
            result.data as ListResponse<HotelRoomReservationInfo>;
          if (rooms == null) {
            rooms = new LocalListResponse();
          } else if (rooms.items == null) {
            rooms.items = [];
          }
          resolve(rooms);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async updateHotelRoomReservation(
    request: UpdateRequest<HotelRoomReservationInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Hotels/UpdateRoomReservation", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async deleteHotelRoomReservation(
    id: number
  ): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Hotels/DeleteRoomReservation", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async disableHotelRoomReservation(
    id: number
  ): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Hotels/DisableRoomReservation", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Meal Service Venues

  public async getMealServiceVenue(id: number): Promise<MealVenueInfo> {
    return new Promise<MealVenueInfo>((resolve) => {
      this.makeRequest<MealVenueInfo>("/Meals/GetMealVenue", id).then(
        (result) => {
          const venue: MealVenueInfo = result.data as MealVenueInfo;
          resolve(venue);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getMealServiceVenues(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number
  ): Promise<ListResponse<MealVenueInfo>> {
    return new Promise<ListResponse<MealVenueInfo>>((resolve) => {
      const request: EventPropertyListRequest = {
        eventId: eventId,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };
      this.makeRequest<ListResponse<MealVenueInfo>>(
        "/Meals/GetVenues",
        request
      ).then(
        (result) => {
          let venues: ListResponse<MealVenueInfo> =
            result.data as ListResponse<MealVenueInfo>;
          if (venues == null) {
            venues = new LocalListResponse();
          } else if (venues.items == null) {
            venues.items = [];
          }
          resolve(venues);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async updateMealServiceVenue(
    request: UpdateRequest<MealVenueInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Meals/UpdateVenue", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async enableMealServiceVenue(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Meals/EnableVenue", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async disableMealServiceVenue(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Meals/DisableVenue", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async getMealReservation(id: number): Promise<MealReservationInfo> {
    return new Promise<MealReservationInfo>((resolve) => {
      this.makeRequest<MealReservationInfo>(
        "/Meals/GetMealReservation",
        id
      ).then(
        (result) => {
          const reservation: MealReservationInfo =
            result.data as MealReservationInfo;
          resolve(reservation);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getMealReservations(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number,
    countryCode: string,
    clubShortName: string = null
  ): Promise<ListResponse<MealReservationInfo>> {
    return new Promise<ListResponse<MealReservationInfo>>((resolve) => {
      const request: DelegationPropertyListRequest = {
        eventId: eventId,
        countryCode: countryCode,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };

      if (clubShortName !== null) request.clubShortName = clubShortName;

      this.makeRequest<ListResponse<MealReservationInfo>>(
        "/Meals/GetMealReservations",
        request
      ).then(
        (result) => {
          let rooms: ListResponse<MealReservationInfo> =
            result.data as ListResponse<MealReservationInfo>;
          if (rooms == null) {
            rooms = new LocalListResponse();
          } else if (rooms.items == null) {
            rooms.items = [];
          }
          resolve(rooms);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async updateMealReservation(
    request: UpdateRequest<MealReservationInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Meals/UpdateMealReservation", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async deleteMealReservation(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Meals/DeleteMealReservation", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async disableMealReservation(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Meals/DisableMealReservation", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Delegations

  public async getDelegation(
    eventId: number,
    countryCode: string,
    clubShortName: string = null
  ): Promise<DelegationInfo> {
    const request: DelegationRequest = {
      eventId: eventId,
      countryCode: countryCode,
    };
    if (clubShortName) {
      request.clubShortName = clubShortName;
    }

    return new Promise<DelegationInfo>((resolve) => {
      this.makeRequest<DelegationInfo>(
        "/Delegations/GetDelegation",
        request
      ).then(
        (result) => {
          const delegation: DelegationInfo = result.data as DelegationInfo;
          if (delegation != null && delegation.countryCode != null) {
            delegation.country = this.entityMapService.getCountryByCode(
              delegation.countryCode
            );
          }
          resolve(delegation);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getDelegations(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number
  ): Promise<ListResponse<DelegationInfo>> {
    return new Promise<ListResponse<DelegationInfo>>((resolve) => {
      const request: EventPropertyListRequest = {
        eventId: eventId,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };
      this.makeRequest<ListResponse<DelegationInfo>>(
        "/Delegations/GetDelegations",
        request
      ).then(
        (result) => {
          let users: ListResponse<DelegationInfo> =
            result.data as ListResponse<DelegationInfo>;
          if (users == null) {
            users = new LocalListResponse();
          } else if (users.items == null) {
            users.items = [];
          }
          users.items.forEach((user) => {
            if (user.countryCode != null) {
              user.country = this.entityMapService.getCountryByCode(
                user.countryCode
              );
            }
          });
          resolve(users);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async updateDelegation(
    request: DelegationInfo
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Delegations/UpdateDelegation", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Missed Informations

  public async getMissedInformations(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number
  ): Promise<ListResponse<MissedInformationsInfo>> {
    return new Promise<ListResponse<MissedInformationsInfo>>((resolve) => {
      const request: EventPropertyListRequest = {
        eventId: eventId,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };
      this.makeRequest<ListResponse<MissedInformationsInfo>>(
        "/Events/GetMissedInformations",
        request
      ).then(
        (result) => {
          let informations: ListResponse<MissedInformationsInfo> =
            result.data as ListResponse<MissedInformationsInfo>;
          resolve(informations);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  //#endregion

  //#region Payment Tracker

  public async getPaymentsInformations(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number
  ): Promise<ListResponse<PaymentTrackerInfo>> {
    return new Promise<ListResponse<PaymentTrackerInfo>>((resolve) => {
      const request: EventPropertyListRequest = {
        eventId: eventId,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };
      this.makeRequest<ListResponse<PaymentTrackerInfo>>(
        "/Payments/GetDelegationsPayments",
        request
      ).then(
        (result) => {
          let informations: ListResponse<PaymentTrackerInfo> =
            result.data as ListResponse<PaymentTrackerInfo>;
          resolve(informations);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async getPayments(
    eventId: number,
    countryCode: string,
    clubShortName: string = null
  ): Promise<InstalmentsInfo[]> {
    return new Promise<InstalmentsInfo[]>((resolve) => {
      const request = {
        eventId: eventId,
        countryCode: countryCode,
        clubShortName: clubShortName,
      };

      this.makeRequest<InstalmentsInfo[]>(
        "/Payments/GetDelegationPayment",
        request
      ).then(
        (result) => {
          let informations: InstalmentsInfo[] =
            result.data as InstalmentsInfo[];
          resolve(informations);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  }

  public async addInstalment(
    request: UpdateRequest<InstalmentsInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Payments/AddInstalment", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion

  //#region Special Export

  public async generateLatestReports(
    eventId: number
  ): Promise<ApiResponse<boolean>> {
    return new Promise<ApiResponse<boolean>>((resolve) => {
      if (eventId == null || eventId < 1) {
        resolve(null);
        return;
      }
      this.makeRequest<boolean>("/Export/GenerateSpecialExport", eventId).then(
        (result) => resolve(result),
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getReports(
    eventId: number
  ): Promise<ApiResponse<SpecialExportInfo[]>> {
    return new Promise<ApiResponse<SpecialExportInfo[]>>((resolve) => {
      if (eventId == null || eventId < 1) {
        resolve(null);
        return;
      }
      this.makeRequest<SpecialExportInfo[]>(
        "/Export/GetSpecialExports",
        eventId
      ).then(
        (result) => resolve(result),
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getReportDetail(
    eventId: number,
    detailId: number
  ): Promise<ApiResponse<SpecialExportInfo>> {
    return new Promise<ApiResponse<SpecialExportInfo>>((resolve) => {
      if (detailId == null || detailId < 1) {
        resolve(null);
        return;
      }
      this.makeRequest<SpecialExportInfo>("/Export/GetSpecialExportDetail", {
        eventId: eventId,
        detailId: detailId,
      }).then(
        (result) => resolve(result),
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async addEditRemark(request: {
    eventId: number;
    detailId: number;
    hotelId: number;
    countryCode: string;
    remark: string;
  }): Promise<ApiResponse<SpecialExportInfo>> {
    return new Promise<ApiResponse<SpecialExportInfo>>((resolve) => {
      this.makeRequest<SpecialExportInfo>("/Export/UpdateRemark", request).then(
        (result) => resolve(result),
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  //#endregion

  //#region Change Notifications

  public async removeNotifications(notificationIds: number[]) {
    if (notificationIds == null || notificationIds.length === 0) {
      return;
    }

    if (this.connection == null) {
      return;
    }

    this.connection.send("RemoveNotifications", notificationIds);
  }

  //#endregion

  //#region Contact Form

  public async sendContactMessage(
    contactRequest: ContactRequest
  ): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      if (!this.currentUser == null) {
        resolve(null);
      }

      this.makeRequest<void>("/ContactForm/SendMessage", contactRequest).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  //#endregion

  //#region Settings

  public async getServerState(): Promise<ServerState> {
    return new Promise<ServerState>((resolve) => {
      this.makeRequest<ServerState>("/Settings/GetState", null).then(
        (responce) => {
          if (responce.isSuccess) {
            resolve(responce.data);
          } else {
            resolve(null);
          }
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getIsInMaintenanceMode(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.makeRequest<boolean>("/Settings/IsInMaintenanceMode", null).then(
        (responce) => {
          if (responce.isSuccess) {
            resolve(responce.data);
          } else {
            resolve(false);
          }
        },
        (error) => {
          console.error(error);
          resolve(false);
        }
      );
    });
  }

  public async setIsInMaintenanceMode(
    inMaintenance: boolean
  ): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>(
        "/Settings/SetInMaintenanceMode",
        inMaintenance
      ).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          resolve(new LocalApiResponse(error.toString()));
        }
      );
    });
  }

  public async getIsInWeakRulesMode(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.makeRequest<boolean>("/Settings/IsInWeakRulesMode", null).then(
        (responce) => {
          if (responce.isSuccess) {
            resolve(responce.data);
          } else {
            resolve(false);
          }
        },
        (error) => {
          console.error(error);
          resolve(false);
        }
      );
    });
  }

  public async setIsInWeakRulesMode(
    inWeakRules: boolean
  ): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Settings/SetInWeakRulesMode", inWeakRules).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          resolve(new LocalApiResponse(error.toString()));
        }
      );
    });
  }

  public async setExportFilesRegenerationForced(
    isExportFilesRegenerationForced: boolean
  ): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>(
        "/Settings/SetExportFilesRegenerationForced",
        isExportFilesRegenerationForced
      ).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          resolve(new LocalApiResponse(error.toString()));
        }
      );
    });
  }

  public async getTermsOfUse(): Promise<ApiResponse<TermsOfUseRequest>> {
    return new Promise<ApiResponse<TermsOfUseRequest>>((resolve) => {
      this.makeRequest<TermsOfUseRequest>("/Settings/GetTermsOfUse", {}).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          resolve(new LocalApiResponse(error.toString()));
        }
      );
    });
  }

  public async updateTermsOfUse(
    termsOfUseRequest: TermsOfUseRequest
  ): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>(
        "/Settings/UpdateTermsOfUse",
        termsOfUseRequest
      ).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          resolve(new LocalApiResponse(error.toString()));
        }
      );
    });
  }

  public async generateUpdatesForExport(
    eventId: number
  ): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Export/GenerateUpdates", eventId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          resolve(new LocalApiResponse(error.toString()));
        }
      );
    });
  }

  //#endregion

  //#region Penalties

  public async getPenalty(id: number): Promise<PenaltyInfo> {
    return new Promise<PenaltyInfo>((resolve) => {
      this.makeRequest<PenaltyInfo>("/Penalties/GetPenalty", id).then(
        (result) => {
          const penalty: PenaltyInfo = result.data as PenaltyInfo;
          resolve(penalty);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    });
  }

  public async getPenalties(
    includeDisabled: boolean,
    itemsPerPage: number,
    page: number,
    filter: string,
    eventId: number
  ): Promise<ListResponse<PenaltyInfo>> {
    return new Promise<ListResponse<PenaltyInfo>>((resolve) => {
      const request: EventPropertyListRequest = {
        eventId: eventId,
        includeInactives: includeDisabled,
        itemsPerPage: itemsPerPage,
        page: page,
        filter: filter,
      };
      this.makeRequest<ListResponse<PenaltyInfo>>(
        "/Penalties/GetPenalties",
        request
      ).then(
        (result) => {
          let penalties: ListResponse<PenaltyInfo> =
            result.data as ListResponse<PenaltyInfo>;
          if (penalties == null) {
            penalties = new LocalListResponse();
          } else if (penalties.items == null) {
            penalties.items = [];
          }
          resolve(penalties);
        },
        (error) => {
          console.error(error);
          resolve(new LocalListResponse());
        }
      );
    });
  }

  public async updatePenalty(
    request: UpdateRequest<PenaltyInfo>
  ): Promise<ApiResponse<number>> {
    return new Promise<ApiResponse<number>>((resolve) => {
      this.makeRequest<number>("/Penalties/UpdatePenalty", request).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<number>(error);
          resolve(result);
        }
      );
    });
  }

  public async enablePenalty(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Penalties/EnablePenalty", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  public async disablePenalty(id: number): Promise<ApiResponse<void>> {
    return new Promise<ApiResponse<void>>((resolve) => {
      this.makeRequest<void>("/Penalties/DisablePenalty", id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          const result = new LocalApiResponse<void>(error);
          resolve(result);
        }
      );
    });
  }

  //#endregion
}
