// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  text-align: center;
  white-space: nowrap;
}

ul {
  list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hotels/hotel-room-reservations-list/hotel-room-reservations-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;;AAGA;EACE,qBAAA;AAAF","sourcesContent":["th, td {\r\n  text-align: center;\r\n  white-space: nowrap\r\n}\r\n\r\n\r\nul {\r\n  list-style-type: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
