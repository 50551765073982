import { Pipe, PipeTransform } from '@angular/core';

import { HotelRoomReservationInfo } from '../models/api/entities/hotel-room-reservation-info';
import { toMoment } from '../shared/utils/utils';

@Pipe({
  name: 'hotelNightsCounter'
})
export class HotelNightsCounterPipe implements PipeTransform {

  transform(checkInDate: string, checkOutDate: string): number {
    if (checkOutDate === null && checkInDate === null) {
      return null;
    }
    return toMoment(checkOutDate).diff(checkInDate, 'days');
  }

}
