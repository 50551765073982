import { TermsOfUseRequest } from './../../models/api/protocol/terms-of-use-request';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/models/api/enums/user-role';
import { isConvertableToNumber, formatMoney, formatUnsignedInteger, deepClone, stringIsNullOrEmpty } from 'src/app/utils/utils';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  formatMoney = formatMoney;
  formatUnsignedInteger = formatUnsignedInteger;

  isInMaintenanceMode: boolean;
  isInWeakRulesMode: boolean;
  isExportFilesRegenerationForced: boolean;
  termsOfUseRequest: TermsOfUseRequest;
  termsOfUseContent: string;

  editorConfig = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
    ],
  }

  isAdmin: boolean;

  public headerItems: PageHeaderItem[];

  messageService: MessageService;
  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private router: Router,
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.apiService.ready().then(() => {
      this.entityMapService.ready().then(() => {
        this.load();
      });
    });
  }

  async load() {
    const user = this.apiService.currentUser;
    if(user == null)
      this.router.navigate(['/auth']);
    if (!(user.role === UserRole.Admin || user.role === UserRole.LOC)) {
      this.router.navigate(['/']);
    }
    this.isAdmin = user.role === UserRole.Admin;
    const state = await this.apiService.getServerState();
    if (state != null) {
      this.isInMaintenanceMode = state.isInMaintenanceMode;
      this.isInWeakRulesMode = state.isInWeakRulesMode;
      this.isExportFilesRegenerationForced = state.isExportFilesRegenerationForced;
    }

    const termsOfUse = await this.apiService.getTermsOfUse();

    if(termsOfUse.data !== null){
      this.termsOfUseContent = termsOfUse.data.content;
    }else{
      this.termsOfUseContent = '';
    }

    this.updateHeaderItems();
  }

  private updateHeaderItems() {
    let headerItems: PageHeaderItem[] = [];
    if (this.isAdmin) {
      headerItems.push({ title: 'Update', iconClass: 'fa-pencil-square-o', callback: () => this.onSave() });
    }
    this.headerItems = headerItems;
  }

  onSave() {
    this.termsOfUseRequest = {
      content:  this.termsOfUseContent
    };

    this.apiService.updateTermsOfUse(this.termsOfUseRequest);
  }

  async toggleMaintenanceMode() {
    let inMaintenance = !this.isInMaintenanceMode;
    this.alertService.showConfirmation(
      'You are going to turn the "maintenance" mode ' + (inMaintenance ? 'OFF' : 'ON') + '. Are you sure?',
      async (confirmed) => {
        if (!confirmed) {
          this.isInMaintenanceMode = !this.isInMaintenanceMode
          return;
        }

        inMaintenance = !inMaintenance;
        const response = await this.apiService.setIsInMaintenanceMode(inMaintenance);
        if (response == null) {
          this.messageService.setToastMessage(false, 'Request to the server failed', 7);
        } else if (!response.isSuccess) {
          this.messageService.setToastMessage(false, response.errorMessage, 7);
        } else {
          const message = 'Maintenance mode switched ' + (inMaintenance ? 'ON' : 'OFF');
          this.messageService.setToastMessage(true, message, 2);
          this.apiService.isInMaintenanceMode = inMaintenance;
          this.isInMaintenanceMode = inMaintenance;
        }
    });
  }

  async toggleWeakRulesMode() {
    let inWeakRulesMode = !this.isInWeakRulesMode;
    this.alertService.showConfirmation(
      'You are going to turn the "weak rules" mode ' + (inWeakRulesMode ? 'OFF' : 'ON') + '. Are you sure?',
      async (confirmed) => {
        if (!confirmed) {
          this.isInWeakRulesMode = !this.isInWeakRulesMode
          return;
        }

        inWeakRulesMode = !inWeakRulesMode;
        const response = await this.apiService.setIsInWeakRulesMode(inWeakRulesMode);
        if (response == null) {
          this.messageService.setToastMessage(false, 'Request to the server failed', 7);
        } else if (!response.isSuccess) {
          this.messageService.setToastMessage(false, response.errorMessage, 7);
        } else {
          const message = 'Weak rules mode switched ' + (inWeakRulesMode ? 'ON' : 'OFF');
          this.messageService.setToastMessage(true, message, 2);
          this.apiService.isInWeakRulesMode = inWeakRulesMode;
          this.isInWeakRulesMode = inWeakRulesMode;
        }
    });
  }

  async toggleExportFilesRegenerationForced() {
    let isExportFilesRegenerationForced = !this.isExportFilesRegenerationForced;

    this.alertService.showConfirmation(
      'You are going to turn ' + (isExportFilesRegenerationForced ? 'OFF' : 'ON') +
      ' forcing the regeneration of exported data files (Excel, PDF, etc.). Are you sure?',
      async (confirmed) => {
        if (!confirmed) {
          this.isExportFilesRegenerationForced = !this.isExportFilesRegenerationForced;
          return;
        }

        isExportFilesRegenerationForced = !isExportFilesRegenerationForced;
        const response = await this.apiService.setExportFilesRegenerationForced(isExportFilesRegenerationForced);
        if (response == null) {
          this.messageService.setToastMessage(false, 'Request to the server failed', 7);
        } else if (!response.isSuccess) {
          this.messageService.setToastMessage(false, response.errorMessage, 7);
        } else {
          const message = 'Turned ' + (isExportFilesRegenerationForced ? 'ON' : 'OFF') + ' forced regeneration of data files';
          this.messageService.setToastMessage(true, message, 2);
          this.isExportFilesRegenerationForced = isExportFilesRegenerationForced;
        }
    });
  }

}
