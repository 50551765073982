import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { toDateString, toMoment } from 'src/app/utils/utils';

import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { MimeTypes } from 'src/app/utils/mime-types';
import { MomentInput } from 'moment';
import { UserInfo } from 'src/app/models/api/entities/user-info';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { DateStatus, DateStatuses } from 'src/app/utils/date-status';
import { FlightInfo } from 'src/app/models/api/entities/flight-info';
import { FlightType } from 'src/app/models/api/enums/flight-type';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { deepClone, isConvertableToNumber, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';
@Component({
  selector: 'app-delegation',
  templateUrl: './delegation.component.html',
  styleUrls: ['./delegation.component.scss']
})
export class DelegationComponent implements OnInit {

  public headerItems: PageHeaderItem[] = [];
  public contactHeaderItems: PageHeaderItem[] = [];
  public covid19HeaderItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public nfUser: UserInfo;
  public canManageDelegation = false;
  public canViewDelegation = false;
  public isNfUser = false;
  public isLocOrAdmin: boolean = false;
  public isObserver: boolean = false;

  public messageService: MessageService;

  public showTravelWarning: boolean = false;
  public travelWarningMessage: string;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.messageService = new MessageService();
  }

  async ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }
    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })
    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      if (curUser.role === 4) {
        this.isNfUser = true;
      }

      if (curUser.role === 1 || curUser.role === 2) {
        this.isLocOrAdmin = true;
      }

      if (curUser.role === 3) {
        this.isObserver = true;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      this.canViewDelegation = canViewDelegation;

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
       this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const nfUser = await this.apiService.getUser(delegation.nfUsername);

      if (nfUser == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }


      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;
      this.nfUser = nfUser;

      const isTravelFillingStarted: boolean = this.onFlights(
        this.evt?.deadlines.travelFillingStartDate,
        this.evt?.deadlines.travelFillingEndDate,
        this.delegation.travelFillingStartDate,
        this.delegation.travelFillingEndDate,
        this.delegation.isTravelFillingEnabled,
        "warning");
      if (isTravelFillingStarted) {
        const persons: number = (await this.apiService.getPersonsList(false, -1, 0, null, this.evt?.id, this.delegation.countryCode, this.clubShortName)).items.length;

        const travels: FlightInfo[] = (await this.apiService.getFlightsList(false, -1, 0, null, this.evt?.id, this.delegation.countryCode, this.clubShortName)).items;

        let arrivalsTotal: number = 0;
        const arrivals: void = travels.filter(t => this.entityMapService.getFlightByCode(t.flightTypeCode) == FlightType.A).forEach(flt => { arrivalsTotal += flt.quantity });

        let departuresTotal: number = 0;
        const departures: void = travels.filter(t => this.entityMapService.getFlightByCode(t.flightTypeCode) == FlightType.D).forEach(flt => { departuresTotal += flt.quantity });

        let flightTypesText = "";

        if (persons !== arrivalsTotal) {
          flightTypesText = "arrival";
          this.showTravelWarning = true;
        }

        if (persons !== departuresTotal) {
          flightTypesText = "departure";
          this.showTravelWarning = true;
        }

        if (persons !== departuresTotal && persons !== arrivalsTotal) {
          flightTypesText = "arrival and departure";
          this.showTravelWarning = true;
        }
        this.travelWarningMessage = `Please, correct the information about the total number of persons or the number of persons indicated in ${flightTypesText} of Travel section. They must be the same.`;

      }

      const breadcrumbs: Breadcrumb[] = [];

      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }

      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
        breadcrumbs.push({ key: '', value: `[${this.delegation.countryCode}] ${this.delegation.country.name}`, link: `` })
      }

      if (this.isObserver) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
        breadcrumbs.push({ key: '', value: `[${this.delegation.countryCode}] ${this.delegation.country.name}`, link: `` })
      }

      this.breadcrumbsService.breadcrumbs = breadcrumbs;
      this.breadcrumbsService.title = evt.name;

      if (canManageThisDelegation) {
        this.contactHeaderItems = [{ title: 'Save', iconClass: 'fa-check', callback: () => this.onSaveContactPerson() }];
        this.covid19HeaderItems = [{ title: 'Update', iconClass: 'fa-save', callback: () => this.onSaveContactPerson() }];
      }

    });

  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    if (this.evt != null) {
      this.router.navigate([`/events/${this.evt.id}`]);
    }
  }

  private async saveContactPerson() {
    const user: UserInfo = deepClone(this.nfUser);

    if (user.phone == null || user.phone === '') {
      this.messageService.setToastMessage(false, 'Invalid phone number', 5);
      return;
    }

    const result = await this.apiService.updateUser({
      isNew: false,
      subject: user,
    });
    if (result.isSuccess) {
      this.messageService.setToastMessage(true, 'NF user updated', 3);
    } else {
      this.messageService.setToastMessage(false, result.errorMessage, 5);
    }
  }


  private downloadFile(path: string, mimeType: string) {
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private viewOverview() {
    if(this.delegation.clubShortName){
      this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/overview`]);
      return;
    }

    this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/overview`]);
  }

  private downloadOverview() {
    let path = `/Export/DelegationOverview?eventId=${this.delegation.eventId}&delegation=${this.delegation.countryCode}`;

    if(this.delegation.clubShortName){
      path = `/Export/DelegationOverview?eventId=${this.delegation.eventId}&delegation=${this.delegation.countryCode}/${this.delegation.clubShortName}`;
    }

    const mimeType = MimeTypes.pdf;
    this.downloadFile(path, mimeType);
  }

  private downloadInvoice() {
    let path = `/Export/DelegationInvoice?eventId=${this.delegation.eventId}&country=${this.delegation.countryCode}`;

    if(this.delegation.clubShortName){
      path = `/Export/DelegationInvoice?eventId=${this.delegation.eventId}&country=${this.delegation.countryCode}&club=${this.delegation.clubShortName}`;
    }

    const mimeType = MimeTypes.pdf;
    this.downloadFile(path, mimeType);
  }

  //#endregion

  //#region Actions

  onClose() {
    this.close();
  }

  onPhoneNumberChange(newNumber: string) {
    this.nfUser.phone = newNumber;
  }

  onSaveContactPerson() {
    this.saveContactPerson();
  }

  onViewOverview() {
    this.viewOverview();
  }

  onDownloadOverview() {
    this.downloadOverview();
  }

  onDownloadInvoice() {
    this.downloadInvoice();
  }

  //#endregion

  //#region Section Actions

  onHotelReservations(
    start: MomentInput,
    end: MomentInput,
    specialStart: MomentInput,
    specialEnd: MomentInput,
    isSpecialDateEnabled: boolean,
    isLateFeatureEnabled: boolean,
    lateStart: MomentInput,
    lateEnd: MomentInput) {

    if (start == null || end == null) {
      return;
    }

    if (this.isLocOrAdmin) {
      if(this.delegation.clubShortName){
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/hotel-reservations`]);
      }else{
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/hotel-reservations`]);
      }
    }

    let dateStatus = new DateStatus();

    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = isLateFeatureEnabled;

    dateStatus.specialStartDate = specialStart;
    dateStatus.specialEndDate = specialEnd;
    dateStatus.isSpecialDateEnabled = isSpecialDateEnabled;

    if (isLateFeatureEnabled) {
      dateStatus.featureStartDate = lateStart;
      dateStatus.featureEndDate = lateEnd;
    }

    switch (dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
      case DateStatuses.SpecialDateNotStarted:
          this.messageService.setToastMessage(false, `Not started`);
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:
        this.messageService.setToastMessage(false, `Ended`);
        break;
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.FeatureEndsSoon:
      case DateStatuses.FeatureOnGoing:
      case DateStatuses.SpecialDateOnGoing:
      case DateStatuses.SpecialDateEndsSoon:
        if(this.delegation.clubShortName){
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/hotel-reservations`]);
        }else{
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/hotel-reservations`]);
        }
      break;
    }

  }

  onMealReservations(
    start: MomentInput,
    end: MomentInput,
    specialStart: MomentInput,
    specialEnd: MomentInput,
    isSpecialDateEnabled: boolean
    ) {

    if (start == null || end == null) {
      return;
    }

    if (this.isLocOrAdmin) {
      if(this.delegation.clubShortName){
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/meal-reservations`]);
      }else{
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/meal-reservations`]);
      }
    }

    let dateStatus = new DateStatus();

    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = false;

    dateStatus.specialStartDate = specialStart;
    dateStatus.specialEndDate = specialEnd;
    dateStatus.isSpecialDateEnabled = isSpecialDateEnabled;


    switch (dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
      case DateStatuses.SpecialDateNotStarted:
          this.messageService.setToastMessage(false, `Not started`);
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:
        this.messageService.setToastMessage(false, `Ended`);
        break;
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.SpecialDateOnGoing:
      case DateStatuses.SpecialDateEndsSoon:
        if(this.delegation.clubShortName){
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/meal-reservations`]);
        }else{
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/meal-reservations`]);
        }
        break;
    }

  }

  onPersons(
    start: MomentInput,
    end: MomentInput,
    specialStart: MomentInput,
    specialEnd: MomentInput,
    isSpecialDateEnabled: boolean
    ) {

    if (start == null || end == null) {
      return;
    }

    if (this.isLocOrAdmin) {
      if(this.delegation.clubShortName){
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/persons`]);
      }else{
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/persons`]);
      }
    }

    let dateStatus = new DateStatus();

    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = false;

    dateStatus.specialStartDate = specialStart;
    dateStatus.specialEndDate = specialEnd;
    dateStatus.isSpecialDateEnabled = isSpecialDateEnabled;


    switch (dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
      case DateStatuses.SpecialDateNotStarted:
          this.messageService.setToastMessage(false, `Not started`);
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:
          this.messageService.setToastMessage(false, `Ended`);
        break;
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.SpecialDateOnGoing:
      case DateStatuses.SpecialDateEndsSoon:
        if(this.delegation.clubShortName){
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/persons`]);
        }else{
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/persons`]);
        }
        break;
    }

  }

  onRoomReservations(
    start: MomentInput,
    end: MomentInput,
    specialStart: MomentInput,
    specialEnd: MomentInput,
    isSpecialDateEnabled: boolean,
    isLateFeatureEnabled: boolean,
    lateStart: MomentInput,
    lateEnd: MomentInput) {

    if (start == null || end == null) {
      return;
    }

    if (this.isLocOrAdmin) {
      if(this.delegation.clubShortName){
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/room-reservations`]);
      }else{
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/room-reservations`]);
      }
    }

    let dateStatus = new DateStatus();

    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = isLateFeatureEnabled;

    dateStatus.specialStartDate = specialStart;
    dateStatus.specialEndDate = specialEnd;
    dateStatus.isSpecialDateEnabled = isSpecialDateEnabled;


    if (isLateFeatureEnabled) {
      dateStatus.featureStartDate = lateStart;
      dateStatus.featureEndDate = lateEnd;
    }

    switch (dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.SpecialDateNotStarted:
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
      case DateStatuses.SpecialDateNotStarted:
          this.messageService.setToastMessage(false, `Not started`);
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:
        this.messageService.setToastMessage(false, `Ended`);
        break;
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.FeatureEndsSoon:
      case DateStatuses.FeatureOnGoing:
      case DateStatuses.SpecialDateOnGoing:
      case DateStatuses.SpecialDateEndsSoon:
        if(this.delegation.clubShortName){
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/room-reservations`]);
        }else{
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/room-reservations`]);
        }
        break;
    }

  }

  onDocuments(
    start: MomentInput,
    end: MomentInput,
    specialStart: MomentInput,
    specialEnd: MomentInput,
    isSpecialDateEnabled: boolean
    ) {

    if (start == null || end == null) {
      return;
    }

    if (this.isLocOrAdmin) {
      if(this.delegation.clubShortName){
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/documents`]);
      }else{
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/documents`]);
      }
    }

    let dateStatus = new DateStatus();

    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = false;

    dateStatus.specialStartDate = specialStart;
    dateStatus.specialEndDate = specialEnd;
    dateStatus.isSpecialDateEnabled = isSpecialDateEnabled;


    switch (dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
      case DateStatuses.SpecialDateNotStarted:

        this.messageService.setToastMessage(false, `Not started`);
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:
        this.messageService.setToastMessage(false, `Ended`);
        break;
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.SpecialDateOnGoing:
      case DateStatuses.SpecialDateEndsSoon:
        if(this.delegation.clubShortName){
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/documents`]);
        }else{
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/documents`]);
        }
        break;
    }

  }

  onFlights(
    start: MomentInput,
    end: MomentInput,
    specialStart: MomentInput,
    specialEnd: MomentInput,
    isSpecialDateEnabled: boolean,
    type = "route") {

    if (start == null || end == null) {
      return;
    }

    if (this.isLocOrAdmin && type == "route") {
      if(this.delegation.clubShortName){
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flights`]);
      }else{
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/flights`]);
      }
    }

    let dateStatus = new DateStatus();

    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = false;

    dateStatus.specialStartDate = specialStart;
    dateStatus.specialEndDate = specialEnd;
    dateStatus.isSpecialDateEnabled = isSpecialDateEnabled;


    switch (dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
      case DateStatuses.SpecialDateNotStarted:

        if (type == "route")
        this.messageService.setToastMessage(false, `Not started`);
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:
        if (type == "route")
        this.messageService.setToastMessage(false, `Ended`);
        break;
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.SpecialDateOnGoing:
      case DateStatuses.SpecialDateEndsSoon:
        if (type == "route"){
          if(this.delegation.clubShortName){
            this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flights`]);
          }else{
            this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/flights`]);
          }
        }

        if (type == "warning") {
          return true;
        }
        break;
    }

  }

  onFlightReservations(
    start: MomentInput,
    end: MomentInput,
    specialStart: MomentInput,
    specialEnd: MomentInput,
    isSpecialDateEnabled: boolean
    ) {

    if (start == null || end == null) {
      return;
    }

    if (this.isLocOrAdmin) {
      if(this.delegation.clubShortName){
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flight-reservations`]);
      }else{
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/flight-reservations`]);
      }
    }

    let dateStatus = new DateStatus();

    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = false;

    dateStatus.specialStartDate = specialStart;
    dateStatus.specialEndDate = specialEnd;
    dateStatus.isSpecialDateEnabled = isSpecialDateEnabled;


    switch (dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
      case DateStatuses.SpecialDateNotStarted:
        this.messageService.setToastMessage(false, `Not started`);
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:
        this.messageService.setToastMessage(false, `Ended`);
        break;
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.SpecialDateOnGoing:
      case DateStatuses.SpecialDateEndsSoon:
        if(this.delegation.clubShortName){
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flight-reservations`]);
        }else{
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/flight-reservations`]);
        }
        break;
    }

  }

  onMusic(
    start: MomentInput,
    end: MomentInput,
    specialStart: MomentInput,
    specialEnd: MomentInput,
    isSpecialDateEnabled: boolean
    ) {

    if (start == null || end == null) {
      return;
    }

    if (this.isLocOrAdmin) {
      if(this.delegation.clubShortName){
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/music`]);
      }else{
        this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/music`]);
      }
    }

    let dateStatus = new DateStatus();

    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = false;

    dateStatus.specialStartDate = specialStart;
    dateStatus.specialEndDate = specialEnd;
    dateStatus.isSpecialDateEnabled = isSpecialDateEnabled;

    switch (dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
      case DateStatuses.SpecialDateNotStarted:

        this.messageService.setToastMessage(false, `Not started`);
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:
        this.messageService.setToastMessage(false, `Ended`);
        break;
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.SpecialDateOnGoing:
      case DateStatuses.SpecialDateEndsSoon:
        if(this.delegation.clubShortName){
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/music`]);
        }else{
          this.router.navigate([`/events/${this.delegation.eventId}/delegations/${this.delegation.countryCode}/music`]);
        }
        break;
    }

  }

}
