import { ListResponse } from './list-response';

export class LocalListResponse<T> implements ListResponse<T> {
    public items: Array<T>;
    public page: number;
    public totalPagesCount: number;
    public totalItemsCount: number;
    public itemsPerPageCount: number;
    public itemsOnPageCount: number;

    public constructor() {
        this.items = [];
        this.page = 0;
        this.totalPagesCount = 0;
        this.totalItemsCount = 0;
        this.itemsPerPageCount = 0;
        this.itemsOnPageCount = 0;
    }
}
