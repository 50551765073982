import { Pipe, PipeTransform } from '@angular/core';

import { EventFeatures } from '../models/api/entities/event-features';
import { EventInfo } from '../models/api/entities/event-info';

@Pipe({
  name: 'columnsCount'
})
export class ColumnsCountPipe implements PipeTransform {

  transform(eventFeatures?: EventFeatures, column = 1): number {
    if (eventFeatures === null) {
      return column;
    }
    if (eventFeatures.isHotelRoomEarlyPerNightPriceEnabled) {
      column++;
    }
    if (eventFeatures.isHotelRoomEarlyCheckInAddPriceEnabled) {
      column++;
    }
    if (eventFeatures.isHotelRoomLateCheckOutAddPriceEnabled) {
      column++;
    }
    if (eventFeatures.isHotelRoomLatePerNightPriceEnabled) {
      column++;
    }
    return column;
  }

}
