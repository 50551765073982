import { PersonGroupInfo } from './../../../models/api/entities/person-group-info';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from 'src/app/services/api.service';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { ChangeSubjectType } from 'src/app/models/api/enums/change-subject-type';
import { Country } from 'src/app/models/api/entities/country';
import { CurrencyType } from 'src/app/models/api/enums/currency-type';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { FunctionTypeInfo } from 'src/app/models/api/entities/function-type-info';
import { GenderInfo } from 'src/app/models/api/entities/gender-info';
import { MealReservationInfo } from 'src/app/models/api/entities/meal-reservation-info';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { PersonRequest } from 'src/app/models/api/protocol/person-request';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { isNullOrUndefined, toMoment } from 'src/app/utils/utils';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { FunctionType } from 'src/app/models/api/enums/function-type';
import { MessageService } from 'src/app/shared/services/message.service';
import { deepClone, stringIsNullOrEmpty, toDate, toDateString } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss']
})
export class PersonsComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() country: Country;

  @Input() clubShortName: string = null;


  @Input() personId: 0;

  @Output() saved = new EventEmitter<PersonInfo>();
  @Output() closed = new EventEmitter<PersonInfo>();

  @ViewChild('personModal', { static: true }) private personModal: TemplateRef<any>;
  private personModalRef: NgbModalRef;

  allPersons: PersonInfo[];

  person: PersonInfo;
  personGroups: ListResponse<PersonGroupInfo> = new LocalListResponse<PersonGroupInfo>();

  allFunctionTypes: FunctionTypeInfo[];
  allGender: GenderInfo[];
  personRequest: PersonRequest;

  isNew: boolean;

  isVisiblle: boolean;
  isReadOnly: boolean;

  birthDay: Date;

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private changeNotificationsService: ChangeNotificationsService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null)
    {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation)
    {
      this.close();
      return;
    }

    this.allFunctionTypes = this.entityMapService.getAllFunctions();
    this.allGender = this.entityMapService.getAllGenders();

    this.personGroups = await this.apiService.getPersonGroups(false,-1,1,"",this.evt.id,this.country.code);

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.country.code);

    if (!canViewThisDelegation)
    {
      this.close();
      return;
    }

    if (this.personId === 0) {
      this.newPerson();
    } else {
      this.loadPerson();
    }
  }

  private async newPerson() {

    this.isNew = true;

    this.person = {
      id: 0,
      eventId: this.evt.id,
      countryCode: this.country.code,
      surname: null,
      name: null,
      gender: null,
      function: null,
      additionalFunction: null,
      birthDate: null,
      groupId: null,
      canBeDeleted: true,
    };

    if(this.clubShortName !== null)
    this.person.clubShortName = this.clubShortName;

    this.open();
  }

  private async loadPerson() {
    if (this.isVisiblle) {
      this.close();
    }

    this.isNew = false;

    this.personRequest = {
      personId: this.personId,
      withPhotos: false
    };

    const person = await this.apiService.getPerson(this.personRequest);

    if ( person == null) {
      this.close();
      return;
    }

    this.birthDay = toDate(person.birthDate);

    if(person.groupId === 0){
      person.groupId = null;
    }

    if(person.additionalFunction === FunctionType.Gymnast){
      person.additionalFunction = null;
    }

    this.person = person;

    this.changeNotificationsService.removeNotificationsByQuery(ChangeSubjectType.Person, [person.id.toString()]);

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.personModalRef != null) {
      this.personModalRef.close();
      this.personModalRef = null;
    }
    this.personModalRef = this.modalService.open(this.personModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.personModalRef != null) {
      this.personModalRef.close();
      this.personModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.person);
  }

  private async save() {

    const person: PersonInfo = deepClone(this.person);

    person.birthDate = toDateString(this.birthDay);

    if (stringIsNullOrEmpty(person.surname)) {
      this.messageService.setToastMessage(false, 'Please add surname.');
      return;
    }

    if (stringIsNullOrEmpty(person.name)) {
      this.messageService.setToastMessage(false, 'Please add name.');
      return;
    }

    if (isNullOrUndefined(person.gender)) {
      this.messageService.setToastMessage(false, 'Please select gender.');
      return;
    }

    if (isNullOrUndefined(person.function)) {
      this.messageService.setToastMessage(false, 'Please select function.');
      return;
    }

    if (this.person.groupId === null) {
      this.person.groupId = 0;
    }

    if (person.groupId === null) {
      person.groupId = 0;
    }

    if(person.additionalFunction === null){
      person.additionalFunction = 0;
    }

    if(this.clubShortName !== null)
      person.clubShortName = this.clubShortName;

    const request: UpdateRequest<PersonInfo> = {
      isNew: this.isNew,
      subject: person
    };

    const result = await this.apiService.updatePerson(request);

    if (result.isSuccess) {
      this.saved.emit(person);
      this.messageService.setToastMessage(true, this.isNew ? 'Person created' : 'Person updated', 10);
      this.onClose();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage, 10);
    }

  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  //#endregion


}
