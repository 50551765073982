// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767px) {
  .table-responsive .dropdown-menu,
  .table-responsive .dropdown-toggle {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}
a {
  text-decoration: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/events/events-list/events-list.component.scss"],"names":[],"mappings":"AAAA;EACE;;IAEE,2BAAA;EACF;AACF;AAEA;EACE;IACE,iBAAA;EAAF;AACF;AAGA;EACE,gCAAA;AADF","sourcesContent":["@media (max-width: 767px) {\r\n  .table-responsive .dropdown-menu,\r\n  .table-responsive .dropdown-toggle {\r\n    position: static !important;\r\n  }\r\n}\r\n\r\n@media (min-width: 768px) {\r\n  .table-responsive {\r\n    overflow: visible;\r\n  }\r\n}\r\n\r\na{\r\n  text-decoration: none !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
