// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control:not(.read-only) {
    cursor: pointer;
}
.fa.fa-unchecked {
    min-width: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/check-box/check-box.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB","sourcesContent":[".form-control:not(.read-only) {\r\n    cursor: pointer;\r\n}\r\n.fa.fa-unchecked {\r\n    min-width: 14px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
