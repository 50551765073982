import { ColumnsCountPipe } from './columns-count.pipe';
import { FlagsImgPipe } from './flags-img.pipe';
import { FlightTypePipe } from './flight-type.pipe';
import { FunctionTypePipe } from './function-type.pipe';
import { GenderTypePipe } from './gender-type.pipe';
import { GeneralCurrencyPipe } from './general-currency.pipe';
import { HotelClassPipe } from './hotel-class.pipe';
import { HotelNightsCounterPipe } from './hotel-nights-counter.pipe';
import { HotelPriceTypePipe } from './hotel-price-type.pipe';
import { HotelSubtotalPipe } from './hotel-subtotal.pipe';
import { MealTypePipe } from './meal-type.pipe';
import { NgModule } from '@angular/core';
import { RoomQuantityPipe } from './room-quantity.pipe';
import { RoomSubtotalPipe } from './room-subtotal.pipe';
import { RoomTypePipe } from './room-type.pipe';
import { GroupTypePipe } from './group-type.pipe';
import { CategoryTypePipe } from './category-type.pipe';
import { ApparatusTypePipe } from './apparatus-type.pipe';
import { PenaltyTypePipe } from './penalty-type.pipe';
import { ToPercentPipe } from './to-percent.pipe';
import { MealSubtotalPipe } from './meal-subtotal.pipe';
import { DateChangePipe } from './date-change.pipe';
import { TextCapitalizePipe } from './text-capitalize.pipe';

@NgModule({
  declarations: [
    HotelSubtotalPipe,
    GeneralCurrencyPipe,
    HotelNightsCounterPipe,
    ColumnsCountPipe,
    RoomSubtotalPipe,
    RoomTypePipe,
    HotelClassPipe,
    RoomQuantityPipe,
    HotelPriceTypePipe,
    MealTypePipe,
    GenderTypePipe,
    FunctionTypePipe,
    FlagsImgPipe,
    FlightTypePipe,
    GroupTypePipe,
    CategoryTypePipe,
    ApparatusTypePipe,
    PenaltyTypePipe,
    ToPercentPipe,
    MealSubtotalPipe,
    DateChangePipe,
    TextCapitalizePipe,
  ],
  exports: [
    HotelSubtotalPipe,
    GeneralCurrencyPipe,
    HotelNightsCounterPipe,
    ColumnsCountPipe,
    RoomSubtotalPipe,
    RoomTypePipe,
    HotelClassPipe,
    RoomQuantityPipe,
    HotelPriceTypePipe,
    MealTypePipe,
    GenderTypePipe,
    FunctionTypePipe,
    FlagsImgPipe,
    FlightTypePipe,
    GroupTypePipe,
    CategoryTypePipe,
    ApparatusTypePipe,
    PenaltyTypePipe,
    ToPercentPipe,
    MealSubtotalPipe,
    DateChangePipe,
    TextCapitalizePipe
  ]
})
export class DelegationPipesModule { }
