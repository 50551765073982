import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ShortEventInfo } from 'src/app/models/api/entities/short-event-info';
import { DisciplineType } from 'src/app/models/api/enums/discipline-type';
import { ContactRequest } from 'src/app/models/api/protocol/contact-request';
import { CurrencyType } from 'src/app/models/api/enums/currency-type';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  messageService: MessageService;

  email: string;
  subject: string;
  message: string;

  events: ShortEventInfo[];
  event: ShortEventInfo;

  other: ShortEventInfo =  {
    id: -1,
    code: null,
    countryCode: null,
    name: 'Other',
    currencyType: CurrencyType.EUR,
    disciplineType: DisciplineType.AG,
  };

  constructor(
    private apiService: ApiService,
    private router: Router
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.loadEvents();
  }

  async loadEvents() {
    const response = await this.apiService.getShortEvents({
      filter: null,
      includeInactives: false,
      itemsPerPage: 0,
      page: 0,
    });
    let events: ShortEventInfo[] = [];
    if (response != null && response.items != null) {
      events = [...events, ...response.items];
    }
    events = [...events, this.other];
    this.events = events;
    this.event = this.events[0];
  }

  getEventDescription(event: ShortEventInfo) {
    if (event === this.other) {
      return event.name;
    } else {
      return '[' + event.code + '] ' + event.name;
    }
  }

  send() {
    const request: ContactRequest = {
      email: this.email,
      subject: this.subject,
      message: this.message,
      eventId: this.event.id,
    };
    this.apiService.sendContactMessage(request).then(response => {
      if (response == null) {
        this.messageService.setMessage(false, 'Unknown error occured', 7);
      } else if (!response.isSuccess) {
        this.messageService.setMessage(false, response.errorMessage, 7);
      } else {
        this.reset();
        this.messageService.setMessage(true, response.successMessage, 4).then(() => {
          this.close();
        });
      }
    });
  }

  reset() {
    this.email = '';
    this.subject = '';
    this.message = '';
  }

  close() {
    this.router.navigate(['/']);
  }

  onSend() {
    this.send();
  }

  onCancel() {
    this.close();
  }

  onReset() {
    this.reset();
  }

}
