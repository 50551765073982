import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../../services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { ListResponse } from '../../../models/api/protocol/list-response';
import { LocalListResponse } from '../../../models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MimeTypes } from 'src/app/utils/mime-types';
import { UserInfo } from '../../../models/api/entities/user-info';
import { UserPrivilege } from '../../../models/api/enums/user-privilege';
import { UserRole } from '../../../models/api/enums/user-role';
import { UserService } from 'src/app/services/user.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  //#region Fields

  public UserRole = UserRole;
  public UserPrivilege = UserPrivilege;

  public includeDisabled = false;
  public itemsPerPage = 10;
  public filter = '';

  public headerItems: PageHeaderItem[];

  public evt: EventInfo;
  public users: ListResponse<UserInfo> = new LocalListResponse<UserInfo>();

  public currentUsername: string;
  public viewingUser: boolean;

  //#endregion

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvents = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);

    if (!canManageEvents) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async (paramMap) => {

      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      let canManageThisEvent = curUser.eventId == evt.id ;

      if(curUser.role === UserRole.Admin)
          canManageThisEvent = true;

      if(curUser.role === UserRole.LOC)
          canManageThisEvent = true;


      if (!canManageThisEvent) {
        this.close();
        return;
      }

      this.evt = evt;

      let breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs = [...breadcrumbs, { key: 'Events', link: `/events` }];
      }
      breadcrumbs = [...breadcrumbs, { key: '', value: `${evt.name}`, link: `/events/${evt.id}` }];
      breadcrumbs = [...breadcrumbs, { key: 'Users', value: ``, link: `` }];
      this.breadcrumbsService.breadcrumbs = breadcrumbs;
      this.breadcrumbsService.title = this.evt.name;
      this.updateHeaderItems();

      this.reloadList();

      if (paramMap.has('username')) {
        const username = paramMap.get('username');
        let userFound = false;
        if (this.users != null && this.users.items.some(t => t.username === username)) {
          userFound = true;
        }
        if (!userFound) {
          const user = await this.apiService.getUser(username);
          if (user != null) {
            userFound = true;
          }
        }

        if (!userFound) {
          this.close();
          return;
        }

        this.viewUser(username);
      }
    });
  }

  private close() {
    const curUser = this.apiService.currentUser;
    if (curUser === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);
    const canViewThisEvent = this.evt != null && canViewEvent && curUser.eventId === this.evt.id;

    if (canViewThisEvent) {
      this.router.navigate(['/events/' + this.evt.id]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private async reloadList() {
    this.users = await this.apiService.getUsers(this.includeDisabled, this.itemsPerPage, this.users.page, this.filter, this.evt.id);
  }

  private viewUser(username: string) {
    this.location.go(`events/${this.evt.id}/users/${username}`);
    this.currentUsername = username;
    this.viewingUser = true;
  }

  private closeUser() {
    this.location.go(`events/${this.evt.id}/users`);
    this.viewingUser = false;
    this.currentUsername = null;
  }

  private disableUser(user: UserInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        if (user.isDisabled) {
          return;
        }
        this.apiService.disableUser(user.username).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `User '${user.username}' disabled`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  private enableUser(user: UserInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        if (!user.isDisabled) {
          return;
        }
        this.apiService.enableUser(user.username).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `User '${user.username}' enabled`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  private updateHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
      headerItems.push({ title: 'Download all users', iconClass: 'fa-file-excel-o', callback: () => this.onDownloadAllUsers() });
      headerItems.push({ title: 'Add user', iconClass: 'fa-plus', callback: () => this.onAddUser() });
    if (this.includeDisabled) {
      headerItems.push({ title: 'Hide disabled', iconClass: 'fa-lock', callback: () => this.onToggleHidden() });
    } else {
      headerItems.push({ title: 'Show all', iconClass: 'fa-unlock', callback: () => this.onToggleHidden() });
    }
    this.headerItems = headerItems;
  }

  onFilterKeyUp(key: any) {
		if (key.which === 13) {
			this.onFilterChange();
		}
	}

  //#endregion

  //#region Actions

  onToggleHidden() {
    this.includeDisabled = !this.includeDisabled;
    this.updateHeaderItems();
    this.reloadList();
  }

  onFilterChange() {
    this.reloadList();
  }

  onPageChanged(page: number) {
    this.users.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onAddUser() {
    this.currentUsername = null;
    this.viewingUser = true;
  }

  onViewUser(user: UserInfo) {
    if (user == null) {
      return;
    }
    this.viewUser(user.username);
  }

  onUserUpdated() {
    this.reloadList();
  }

  onUserClosed() {
    this.closeUser();
  }

  onDisableUser(user: UserInfo) {
    this.disableUser(user);
  }

  onEnableUser(user: UserInfo) {
    this.enableUser(user);
  }

  onDownloadAllUsers(){
    this.downloadAllUsers();
  }

  //#endregion

  //#region Data

  private downloadAllUsers() {
    const path = `/Export/Contacts?eventId=${this.evt.id}`;
    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  public getFlagAddress(user: UserInfo): string {
    if (user == null || user.country == null) {
      return null;
    }
    return this.entityMapService.getCountryFlagAddress(user.country.code);
  }

  //#endregion

}
