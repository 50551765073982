import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { isNullOrUndefined } from "src/app/shared/utils/utils";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrl: "./pagination.component.scss",
})
export class PaginationComponent implements OnInit, DoCheck {
  private _page: number;
  pagesToShow = 5;
  pageRangeStartPoint: number;
  pageRangeEndPoint: number;
  pageRange = [];

  @Input() public set page(value: number) {
    this._page = value;
    this.pageOut = value + 1;
  }

  private get halfOfPagesToShow() {
    return Math.floor(this.pagesToShow / 2);
  }

  public get page(): number {
    return this._page;
  }
  @Input() public totalPagesCount = 0;
  @Input() public totalItemsCount = 0;
  @Input() public itemsPerPage: number;

  public pageOut = 1;
  public perPageItemOut = 10;
  public showSelect = false;

  @Output() public pageChanged = new EventEmitter<number>();
  @Output() public itemsPerPageChanged = new EventEmitter<number>();
  private notificationCanceltionHandler: Function;

  constructor() {}

  ngDoCheck(): void {
    this.pageRange = this.getPageRange();
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.itemsPerPage)) {
      this.perPageItemOut = this.itemsPerPage;
      this.showSelect = true;
    }
  }

  public onPageOutChanged() {
    this.onPageChange(this.pageOut - 1, null);
  }

  public onPerPageItemChanged() {
    this.notifyPerPageItemChanged(this.perPageItemOut);
  }

  public notifyPageChanged(page: number) {
    if (this.notificationCanceltionHandler != null) {
      this.notificationCanceltionHandler();
      this.notificationCanceltionHandler = null;
    }
    let shouldCancel = false;
    this.notificationCanceltionHandler = () => {
      shouldCancel = true;
    };
    setTimeout(() => {
      if (shouldCancel) {
        return;
      }
      this.notificationCanceltionHandler = null;
      this.pageChanged.emit(page);
    }, 300);
  }

  public notifyPerPageItemChanged(item: number) {
    if (this.notificationCanceltionHandler != null) {
      this.notificationCanceltionHandler();
      this.notificationCanceltionHandler = null;
    }
    let shouldCancel = false;
    this.notificationCanceltionHandler = () => {
      shouldCancel = true;
    };
    setTimeout(() => {
      if (shouldCancel) {
        return;
      }
      this.notificationCanceltionHandler = null;
      this.itemsPerPageChanged.emit(item);
    }, 300);
  }

  //#region NewLogic
  get totalPages(): number {
    return this.totalPagesCount;
  }

  onPageChange(incomingPage: number, event: Event): void {
    event?.preventDefault();
    if (incomingPage < 0) {
      incomingPage = 0;
    }
    if (incomingPage > this.totalPagesCount - 1) {
      incomingPage = this.totalPagesCount - 1;
    }
    if (incomingPage === this.page) {
      return;
    }
    this.pageOut = incomingPage + 1;
    this.notifyPageChanged(incomingPage);
  }

  getPageRange(): number[] {
    let start = Math.max(1, this._page - this.halfOfPagesToShow + 1);
    let end = Math.min(this.totalPages, start + this.pagesToShow - 1);

    if (end == this.totalPages) {
      start = this.totalPages - this.pagesToShow + 1;
    }

    if (start < 1) {
      start = 1;
    }

    if (end > this.totalPages) {
      end = this.totalPages;
    }

    this.pageRangeStartPoint = start;
    this.pageRangeEndPoint = end;
    let range = Array(end - start + 1)
      .fill(0)
      .map((_, index) => start + index);
    return range;
  }
  //#endregion
}
