import * as moment from "moment";

import { ActivatedRoute, Router } from "@angular/router";
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { BreadcrumbsService } from "src/app/services/breadcrumbs.service";
import { Country } from "src/app/models/api/entities/country";
import { CurrencyTypeInfo } from "src/app/models/api/entities/currency-type-info";
import { DisciplineType } from "src/app/models/api/enums/discipline-type";
import { DisciplineTypeInfo } from "src/app/models/api/entities/discipline-type-info";
import { EntityMapService } from "src/app/services/entity-map.service";
import { EventDeadlines } from "src/app/models/api/entities/event-deadlines";
import { EventDeadlinesLocal } from "src/app/models/event-deadlines-local";
import { EventFeatures } from "src/app/models/api/entities/event-features";
import { EventInfo } from "src/app/models/api/entities/event-info";
import { EventStage } from "src/app/models/api/enums/event-stage";
import { NgbNavConfig } from "@ng-bootstrap/ng-bootstrap";
import { UserPrivilege } from "src/app/models/api/enums/user-privilege";
import { UserService } from "src/app/services/user.service";
import { MessageService } from "src/app/shared/services/message.service";
import { isConvertableToNumber, toDate } from "src/app/shared/utils/utils";
import { stringIsNullOrEmpty, toDateTimeString } from "src/app/utils/utils";
import { AlertService } from "src/app/shared/services/alert.service";
import { IFormFile } from "src/app/models/api/entities/IFormFile";
import { EDiplomaTypes } from "src/app/models/api/enums/diplomas-types";
import { environment } from "src/environments/environment";
import { CdkDragEnd } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-event-settings",
  templateUrl: "./event-settings.component.html",
  styleUrls: ["./event-settings.component.scss"],
  providers: [NgbNavConfig],
})
export class EventSettingsComponent implements OnInit {
  storagePath = `${environment.apiUrl}/Storages/GetFile/`;

  public DisciplineType = DisciplineType;
  public isNavbarCollapsed = true;

  FullName: boolean = false;
  Country: boolean = false;
  Function: boolean = false;
  pickerClicked: boolean = false;
  textColor!: string;
  gallery!: any;
  posterFile: any;
  diplomModes: EDiplomaTypes = EDiplomaTypes.Portrait;
  public Landscape: Boolean = false;

  public allCountries: Country[];
  public allCurrencies: CurrencyTypeInfo[];
  public allDisciplines: DisciplineTypeInfo[];

  today?: Date;
  minDate?: Date;
  maxDate?: Date;

  public name = "";
  public isReloadRequired = false;
  public country: Country = null;
  public location = "";
  public discipline: DisciplineTypeInfo = null;
  public currency: CurrencyTypeInfo = null;
  public startDate: Date;
  public endDate: Date;
  public canManageSystem = false;
  public bankInfo = "";
  public entryFeePerGymnasts: number = 0;
  public entryFeePerGroup: number = 0;

  public quickTestFeePerPerson: number = 0;
  public pcrFeePerPerson: number = 0;

  editorConfig = {
    placeholder: "",
    tabsize: 2,
    height: "200px",
    toolbar: [["style", ["bold", "italic", "underline", "clear"]]],
  };

  public eventFeatures: EventFeatures;
  public eventDeadlines: EventDeadlinesLocal = {};

  public evt: EventInfo;

  public messageService: MessageService;

  coordinates: { top: number; left: number; key: string; isMove?: boolean }[] =
    [
      {
        top: undefined,
        left: undefined,
        key: "Fullname",
        isMove: true,
      },
      {
        top: undefined,
        left: undefined,
        key: "Country",
        isMove: true,
      },
      {
        top: undefined,
        left: undefined,
        key: "Function",
        isMove: true,
      },
    ];

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private ngbNavConfig: NgbNavConfig,
    private renderer: Renderer2
  ) {
    this.messageService = new MessageService();

    this.ngbNavConfig.destroyOnHide = false;
    this.ngbNavConfig.roles = "tablist";
  }

  async ngOnInit() {
    this.load();
  }

  getImagePos(event: MouseEvent){

    console.log("Bura girdi");
    const imageElement = event.target as HTMLImageElement;

    const clickX = event.offsetX;
    const clickY = event.offsetY;

    const imageWidth = imageElement.clientWidth;
    const imageHeight = imageElement.clientHeight;

    const topPercent = (clickY / imageHeight) * 100;
    const leftPercent = (clickX / imageWidth) * 100;

    // this.coordinates[0].top = topPercent;
    // this.coordinates[0].left = leftPercent;
    
    console.log(`This is mine->Top: ${topPercent.toFixed(2)}%, Left: ${leftPercent.toFixed(2)}%`);
  }

  getPosition(event: CdkDragEnd, index: number, container: HTMLElement): void {
    // this.coordinates[index].isMove = true;
    // const dragPosition = event.source.getFreeDragPosition();
    // const containerRect = container.getBoundingClientRect();

    // const containerWidth = containerRect.width;
    // const containerHeight = containerRect.height;

    // // Get the drag container's offset from the parent (posterContainer)
    // const dragElementRect =
    //   event.source.element.nativeElement.getBoundingClientRect();

    // // Calculate percentages based on container dimensions and the element's position inside the container
    // const xPercent =
    //   ((dragElementRect.left - containerRect.left) / containerWidth) * 100;
    // const yPercent =
    //   ((dragElementRect.top - containerRect.top) / containerHeight) * 100;

    //   // if(index!=0){
    //     this.coordinates[index].top = yPercent;
    //     this.coordinates[index].left = xPercent;
    //   // }
    
    // Store or manipulate percentage positions as needed



    this.coordinates[index].isMove = true;
    const dragPosition = event.source.getFreeDragPosition();

    const containerRect = container.getBoundingClientRect();



    const topPercent = (dragPosition.y / containerRect.height) * 100;
    const leftPercent = (dragPosition.x / containerRect.width) * 100;

    this.coordinates[index].top = topPercent;
    this.coordinates[index].left = leftPercent;
    console.log(this.coordinates);
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    this.allCountries = this.entityMapService.allCountries;
    this.allDisciplines = this.entityMapService.getAllDisciplineTypes();
    this.allCurrencies = this.entityMapService.getAllCurrencies();

    this.today = moment().set("hour", 0).set("minute", 0).toDate();
    this.minDate = moment()
      .add(0, "days")
      .set("hour", 0)
      .set("minute", 0)
      .toDate();
    this.maxDate = moment()
      .add(10, "years")
      .set("hour", 0)
      .set("minute", 0)
      .toDate();

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageSystem
    );
    const canManageEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageEvent
    );

    if (!canManageEvent) {
      this.close();
      return;
    }

    this.route.parent.paramMap.subscribe(async (paramMap) => {
      const evtIdStr = paramMap.get("eventId");
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      this.apiService.defaultCoordinate(evtIdStr).subscribe((e) => {
        this.gallery = e.data.diplomaId ? this.storagePath +  e.data.diplomaId : null;
        if(e.data.positions && e.data.positions.length>0){
          e.data.positions.forEach(newPosition=>{
            this.coordinates.forEach(f=>{
              if(newPosition.key==f.key){
                f.top=newPosition.top;
                f.left=newPosition.left;
                f.isMove=false;
              }
            })
            })
        }
        console.log(this.coordinates);
        
      });

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      //this.textColor = (evt.hexColor && evt.hexColor.trim() !== "") ? evt.hexColor : "#fff";
      this.textColor = evt.hexColor;
      this.gallery = evt.diplomaId ? this.storagePath + evt.diplomaId : null;

      if (evt == null) {
        this.close();
        return;
      }

      const canManageThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canManageThisEvent) {
        this.close();
        return;
      }

      this.evt = evt;
      this.name = evt.name;
      this.country = this.entityMapService.getCountryByCode(evt.countryCode);
      this.location = evt.location;
      this.discipline = this.entityMapService.getDisciplineByType(
        evt.disciplineType
      );
      this.currency = this.entityMapService.getCurrencyByType(evt.currencyType);
      this.startDate = toDate(evt.startDate);
      this.endDate = toDate(evt.endDate);
      this.bankInfo = evt.bankInfo;

      this.eventFeatures = evt.features;
      this.entryFeePerGymnasts = evt.features.entryFeePerGymnasts;
      this.entryFeePerGroup = evt.features.entryFeePerGroup;

      this.quickTestFeePerPerson = evt.features.quickTestFeePerPerson;
      this.pcrFeePerPerson = evt.features.pcrFeePerPerson;

      this.eventDeadlines = this.convertDeadlinesToLocal(evt.deadlines);
      this.canManageSystem = canManageSystem;
      let breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs = [...breadcrumbs, { key: "Events", link: `/events` }];
      }
      breadcrumbs = [
        ...breadcrumbs,
        { key: "", value: `${evt.name}`, link: `` },
      ];
      breadcrumbs = [...breadcrumbs, { key: "Settings", value: ``, link: `` }];
      this.breadcrumbsService.breadcrumbs = breadcrumbs;
      this.breadcrumbsService.title = this.evt.name;
    });
  }

  async toggleAccomodationMode() {
    this.eventFeatures.isAccomodationEnabled =
      !this.eventFeatures.isAccomodationEnabled;
    this.isReloadRequired = true;
  }

  async toggleMealsMode() {
    this.eventFeatures.isMealsEnabled = !this.eventFeatures.isMealsEnabled;
    this.isReloadRequired = true;
  }

  async toggleMusicMode() {
    this.eventFeatures.isMusicEnabled = !this.eventFeatures.isMusicEnabled;
  }

  async toggleHotelRoomLatePerNightPriceMode() {
    this.eventFeatures.isHotelRoomLatePerNightPriceEnabled =
      !this.eventFeatures.isHotelRoomLatePerNightPriceEnabled;
  }

  async toggleHotelRoomEarlyPerNightPriceMode() {
    this.eventFeatures.isHotelRoomEarlyPerNightPriceEnabled =
      !this.eventFeatures.isHotelRoomEarlyPerNightPriceEnabled;
  }

  async toggleHotelRoomEarlyCheckInAddPriceMode() {
    this.eventFeatures.isHotelRoomEarlyCheckInAddPriceEnabled =
      !this.eventFeatures.isHotelRoomEarlyCheckInAddPriceEnabled;
  }

  async toggleHotelRoomLateCheckOutAddPriceMode() {
    this.eventFeatures.isHotelRoomLateCheckOutAddPriceEnabled =
      !this.eventFeatures.isHotelRoomLateCheckOutAddPriceEnabled;
  }

  async togglePenaltyFunctionMode() {
    this.eventFeatures.isPenaltyFunctionEnabled =
      !this.eventFeatures.isPenaltyFunctionEnabled;
  }

  async toggleClubFunctionMode() {
    this.eventFeatures.isClubExist = !this.eventFeatures.isClubExist;
  }

  async toggleEntryFeeMode() {
    this.eventFeatures.isEntryFeeEnabled =
      !this.eventFeatures.isEntryFeeEnabled;
  }

  async toggleCovid19Mode() {
    this.eventFeatures.isCovid19Enabled = !this.eventFeatures.isCovid19Enabled;
  }

  toggleLandscapeMode() {
    this.Landscape = !this.Landscape;
    this.diplomModes = EDiplomaTypes.Landscape;
  }
  togglePortraitFeeMode() {
    this.Landscape = !this.Landscape;
    this.diplomModes = EDiplomaTypes.Portrait;
  }
  async onfileSelelcted(
    _event: any,
    fileInput: HTMLInputElement
  ): Promise<void> {
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];

      // Ensure the file is an image
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const img = new Image();

          img.onload = () => {
            // Checking the image dimensions
            const width = img.width;
            const height = img.height;
            this.gallery = e.target.result;
            this.coordinates=
            [
              {
                top: undefined,
                left: undefined,
                key: "Fullname",
                isMove: true,
              },
              {
                top: undefined,
                left: undefined,
                key: "Country",
                isMove: true,
              },
              {
                top: undefined,
                left: undefined,
                key: "Function",
                isMove: true,
              },
            ];
          };

          img.src = e.target.result; // The data URL from FileReader
        };

        reader.readAsDataURL(file as Blob);
        this.posterFile = file;
        // Clear the file input value after processing
        fileInput.value = "";
      } else {
        this.messageService.setToastMessage(
          true,
          "Please select a valid image file.",
          7
        );
      }
    }
  }
  removeAlbom() {
    this.gallery = "";
    this.posterFile = null;
  }

  async SaveAlbom() {
    
    if (stringIsNullOrEmpty(this.posterFile)) {
      await this.apiService.fetchImage(this.gallery).then((res) => {
        console.log("res=" + res.body);
        this.posterFile = this.createImageFromBlob(res.body);
      });
    }
    this.apiService
      .uploadDelegationDiploma(
        this.posterFile,
        this.evt.id,
        this.diplomModes,
        this.textColor,
        this.coordinates
      )
      .then((result) => {
        if (result.isSuccess) {
          this.messageService.setToastMessage(
            true,
            "Albom added successfully !!!",
            2
          );
        }
      });
  }
  //---------- Adding inputs to diplom----------
  addOrRemoveFullName() {
    this.FullName = !this.FullName;
  }
  addOrRemoveCountry() {
    this.Country = !this.Country;
  }
  addOrRemoveFunction() {
    this.Function = !this.Function;
  }
  createImageFromBlob(image: Blob) {
    const file = new File([image], "image.jpg", { type: image.type });
    return file;
  }
  ChooseColor() {
    this.pickerClicked = !this.pickerClicked;
  }
  chosenColor(color: string) {
    this.textColor = color;
    this.pickerClicked = false;
  }
  private close() {
    const user = this.apiService.currentUser;
    if (user == null) {
      this.router.navigate(["/auth"]);
      return;
    }

    const canManageEvents = this.entityMapService.userCan(
      user,
      UserPrivilege.ManageEvent
    );

    if (canManageEvents) {
      this.router.navigate([`/events/${this.evt.id}`]);
    } else {
      this.router.navigate(["/"]);
    }
  }

  private async save(): Promise<string> {
    return new Promise<string>(async (resolve) => {
      const evt: EventInfo = {
        id: this.evt.id,
        code: null,
        eventStage: EventStage.PreRegistrationStart,
        name: this.name,
        countryCode: this.country.code,
        country: null,
        currencyType: this.currency.currencyType,
        timeZone: null,
        location: this.location,
        bankInfo: this.bankInfo,
        disciplineType: this.discipline.disciplineType,
        groupTypes: [],
        categoryTypes: [],
        apparatusTypes: [],
        unitTypeCodes: [],
        unitTypes: [],

        startDate: toDateTimeString(this.startDate),
        endDate: toDateTimeString(this.endDate),

        deadlines: this.convertLocalToDeadlines(this.eventDeadlines, 1),
        features: this.eventFeatures,

        delegationsCount: 0,
        isDisabled: false,

        updatedOn: null,
        hexColor: this.evt.hexColor,
        diplomaId: this.evt.diplomaId,
      };

      evt.features.entryFeePerGymnasts = this.entryFeePerGymnasts;
      evt.features.entryFeePerGroup = this.entryFeePerGroup;

      evt.features.quickTestFeePerPerson = this.quickTestFeePerPerson;
      evt.features.pcrFeePerPerson = this.pcrFeePerPerson;

      const result = await this.apiService.updateEvent(evt);

      if (result.isSuccess) {
        resolve(null);
      } else {
        resolve(result.errorMessage);
      }
    });
  }

  async onSave() {
    const error = await this.save();
    if (error != null) {
      this.messageService.setToastMessage(false, error, 4);
    } else {
      this.messageService.setToastMessage(true, "Event updated", 4);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (this.isReloadRequired) {
        location.reload();
      }
    }
  }

  async onClose() {
    this.close();
  }

  onEnableEvent() {
    if (!this.canManageSystem)
      this.messageService.setToastMessage(true, `You can't enable event!`);

    const message = "Are you sure?";

    this.alertService.showConfirmation(message, (confirmed) => {
      if (confirmed) {
        this.apiService.enableEvent(this.evt.id).then((result) => {
          if (result.isSuccess) {
            this.router.navigate([`/events`]);
            this.messageService.setToastMessage(
              true,
              `Event successfully enabled!`
            );
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  onDisableEvent() {
    if (!this.canManageSystem)
      this.messageService.setToastMessage(true, `You can't disable event!`);

    const message = "Are you sure?";

    this.alertService.showConfirmation(message, (confirmed) => {
      if (confirmed) {
        this.apiService.disableEvent(this.evt.id).then((result) => {
          if (result.isSuccess) {
            this.router.navigate([`/events`]);
            this.messageService.setToastMessage(
              true,
              `Event successfully disabled!`
            );
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  convertDeadlinesToLocal(deadlines: EventDeadlines): EventDeadlinesLocal {
    const deadlinesLocal: EventDeadlinesLocal = {
      delegationFillingStartDate: toDate(deadlines.delegationFillingStartDate),
      delegationFillingEndDate: toDate(deadlines.delegationFillingEndDate),
      accommodationFillingStartDate: toDate(
        deadlines.accommodationFillingStartDate
      ),
      accommodationFillingEndDate: toDate(
        deadlines.accommodationFillingEndDate
      ),
      accommodationLateBookingStartDate: toDate(
        deadlines.accommodationLateBookingStartDate
      ),
      accommodationLateBookingEndDate: toDate(
        deadlines.accommodationLateBookingEndDate
      ),
      accomodationRangeStartDate: toDate(deadlines.accomodationRangeStartDate),
      accommodationRangeEndDate: toDate(deadlines.accommodationRangeEndDate),
      accommodationRoomFillingStartDate: toDate(
        deadlines.accommodationRoomFillingStartDate
      ),
      accommodationRoomFillingEndDate: toDate(
        deadlines.accommodationRoomFillingEndDate
      ),
      mealsFillingStartDate: toDate(deadlines.mealsFillingStartDate),
      mealsFillingEndDate: toDate(deadlines.mealsFillingEndDate),
      mealsRangeStartDate: toDate(deadlines.mealsRangeStartDate),
      mealsRangeEndDate: toDate(deadlines.mealsRangeEndDate),
      visaFillingStartDate: toDate(deadlines.visaFillingStartDate),
      visaFillingEndDate: toDate(deadlines.visaFillingEndDate),
      travelFillingStartDate: toDate(deadlines.travelFillingStartDate),
      travelFillingEndDate: toDate(deadlines.travelFillingEndDate),
      photoFillingStartDate: toDate(deadlines.photoFillingStartDate),
      photoFillingEndDate: toDate(deadlines.photoFillingEndDate),
      musicFillingStartDate: toDate(deadlines.musicFillingStartDate),
      musicFillingEndDate: toDate(deadlines.musicFillingEndDate),
    };
    return deadlinesLocal;
  }

  convertLocalToDeadlines(
    deadlinesLocal: EventDeadlinesLocal,
    evtId: number
  ): EventDeadlines {
    const deadlines: EventDeadlines = {
      eventId: evtId,
      delegationFillingStartDate: toDateTimeString(
        deadlinesLocal.delegationFillingStartDate
      ),
      delegationFillingEndDate: toDateTimeString(
        deadlinesLocal.delegationFillingEndDate
      ),
      accommodationFillingStartDate: toDateTimeString(
        deadlinesLocal.accommodationFillingStartDate
      ),
      accommodationFillingEndDate: toDateTimeString(
        deadlinesLocal.accommodationFillingEndDate
      ),
      accommodationLateBookingStartDate: toDateTimeString(
        deadlinesLocal.accommodationLateBookingStartDate
      ),
      accommodationLateBookingEndDate: toDateTimeString(
        deadlinesLocal.accommodationLateBookingEndDate
      ),
      accomodationRangeStartDate: toDateTimeString(
        deadlinesLocal.accomodationRangeStartDate
      ),
      accommodationRangeEndDate: toDateTimeString(
        deadlinesLocal.accommodationRangeEndDate
      ),
      accommodationRoomFillingStartDate: toDateTimeString(
        deadlinesLocal.accommodationRoomFillingStartDate
      ),
      accommodationRoomFillingEndDate: toDateTimeString(
        deadlinesLocal.accommodationRoomFillingEndDate
      ),
      mealsFillingStartDate: toDateTimeString(
        deadlinesLocal.mealsFillingStartDate
      ),
      mealsFillingEndDate: toDateTimeString(deadlinesLocal.mealsFillingEndDate),
      mealsRangeStartDate: toDateTimeString(deadlinesLocal.mealsRangeStartDate),
      mealsRangeEndDate: toDateTimeString(deadlinesLocal.mealsRangeEndDate),
      visaFillingStartDate: toDateTimeString(
        deadlinesLocal.visaFillingStartDate
      ),
      visaFillingEndDate: toDateTimeString(deadlinesLocal.visaFillingEndDate),
      travelFillingStartDate: toDateTimeString(
        deadlinesLocal.travelFillingStartDate
      ),
      travelFillingEndDate: toDateTimeString(
        deadlinesLocal.travelFillingEndDate
      ),
      photoFillingStartDate: toDateTimeString(
        deadlinesLocal.photoFillingStartDate
      ),
      photoFillingEndDate: toDateTimeString(deadlinesLocal.photoFillingEndDate),
      musicFillingStartDate: toDateTimeString(
        deadlinesLocal.musicFillingStartDate
      ),
      musicFillingEndDate: toDateTimeString(deadlinesLocal.musicFillingEndDate),
    };
    return deadlines;
  }
}
