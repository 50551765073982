import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { InstalmentsInfo } from 'src/app/models/api/entities/payment-tracker/instalments-info';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-manage-payment',
  templateUrl: './manage-payment.component.html',
  styleUrls: ['./manage-payment.component.scss']
})
export class ManagePaymentComponent implements OnInit {

  @Input() btnTitle: string = 'Enter installments';
  @Input() trackId: number = 0;
  @Input() installmentId: number = 0;
  @Input() ammount: number = 0;
  @Input() userFullName: string;
  @Output() saved = new EventEmitter<InstalmentsInfo>();
  @Input() isChangeInstallment: boolean = false;

  instalment: InstalmentsInfo;
  addNew: boolean = false;

  constructor(
    private messageService: MessageService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  private async load() {

    const userFullName = this.userFullName;

  }

  private async save() {

    // if(this.ammount > this.maxRestAmount){
    //   this.messageService.setToastMessage(false, `Maximum amount of installment must be ${this.maxRestAmount}`);
    //   return;
    // }

    let instalment: InstalmentsInfo = {
      trackId: this.trackId,
      amount: this.ammount,
      enteredBy: this.userFullName,
    };

    const request: UpdateRequest<InstalmentsInfo> = {
      isNew: true,
      subject: instalment
    };

    if(this.isChangeInstallment){
      instalment.id = this.installmentId;
      request.isNew = false
    }

    const result = await this.apiService.addInstalment(request);

    if (result.isSuccess) {
      this.saved.emit(instalment);
      this.messageService.setToastMessage(true, 'Instalment entered.', 7);
    } else {
      this.messageService.setToastMessage(false, result.errorMessage);
    }

    this.onAddNew();
  }

  async onSave() {
    this.save();
  }

  onAddNew(){
    this.addNew = !this.addNew;
  }

  onCancel(){
    this.onAddNew();
  }

}
