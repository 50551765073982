import { Pipe, PipeTransform } from '@angular/core';

import { EntityMapService } from '../services/entity-map.service';
import { MealType } from '../models/api/enums/meal-type';

@Pipe({
  name: 'mealType'
})
export class MealTypePipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  async transform(mealType: MealType): Promise<string> {
    if (mealType === null) {
      return null;
    }

    await this.entityMapService.ready();

    return this.entityMapService.getMealTypeName(mealType.toString());

  }

}
