import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelClassType } from 'src/app/models/api/enums/hotel-class-type';
import { HotelInfo } from 'src/app/models/api/entities/hotel-info';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss']
})
export class HotelComponent implements OnInit {

  public isNavbarCollapsed = true;

  public evt: EventInfo;
  public hotel: HotelInfo;
  public allHotelClassTypes: HotelClassType[];
  public isNew = true;
  public canManage = false;
  public headerItems: PageHeaderItem[];

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);

    if (!canViewEvent) {
      this.close();
      return;
    }

    this.allHotelClassTypes = this.entityMapService.allHotelClassTypes;

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async (paramMap) => {
      this.route.data.subscribe(async (data) => {
        const hotelIdStr = paramMap.get('hotelId');

        const isNewHotel = data != null && data.isNewHotel === true;

        if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
          this.close();
          return;
        }

        const evtId = Number(evtIdStr);
        const evt = await this.apiService.getEvent(evtId);
        if (evt == null) {
          this.close();
          return;
        }

        const canViewThisEvent = canManageSystem || curUser.eventId == evt.id;
        if (!canViewThisEvent) {
          this.close();
          return;
        }

        let hotelId = 0;
        if (isConvertableToNumber(hotelIdStr)) {
          hotelId = Number(hotelIdStr);
        }

        if (isNewHotel && hotelId !== 0) {
          this.close();
          return;
        }

        let hotel: HotelInfo;
        if (isNewHotel) {
          if (isNewHotel && !canManageEvent) {
            this.close();
            return;
          } else {
            hotel = {
              id: 0,
              eventId: evt.id,
              hotelClass: HotelClassType.III,
              roomsInfo: [],
              isDisabled: false,
              name: '',
            };
          }
        } else {
          hotel = await this.apiService.getHotel(hotelId);
          if (hotel == null) {
            this.close();
            return;
          }
        }

        this.evt = evt;
        this.hotel = hotel;
        this.isNew = isNewHotel;
        this.canManage = canManageEvent;

        let breadcrumbs: Breadcrumb[] = [];
        if (canManageSystem) {
          breadcrumbs = [...breadcrumbs, { key: 'Events', link: `/events` }]
        }
        breadcrumbs = [...breadcrumbs, { key: '', value: `${evt.name}`, link: `/events/${evt.id}` }]
        breadcrumbs = [...breadcrumbs, { key: 'Hotels', link: `/events/${evt.id}/hotels` }]
        breadcrumbs = [...breadcrumbs, { key: '', value: `${this.hotel.name ? this.hotel.name : 'New hotel'}`, link: `` }]
        this.breadcrumbsService.breadcrumbs = breadcrumbs;
        this.breadcrumbsService.title = this.hotel.name;

        this.headerItems = [
          { title: 'Save', iconClass: 'fa-check', callback: () => this.onSave() },
          { title: 'Close', iconClass: 'fa-times', callback: () => this.onClose() },
        ]
      });
    });
  }

  private close() {
    if (this.evt == null) {
      this.router.navigate(['/']);
      return;
    }

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);
    const canViewThisEvent = canManageSystem || (canViewEvent && curUser.eventId === this.evt.id);

    if (canViewThisEvent) {
      this.router.navigate(['/events/' + this.evt.id + '/hotels']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private async save() {

    if (stringIsNullOrEmpty(this.hotel.name)) {
      this.messageService.setToastMessage(false, 'Please add name.');
      return;
    }

    const result = await this.apiService.updateHotel({ isNew: this.isNew, subject: this.hotel });
    if (result.isSuccess) {
      this.messageService.setToastMessage(true, this.isNew ? 'Hotel created' : 'Hotel updated', 10);
      this.router.navigate([`/events/${this.evt.id}/hotels/${result.data}`]);
    } else {
      this.messageService.setToastMessage(false, result.errorMessage, 10);
    }
  }

  //#endregion

  //#region Actions

  onSave() {
    this.save();
  }

  onClose() {
    this.close();
  }

  //#endregion

  //#region Data

  getHotelClassTypeName(hotelClass: HotelClassType) {
    return this.entityMapService.getHotelClassTypeName(hotelClass);
  }

  //#endregion

}
