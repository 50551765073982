import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from '../../services/message.service';

export enum MessageType {
	card = 'card',
	modal = 'modal',
}

@Component({
	selector: '<sac-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

	@Input() public messageService: MessageService;
	@Input() public type: MessageType = MessageType.card;
	@Input() public classes: string;

	constructor(mainMessageService: MessageService) {
		this.messageService = mainMessageService;
	}

	ngOnInit() {
	}

}
