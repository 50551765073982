import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { StaticData } from '../models/api/entities/static-data';

import { Country } from '../models/api/entities/country';
import { City } from '../models/api/entities/city';
import { UserRole } from '../models/api/enums/user-role';
import { UserPrivilege } from '../models/api/enums/user-privilege';
import { UserRoleInfo } from '../models/api/entities/user-role-info';
import { UserInfo } from '../models/api/entities/user-info';
import { DisciplineTypeInfo } from '../models/api/entities/discipline-type-info';
import { DisciplineType } from '../models/api/enums/discipline-type';
import { ApparatusTypeInfo } from '../models/api/entities/apparatus-type-info';
import { ApparatusType } from '../models/api/enums/apparatus-type';
import { UnitType } from '../models/api/entities/unit-type';
import { sleep } from '../utils/utils';
import { GroupType } from '../models/api/enums/group-type';
import { GroupTypeInfo } from '../models/api/entities/group-type-info';
import { CategoryTypeInfo } from '../models/api/entities/category-type-info';
import { CategoryType } from '../models/api/enums/category-type';
import { Gender } from '../models/api/enums/gender';
import { GenderInfo } from '../models/api/entities/gender-info';
import { EventStage } from '../models/api/enums/event-stage';
import { EventStageInfo } from '../models/api/entities/event-stage-info';
import { isString } from 'util';
import { FunctionType } from '../models/api/enums/function-type';
import { FunctionTypeInfo } from '../models/api/entities/function-type-info';
import { CurrencyType } from '../models/api/enums/currency-type';
import { CurrencyTypeInfo } from '../models/api/entities/currency-type-info';
import { HotelClassType } from '../models/api/enums/hotel-class-type';
import { HotelClassTypeInfo } from '../models/api/entities/hotel-class-type-info';
import { RoomType } from '../models/api/enums/room-type';
import { RoomTypeInfo } from '../models/api/entities/room-type-info';
import { MealType } from '../models/api/enums/meal-type';
import { MealTypeInfo } from '../models/api/entities/meal-type-info';
import { Airport } from '../models/api/entities/airport';
import { FlightType } from '../models/api/enums/flight-type';
import { FlightTypeInfo } from '../models/api/entities/flight-type-info';
import { VisaType } from '../models/api/enums/visa-type';
import { VisaTypeInfo } from '../models/api/entities/visa-type-info';
import { PenaltyType } from '../models/api/enums/penalty-type';
import { PenaltyTypeInfo } from '../models/api/entities/penalty-type-info';

@Injectable()
export class EntityMapService {
  private apiService: ApiService;

  private isPreloading = false;

  //#region Data Fields

  private staticData: StaticData = null;

  public allCountries: Country[] = [];
  public uegCountries: Country[] = [];

  public allCities: City[] = [];

  public allAirports: Airport[] = [];

  public allUserRoles: UserRole[] = [];
  private rolesCache: UserRoleInfo[] = [];

  public allGenders: Gender[] = [];
  private gendersCache: GenderInfo[] = [];

  public allCurrencies: CurrencyType[] = [];
  private currenciesCache: CurrencyTypeInfo[] = [];

  public allFlightTypes: FlightType[] = [];
  private flightCache: FlightTypeInfo[] = [];

  public allDisciplineTypes: DisciplineType[] = [];
  private disciplineTypesCache: DisciplineTypeInfo[] = [];

  public allApparatusTypes: ApparatusType[] = [];
  private apparatusTypesCache: ApparatusTypeInfo[] = [];

  private unitTypesCache: UnitType[] = [];

  public allGroupTypes: GroupType[] = [];
  private groupTypesCache: GroupTypeInfo[] = [];

  public allCategoryTypes: CategoryType[] = [];
  private categoryTypesCache: CategoryTypeInfo[] = [];

  public allEventStages: EventStage[] = [];
  private eventStagesCache: EventStageInfo[] = [];

  public allFunctionTypes: FunctionType[] = [];
  private functionTypesCache: FunctionTypeInfo[] = [];

  public allHotelClassTypes: HotelClassType[] = [];
  private hotelClassTypesCache: HotelClassTypeInfo[] = [];

  public allRoomTypes: RoomType[] = [];
  private roomTypesCache: RoomTypeInfo[] = [];

  public allMealTypes: MealType[] = [];
  private mealTypesCache: MealTypeInfo[] = [];

  public allVisaTypes: VisaType[] = [];
  private visaTypesCache: VisaTypeInfo[] = [];

  public allPenaltyTypes: PenaltyType[] = [];
  private penaltyTypesCache: PenaltyTypeInfo[] = [];

  //#endregion

  constructor() { }

  public async setApiServise(apiService: ApiService) {
    this.apiService = apiService;
    this.preloadAll();
  }

  public ready(): Promise<void> {
    return new Promise<void>(async resolve => {
      await this.preloadAll();
      resolve();
    });
  }

  //#region Data Preloading

  private async preloadAll() {
    while (this.isPreloading) {
      await sleep(50);
    }

    if (this.staticData != null) {
      return;
    }

    this.isPreloading = true;

    await this.preloadStaticData();

    this.preloadCountries();
    this.preloadCities();
    this.preloadAirports();
    this.preloadUserRoles();
    this.preloadGenders();
    this.preloadCurrencies();
    this.preloadFlights();
    this.preloadEventStages();
    this.preloadDisciplineTypes();
    this.preloadApparatusTypes();
    this.preloadUnitTypes();
    this.preloadGroupTypes();
    this.preloadCategoryTypes();
    this.preloadFunctionTypes();
    this.preloadHotelClassTypes();
    this.preloadRoomTypes();
    this.preloadMealTypes();
    this.preloadVisaTypes();
    this.preloadPenaltyTypes();

    this.isPreloading = false;
  }

  //#endregion

  //#region StaticData

  private async preloadStaticData() {
    await this.apiService.getStaticData().then(staticData => {
      if (staticData == null) {
        return;
      }
      this.staticData = staticData;
    });
  }

  //#endregion

  //#region Countries

  private preloadCountries() {
    this.allCountries = this.staticData.countries;
    this.uegCountries = this.allCountries.filter(country => {
      return country.isMemberOfUeg;
    });
  }

  public getCountryByCode(countryCode: string): Country {
    if (countryCode == null) {
      return null;
    }

    const country = this.allCountries.find(
      c => c.code.toLowerCase() === countryCode.toLowerCase()
    );
    return country;
  }

  public getCountryFlagAddress(country: string | Country): string {
    if (country == null) {
      return null;
    }
    let countryCode: string;
    if (isString(country)) {
      countryCode = <string>country;
    } else {
      countryCode = (<Country>country).code;
    }
    countryCode = countryCode.toLowerCase();
    const address = 'assets/images/flags/' + countryCode + '.gif';
    return address;
  }

  //#endregion

  //#region Cities

  private preloadCities() {
    this.allCities = this.staticData.cities;
  }

  public getCitiesByCountryCode(countryCode: string): any {
    if (countryCode == null) {
      return null;
    }

    const cities = this.allCities.find(c => c.iocCode.toLowerCase() === countryCode.toLowerCase()).cities;

    return cities;
  }

  //#endregion

  //#region Airports

  private preloadAirports() {
    this.allAirports = this.staticData.airports;
  }

  public getAirportsByCountryName(countryName: string): Airport[] {
    if (countryName == null) {
      return null;
    }

    return this.allAirports.filter(c => c.country.toLowerCase() === countryName.toLowerCase());

  }

  public getAirportByIataCode(iataCode: string): Airport {
    if (iataCode == null) {
      return null;
    }

    const airport = this.allAirports.find(c => c.iataCode.toLowerCase() === iataCode.toLowerCase());

    return airport;
  }

  //#endregion

  //#region Genders

  private preloadGenders() {
    this.allGenders = Object.keys(Gender).filter(key => !isNaN(Number(Gender[key]))).map(key => Gender[key]);
    this.gendersCache = this.staticData.genderTypes;
  }

  public getAllGenders(): GenderInfo[] {
    return this.gendersCache;
  }

  public getGenderByType(
    gender: Gender
  ): GenderInfo {
    return this.gendersCache.find(
      d => d.gender === gender
    );
  }

  //#endregion

  //#region VisaTypes

  private preloadVisaTypes() {
    this.allVisaTypes = Object.keys(VisaType).filter(key => !isNaN(Number(VisaType[key]))).map(key => VisaType[key]);
    this.visaTypesCache = this.staticData.visaTypes;
  }

  public getAllVisaTypes(): VisaTypeInfo[] {
    return this.visaTypesCache;
  }

  public getVisaTypeByType(
    visaType: VisaType
  ): VisaTypeInfo {
    return this.visaTypesCache.find(
      d => d.visaType === visaType
    );
  }

  //#endregion

  //#region PenaltyTypes

  private preloadPenaltyTypes() {
    this.allPenaltyTypes = Object.keys(PenaltyType).filter(key => !isNaN(Number(PenaltyType[key]))).map(key => PenaltyType[key]);
    this.penaltyTypesCache = this.staticData.penaltyTypes;
  }

  public getAllPenaltyTypes(): PenaltyTypeInfo[] {
    return this.penaltyTypesCache;
  }

  public getPenaltyTypeByType(
    penaltyType: PenaltyType
  ): PenaltyTypeInfo {
    return this.penaltyTypesCache.find(
      d => d.penaltyType === penaltyType
    );
  }

  public getPenaltyTypeName(penaltyType: PenaltyType): string {
    const penalty = this.penaltyTypesCache.find(t => t.penaltyType === penaltyType);
    return penalty == null ? null : penalty.name;
  }

  //#endregion

  //#region Currencies

  private preloadCurrencies() {
    this.allCurrencies = Object.keys(CurrencyType)
      .filter(key => !isNaN(Number(CurrencyType[key])))
      .map(key => CurrencyType[key]);
    this.currenciesCache = this.staticData.currencyTypes;
  }

  public getAllCurrencies(): CurrencyTypeInfo[] {
    return this.currenciesCache;
  }

  public getCurrencyByType(
    currency: CurrencyType
  ): CurrencyTypeInfo {
    return this.currenciesCache.find(
      d => d.currencyType === currency
    );
  }

  //#endregion

  //#region Flights

  private preloadFlights() {
    this.allFlightTypes = Object.keys(FlightType)
      .filter(key => !isNaN(Number(FlightType[key])))
      .map(key => FlightType[key]);
    this.flightCache = this.staticData.flightTypes;
  }

  public getAllFlightTypes(): FlightTypeInfo[] {
    return this.flightCache;
  }

  public getFlightByType(flight: FlightType): FlightTypeInfo {
    return this.flightCache.find(
      d => d.code === flight.toString()
    );
  }

  public getFlightByCode(code: string): FlightType {
    return this.flightCache.find(d => d.code === code).flightType;
  }

  //#endregion


  //#region Users/Roles/Privileges

  private preloadUserRoles() {
    this.allUserRoles = Object.keys(UserRole)
      .filter(key => !isNaN(Number(UserRole[key])))
      .map(key => UserRole[key]);
    this.rolesCache = this.staticData.userRoles;
  }

  public userCan(user: UserInfo, privilege: UserPrivilege): boolean {
    if (user == null) {
      return false;
    }
    return this.roleCan(user.role, privilege);
  }

  public roleCan(role: UserRole, privilege: UserPrivilege): boolean {
    if (role == null || privilege == null) {
      return null;
    }
    const roleItem = this.rolesCache.find(item => item.role === role);
    if (roleItem == null) {
      return false;
    }
    const result = roleItem.privileges.some(t => t === privilege);
    return result;
  }

  //#endregion

  //#region DisciplineTypes

  private async preloadDisciplineTypes() {
    this.allDisciplineTypes = Object.keys(DisciplineType)
      .filter(key => !isNaN(Number(DisciplineType[key])))
      .map(key => DisciplineType[key]);
    this.disciplineTypesCache = this.staticData.disciplines;
  }

  public getAllDisciplineTypes(): DisciplineTypeInfo[] {
    return this.disciplineTypesCache;
  }

  public getDisciplineByType(
    disciplineType: DisciplineType
  ): DisciplineTypeInfo {
    return this.disciplineTypesCache.find(
      d => d.disciplineType === disciplineType
    );
  }

  public getDisciplineTypesJoint(
    disciplineTypes: DisciplineType[] | DisciplineTypeInfo[]
  ): string {
    if (disciplineTypes == null || disciplineTypes.length === 0) {
      return '';
    }
    if (typeof disciplineTypes[0] === 'number') {
      return (<DisciplineType[]>disciplineTypes)
        .map(t => this.getDisciplineByType(t).code)
        .map(t => `[${t}]`)
        .join(', ');
    } else {
      return (<DisciplineTypeInfo[]>disciplineTypes)
        .map(t => this.getDisciplineByType(t.disciplineType).code)
        .map(t => `[${t}]`)
        .join(', ');
    }
  }

  //#endregion

  //#region ApparatusTypes

  private preloadApparatusTypes() {
    this.allApparatusTypes = Object.keys(ApparatusType)
      .filter(key => !isNaN(Number(ApparatusType[key])))
      .map(key => ApparatusType[key]);

    const apparatuses: ApparatusTypeInfo[] = [];
    for (const disciplineType of this.disciplineTypesCache) {
      if (
        disciplineType.apparatusTypes != null &&
        disciplineType.apparatusTypes.length > 0
      ) {
        for (const apparatusType of disciplineType.apparatusTypes) {
          if (
            apparatuses.every(
              a => a.apparatusType !== apparatusType.apparatusType
            )
          ) {
            apparatuses.push(apparatusType);
          }
        }
      }
    }
    this.apparatusTypesCache = apparatuses;
  }

  public getAllApparatusTypes(): ApparatusTypeInfo[] {
    return this.apparatusTypesCache;
  }

  public getApparatusByType(apparatusType: ApparatusType): ApparatusTypeInfo {
    return this.apparatusTypesCache.find(
      a => a.apparatusType === apparatusType
    );
  }

  public getApparatusTypesJoint(
    apparatusTypes: ApparatusType[] | ApparatusTypeInfo[]
  ): string {
    if (apparatusTypes == null || apparatusTypes.length === 0) {
      return '';
    }
    if (typeof apparatusTypes[0] === 'number') {
      return (<ApparatusType[]>apparatusTypes)
        .map(t => this.getApparatusByType(t).code)
        .map(t => `[${t}]`)
        .join(', ');
    } else {
      return (<ApparatusTypeInfo[]>apparatusTypes)
        .map(t => this.getApparatusByType(t.apparatusType).code)
        .map(t => `[${t}]`)
        .join(', ');
    }
  }

  //#endregion

  //#region UnitTypes

  private preloadUnitTypes() {
    const unitTypes: UnitType[] = [];
    for (const disciplineType of this.disciplineTypesCache) {
      if (
        disciplineType.unitTypes != null &&
        disciplineType.unitTypes.length > 0
      ) {
        for (const unitType of disciplineType.unitTypes) {
          if (unitTypes.every(u => u.code !== unitType.code)) {
            unitTypes.push(unitType);
          }
        }
      }
    }
    this.unitTypesCache = unitTypes;
  }

  public getAllUnitTypes(): UnitType[] {
    return this.unitTypesCache;
  }

  public getUnitByCode(code: string): UnitType {
    return this.unitTypesCache.find(a => a.code === code);
  }

  //#endregion

  //#region GroupTypes

  private preloadGroupTypes() {
    this.allGroupTypes = Object.keys(GroupType)
      .filter(key => !isNaN(Number(GroupType[key])))
      .map(key => GroupType[key]);

    const groupTypes: GroupTypeInfo[] = [];
    for (const disciplineType of this.disciplineTypesCache) {
      if (
        disciplineType.groupTypes != null &&
        disciplineType.groupTypes.length > 0
      ) {
        for (const groupType of disciplineType.groupTypes) {
          if (groupTypes.every(g => g.groupType !== groupType.groupType)) {
            groupTypes.push(groupType);
          }
        }
      }
    }
    this.groupTypesCache = groupTypes;
  }

  public getAllGroupTypes(): GroupTypeInfo[] {
    return this.groupTypesCache;
  }

  public getGroupByType(groupType: GroupType): GroupTypeInfo {
    return this.groupTypesCache.find(a => a.groupType === groupType);
  }

  //#endregion

  //#region CategoryTypes

  private preloadCategoryTypes() {
    this.allCategoryTypes = Object.keys(CategoryType)
      .filter(key => !isNaN(Number(CategoryType[key])))
      .map(key => CategoryType[key]);

    const categoryTypes: CategoryTypeInfo[] = [];
    for (const disciplineType of this.disciplineTypesCache) {
      if (
        disciplineType.categoryTypes != null &&
        disciplineType.categoryTypes.length > 0
      ) {
        for (const categoryType of disciplineType.categoryTypes) {
          if (
            categoryTypes.every(
              g => g.categoryType !== categoryType.categoryType
            )
          ) {
            categoryTypes.push(categoryType);
          }
        }
      }
    }
    this.categoryTypesCache = categoryTypes;
  }

  public getAllCategoryTypes(): CategoryTypeInfo[] {
    return this.categoryTypesCache;
  }

  public getCategoryByType(categoryType: CategoryType): CategoryTypeInfo {
    return this.categoryTypesCache.find(a => a.categoryType === categoryType);
  }

  //#endregion

  //#region EventStages

  private preloadEventStages() {
    this.allEventStages = Object.keys(EventStage)
      .filter(key => !isNaN(Number(EventStage[key])))
      .map(key => EventStage[key]);

    this.eventStagesCache = this.staticData.eventStages;
  }

  public getAllEventStages(): EventStageInfo[] {
    return this.eventStagesCache;
  }

  public getEventStageType(eventStage: EventStage): EventStageInfo {
    return this.eventStagesCache.find(a => a.eventStage === eventStage);
  }

  public getEventStageName(eventStage: EventStage): string {
    const stage = this.eventStagesCache.find(t => t.eventStage === eventStage);
    return stage == null ? null : stage.name;
  }

  //#endregion

  //#region FunctionTypes

  private preloadFunctionTypes() {
    this.allFunctionTypes = Object.keys(FunctionType)
      .filter(key => !isNaN(Number(FunctionType[key])))
      .map(key => FunctionType[key]);

    this.functionTypesCache = this.staticData.functionTypes;
  }

  public getAllFunctions(): FunctionTypeInfo[] {
    return this.functionTypesCache;
  }

  public getFunctionType(functionType: FunctionType): FunctionTypeInfo {
    return this.functionTypesCache.find(a => a.functionType === functionType);
  }

  public getFunctionTypeName(functionType: FunctionType): string {
    const fn = this.functionTypesCache.find(t => t.functionType === functionType);
    return fn == null ? null : fn.name;
  }

  //#endregion

  //#region HotelClassTypes

  private preloadHotelClassTypes() {
    this.allHotelClassTypes = Object.keys(HotelClassType)
      .filter(key => !isNaN(Number(HotelClassType[key])))
      .map(key => HotelClassType[key]);

    this.hotelClassTypesCache = this.staticData.hotelClasses;
  }

  public getAllHotelClasses(): HotelClassTypeInfo[] {
    return this.hotelClassTypesCache;
  }

  public getHotelClassType(hotelClassType: HotelClassType): HotelClassTypeInfo {
    return this.hotelClassTypesCache.find(a => a.hotelClassType === hotelClassType);
  }

  public getHotelClassTypeName(hotelClassType: HotelClassType): string {
    const hotelClass = this.hotelClassTypesCache.find(t => t.hotelClassType === hotelClassType);
    return hotelClass == null ? null : hotelClass.name;
  }

  //#endregion

  //#region RoomTypes

  private preloadRoomTypes() {
    this.allRoomTypes = Object.keys(RoomType)
      .filter(key => !isNaN(Number(RoomType[key])))
      .map(key => RoomType[key]);

    this.roomTypesCache = this.staticData.roomTypes;
  }

  public getAllRoomTypes(): RoomTypeInfo[] {
    return this.roomTypesCache;
  }

  public getRoomType(roomType: RoomType): RoomTypeInfo {
    return this.roomTypesCache.find(a => a.roomType === roomType);
  }

  public getRoomTypeName(roomType: RoomType): string {
    const room = this.roomTypesCache.find(t => t.roomType === roomType);
    return room == null ? null : room.name;
  }

  //#endregion

  //#region MealTypes

  private preloadMealTypes() {
    this.allMealTypes = Object.keys(MealType)
      .filter(key => !isNaN(Number(MealType[key])))
      .map(key => MealType[key]);

    this.mealTypesCache = this.staticData.mealTypes;
  }

  public getAllMealTypes(): MealTypeInfo[] {
    return this.mealTypesCache;
  }

  public getMealType(mealType: MealType): MealTypeInfo {
    return this.mealTypesCache.find(a => a.mealType === mealType);
  }

  public getMealTypeName(mealType: string): string {
    const meal = this.mealTypesCache.find(t => t.code === mealType);
    return meal == null ? null : meal.name;
  }

  //#endregion

}
