export enum RoomType {
  S = 0,
  TW,
  TR,
  QD,
  TW_SEP_B,
  TW_SH_B,
  Single_BB,
  Single_HB,
  Single_FB,
  Twin_BB,
  Twin_HB,
  Twin_FB,
  Triple_BB,
  Triple_HB,
  Triple_FB,
  Multiple_4_beds_BB,
  Multiple_4_beds_HB,
  Multiple_4_beds_FB,
  Multiple_6_beds_BB,
  Multiple_6_beds_HB,
  Multiple_6_beds_FB
}
