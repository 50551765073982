// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  text-align: center;
  white-space: nowrap;
  width: 38%;
}

th:last-child {
  border-right: 0;
}

.table-container {
  overflow: auto;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}`, "",{"version":3,"sources":["webpack://./src/app/components/events/special-export/special-export.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,UAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACE,cAAA;AACF;;AACA;EACE,UAAA;EACA,uBAAA,EAAA,+BAAA;AAEF","sourcesContent":["th, td {\r\n    text-align: center;\r\n    white-space: nowrap;\r\n    width: 38%;\r\n}\r\n\r\nth:last-child {\r\n    border-right: 0;\r\n}\r\n\r\n.table-container{\r\n  overflow: auto;\r\n}\r\n::-webkit-scrollbar {\r\n  width: 0px;\r\n  background: transparent; /* make scrollbar transparent */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
