import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dateMonthName'
})
export class DateMonthNamePipe implements PipeTransform {

	transform(input: number): string {
		const monthNames: string[] = [
			'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
			'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
		];

		return monthNames[input - 1];
	}

}
