import { UserService } from './../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { FlightInfo } from 'src/app/models/api/entities/flight-info';
import { FlightType } from 'src/app/models/api/enums/flight-type';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MimeTypes } from 'src/app/utils/mime-types';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { PersonsListRequest } from 'src/app/models/api/protocol/persons-list-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { isConvertableToNumber, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-flights-list',
  templateUrl: './flights-list.component.html',
  styleUrls: ['./flights-list.component.scss']
})
export class FlightsListComponent implements OnInit {

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public flights: ListResponse<FlightInfo> = new LocalListResponse<FlightInfo>();

  public currentFlightId: number;
  public viewingFlights = false;

  public canManageDelegation = false;

  public isObserver: boolean = false;

  public messageService: MessageService;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      if ( curUser.role === 3 ){
        this.isObserver = true;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;

      const breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }
      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      if (this.isObserver) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      // eslint-disable-next-line max-len
      if(evt.features.isClubExist === false)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` });
        breadcrumbs.push({ key: 'Travel', value: '', link:''})
      }

      if(evt.features.isClubExist === true)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}` });
        breadcrumbs.push({ key: 'Travel', value: '', link:''})
      }

      this.breadcrumbsService.breadcrumbs = breadcrumbs;

      this.updateHeaderItems();

      if (params.has('flightId')) {
        const flightIdStr = params.get('flightId');

        let flightId = 0;
        if (isConvertableToNumber(flightIdStr)) {
          flightId = Number(flightIdStr);
        }

        if (flightId !== 0) {
          this.viewFlight(flightId);
        }else{
          this.addFlight();
        }

      };

      this.reloadList();

    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private updateHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    if (this.canManageDelegation) {
      // headerItems.push({ title: 'Download travel', iconClass: 'fa-file-excel-o', callback: () => this.onDownloadAll() });
      // headerItems.push({ title: 'Download arrivals', iconClass: 'fa-file-excel-o', callback: () => this.onDownloadArrivals() });
      // headerItems.push({ title: 'Download departures', iconClass: 'fa-file-excel-o', callback: () => this.onDownloadDepartures() });
      headerItems.push({ title: 'Add flight', iconClass: 'fa-plus', callback: () => this.onAddFlight() });
    }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    // eslint-disable-next-line max-len
    this.flights = await this.apiService.getFlightsList(true, this.itemsPerPage, this.flights.page, null, this.delegation.eventId, this.delegation.countryCode, this.clubShortName);
  }

  private viewFlight(flightId: number) {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/flights/${flightId}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flights/${flightId}`;

    this.location.go(path);
    this.currentFlightId = flightId;
    this.viewingFlights = true;
  }

  private closeFlight() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/flights`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flights`;

    this.location.go(path);
    this.viewingFlights = false;
    this.currentFlightId = 0;
    this.onFlightUpdated();
  }

  private addFlight() {
    this.viewFlight(0);
  }

  private downloadAll() {
    let path = `/Export/FlightReservations?eventId=${this.evt.id}&country=${this.delegation.countryCode}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `/Export/FlightReservations?eventId=${this.evt.id}&country=${this.delegation.countryCode}&club=${this.delegation.clubShortName}`;

    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private downloadArrivals() {
    let path = `/Export/FlightReservations?eventId=${this.evt.id}&arrivals=true&country=${this.delegation.countryCode}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `/Export/FlightReservations?eventId=${this.evt.id}&arrivals=true&country=${this.delegation.countryCode}&club=${this.delegation.clubShortName}`;

    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private downloadDepartures() {
    let path = `/Export/FlightReservations?eventId=${this.evt.id}&departures=true&country=${this.delegation.countryCode}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `/Export/FlightReservations?eventId=${this.evt.id}&departures=true&country=${this.delegation.countryCode}&club=${this.delegation.clubShortName}`;

    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private deleteFlight(flight: FlightInfo) {
    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        this.apiService.deleteFlight(flight.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `Travel '${flight.flightNumber} has successfully deleted`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  //#endregion

  //#region Actions

  onPageChanged(page: number) {
    this.flights.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onAddFlight() {
    this.addFlight();
  }

  onViewFlight(flight: FlightInfo) {
    this.viewFlight(flight.id);
  }

  onDeleteFlight(flight: FlightInfo) {
    this.deleteFlight(flight);
  }

  onFlightUpdated() {
    this.reloadList();
  }

  onFlightClosed() {
    this.closeFlight();
  }

  onDownloadAll() {
    this.downloadAll();
  }

  onDownloadArrivals() {
    this.downloadArrivals();
  }

  onDownloadDepartures() {
    this.downloadDepartures();
  }

  //#endregion

  getFlightTypeName(flight: FlightInfo) {
    if (flight === null || flight.flightType === null) {
      return null;
    }
    return this.entityMapService.getFlightByType(flight.flightType);
  }


}
