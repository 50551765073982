import { UpdateRequest } from '../../models/api/protocol/update-request';
import { ApiService } from 'src/app/services/api.service';
import { DelegationInfo } from '../../models/api/entities/delegation-info';
import { EventInfo } from '../../models/api/entities/event-info';
import { Component, Input, OnInit } from '@angular/core';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-person-entry-fee',
  templateUrl: './person-entry-fee.component.html',
  styleUrls: ['./person-entry-fee.component.scss']
})
export class PersonEntryFeeComponent implements OnInit {

  public entryFeeHeaderItems: PageHeaderItem[] = [];

  @Input() evt: EventInfo;
  @Input() delegation: DelegationInfo;
  @Input() canManageThisDelegation: boolean;
  @Input() readonly: boolean = false;

  public entryFeeCount: number = 0;
  public totalPerson: number = 0;

  constructor(
    private readonly apiService: ApiService,
    private readonly messageService: MessageService
    ) { }

  ngOnInit(): void {
    this.load();
  }

  private async load() {

    this.entryFeeCount = this.delegation.entryFeePersonsCount;

    if (this.canManageThisDelegation) {
      this.entryFeeHeaderItems = [{ title: 'Update', iconClass: 'fa-save text-primary', callback: () => this.onSaveEntryFeeInfo() }];
    }

  }

  onIncDecEntryFee(num: number) {
    this.incDecEntryFee(num);
  }

  onSaveEntryFeeInfo() {
    this.saveEntryFeeInfo();
  }

  private async incDecEntryFee(num: number) {
    if(num < 0 && this.entryFeeCount === 0){
      this.entryFeeCount = 0;
      return;
    }

    this.entryFeeCount += num;
  }

  countTotal(price: number, personsCount: number): number {
    return price * personsCount;
  }

  private async saveEntryFeeInfo() {
    this.delegation.entryFeePersonsCount = this.entryFeeCount;
    const request = await this.apiService.updateDelegation(this.delegation);

    if(request.isSuccess){
      this.messageService.setToastMessage(true, 'Entry-Fee information updated.');
    }
  }

}
