import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { List } from 'lodash';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { SpecialExportInfo } from 'src/app/models/api/entities/special-export/special-export-info';
import { ExportType } from 'src/app/models/api/enums/export-type';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { ApiResponse } from 'src/app/models/api/protocol/api-response';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';
import { MimeTypes } from 'src/app/utils/mime-types';

@Component({
  selector: 'app-special-export',
  templateUrl: './special-export.component.html',
  styleUrls: ['./special-export.component.scss'],
  providers: [NgbNavConfig]
})
export class SpecialExportComponent implements OnInit {

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public canManageEvent = false;

  public allReportsList: ApiResponse<SpecialExportInfo[]>;
  public accomodationsList: SpecialExportInfo[];
  public roomingList: SpecialExportInfo[];
  public mealList: SpecialExportInfo[];

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private ngbNavConfig: NgbNavConfig
  ) {
    this.messageService = new MessageService();
    this.ngbNavConfig.destroyOnHide = false;
    this.ngbNavConfig.roles = 'tablist';
  }

  ngOnInit(): void {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);

    if (!canViewEvent) {
      this.close();
      return;
    }

    this.route.parent.paramMap.subscribe(async params => {
      const evtIdStr = params.get('eventId');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      this.canManageEvent = canManageEvent;

      this.evt = evt;

      if (curUser.role === 1) {
        this.breadcrumbsService.breadcrumbs = [
          { key: 'Events', link: `/events` },
        ];
      }

      if (canManageEvent) {
        this.breadcrumbsService.breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        this.breadcrumbsService.breadcrumbs.push({ key: 'Special Export', link: `` });
        this.breadcrumbsService.title = evt.name;

        this.headerItems = [
          ...this.headerItems,
          { title: 'Create Recent Reports', iconClass: 'fa-cogs', callback: () => this.onGenerateLatestReports() },
          { title: 'Close', iconClass: 'fa-close', callback: () => this.onClose() }
        ];
      }

      this.reloadList();
    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate([`/events/${this.evt.id}`]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private async reloadList() {
    this.allReportsList = await this.apiService.getReports(this.evt.id);

    this.accomodationsList = this.allReportsList.data.filter(t => t.exportType === ExportType.Accommodation);
    this.roomingList = this.allReportsList.data.filter(t => t.exportType === ExportType.Rooming);
    this.mealList = this.allReportsList.data.filter(t => t.exportType === ExportType.Meal);
  }

  private detail(exportId: number) {
      this.router.navigate([`/events/${this.evt.id}/special-export/${exportId}/detail`]);
  }

  private download(exportId: number) {
    const path = `/Export/SpecialExport?eventId=${this.evt.id}&exportId=${exportId}`;
    const mimeType = MimeTypes.xls;
    this.downloadFile(path, mimeType);
  }

  private async generateLatestReports(){
      await this.apiService.generateLatestReports(this.evt.id);
      await this.reloadList();
  }

  private downloadFile(path: string, mimeType: string) {
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  //#region Actions

  onClose() {
    this.close();
  }

  onGenerateLatestReports() {
    this.generateLatestReports();
  }

  public onDownload(exportId: number) {
    this.download(exportId);
  }

  public onDetail(exportId: number) {
    this.detail(exportId);
  }

}
