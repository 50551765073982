import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { EventInfo } from "src/app/models/api/entities/event-info";
import { PaymentTrackerInfo } from "src/app/models/api/entities/payment-tracker/payment-tracker-info";
import { UserPrivilege } from "src/app/models/api/enums/user-privilege";
import { ListResponse } from "src/app/models/api/protocol/list-response";
import { LocalListResponse } from "src/app/models/api/protocol/local-list-response";
import { ApiService } from "src/app/services/api.service";
import { BreadcrumbsService } from "src/app/services/breadcrumbs.service";
import { EntityMapService } from "src/app/services/entity-map.service";
import { UserService } from "src/app/services/user.service";
import { PageHeaderItem } from "src/app/shared/models/page-header-item";
import { MessageService } from "src/app/shared/services/message.service";
import { isConvertableToNumber } from "src/app/shared/utils/utils";

@Component({
  selector: "app-payment-tracker",
  templateUrl: "./payment-tracker.component.html",
  styleUrls: ["./payment-tracker.component.scss"],
})
export class PaymentTrackerComponent implements OnInit {
  public headerItems: PageHeaderItem[] = [];

  public itemsPerPage = 10;
  public filter = "";

  public evt: EventInfo;
  public payments: ListResponse<PaymentTrackerInfo> =
    new LocalListResponse<PaymentTrackerInfo>();
  public canManageEvent = false;
  public canManagePayment = false;
  public userFullName: string;

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit(): void {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageSystem
    );
    const canManageEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageEvent
    );
    const canViewEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ViewEvent
    );
    const canViewPayment = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ViewPayment
    );
    const canManagePayment = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManagePayment
    );

    if (!canViewEvent) {
      this.close();
      return;
    }

    this.route.parent.paramMap.subscribe(async (params) => {
      const evtIdStr = params.get("eventId");
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      const canViewThisPayment = canViewPayment || curUser.eventId === evt.id;
      if (!canViewThisPayment) {
        this.close();
        return;
      }

      const canManageThisPayment =
        canManagePayment || curUser.eventId === evt.id;
      if (canManageThisPayment) {
        this.canManagePayment = true;
      }

      this.canManageEvent = canManageEvent;

      this.evt = evt;

      if (curUser.role === 1) {
        this.breadcrumbsService.breadcrumbs = [
          { key: "Events", link: `/events` },
        ];
      }

      if (canViewEvent) {
        this.breadcrumbsService.breadcrumbs.push({
          key: "",
          value: `${evt.name}`,
          link: `/events/${evt.id}`,
        });
        this.breadcrumbsService.breadcrumbs.push({
          key: "Payment tracker",
          link: ``,
        });
        this.breadcrumbsService.title = evt.name;
      }

      if (canManageEvent) {
        this.headerItems = [
          ...this.headerItems,
          {
            title: "Export",
            iconClass: "fa-file-excel-o",
            callback: () => this.onExport(),
          },
          {
            title: "Close",
            iconClass: "fa-close",
            callback: () => this.onClose(),
          },
        ];
      }

      this.userFullName = `${curUser.name} ${curUser.surname}`;

      this.reloadList();
    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(["/auth"]);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      user,
      UserPrivilege.ManageSystem
    );

    if (canManageSystem) {
      this.router.navigate([`/events/${this.evt.id}`]);
    } else {
      this.router.navigate(["/"]);
    }
  }

  private export() {}

  private async reloadList() {
    const payments = await this.apiService.getPaymentsInformations(
      false,
      this.itemsPerPage,
      this.payments.page,
      this.filter,
      this.evt.id
    );
    this.payments = payments;
  }

  //#region Actions
  onFilterChange() {
    this.reloadList();
  }

  onPageChanged(page: number) {
    this.payments.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onClose() {
    this.close();
  }

  onExport() {
    this.export();
  }

  onInstalmentAdded() {
    this.reloadList();
  }

  onFilterKeyUp(key: any) {
    if (key.which === 13) {
      this.onFilterChange();
    }
  }
}
