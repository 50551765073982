import { PenaltyType } from './../../../../models/api/enums/penalty-type';
import { IntToPercent, isNullOrUndefined, PercentToInt } from 'src/app/utils/utils';
import { PenaltyInfo } from './../../../../models/api/entities/penalty-info';
import { EventInfo } from './../../../../models/api/entities/event-info';
import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { MessageService } from 'src/app/shared/services/message.service';
import { deepClone, sleep, toDate, toDateTimeString } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-penalty',
  templateUrl: './penalty.component.html',
  styleUrls: ['./penalty.component.scss']
})
export class PenaltyComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() penaltyId: number;
  @Input() penaltyType: PenaltyType;

  @Output() saved = new EventEmitter<PenaltyType>();
  @Output() closed = new EventEmitter<PenaltyInfo>();

  @ViewChild('policyModal', { static: true }) private policyModal: TemplateRef<any>;
  private policyModalRef: NgbModalRef;

  public penalty: PenaltyInfo;
  isNew: boolean;

  isVisible: boolean;

  public startDate: Date;
  public endDate: Date;
  public percent: number;

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);

    if (!canManageEvent) {
      this.close();
      return;
    }

    const canManageThisEvent = canManageSystem || curUser.eventId == this.evt.id;
    if (!canManageThisEvent) {
      this.close();
      return;
    }

    switch (this.penaltyType) {
      case PenaltyType.Accommodation:
        this.startDate = toDate(this.evt.deadlines.accommodationFillingStartDate);
        this.endDate = toDate(this.evt.deadlines.accommodationFillingEndDate);

        if(this.evt.features.isHotelRoomLatePerNightPriceEnabled)
        this.endDate = toDate(this.evt.deadlines.accommodationLateBookingEndDate);

        break;
      case PenaltyType.Meal:
        this.startDate = toDate(this.evt.deadlines.mealsFillingStartDate);
        this.endDate = toDate(this.evt.deadlines.mealsFillingEndDate);

        break;
    }

    if (this.penaltyId === 0) {
      this.newPolicy();
    } else {
      this.loadPolicy();
    }

  }

  private async newPolicy() {

    this.percent = 1;

    await sleep(50);
    this.isNew = true;
    this.penalty = {
      id: 0,
      eventId: this.evt.id,
      percent: 1,
      penaltyType: this.penaltyType,
      startDate: null,
      endDate: null,
      isDisabled: false
    };
    this.open();
  }

  private async loadPolicy() {
    if (this.isVisible) {
      this.onClose();
    }
    this.isNew = false;
    const penalty = await this.apiService.getPenalty(this.penaltyId);
    if (penalty === null) {
      return;
    }

    this.startDate = toDate(penalty.startDate);
    this.endDate = toDate(penalty.endDate);
    this.percent = IntToPercent(penalty.percent);

    this.penalty = penalty;
    this.open();
  }

  private async open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.policyModalRef != null) {
      this.policyModalRef.close();
      this.policyModalRef = null;
    }

    this.policyModalRef = this.modalService.open(this.policyModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisible = true;
  }

  private close() {
    if (this.policyModalRef != null) {
      this.policyModalRef.close();
      this.policyModalRef = null;
    }
    this.isVisible = false;
    this.closed.emit(this.penalty);
  }

  private async save() {

    const penalty: PenaltyInfo = deepClone(this.penalty);

    if(isNullOrUndefined(penalty.percent)){
      this.messageService.setToastMessage(false, 'Please set percentage.');
    }

    if(isNullOrUndefined(penalty.penaltyType)){
      this.messageService.setToastMessage(false, 'Please set type.');
    }

    penalty.percent = PercentToInt(this.percent);

    penalty.startDate = toDateTimeString(this.startDate);
    penalty.endDate = toDateTimeString(this.endDate);

    penalty.eventId = this.evt.id;

    const request: UpdateRequest<PenaltyInfo> = {
      isNew: this.isNew,
      subject: penalty
    };

    const result = await this.apiService.updatePenalty(request);

    if (result.isSuccess) {
      this.saved.emit(this.penaltyType);
      this.messageService.setToastMessage(true, this.isNew ? 'Policy created' : 'Policy updated', 7);
      this.onClose();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage);
    }

  }

  onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

}
