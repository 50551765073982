// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  text-align: center;
  white-space: nowrap;
}

td {
  vertical-align: inherit;
}

.table-responsive {
  overflow-x: unset !important;
}

ul {
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
}

td li {
  border-bottom: 1px solid #a6d4de;
}

td li:last-child {
  border-bottom: none;
}

.incDecButton {
  background: none;
  border: none;
  margin: 0 5px;
}

.incDecButton:focus, .incDecButton:active {
  outline: none;
}

.disabled {
  background-color: #313131;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/meals/meal-reservations-list/meal-reservations-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;;AACA;EACA,uBAAA;AAEA;;AAEA;EACA,4BAAA;AACA;;AAEA;EACA,qBAAA;EACA,SAAA;EACA,uBAAA;AACA;;AAEA;EACA,gCAAA;AACA;;AAEA;EACA,mBAAA;AACA;;AAEA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AACA;;AACA;EACA,aAAA;AAEA;;AACA;EACA,yBAAA;EACA,WAAA;AAEA","sourcesContent":["th, td {\r\n  text-align: center;\r\n  white-space: nowrap\r\n}\r\ntd {\r\nvertical-align: inherit;\r\n}\r\n\r\n\r\n.table-responsive{\r\noverflow-x: unset!important;\r\n}\r\n\r\nul {\r\nlist-style-type: none;\r\nmargin: 0;\r\npadding-inline-start: 0;\r\n}\r\n\r\ntd li {\r\nborder-bottom: 1px solid #a6d4de;\r\n}\r\n\r\ntd li:last-child {\r\nborder-bottom: none;\r\n}\r\n\r\n.incDecButton{\r\nbackground: none;\r\nborder:none;\r\nmargin: 0 5px;\r\n}\r\n.incDecButton:focus, .incDecButton:active{\r\noutline: none;\r\n}\r\n\r\n.disabled {\r\nbackground-color: #313131;\r\ncolor: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
