import { Component, OnInit } from '@angular/core';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { ApiService } from 'src/app/services/api.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { PersonGroupInfo } from 'src/app/models/api/entities/person-group-info';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { isConvertableToNumber, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-groups-manage',
  templateUrl: './groups-manage.component.html',
  styleUrls: ['./groups-manage.component.scss']
})
export class GroupsManageComponent implements OnInit {

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;

  public currentGroupId: number;
  public viewingGroup = false;

  public canManageDelegation = false;

  public messageService: MessageService;

  groups: ListResponse<PersonGroupInfo> = new LocalListResponse<PersonGroupInfo>();


  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;


      const breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }
      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      // eslint-disable-next-line max-len
      breadcrumbs.push(
        { key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` },
        { key: 'Persons', link: `/events/${evt.id}/delegations/${delegation.country.code}/persons` },
        { key: 'Group', link: `` }
        );
      this.breadcrumbsService.breadcrumbs = breadcrumbs;
      this.breadcrumbsService.title = evt.name;

      this.updateHeaderItems();

      this.reloadList();

    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private updateHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    if (this.canManageDelegation) {
      if(this.evt.features.isEntryFeeEnabled){
        headerItems.push({ title: 'Add group', iconClass: 'fa-plus-circle', callback: () => this.onAddGroup() });
      }
    }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    this.groups = await this.apiService.getPersonGroups(false,-1,1,"",this.evt.id, this.delegation.country.code);
  }

  private viewGroup(groupId: number) {
    this.location.go(`events/${this.evt.id}/delegations/${this.delegation.countryCode}/persons/group/${groupId}`);
    this.currentGroupId = groupId;
    this.viewingGroup = true;
  }

  private addGroup() {
    this.viewGroup(0);
  }

  private deleteGroup(group: PersonGroupInfo) {
    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        this.apiService.deletePersonGroup(group.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `Group '${group.name} has successfully deleted`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  private closePersonGroup() {
    this.location.go(`events/${this.evt.id}/delegations/${this.delegation.countryCode}/persons/manage/group`);
    this.viewingGroup = false;
    this.currentGroupId = 0;
  }

  onAddGroup() {
    this.addGroup();
  }

  onViewGroup(group: PersonGroupInfo) {
    this.viewGroup(group.id);
  }

  onDeleteGroup(group: PersonGroupInfo) {
    this.deleteGroup(group);
  }

  onPersonGroupUpdated() {
    this.reloadList();
  }

  onPersonGroupClosed() {
    this.closePersonGroup();
  }

}


