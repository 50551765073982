import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  AfterViewInit,
  ChangeDetectorRef,
  Renderer2,
} from "@angular/core";
import { NgbProgressbarConfig } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services/api.service";
import * as Plyr from "plyr";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "app-music-player",
  templateUrl: "./music-player.component.html",
  styleUrls: ["./music-player.component.scss"],
})
export class MusicPlayerComponent implements AfterViewInit, OnChanges {
  public musicPlayer: Plyr;

  public musicSource: Plyr.Source[];

  public musicPlayerControls: Plyr.Options = {
    controls: [
      "play",
      "seekTime",
      "progress",
      "current-time",
      "mute",
      "pip",
      "volume",
    ],
  };

  public progress: number = 0;

  public isUploadProgress: boolean = false;

  public buttonTitle: string = "Upload & Change";

  @Input() musicId: number;

  @Input() isBlob: boolean;

  @Input() isMusicChanged: boolean;

  @Input() blobUrl: string;

  @Output() musicDuration = new EventEmitter();

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private el: ElementRef,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    progressBar: NgbProgressbarConfig
  ) {
    progressBar.max = 100;
    progressBar.striped = true;
    progressBar.animated = true;
    progressBar.type = "info";
    progressBar.height = "23px";

    this.messageService = new MessageService();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isBlob) {
      this.musicSource = [{ src: this.blobUrl, type: "audio/mp3" }];
      this.updatePlayer();
    } else {
      if (this.isMusicChanged) {
        this.musicSource = [{ src: this.blobUrl, type: "audio/mp3" }];
        this.updatePlayer();
      } else {
        if (this.musicId !== 0) {
          this.musicSource = [
            {
              src: this.apiService.getMusicAddress(this.musicId),
              type: "audio/mp3",
            },
          ];
          this.updatePlayer();
        }
      }
    }

    this.updatePlayer();

    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    if (this.isBlob && this.isMusicChanged) {
      this.musicSource = [{ src: this.blobUrl, type: "audio/mp3" }];
      this.updatePlayer();
    } else {
      if (this.musicId !== 0) {
        this.musicSource = [
          {
            src: this.apiService.getMusicAddress(this.musicId),
            type: "audio/mp3",
          },
        ];
        this.updatePlayer();
      }
    }
    this.cd.detectChanges();
    this.updatePlayer();
  }

  setMusicDuration(): string {
    return (<HTMLElement>this.el.nativeElement).querySelector(".plyr__time")
      .textContent;
  }

  getMusicDuration() {
    this.musicDuration.emit(this.setMusicDuration());
  }

  private updatePlayer() {
    const parentElement = document.getElementById(this.musicId + "div");
    if (parentElement) {
      const audioContainer = parentElement?.getElementsByClassName("audio-container")[0];
      const player = parentElement?.getElementsByTagName("audio")[0];
      if (player && audioContainer) {
        player.remove();
        const audioPlayer = this.renderer.createElement("audio");
        this.renderer.setAttribute(audioPlayer, "id", this.musicId.toString());
        this.renderer.setAttribute(audioPlayer, "controls", "");
        this.renderer.setAttribute(audioPlayer, "playsinline", "");
        this.renderer.setAttribute(audioPlayer, "class", "player");

        const sourceElement = this.renderer.createElement("source");
        this.renderer.setAttribute(sourceElement, "type", "audio/mp3");
        this.renderer.setAttribute(
          sourceElement,
          "src",
          this.musicSource[0].src
        );

        this.renderer.appendChild(audioPlayer, sourceElement);
        this.renderer.appendChild(audioContainer, audioPlayer);
      }
    }
  }
}
