import { Pipe, PipeTransform } from '@angular/core';

import { RoomType } from '../models/api/enums/room-type';

@Pipe({
  name: 'roomQuantity'
})
export class RoomQuantityPipe implements PipeTransform {

  transform(quantity: number, roomType: RoomType): number {
    if (quantity === null) {
      return 0;
    }
    let total = quantity;
    switch (roomType) {
      case RoomType.QD:
        total = quantity * 4;
        break;
      case RoomType.TR:
        total = quantity * 3;
        break;
      case RoomType.TW:
        total = quantity * 2;
        break;
      case RoomType.TW_SEP_B:
        total = quantity * 2;
        break;
      case RoomType.TW_SH_B:
        total = quantity * 2;
        break;
      case RoomType.Twin_BB:
        total = quantity * 2;
        break;
      case RoomType.Twin_HB:
        total = quantity * 2;
        break;
      case RoomType.Twin_FB:
        total = quantity * 2;
        break;
      case RoomType.Triple_BB:
        total = quantity * 3;
        break;
      case RoomType.Triple_HB:
        total = quantity * 3;
        break;
      case RoomType.Triple_FB:
        total = quantity * 3;
        break;
      case RoomType.Multiple_4_beds_BB:
        total = quantity * 4;
        break;
      case RoomType.Multiple_4_beds_HB:
        total = quantity * 4;
        break;
      case RoomType.Multiple_4_beds_FB:
        total = quantity * 4;
        break;
      case RoomType.Multiple_6_beds_BB:
        total = quantity * 6;
        break;
      case RoomType.Multiple_6_beds_HB:
        total = quantity * 6;
        break;
      case RoomType.Multiple_6_beds_FB:
        total = quantity * 6;
        break;
    }

    return total;
  }

}
