import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyType } from '../models/api/enums/currency-type';
import { formatMoney } from '../shared/utils/utils';

@Pipe({
  name: 'generalCurrency'
})
export class GeneralCurrencyPipe implements PipeTransform {

  transform(value: number, currencyType: CurrencyType, onlyName = false): string {
    if (onlyName) {
      return `${CurrencyType[currencyType]}`;
    }
    return value === null ? null : `${formatMoney(value)} ${CurrencyType[currencyType]}`;
  }

}
