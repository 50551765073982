import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { ApiService } from "src/app/services/api.service";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { BreadcrumbsService } from "src/app/services/breadcrumbs.service";
import { EntityMapService } from "src/app/services/entity-map.service";
import { ListResponse } from "src/app/models/api/protocol/list-response";
import { LocalListResponse } from "src/app/models/api/protocol/local-list-response";
import { Location } from "@angular/common";
import { MealVenueInfo } from "src/app/models/api/entities/meal-venue-info";
import { MimeTypes } from "src/app/utils/mime-types";
import { UserPrivilege } from "src/app/models/api/enums/user-privilege";
import { EventInfo } from "src/app/models/api/entities/event-info";
import { UserService } from "src/app/services/user.service";
import { PageHeaderItem } from "src/app/shared/models/page-header-item";
import { MessageService } from "src/app/shared/services/message.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { isConvertableToNumber } from "src/app/shared/utils/utils";

@Component({
  selector: "app-meal-venue-list",
  templateUrl: "./meal-venue-list.component.html",
  styleUrls: ["./meal-venue-list.component.scss"],
})
export class MealVenueListComponent implements OnInit {
  public itemsPerPage = 10;
  public filter = "";

  private _includeDisabled = false;
  public get includeDisabled(): boolean {
    return this._includeDisabled;
  }
  public set includeDisabled(value: boolean) {
    this._includeDisabled = value;
    this.updateHeaderItems();
  }

  public headerItems: PageHeaderItem[];

  public evt: EventInfo;
  public venues: ListResponse<MealVenueInfo> =
    new LocalListResponse<MealVenueInfo>();

  public canManageEvent = false;

  public currentVenueId: number;
  public viewingMealVenue: boolean;

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageSystem
    );
    const canManageEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageEvent
    );
    const canViewEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ViewEvent
    );

    if (!canViewEvent) {
      this.close();
      return;
    }

    this.route.parent.paramMap.subscribe(async (paramMap) => {
      const evtIdStr = paramMap.get("eventId");

      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      this.evt = evt;
      this.canManageEvent = canManageEvent;

      this.updateHeaderItems();

      let breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs.push({ key: "Events", link: `/events` });
      }
      breadcrumbs.push({
        key: "",
        value: `${evt.name}`,
        link: `/events/${evt.id}`,
      });
      breadcrumbs.push({
        key: "Meal services",
        value: ``,
        link: ``,
      });

      this.breadcrumbsService.breadcrumbs = breadcrumbs;
      this.breadcrumbsService.title = evt.name;

      if (paramMap.has("venueId")) {
        const venueIdStr = paramMap.get("venueId");
        let venueFound = false;

        if (venueIdStr == null || !isConvertableToNumber(venueIdStr)) {
          this.close();
          return;
        }

        const venueId = Number(venueIdStr);

        if (
          this.venues !== null &&
          this.venues.items.some((v) => v.id === venueId)
        ) {
          venueFound = true;
        }

        if (!venueFound) {
          const venue = await this.apiService.getMealServiceVenue(venueId);
          if (venue !== null) {
            venueFound = true;
          }
        }

        if (!venueFound) {
          this.close();
          return;
        }

        this.viewVenue(venueId);
      }

      this.reloadList();
    });
  }

  private updateHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    if (this.canManageEvent) {
      headerItems.push({
        title: "Download venues",
        iconClass: "fa-file-excel-o",
        callback: () => this.onDownloadVenues(),
      });
      headerItems.push({
        title: "Add venue",
        iconClass: "fa-plus",
        callback: () => this.onAddVenue(),
      });
    }
    if (this.includeDisabled) {
      headerItems.push({
        title: "Hide disabled",
        iconClass: "fa-lock",
        callback: () => this.onToggleHidden(),
      });
    } else {
      headerItems.push({
        title: "Show all",
        iconClass: "fa-unlock",
        callback: () => this.onToggleHidden(),
      });
    }
    this.headerItems = headerItems;
  }

  private close() {
    const curUser = this.apiService.currentUser;
    if (curUser === null) {
      this.router.navigate(["/auth"]);
      return;
    }

    const canViewEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ViewEvent
    );
    const canViewThisEvent =
      this.evt != null && canViewEvent && curUser.eventId === this.evt.id;

    if (canViewThisEvent) {
      this.router.navigate(["/events/" + this.evt.id]);
    } else {
      this.router.navigate(["/"]);
    }
  }

  public toggleHidden() {
    this.includeDisabled = !this.includeDisabled;
    this.updateHeaderItems();
    this.reloadList();
  }

  public async reloadList() {
    // eslint-disable-next-line max-len
    this.venues = await this.apiService.getMealServiceVenues(
      this.includeDisabled,
      this.itemsPerPage,
      this.venues.page,
      this.filter,
      this.evt.id
    );
  }

  private downloadVenues() {
    const path = `/Export/Meals?eventId=${this.evt.id}`;
    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then((error) => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  //#endregion

  //#region Actions

  private viewVenue(venueId: number) {
    this.location.go(`/events/${this.evt.id}/meal-venues/${venueId}`);
    this.currentVenueId = venueId;
    this.viewingMealVenue = true;
  }

  private addVenue() {
    this.viewVenue(0);
  }

  private closeVenue() {
    this.location.go(`/events/${this.evt.id}/meal-venues/`);
    this.viewingMealVenue = false;
  }

  public onFilterChange() {
    this.reloadList();
  }

  public onToggleHidden() {
    this.toggleHidden();
  }

  public onPageChanged(page: number) {
    this.venues.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  public onDownloadVenues() {
    this.downloadVenues();
  }

  public onAddVenue() {
    this.addVenue();
  }

  public onViewVenue(venue: MealVenueInfo) {
    if (venue === null) {
      return;
    }
    this.viewVenue(venue.id);
  }

  onVenueClosed() {
    this.closeVenue();
  }

  onVenueUpdated() {
    this.reloadList();
  }

  public onDisableVenue(venue: MealVenueInfo) {
    const message = "Are you sure? ";
    const alert = this.alertService.showConfirmation(message, (confirmed) => {
      if (confirmed) {
        if (venue.isDisabled) {
          return;
        }
        this.apiService.disableMealServiceVenue(venue.id).then((result) => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(
              true,
              `Meal service venue '${venue.name}' disabled`
            );
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  public onEnableVenue(venue: MealVenueInfo) {
    const message = "Are you sure? ";
    const alert = this.alertService.showConfirmation(message, (confirmed) => {
      if (confirmed) {
        if (!venue.isDisabled) {
          return;
        }
        this.apiService.enableMealServiceVenue(venue.id).then((result) => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(
              true,
              `Meal service venue '${venue.name}' enabled`
            );
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  //#endregion

  onFilterKeyUp(key: any) {
		if (key.which === 13) {
			this.onFilterChange();
		}
	}
}
