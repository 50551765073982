import { Component, OnInit } from '@angular/core';

import { LoaderService } from '../../services/loader.service';

@Component({
	selector: 'sac-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

	constructor(public loaderService: LoaderService) { }

	ngOnInit() { }

}
