import { Pipe, PipeTransform } from '@angular/core';
import { toDate } from '../shared/utils/utils';

@Pipe({
  name: 'dateChange'
})
export class DateChangePipe implements PipeTransform {

  transform(defaultDate: string): string {

    const stringToDate = toDate(defaultDate);

    const valueYear: number = stringToDate.getFullYear();

    const currentDate: Date = new Date();

    const currentYear = currentDate.getFullYear();

    // if(valueYear === 1 || valueYear === currentYear)
    // return null;

    return defaultDate;
  }

}
