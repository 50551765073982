import { DateStatus, DateStatuses } from './../../../utils/date-status';
import { Component, Input, OnInit } from '@angular/core';

import { MomentInput } from 'moment';
import { toDate, toDateTimeString, toMoment } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-delegation-section-button',
  templateUrl: './delegation-section-button.component.html',
  styleUrls: ['./delegation-section-button.component.scss']
})
export class DelegationSectionButtonComponent implements OnInit {

  private bufferDays: number = 3;

  @Input() title: string;
  @Input() iconClass: string;
  @Input() start: MomentInput;
  @Input() specialStart: MomentInput;
  @Input() isSpecialDateEnabled: boolean;
  @Input() end: MomentInput;
  @Input() specialEnd: MomentInput;
  @Input() lateStart: MomentInput;
  @Input() lateEnd: MomentInput;
  @Input() isLateFeatureEnabled: boolean = false;
  @Input() click: Function;

  dateStatus: DateStatus;

  constructor() { }

  ngOnInit() {

    let dateStatus = new DateStatus();

    dateStatus.startDate = this.start;
    dateStatus.endDate = this.end;
    dateStatus.isFeatureEnabled = this.isLateFeatureEnabled;

    if (this.isLateFeatureEnabled) {
      dateStatus.featureStartDate = this.lateStart;
      dateStatus.featureEndDate = this.lateEnd;
    }

    dateStatus.specialStartDate = this.specialStart;
    dateStatus.specialEndDate = this.specialEnd;
    dateStatus.isSpecialDateEnabled = this.isSpecialDateEnabled;
    this.dateStatus = dateStatus;

  }

  get buttonClass(): string {

    const defaultClass = 'btn-outline-secondary';
    const endedClass = 'btn-secondary';
    const lateBooking = 'btn-primary'
    let returnClass = '';

    if (this.start == null || this.end == null) {
      return defaultClass;
    }

    switch (this.dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.FeatureEndsSoon:
      case DateStatuses.SpecialDateEndsSoon:
      case DateStatuses.SpecialDateNotStarted:

        returnClass = defaultClass;
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:
        returnClass = endedClass;
        break;
      case DateStatuses.FeatureOnGoing:
      case DateStatuses.SpecialDateOnGoing:

        returnClass = lateBooking;
        break;
    }

    return returnClass;

  }

  get badgeClass(): string {

    const defaultClass = '';
    const notStartedClass = 'text-secondary';
    const soonStartsClass = 'text-info';
    const startedClass = 'text-success';
    const lateStartedClass = 'text-default';
    const soonEndsClass = 'text-warning';

    const endedClass = '';

    let returnClass = '';

    if (this.start == null || this.end == null) {
      return defaultClass;
    }

    switch (this.dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
      case DateStatuses.FeatureNotStarted:
      case DateStatuses.SpecialDateNotStarted:

        returnClass = notStartedClass;
        break;
      case DateStatuses.StartsSoon:
      case DateStatuses.FeatureStartsSoon:
        returnClass = soonStartsClass;
        break;
      case DateStatuses.OnGoing:
        returnClass = startedClass;
        break;
      case DateStatuses.FeatureOnGoing:
      case DateStatuses.SpecialDateOnGoing:
        returnClass = lateStartedClass;
        break;
      case DateStatuses.EndsSoon:
      case DateStatuses.FeatureEndsSoon:
      case DateStatuses.SpecialDateEndsSoon:
        returnClass = soonEndsClass;
        break;
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
      case DateStatuses.SpecialDateEnded:

        returnClass = endedClass;
        break;
    }

    return returnClass;

  }

  get badgeText(): string {

    if (this.start == null || this.end == null) {
      return '';
    }

    const startDate = toDate(this.start);
    if (startDate == null) {
      return '';
    }

    const endDate = toDate(this.end);
    if (endDate == null) {
      return '';
    }

    var today = new Date();

    let returnText = '';
    let daysLeft: number = 0;

    switch (this.dateStatus.getStatus()) {
      case DateStatuses.NotStarted:
        returnText = `Starts on ${toDateTimeString(startDate)}`;
        break;
      case DateStatuses.FeatureNotStarted:
        returnText = `Late booking starts on ${this.lateStart}`;
        break;
      case DateStatuses.SpecialDateNotStarted:
        returnText = `Special permission starts on ${this.lateStart}`;
        break;
      case DateStatuses.StartsSoon:
        daysLeft = toMoment(startDate).diff(today, 'days');
        returnText = `Starts on ${toDateTimeString(startDate)}, ${daysLeft} days left`;
        break;
      case DateStatuses.FeatureStartsSoon:
        daysLeft = toMoment(toDate(this.lateStart)).diff(today, 'days');
        returnText = `Late booking starts on ${this.lateStart}, ${daysLeft} days left`;
        break;
      case DateStatuses.OnGoing:
        returnText = `Ends on ${toDateTimeString(endDate)}`;
        break;
      case DateStatuses.FeatureOnGoing:
        returnText = `Late booking ends on ${toDateTimeString(this.lateEnd)}`;
        break;
      case DateStatuses.EndsSoon:
        daysLeft = toMoment(endDate).set('hours', 0).set('minutes', 0).set('seconds', 0).diff(today, 'days');
        returnText = `Ends on ${toDateTimeString(endDate)}, ${daysLeft} days left`;
        break;
      case DateStatuses.FeatureEndsSoon:
        daysLeft = toMoment(toDateTimeString(this.lateEnd)).set('hours', 0).set('minutes', 0).set('seconds', 0).diff(today, 'days');
        returnText = `Late booking ends on ${toDateTimeString(this.lateEnd)}, ${daysLeft} days left`;
        break;
      case DateStatuses.Ended:
        returnText = `Ended on ${toDateTimeString(endDate)}`;
        break;
      case DateStatuses.FeatureEnded:
        returnText = `Ended on ${toDateTimeString(this.lateEnd)}`;
        break;
      case DateStatuses.SpecialDateOnGoing:
        returnText = `Special permission ends on ${toDateTimeString(this.specialEnd)}`;
        break;
      case DateStatuses.SpecialDateEndsSoon:
        daysLeft = toMoment(toDateTimeString(this.specialEnd)).set('hours', 0).set('minutes', 0).set('seconds', 0).diff(today, 'days');
        returnText = `Special permission ends on ${toDateTimeString(this.specialEnd)}, ${daysLeft} days left`;
        break;
      case DateStatuses.SpecialDateEnded:
        returnText = `Ended on ${this.specialEnd}`;
        break;
    }

    return returnText;

  }

  onClick() {
    if (this.click != null) {
      this.click();
    }
  }

}
