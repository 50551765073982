import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNull } from 'lodash';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelInfo } from 'src/app/models/api/entities/hotel-info';
import { RoomingDetailsInfo } from 'src/app/models/api/entities/special-export/rooming/rooming-details-info';
import { SpecialExportInfo } from 'src/app/models/api/entities/special-export/special-export-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';

@Component({
  selector: 'app-roomings-list',
  templateUrl: './roomings-list.component.html',
  styleUrls: ['./roomings-list.component.scss']
})
export class RoomingsListComponent implements OnInit {

  @Input() evt: EventInfo;
  @Input() hotels: ListResponse<HotelInfo> = new LocalListResponse<HotelInfo>();
  @Input() detailInfo: SpecialExportInfo;
  public typeDetailId: number;

  public isRoomingDetail: boolean = false;

  public selectedRooming: RoomingDetailsInfo;

  @Output() updates = new EventEmitter<string>();

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.load();
  }

  private async load() {
    this.route.paramMap.subscribe(async params => {

      const typeDetailIdStr = params.get('typeDetail');

      const typeDetailId = Number(typeDetailIdStr);

      if (typeDetailId !== 0) {
        this.isRoomingDetail = true;
      }
      this.typeDetailId = typeDetailId;

      if (!isNull(this.detailInfo.roomingDetails)) {
        const roomingDetail = this.detailInfo.roomingDetails.filter(t => t.hotelId === typeDetailId);
        this.selectedRooming = roomingDetail[0];
      }

    });
  }

  onDetail(TypeDetailId: number) {
    this.router.navigate([`/events/${this.evt.id}/special-export/${this.detailInfo.id}/detail/${TypeDetailId}`]);
  }

  onAccommodationUpdated() {
    this.updates.emit('upd');
    const roomingDetail = this.detailInfo.roomingDetails.filter(t => t.hotelId === this.typeDetailId);
    this.selectedRooming = roomingDetail[0];
  }

}
