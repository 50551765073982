import * as moment from 'moment';
import * as lodash from 'lodash';
import { isNumber } from 'util';

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function stringIsNullOrEmpty(str: string) {
  return str == null || str.length === 0;
}

//#region Date
export const dateFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'YYYY-MM-DD HH:mm';

export function toMoment(date: moment.MomentInput): moment.Moment {
  if (date == null) {
    return null;
  }
  return moment(date, [dateTimeFormat, dateFormat]);
}

export function toDateString(date: moment.MomentInput): string {
  if (date == null) { return null; }
  const m = toMoment(date);
  return m == null ? null : m.format(dateFormat);
}

export function toDateTimeString(date: moment.MomentInput): string {
  if (date == null) { return null; }
  const m = toMoment(date);
  return m == null ? null : m.format(dateTimeFormat);
}

export function toDate(date: moment.MomentInput): Date {
  const m = toMoment(date);
  return m == null ? null : m.toDate();
}
//#endregion

export function newGuid(): string {
  let result: string;
  let i: string;
  let j: number;

  result = '';
  for (j = 0; j < 32; j++) {
    if (j === 8 || j === 12 || j === 16 || j === 20) {
        result = result + '-';
    }
    i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
    result = result + i;
  }
  return result;
}

export function numberToRoman(num?: number): string {
  if (num == null || isNaN(num)) {
      return '';
  }
  const digits = String(+num).split('');
  const key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
             '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
             '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
  let roman = '';
  let i = 3;
  while (i--) {
      roman = (key[+digits.pop() + (i * 10)] || '') + roman;
  }
  return Array(+digits.join('') + 1).join('M') + roman;
}

//#region Clone object

export function arraysEqual(a: any[], b: any[]) {
  if (a === b) { return true; }
  if (a == null || b == null) { return false; }
  if (a.length !== b.length) { return false; }

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) { return false; }
  }
  return true;
}

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function isArry(item) {
  return (item && typeof item === 'object' && Array.isArray(item));
}

export function deepClone(original) {
  return lodash.cloneDeep(original);
}

export function isNullOrUndefined(item) {
  return item === undefined || item === null;
}

//#endregion

export function isConvertableToNumber(item) {
  if (item == null) {
    return false;
  }

  if (isNumber(item)) {
    return true;
  }

  if (!(/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(item))) {
    return false;
  }

  const val = Number(item);
  if (isNaN(val) || !isFinite(val)) {
    return false;
  }

  return true;
}

export function formatMoney(number: String | Number): string {
  const value: Number = isNumber(number) ? Number(number) : (isConvertableToNumber(number) ? Number(number) : 0);
  if (value === 0) {
    return '0';
  }
  let str = value.toFixed(2);
  str = str.replace(',', '.');
  return str;
}

export function formatInteger(number: String | Number): string {
  const value: Number = isNumber(number) ? Number(number) : (isConvertableToNumber(number) ? Number(number) : 0);
  if (value === 0) {
    return '0';
  }
  const str = value.toFixed(0);
  return str;
}

export function formatUnsignedInteger(number: String | Number): string {
  let value: Number = isNumber(number) ? Number(number) : (isConvertableToNumber(number) ? Number(number) : 0);
  if (value === 0) {
    return '0';
  }
  value = Math.abs(parseInt(value.toFixed(0), 0));
  const str = value.toFixed(0);
  return str;
}

export function PercentToInt(number: number): number{
    return number * 1000;
}

export function IntToPercent(number: number): number{
  return number / 1000;
}
