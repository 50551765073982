// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th,
td {
  white-space: nowrap;
}

.table-responsive {
  overflow-x: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/music/music-list/music-list.component.scss"],"names":[],"mappings":"AAAA;;EAEE,mBAAA;AACF;;AAEA;EACE,4BAAA;AACF","sourcesContent":["th,\r\ntd {\r\n  white-space: nowrap;\r\n}\r\n\r\n.table-responsive {\r\n  overflow-x: unset !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
