import { UserInfo } from './../../models/api/entities/user-info';
import { ChangeDetectorRef, ApplicationRef, Component, OnInit, NgZone, OnChanges, SimpleChanges, ChangeDetectionStrategy, DoCheck, Input, AfterViewInit} from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserRole } from '../../models/api/enums/user-role';
import { UserPrivilege } from '../../models/api/enums/user-privilege';
import { ApiService } from '../../services/api.service';
import { EntityMapService } from '../../services/entity-map.service';
import { Router } from '@angular/router';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { ChangeNotificationInfo } from 'src/app/models/api/entities/change-notification-info';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  user: UserInfo;
  isLoggedIn = false;

  UserPrivilege = UserPrivilege;
  UserRole = UserRole;

  public isProduction = false;
  public isNavbarCollapsed = true;
  areNotificationsAvailable: boolean;
  notifications: ChangeNotificationInfo[] = [];

  constructor(
    private apiService: ApiService,
    public entityMapService: EntityMapService,
    public changeNotificationsService: ChangeNotificationsService,
    public router: Router,
    public cdr: ChangeDetectorRef
  ) {
    this.isProduction = environment.production === true;
    this.apiService.currentUser$.subscribe(user => {
      this.user = user;
      this.isLoggedIn = this.user != null;
    })
    }

    ngOnInit() {
      this.changeNotificationsService.notificationsChange.subscribe(value => {
        this.notifications = value;
      })

      this.changeNotificationsService.isNotificationsAvailableChange.subscribe(value => {
        this.areNotificationsAvailable = value;
      })
    }

  public onLogOut() {
    this.collapseNavbar();
    this.isLoggedIn = false;
    this.apiService.logout();
  }

  public collapseNavbar() {
    this.isNavbarCollapsed = true;
  }

  public navigate(path: string) {
    if (path === null) {
      return;
    }
    this.collapseNavbar();
    this.router.navigate([path])
  }

  public linkIsActive(link: string|string[]): boolean {
    if (typeof link === 'string') {
      return this.router.url.startsWith(<string>link);
    }

    const sublinks = <string[]>link;
    for (const sublink of sublinks) {
      if (this.router.url.startsWith(sublink)) {
        return true;
      }
    }
    return false;
  }

  public getFlagAddress(): string {
    if (this.user == null || this.user.country == null) {
      return null;
    }
    return this.entityMapService.getCountryFlagAddress(this.user.country.code);
  }

  showNotifications() {
    this.collapseNavbar();
    this.changeNotificationsService.showView();
  }
}
