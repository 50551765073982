// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody tr {
  justify-content: space-around;
}

.delete-button-cell {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/components/flights/flight-passengers-list/flight-passengers-list.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":["tbody tr{\r\n  justify-content: space-around;\r\n}\r\n\r\n.delete-button-cell {\r\n  text-align: right;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
