import { Country } from './../../../../models/api/entities/country';
import { RoomingDetailsInfo } from 'src/app/models/api/entities/special-export/rooming/rooming-details-info';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { RoomingDetailsDelegationsInfo } from 'src/app/models/api/entities/special-export/rooming/rooming-details-delegations-info';
import { RoomingDelegationGrouppedInfo } from 'src/app/models/api/entities/special-export/rooming/rooming-delegation-groupped-info';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-special-export-detail-rooming',
  templateUrl: './special-export-detail-rooming.component.html',
  styleUrls: ['./special-export-detail-rooming.component.scss']
})
export class SpecialExportDetailRoomingComponent implements OnInit {

  @Input() eventId: number;
  @Input() roomingDetail: RoomingDetailsInfo;

  public roomTypesFor = [];

  public roomingCountries: Country[];

  test: RoomingDelegationGrouppedInfo[] = [];

  constructor(
    config: NgbModalConfig
  ) {
    config.size = 'lg';
    config.backdrop = 'static';
  }

  ngOnInit(): void {

    for (let index = 0; index < this.roomingDetail.roomTypes.length; index++) {
      this.roomTypesFor[index] = index;
    }

    this.test = this.getByDelegation(this.roomingDetail.delegations);

    console.log('test :>> ', this.test);

  }

  getByDelegation(delegations: RoomingDetailsDelegationsInfo[]) {

    // todo - draft variant.

    let test: RoomingDelegationGrouppedInfo[] = [];

    delegations.forEach(v => {

      let filteredDelegation = delegations.filter(t => t.country.code === v.country.code);
      let updateAvailable = filteredDelegation.filter(t => t.updateAvailable === true).length > 0 ? true : false;

      let arrayRange = 0;
      let zzz = [];

      for (let index = 0; index < this.roomingDetail.roomTypes.length; index++) {

        let tempfilter = filteredDelegation.filter(t => t.roomType === this.roomingDetail.roomTypes[index].roomType);

        zzz[index] = tempfilter;

        let temprange = tempfilter.length;

        if(arrayRange <= temprange){
          arrayRange = temprange;
        }

      }

      if (!test.some(t => t.country.code === v.country.code)) {
        test.push({
          country: v.country,
          isCounryUpdated: updateAvailable,
          details: Array(arrayRange),
          detailsAlter: zzz
        })
      }

    });

    return test;
  }



}
