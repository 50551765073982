import { ApiService } from 'src/app/services/api.service';
import { DelegationInfo } from './../../models/api/entities/delegation-info';
import { EventInfo } from './../../models/api/entities/event-info';
import { Component, Input, OnInit } from '@angular/core';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.scss']
})
export class Covid19Component implements OnInit {

  public covid19HeaderItems: PageHeaderItem[] = [];

  @Input() evt: EventInfo;
  @Input() delegation: DelegationInfo;
  @Input() canManageThisDelegation: boolean;
  @Input() readonly: boolean = false;

  public pcrArrivalCount: number = 0;
  public pcrDepartureCount: number = 0;

  public quickArrivalCount: number = 0;
  public quickDepartureCount: number = 0;
  
  public totalPerson: number = 0;
  
  public clubShortName: string = null;

  constructor(
    private readonly apiService: ApiService,
    private readonly messageService: MessageService
    ) { }

  ngOnInit(): void {
    this.load();
  }

  private async load() {

    this.pcrArrivalCount = this.delegation.covid19PcrPersonsArrivalCount;
    this.pcrDepartureCount = this.delegation.covid19PcrPersonsDepartureCount;
    
    this.quickArrivalCount = this.delegation.covid19QuickPersonsArrivalCount;
    this.quickDepartureCount = this.delegation.covid19QuickPersonsDepartureCount;

    if(this.evt.features.isClubExist){
      this.clubShortName = this.delegation.clubShortName;
    }

    this.totalPerson = (await this.apiService.getPersonsList(false,-1,0,null,this.delegation.eventId,this.delegation.countryCode,this.clubShortName)).items.length;

    if (this.canManageThisDelegation) {
      this.covid19HeaderItems = [{ title: 'Update', iconClass: 'fa-save text-danger', callback: () => this.onSaveCovid19Info() }];
    }

  }

  onIncDecPCRArrival(num: number) {
    this.incDecPCRArrival(num);
  }

  onIncDecPCRDeparture(num: number) {
    this.incDecPCRDeparture(num);
  }

  onIncDecQuickArrival(num: number) {
    this.incDecQuickArrival(num);
  }

  onIncDecQuickDeparture(num: number) {
    this.incDecQuickDeparture(num);
  }

  onSaveCovid19Info() {
    this.saveCovid19Info();
  }

  private async incDecPCRArrival(num: number) {
    if(num < 0 && this.pcrArrivalCount === 0){
      this.pcrArrivalCount = 0;
      return;
    }

    if(this.totalPerson <= this.pcrArrivalCount && num !== -1)
    return;

    this.pcrArrivalCount += num;
  }

  private async incDecPCRDeparture(num: number) {
    if(num < 0 && this.pcrDepartureCount === 0){
      this.pcrDepartureCount = 0;
      return;
    }

    if(this.totalPerson <= this.pcrDepartureCount && num !== -1)
    return;

    this.pcrDepartureCount += num;
  }

  private async incDecQuickArrival(num: number) {
    if(num < 0 && this.quickArrivalCount === 0){
      this.quickArrivalCount = 0;
      return;
    }

    if(this.totalPerson <= this.quickArrivalCount && num !== -1)
    return;

    this.quickArrivalCount += num;
  }

  private async incDecQuickDeparture(num: number) {
    if(num < 0 && this.quickDepartureCount === 0){
      this.quickDepartureCount = 0;
      return;
    }

    if(this.totalPerson <= this.quickDepartureCount && num !== -1)
    return;

    this.quickDepartureCount += num;
  }

  countTotal(price: number, personsCount: number): number {
    return price * personsCount;
  }

  private async saveCovid19Info() {

    this.delegation.covid19PcrPersonsArrivalCount = this.pcrArrivalCount;
    this.delegation.covid19PcrPersonsDepartureCount = this.pcrDepartureCount;
    this.delegation.covid19QuickPersonsArrivalCount = this.quickArrivalCount;
    this.delegation.covid19QuickPersonsDepartureCount = this.quickDepartureCount;

    const request = await this.apiService.updateDelegation(this.delegation);

    if(request.isSuccess){
      this.messageService.setToastMessage(true, 'Covid-19 PCR Test information updated.');
    }
  }

}
