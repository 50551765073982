// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  border-top: 1px solid #66686B;
  margin: 10px 0;
  display: grid;
}

.input-control label {
  font-size: 1.3rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/delegations/special-deadlines/special-deadlines.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,cAAA;EACA,aAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".divider{\r\n  border-top: 1px solid #66686B;\r\n  margin: 10px 0;\r\n  display: grid;\r\n}\r\n\r\n.input-control label {\r\n  font-size: 1.3rem;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
