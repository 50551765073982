import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ListResponse } from '../../../models/api/protocol/list-response';
import { LocalListResponse } from '../../../models/api/protocol/local-list-response';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { EventInfo } from '../../../models/api/entities/event-info';
import { Country } from '../../../models/api/entities/country';
import { DisciplineType } from '../../../models/api/enums/discipline-type';
import { EventStage } from '../../../models/api/enums/event-stage';
import { EntityMapService } from '../../../services/entity-map.service';
import { UserPrivilege } from '../../../models/api/enums/user-privilege';
import { toDateString } from '../../../utils/utils';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FilterComponent } from 'src/app/shared/modals/filter/filter.component';
import { Subscription } from 'rxjs';
import { ListRequest } from 'src/app/models/api/protocol/list-request';
@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {
  modalRef!:NgbModalRef;
  public DisciplineType = DisciplineType;
  public EventStage = EventStage;

  public itemsPerPage = 10;
  public filter = '';

  public events: ListResponse<EventInfo> = new LocalListResponse<EventInfo>();
  public headerItems: PageHeaderItem[];

  public messageService: MessageService;
  //#region Subscriptions
  subscriptions$=new Subscription();
  //#endregiom
  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private router: Router
  ) {
    this.messageService = new MessageService();
    
  }

  //#region Routines

  ngOnInit() {
    this.load();
  }

  public async load()
  {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    if (!canManageSystem) {
      this.close();
      return;
    }

    this.headerItems = [
      { title: 'Add event', iconClass: 'fa-plus', callback: () => this.onAddEvent() }
    ]

    this.reloadList();
  }

  public reloadList() {
    this.apiService.getEvents({
      itemsPerPage: this.itemsPerPage,
      page: this.events.page,
      includeInactives: true,
      filter: this.filter})
    .then(events => {
      this.events = events;
    });
  }

  private close() {
    this.router.navigate(['/']);
    return;
  }

  //#endregion

  //#region Actions

  public onFilterChange() {
    this.reloadList();
  }

  public onPageChanged(page: number) {
    this.events.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  public onAddEvent() {
    this.router.navigate(['/events/new']);
  }

  public onViewEvent(event: EventInfo) {
    this.router.navigate(['/events/' + event.id]);
  }

  //#endregion

  //#region Data query

  public getEventById(eventId: number): EventInfo {
    if (eventId == null) {
      return null;
    }

    if (this.events == null) {
      return null;
    }

    return this.events.items.find(t => t.id === eventId);
  }

  public getFlagAddress(country: Country): string {
    if (country == null) {
      return null;
    }
    return this.entityMapService.getCountryFlagAddress(country.code);
  }

  public getEventStageNameByCode(eventStage: EventStage): string {
    if (eventStage == null) {
      return null;
    }
    return this.entityMapService.getEventStageName(eventStage);
  }

  public getEventStartDate(startDate: string): string {
    return toDateString(startDate);
  }

  public getShortEventName(evt: EventInfo): string {
    let shortName = evt.name.substring(0, 27);
    if (shortName.length === 27) {
      shortName += '...';
    }
    return shortName;
  }

  //#endregion

  onFilterKeyUp(key: any) {
		if (key.which === 13) {
			this.onFilterChange();
		}
	}

  filterEvents(){
    const modalOptions:NgbModalOptions={
      windowClass:'zoom',
      size:'sm',
      ariaLabelledBy:'event-filter',
      centered:true
    }
    this.modalRef=this.modalService.open(
      FilterComponent,
      modalOptions
    );
    this.modalRef.componentInstance['Events']=this.events;
    this.subscriptions$.add(
      (
        this.modalRef.componentInstance as FilterComponent
      ).filterOptions.subscribe((result)=>{
        const filter:ListRequest={
          includeInactives: result.includeInactives,
          itemsPerPage: result.itemsPerPage,
          page: result.page,
          
          filter: result.filter,
          includeIds: result.includeIds,
          excludeIds: result.excludeIds,
          includeCodes: result.includeCodes,
          excludeCodes: result.excludeCodes
        };
        this.reloadList()
      })
    )
  }
}
