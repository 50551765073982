import { ChangeNotificationInfo } from './../models/api/entities/change-notification-info';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { ApiService } from './api.service';
import { ChangeSubjectType } from '../models/api/enums/change-subject-type';
import { ReplaySubject } from 'rxjs';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ChangeNotificationsService {

  private _notifications$ = new ReplaySubject<ChangeNotificationInfo[]>(1);
  @Output() public notificationsChange =this._notifications$.asObservable();

  private _isNotificationsViewVisible = false;
  private _notifications: ChangeNotificationInfo[] = [];
  public get notifications(): ChangeNotificationInfo[] { return this._notifications; }

  public get isNotificationsViewVisible(): boolean { return this._isNotificationsViewVisible; }
  public set isNotificationsViewVisible(value: boolean) {
    if (value !== this._isNotificationsViewVisible) {
      this._isNotificationsViewVisible = value;
      this.updateIsNotificationViewVisible(this.isNotificationsViewVisible);
    }
  }

  private _isNotificationsAvailable = false;

  private _isNotificationViewVisible$ = new ReplaySubject<boolean>(1);
  @Output() public isNotificationsViewVisibleChange = this._isNotificationViewVisible$.asObservable();

  private _isNotificationsAvailable$ = new ReplaySubject<boolean>(1)
  @Output() public isNotificationsAvailableChange = this._isNotificationsAvailable$.asObservable();

  private apiService: ApiService;

  constructor() {}

  updateNotifications(changedNotifications: ChangeNotificationInfo[]) {
    this._notifications$.next(changedNotifications);

 }

 updateIsNotificationViewVisible(isViewVisible: boolean){
  this._isNotificationViewVisible$.next(isViewVisible)
 }

 updateIsNotificationsAvailable(isNotificationAvailable: boolean){
  this._isNotificationsAvailable$.next(isNotificationAvailable);
 }

  public setApiService(apiService: ApiService)
  {
    this.apiService = apiService;
  }

  public setNotifications(notifications: ChangeNotificationInfo[])
  {
    if (notifications == null) {
      notifications = [];
    }
    this._notifications = notifications;
    this.updateNotifications(this._notifications);
    this._isNotificationsAvailable = notifications.length > 0;
    this.updateIsNotificationsAvailable(this._isNotificationsAvailable)
  }

  public showView() {
    this.isNotificationsViewVisible = true;
  }

  public hideView() {
    this.isNotificationsViewVisible = false;
  }

  public toggleView() {
    if (this.isNotificationsViewVisible) {
      this.hideView();
    } else {
      this.showView();
    }
  }

  public async removeNotifications(notifications: ChangeNotificationInfo[]) {
    if (this.apiService == null) {
      return;
    }

    const ids = notifications.filter(a => this.notifications.some(b => b.id === a.id)).map(t => t.id);
    await this.apiService.removeNotifications(ids);
  }

  public removeNotificationsByQuery(subjectType: ChangeSubjectType, subjectId: string[]) {
    const notifications = this.notifications.filter(t =>
      t.changeSubjectType === subjectType &&
      t.subjectId.length === subjectId.length &&
      t.subjectId.every(a => subjectId.some(b => b === a)));

    if (notifications.length === 0) {
      return;
    }

    this.removeNotifications(notifications);
  }
}
