import { isNullOrUndefined } from 'src/app/utils/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../../services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { Country } from 'src/app/models/api/entities/country';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { ListResponse } from '../../../models/api/protocol/list-response';
import { LocalListResponse } from '../../../models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MimeTypes } from 'src/app/utils/mime-types';
import { UserInfo } from '../../../models/api/entities/user-info';
import { UserPrivilege } from '../../../models/api/enums/user-privilege';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { UserService } from 'src/app/services/user.service';
import { isConvertableToNumber, toDateTimeString } from 'src/app/shared/utils/utils';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-delegations-list',
  templateUrl: './delegations-list.component.html',
  styleUrls: ['./delegations-list.component.scss']
})
export class DelegationsListComponent implements OnInit {

  toDateTimeString = toDateTimeString;

  //#region Fields

  public includeDisabled = false;
  public itemsPerPage = 10;
  public filter = '';

  public evt: EventInfo;
  public delegations: ListResponse<DelegationInfo> = new LocalListResponse<DelegationInfo>();
  public delegation: DelegationInfo;
  public currentCountryCode: string;
  public viewingDeadlines = false;

  public users: UserInfo[] = [];

  public canManageEvent = false;


  //#endregion

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);


    if (!canViewEvent) {
      this.close();
      return;
    }

    this.route.parent.paramMap.subscribe(async params => {

      const evtIdStr = params.get("eventId");

      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      this.evt = evt;
      this.canManageEvent = canManageEvent;

      let breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs = [...breadcrumbs, { key: 'Events', link: `/events` }]
      }
      breadcrumbs = [...breadcrumbs, { key: '', value: `${evt.name}`, link: `/events/${evt.id}` }]
      breadcrumbs = [...breadcrumbs, { key: 'Delegations', value: ``, link: `` }]
      this.breadcrumbsService.breadcrumbs = breadcrumbs;
      this.breadcrumbsService.title = evt.name;

      await this.reloadList();

      if (params.has('countryCode')) {
        const countryCode = params.get('countryCode');

        if (!isNullOrUndefined(countryCode)) {
          this.viewSpecialDeadlines(countryCode);
        }else{
          this.closeSpecialDeadlines();
        }

      };
    });
  }

  private close() {
    const curUser = this.apiService.currentUser;
    if (curUser === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);
    const canViewThisEvent = this.evt != null && canViewEvent && curUser.eventId === this.evt.id;

    if (canViewThisEvent) {
      this.router.navigate(['/events/' + this.evt.id]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private async reloadList() {
    const delegations = await this.apiService
      .getDelegations(this.includeDisabled, this.itemsPerPage, this.delegations.page, this.filter, this.evt.id);
    let usernames = delegations.items
      .map(t => t.updatedByUsername)
      .filter(t => t != null && !this.users.some(u => u.username === t));
    usernames = Array.from(new Set(usernames));
    if (usernames.length > 0) {
      const usersResponce = await this.apiService.getUsers(true, 0, 1, null, null, usernames);
      const users = [...this.users, ...usersResponce.items];
      this.users = users;
      for (const delegation of delegations.items) {
        if (delegation.updatedByUsername == null) {
          continue;
        }
        delegation.updatedByUser = this.users.find(t => t.username == delegation.updatedByUsername)
      }
    }
    this.delegations = delegations;
  }

  private downloadFile(path: string, mimeType: string) {
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private viewDelegation(countryCode: string, clubShortName: string) {
    let route = `events/${this.evt.id}/delegations/${countryCode}`;
    if(clubShortName){
      route = `events/${this.evt.id}/delegations/${countryCode}/${clubShortName}`;
    }
    this.router.navigate([route]);
  }

  private viewSpecialDeadlines(countryCode: string) {
    this.delegation = this.delegations.items.filter(t => t.countryCode == countryCode)[0];
    this.location.go(`events/${this.evt.id}/delegations/special-deadlines/${countryCode}`);
    this.currentCountryCode = countryCode;
    this.viewingDeadlines = true;
  }

  private downloadInvoice(countryCode: string, clubShortName: string) {
    let path = `/Export/DelegationInvoice?eventId=${this.evt.id}&country=${countryCode}`;
    if(clubShortName){
      path = `/Export/DelegationInvoice?eventId=${this.evt.id}&country=${countryCode}&club=${clubShortName}`;
    }
    const mimeType = MimeTypes.pdf;
    this.downloadFile(path, mimeType);
  }

  private specialDeadlines(countryCode: string) {
    this.viewSpecialDeadlines(countryCode);
  }

  private downloadAnthem(countryCode: string) {
    const path = `/Music/GetAnthem?country=${countryCode}&isDownload=true&rand=${Math.floor(Math.random() * 2000)}`;
    const mimeType = MimeTypes.mp3;
    this.downloadFile(path, mimeType);
  }

  private closeSpecialDeadlines(){
    this.location.go(`events/${this.evt.id}/delegations/`);
    this.viewingDeadlines = false;
    this.currentCountryCode = null;
    this.reloadList();
  }

  //#endregion

  //#region Actions

  onFilterChange() {
    this.reloadList();
  }

  onPageChanged(page: number) {
    this.delegations.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onViewDelegation(delegation: DelegationInfo) {
    if (delegation == null) {
      return;
    }
    this.viewDelegation(delegation.countryCode, delegation.clubShortName);
  }

  onDownloadInvoice(delegation: DelegationInfo) {
    if (delegation == null) {
      return;
    }
    this.downloadInvoice(delegation.countryCode, delegation.clubShortName);
  }

  onSpecialDeadlines(delegation: DelegationInfo) {
    if (delegation == null) {
      return;
    }
    this.specialDeadlines(delegation.countryCode);
  }

  onDownloadAnthem(delegation: DelegationInfo) {
    if (delegation == null) {
      return;
    }
    this.downloadAnthem(delegation.countryCode);
  }

  onSpecialDeadlinesUpdated() {
    this.reloadList();
  }

  onSpecialDeadlinesClosed() {
    this.closeSpecialDeadlines();
  }

  //#endregion

  //#region Data

  public getFlagAddress(country: Country): string {
    if (country == null) {
      return null;
    }
    return this.entityMapService.getCountryFlagAddress(country.code);
  }


  onFilterKeyUp(key: any) {
		if (key.which === 13) {
			this.onFilterChange();
		}
	}

  //#endregion

}
