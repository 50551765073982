import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public yearStr = '2018';

  public isNavbarCollapsed = true;

  constructor() { }

  ngOnInit() {
    const curYear = new Date().getFullYear();
    if (curYear.toString() !== this.yearStr) {
      this.yearStr = this.yearStr + ' - ' + curYear;
    }
  }

}
