import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from 'src/app/services/api.service';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { ChangeSubjectType } from 'src/app/models/api/enums/change-subject-type';
import { Country } from 'src/app/models/api/entities/country';
import { CurrencyType } from 'src/app/models/api/enums/currency-type';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { MealReservationInfo } from 'src/app/models/api/entities/meal-reservation-info';
import { MealType } from 'src/app/models/api/enums/meal-type';
import { MealTypeInfo } from 'src/app/models/api/entities/meal-type-info';
import { MealVenueInfo } from 'src/app/models/api/entities/meal-venue-info';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { isNullOrUndefined, toMoment } from 'src/app/utils/utils';
import { isNull } from 'lodash';
import { PenaltyType } from 'src/app/models/api/enums/penalty-type';
import { HotelNightsCounterPipe } from 'src/app/pipes/hotel-nights-counter.pipe';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { deepClone, toDate, toDateString } from 'src/app/shared/utils/utils';
export interface RangeCheck {
  IsOutOfRangeServiceStartDate: boolean;
  IsAggreeWithRangeServiceStartDate: boolean;
  IsOutOfRangeServiceEndDate: boolean;
  IsAggreeWithRangeServiceEndDate: boolean;
}
export enum AggreeType {
  StartDate,
  EndDate
}

@Component({
  selector: 'app-meal-reservations',
  templateUrl: './meal-reservations.component.html',
  styleUrls: ['./meal-reservations.component.scss']
})
export class MealReservationsComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() country: Country;

  @Input() clubShortName: string = null;

  @Input() reservationId: 0;

  @Output() saved = new EventEmitter<MealReservationInfo>();
  @Output() closed = new EventEmitter<MealReservationInfo>();

  @ViewChild('mealReservationModal', { static: true }) private mealReservationModal: TemplateRef<any>;
  private mealReservationModalRef: NgbModalRef;

  allMealVenues: MealVenueInfo[];
  allMealTypes: MealTypeInfo[];

  mealVenue: MealVenueInfo;

  reservation: MealReservationInfo;

  minDate: string;
  maxDate: string;

  serveDate: Date;

  isNew: boolean;

  price: number = 0;

  isVisiblle: boolean;
  isReadOnly: boolean;

  penaltyType: PenaltyType = PenaltyType.Meal;
  isAgreeWithCancellationPolicy: boolean = false;

  messageService: MessageService;

  rangeCheck: RangeCheck = {
    IsOutOfRangeServiceStartDate: false,
    IsAggreeWithRangeServiceStartDate: false,
    IsOutOfRangeServiceEndDate: false,
    IsAggreeWithRangeServiceEndDate: false,
  }

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private changeNotificationsService: ChangeNotificationsService,
    private alertService: AlertService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    this.allMealTypes = this.entityMapService.getAllMealTypes();

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.country.code);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }

    this.minDate = toDateString(toMoment(this.evt.startDate).add(-10, 'month').toDate());
    this.maxDate = toDateString(toMoment(this.evt.startDate).add(10, 'month').toDate());

    this.serveDate = toDate(this.evt.deadlines.mealsRangeStartDate);

    if (this.evt.features.isPenaltyFunctionEnabled)
      this.isAgreeWithCancellationPolicy = false;

    if (this.reservationId === 0) {
      this.newReservation();
    } else {
      this.loadReservation();
    }
  }

  private async newReservation() {

    const meals = await this.apiService.getMealServiceVenues(false, -1, 1, null, this.evt.id);
    this.allMealVenues = meals.items.filter(t => t.id > 0);

    if (this.allMealVenues.length === 0) {
      this.messageService.setToastMessage(false, 'There is no meal venues available in this event.', 3);
      this.close();
      return;
    }

    this.isNew = true;
    this.reservation = {
      id: 0,
      venueId: null,
      eventId: this.evt.id,
      countryCode: this.country.code,
      serveDate: toDateString(this.serveDate),
      mealType: null,
      quantity: 1,
      name: null,
      price: null,
      isAgreeWithCancellationPolicy: this.isAgreeWithCancellationPolicy
    };

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      this.reservation.clubShortName = this.clubShortName;

    this.open();
  }

  private async loadReservation() {
    if (this.isVisiblle) {
      this.close();
    }

    const meals = await this.apiService.getMealServiceVenues(false, -1, 1, null, this.evt.id);
    this.allMealVenues = meals.items.filter(t => t.id > 0);

    if (this.allMealVenues.length === 0) {
      this.messageService.setToastMessage(false, 'There is no meal venues available in this event.', 3);
      this.close();
      return;
    }


    this.isNew = false;

    const reservation = await this.apiService.getMealReservation(this.reservationId);
    if (reservation == null) {
      this.close();
      return;
    }

    this.serveDate = toDate(reservation.serveDate);

    this.reservation = reservation;

    this.isAgreeWithCancellationPolicy = this.reservation.isAgreeWithCancellationPolicy;

    this.changeNotificationsService.removeNotificationsByQuery(ChangeSubjectType.MealReservation, [reservation.id.toString()]);

    this.onSelectChanged();

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.mealReservationModalRef != null) {
      this.mealReservationModalRef.close();
      this.mealReservationModalRef = null;
    }
    this.mealReservationModalRef = this.modalService.open(this.mealReservationModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.mealReservationModalRef != null) {
      this.mealReservationModalRef.close();
      this.mealReservationModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.reservation);
  }

  private async save() {

    const reservation: MealReservationInfo = deepClone(this.reservation);

    reservation.eventId = this.evt.id;
    reservation.serveDate = toDateString(this.serveDate);

    this.serveDateRangeCheck(reservation.serveDate);

    if (this.rangeCheck.IsOutOfRangeServiceStartDate && !this.rangeCheck.IsAggreeWithRangeServiceStartDate) {
      this.rangeAlert(AggreeType.StartDate, "You are out of meals service range start date. ");
      return;
    }

    if (this.rangeCheck.IsOutOfRangeServiceEndDate && !this.rangeCheck.IsAggreeWithRangeServiceEndDate) {
      this.rangeAlert(AggreeType.EndDate, "You are out of meals service range end date.");
      return;
    }

    if (isNull(reservation.venueId)) {
      this.messageService.setToastMessage(false, 'Please select venue.');
      return;
    }

    if (isNullOrUndefined(reservation.mealType)) {
      this.messageService.setToastMessage(false, 'Please select meal type.');
      return;
    }

    if (reservation.quantity === 0) {
      this.messageService.setToastMessage(false, 'Please add quantity.');
      return;
    }

    if(this.evt.features.isPenaltyFunctionEnabled && !this.isAgreeWithCancellationPolicy){
      this.messageService.setToastMessage(false, "Please agree with cancellation policy.");
      return;
    }

    if(this.evt.features.isPenaltyFunctionEnabled){
      reservation.isAgreeWithCancellationPolicy = this.isAgreeWithCancellationPolicy;
    }

    if(this.evt.features.isClubExist && this.clubShortName !== null)
      reservation.clubShortName = this.clubShortName;

    const request: UpdateRequest<MealReservationInfo> = {
      isNew: this.isNew,
      subject: reservation
    };

    const result = await this.apiService.updateMealReservation(request);
    if (result.isSuccess) {
      this.saved.emit(this.reservation);
      this.messageService.setToastMessage(true, this.isNew ? 'Meal reservation created' : 'Meal reservation updated', 10);
      this.onClose();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage, 10);
    }

  }

  serveDateRangeCheck(serveDate: string) {

    const hotelNightsCounterPipe = new HotelNightsCounterPipe();

    const countServeDateServiceStart: number = hotelNightsCounterPipe.transform(this.evt.deadlines.mealsRangeStartDate, serveDate);
    const countServeDateServiceEnd: number = hotelNightsCounterPipe.transform(serveDate, this.evt.deadlines.mealsRangeEndDate);

    if (countServeDateServiceStart < 0)
      this.rangeCheck.IsOutOfRangeServiceStartDate = true;

    if (countServeDateServiceEnd < 0)
      this.rangeCheck.IsOutOfRangeServiceEndDate = true;

  }

  rangeAlert(aggreeType: AggreeType, message: string): void {
    this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        switch (aggreeType) {
          case AggreeType.StartDate:
            this.rangeCheck.IsAggreeWithRangeServiceStartDate = true;
            break;
          case AggreeType.EndDate:
            this.rangeCheck.IsAggreeWithRangeServiceEndDate = true;
            break;
        }
      }
    });
  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  onSelectChanged() {
    this.price = 0;

    this.allMealTypes = this.entityMapService.getAllMealTypes();

    let getFilteredVenue = this.allMealVenues.filter(v => v.id === this.reservation.venueId)[0];

    if (this.reservation.mealType !== null) {

      switch (this.reservation.mealType.toString()) {
        case MealType[MealType.B]:
          this.price = getFilteredVenue.breakfastServicePrice;
          break;
        case MealType[MealType.L]:
          this.price = getFilteredVenue.lunchServicePrice;
          break;
        case MealType[MealType.D]:
          this.price = getFilteredVenue.dinnerServicePrice;
          break;
      }

    }

    if (getFilteredVenue.breakfastServicePrice === null || getFilteredVenue.breakfastServicePrice === 0)
      this.allMealTypes = this.allMealTypes.filter(t => t.mealType !== MealType.B);

    if (getFilteredVenue.lunchServicePrice === null || getFilteredVenue.lunchServicePrice === 0)
      this.allMealTypes = this.allMealTypes.filter(t => t.mealType !== MealType.L);

    if (getFilteredVenue.dinnerServicePrice === null || getFilteredVenue.dinnerServicePrice === 0)
      this.allMealTypes = this.allMealTypes.filter(t => t.mealType !== MealType.D);


  }


  //#endregion

}

