import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { ApiService } from "src/app/services/api.service";
import { BreadcrumbsService } from "src/app/services/breadcrumbs.service";
import { EntityMapService } from "src/app/services/entity-map.service";
import { EventInfo } from "src/app/models/api/entities/event-info";
import { ListResponse } from "src/app/models/api/protocol/list-response";
import { LocalListResponse } from "src/app/models/api/protocol/local-list-response";
import { MailInfo } from "src/app/models/api/entities/mail-info";
import { UpdateRequest } from "src/app/models/api/protocol/update-request";
import { UserInfo } from "src/app/models/api/entities/user-info";
import { UserPrivilege } from "src/app/models/api/enums/user-privilege";
import { UserRole } from "src/app/models/api/enums/user-role";
import { UserService } from "src/app/services/user.service";
import { PageHeaderItem } from "src/app/shared/models/page-header-item";
import { MessageService } from "src/app/shared/services/message.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { isConvertableToNumber } from "src/app/shared/utils/utils";

@Component({
  selector: "app-mailbox",
  templateUrl: "./mailbox.component.html",
  styleUrls: ["./mailbox.component.scss"],
})
export class MailboxComponent implements OnInit {
  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public canManageEvent = false;
  public canManageSystem = false;

  public contactsList: UserInfo[];
  public contactsSelected: [];
  public subject: string;
  public message: string;

  public messageService: MessageService;

  editorConfig = {
    placeholder: "",
    tabsize: 2,
    height: "200px",
    toolbar: [["style", ["bold", "italic", "underline", "clear"]]],
  };

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private alertService: AlertService
  ) {
    this.messageService = new MessageService();
  }

  async ngOnInit(): Promise<void> {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageSystem
    );
    this.canManageSystem = canManageSystem;
    const canManageEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageEvent
    );
    const canViewEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ViewEvent
    );

    if (!canViewEvent) {
      this.close();
      return;
    }

    this.route.parent.paramMap.subscribe(async (params) => {
      const evtIdStr = params.get("eventId");
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      this.canManageEvent = canManageEvent;

      this.evt = evt;

      if (curUser.role === 1) {
        this.breadcrumbsService.breadcrumbs = [
          { key: 'Events', link: `/events` },
        ];
      }

      if (canManageEvent) {
        this.breadcrumbsService.breadcrumbs.push({
          key: "",
          value: `${evt.name}`,
          link: `/events/${evt.id}`,
        });
        this.breadcrumbsService.breadcrumbs.push({
          key: "Mail",
          link: ``,
        });
        this.breadcrumbsService.title = evt.name;


        this.headerItems = [
          ...this.headerItems,
          {
            title: "Send",
            iconClass: "fa-send",
            callback: () => this.onSendMail(),
          },
          {
            title: "Close",
            iconClass: "fa-close",
            callback: () => this.onClose(),
          },
        ];
      }

      const contactsResponse = await this.apiService.getUsers(
        false,
        200,
        0,
        null,
        this.evt.id
      );

      this.contactsList = contactsResponse.items.filter(
        (u) => u.role !== UserRole.LOC
      );
    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(["/auth"]);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      user,
      UserPrivilege.ManageSystem
    );

    if (canManageSystem) {
      this.router.navigate(["/events"]);
    } else {
      this.router.navigate(["/"]);
    }
  }

  private async send() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(["/auth"]);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      user,
      UserPrivilege.ManageSystem
    );

    if (canManageSystem) {
      const request: UpdateRequest<MailInfo> = {
        isNew: false,
        subject: {
          eventId: this.evt.id,
          users: this.contactsSelected,
          subject: this.subject,
          text: this.message,
        },
      };

      const result = await this.apiService.sendMail(request);

      if (result.isSuccess) {
        this.messageService.setToastMessage(true, "Mail send", 10);
        this.router.navigate([`/events/${this.evt.id}`]);
      } else {
        this.messageService.setToastMessage(false, result.errorMessage, 10);
      }
    } else {
      this.router.navigate(["/"]);
    }
  }

  //#endregion

  //#region Actions

  onClose() {
    this.close();
  }

  onSendMail() {
    this.send();
  }
}
