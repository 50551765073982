// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[1].use[0]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[1].use[1]!../../../../../node_modules/@ng-select/ng-select/themes/default.theme.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group-prepend {
    text-align: center;
}

.input-group-prepend .input-group-text {
    min-width: 30px;
    text-align: center;
}

.popover {
    max-width: unset !important;
}

.width-100-pr {
  width: 70%;
 }

@media (min-width: 768px) {

    [class^="col-"]:not(.first) {
        padding-left: 5px;
    }

    [class^="col-"]:not(.last) {
        padding-right: 5px;
    }

}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/date-input/date-input.component.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;EACE,UAAU;CACX;;AAED;;IAEI;QACI,iBAAiB;IACrB;;IAEA;QACI,kBAAkB;IACtB;;AAEJ","sourcesContent":["@import \"../../../../../node_modules/@ng-select/ng-select/themes/default.theme.css\";\r\n\r\n.input-group-prepend {\r\n    text-align: center;\r\n}\r\n\r\n.input-group-prepend .input-group-text {\r\n    min-width: 30px;\r\n    text-align: center;\r\n}\r\n\r\n.popover {\r\n    max-width: unset !important;\r\n}\r\n\r\n.width-100-pr {\r\n  width: 70%;\r\n }\r\n\r\n@media (min-width: 768px) {\r\n\r\n    [class^=\"col-\"]:not(.first) {\r\n        padding-left: 5px;\r\n    }\r\n\r\n    [class^=\"col-\"]:not(.last) {\r\n        padding-right: 5px;\r\n    }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
