import { Pipe, PipeTransform } from '@angular/core';

import { Country } from '../models/api/entities/country';
import { EntityMapService } from '../services/entity-map.service';

@Pipe({
  name: 'flagsImg'
})
export class FlagsImgPipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  transform(country: Country): string {
    if (country == null) {
      return null;
    }
    return this.entityMapService.getCountryFlagAddress(country);
  }

}
