export enum ApparatusType {
  // AG
  PH = 0, // Pommel Horse
  SR, // Still Rings
  PB, // Parallel Bars
  HB, // Horizontal Bar

  VT, // Vault
  FX, // Floor Exercises

  UB, // Uneven Bars
  BB, // Balance Beam

  // RG individual
  RP, // Rope
  HP, // Hoop
  BL, // Ball
  CL2, // Clubs
  RB, // Ribbon

  // RG group
  RB5, // 5 Ribbons
  RP5, // 5 Ropes
  BL5, // 5 Balls
  HP5, // 5 Hoops
  CL10, // 5 Pairs of Clubs
  BL3_HP2, // 3 Balls & 2 Hoops
  BL3_RP2, // 3 Balls & 2 Ropes
  HP3_CL4, // 3 Hoops & 4 Clubs
  HP3_RB2, // 3 Hoops & 2 Ribbons
  BL3_CL4, // 3 Balls & 4 Clubs
  RB3_BL2, // 3 RIBONS & 2 Balls

  FL, // Floor
  TUM, // Tumble
  TRA, // Trampet

  BAL, // Balance
  COM, // Combined
  DYN // Dynamic
}
