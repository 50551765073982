import { GroupType } from './../models/api/enums/group-type';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityMapService } from '../services/entity-map.service';
import { GroupTypeInfo } from '../models/api/entities/group-type-info';

@Pipe({
  name: 'groupType'
})
export class GroupTypePipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }
  
  async transform(groupType: GroupType, withShortCode: boolean = false): Promise<string> {
    if (groupType === null) {
      return null;
    }
    await this.entityMapService.ready();

    let getTypeInfo: GroupTypeInfo = this.entityMapService.getGroupByType(groupType);
    
    let returnName: string = getTypeInfo.name;

    if(withShortCode){
      returnName = `[${getTypeInfo.code}] ${getTypeInfo.name}`;
    }
    
    return returnName
  }

}
