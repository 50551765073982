import { Component } from '@angular/core';

@Component({
  selector: 'app-event-container',
  templateUrl: './event-container.component.html',
  styleUrl: './event-container.component.scss'
})
export class EventContainerComponent {

}
