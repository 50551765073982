import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { CurrencyType } from 'src/app/models/api/enums/currency-type';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelInfo } from 'src/app/models/api/entities/hotel-info';
import { HotelRoomInfo } from 'src/app/models/api/entities/hotel-room-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MimeTypes } from 'src/app/utils/mime-types';
import { RoomType } from 'src/app/models/api/enums/room-type';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { formatMoney } from 'src/app/utils/utils';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-hotel-rooms-list',
  templateUrl: './hotel-rooms-list.component.html',
  styleUrls: ['./hotel-rooms-list.component.scss']
})
export class HotelRoomsListComponent implements OnInit {

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[];

  @Input() public evt: EventInfo;
  @Input() public hotel: HotelInfo;

  public rooms: ListResponse<HotelRoomInfo> = new LocalListResponse<HotelRoomInfo>();

  public currentRoomId: number;
  public viewingRoom: boolean;

  public canManageEvent = false;

  public messageService: MessageService;

  public isQuantityDisabled: boolean = false;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);

    if (!canViewEvent) {
      return;
    }

    const canViewThisEvent = canManageSystem || curUser.eventId === this.evt.id;
    if (!canViewThisEvent) {
      return;
    }

    this.canManageEvent = canManageEvent;

    this.updateHeaderItems();

    this.route.paramMap.subscribe(async (paramMap) => {
      const roomIdStr = paramMap.get('roomId');

      let roomId = 0;
      if (isConvertableToNumber(roomIdStr)) {
        roomId = Number(roomIdStr);
      }

      if (roomId === 0) {
        return;
      }

      this.viewRoom(roomId);
    });

    this.reloadList();
  }

  private updateHeaderItems() {
    let headerItems: PageHeaderItem[] = [];
    if (this.canManageEvent) {
      headerItems.push({ title: 'Download rooms', iconClass: 'fa-file-excel-o', callback: () => this.onDownloadRooms() });
      headerItems.push({ title: 'Add room', iconClass: 'fa-plus', callback: () => this.onAddRoom() });
    }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    this.rooms = await this.apiService
      .getHotelRooms(true, this.itemsPerPage, this.rooms.page, null, this.hotel.id);

    const isQuantityDisabled = this.rooms.items.filter(t => t.totalQuantity !== 0).length === 0;

    this.isQuantityDisabled = isQuantityDisabled;

  }

  private viewRoom(roomId: number) {
    this.location.go(`events/${this.evt.id}/hotels/${this.hotel.id}/rooms/${roomId}`);
    this.currentRoomId = roomId;
    this.viewingRoom = true;
  }

  private closeRoom() {
    this.location.go(`events/${this.evt.id}/hotels/${this.hotel.id}`);
    this.viewingRoom = false;
    this.currentRoomId = 0;
  }

  private addRoom() {
    this.viewRoom(0);
  }

  private disableRoom(room: HotelRoomInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        if (room.isDisabled) {
          return;
        }
        this.apiService.disableHotelRoom(room.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `Room ${this.hotel.name} disabled`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });

      }
    });
  }

  private enableRoom(room: HotelRoomInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        if (!room.isDisabled) {
          return;
        }
        this.apiService.enableHotelRoom(room.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `Room '${room.id}' enabled`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  private downloadHotelRoom() {
    const path = `/Export/Hotel?eventId=${this.evt.id}&hotelId=${this.hotel.id}`;
    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  //#endregion

  //#region Actions

  onDownloadRooms(){
    this.downloadHotelRoom();
  }

  onPageChanged(page: number) {
    this.rooms.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onAddRoom() {
    this.addRoom();
  }

  onViewRoom(room: HotelRoomInfo) {
    if (room == null) {
      return;
    }
    this.viewRoom(room.id);
  }

  onRoomUpdated() {
    this.reloadList();
  }

  onRoomClosed() {
    this.closeRoom();
  }

  onDisableRoom(room: HotelRoomInfo) {
    this.disableRoom(room);
  }

  onEnableRoom(room: HotelRoomInfo) {
    this.enableRoom(room);
  }

  //#endregion

}
