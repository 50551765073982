// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=number] {
    border: 1px solid #ced4da;
}

.input-group {
    max-width: 250px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.input-group-prepend button {
    margin-right: 1px;
}

.input-group-append button{
    margin-left: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/pager/pager.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["input[type=number] {\r\n    border: 1px solid #ced4da;\r\n}\r\n\r\n.input-group {\r\n    max-width: 250px;\r\n    border: 1px solid #ced4da;\r\n    border-radius: 4px;\r\n}\r\n\r\n.input-group-prepend button {\r\n    margin-right: 1px;\r\n}\r\n\r\n.input-group-append button{\r\n    margin-left: 1px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
