import { EventEmitter, Injectable, Output } from '@angular/core';

export type Confirmation = ((confirmed: boolean) => void);

@Injectable({
	providedIn: 'root'
})
export class AlertService {

	private confirmationCallback?: Confirmation;

	@Output() public visibilityChanged = new EventEmitter<void>();

	private _isVisible: boolean;
	public get isVisible(): boolean {
		return this._isVisible;
	}

	private _message: string;
	get message(): string {
		return this._message;
	}

	private _trueButtonTitle: string;
	get trueButtonTitle(): string {
		return this._trueButtonTitle;
	}

	private _falseButtonTitle: string;
	get falseButtonTitle(): string {
		return this._falseButtonTitle;
	}

	private _isConfirmation: boolean;
	get isConfirmation(): boolean {
		return this._isConfirmation;
	}

	constructor() { }

	public showAlert(message: string) {
		if (this.isVisible) {
			this.cancel();
		}
		this._trueButtonTitle = 'Confirm';
		this._falseButtonTitle = 'Cancel';
		this._message = message;
		this._isConfirmation = false;
		this._isVisible = true;
		this.visibilityChanged.emit();
	}

	public showConfirmation(message: string, confirmation: Confirmation) {
		this.showConfirmationConfirmCancel(message, confirmation);
	}

	public showConfirmationConfirmCancel(message: string, confirmation: Confirmation) {
		if (this.isVisible) {
			this.cancel();
		}
		this._trueButtonTitle = 'Confirm';
		this._falseButtonTitle = 'Cancel';
		this._message = message;
		this._isConfirmation = true;
		this._isVisible = true;
		this.confirmationCallback = confirmation;
		this.visibilityChanged.emit();
	}

	public showConfirmationYesNo(message: string, confirmation: Confirmation) {
		if (this.isVisible) {
			this.cancel();
		}
		this._trueButtonTitle = 'Yes';
		this._falseButtonTitle = 'No';
		this._message = message;
		this._isConfirmation = true;
		this._isVisible = true;
		this.confirmationCallback = confirmation;
		this.visibilityChanged.emit();
	}

	public confirm() {
		if (this.confirmationCallback != null) {
			this.confirmationCallback(true);
		}
		this._isVisible = false;
		this._message = null;
		this._isConfirmation = false;
		this.confirmationCallback = null;
	}

	public cancel() {
		if (this.isConfirmation && this.confirmationCallback != null) {
			this.confirmationCallback(false);
		}
		this._isVisible = false;
		this._message = null;
		this._isConfirmation = false;
		this.confirmationCallback = null;
	}
}
