// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  text-align: center;
  white-space: nowrap;
}

.brd-right {
  border-right: 1.5px solid #15a3b8 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/events/missed-informations/missed-informations.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;AACJ;;AAGA;EACE,4CAAA;AAAF","sourcesContent":["th, td {\r\n    text-align: center;\r\n    white-space: nowrap\r\n}\r\n\r\n\r\n.brd-right{\r\n  border-right: 1.5px solid #15a3b8!important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
