// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  text-align: center;
  white-space: nowrap;
}

td {
  vertical-align: inherit;
}

th:last-child {
  border-right: 0;
}

.table-responsive {
  overflow-x: unset !important;
}

ul {
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
}

td li {
  border-bottom: 1px solid;
}

td li:last-child {
  border-bottom: none;
}

.incDecButton {
  background: none;
  border: none;
  margin: 0 5px;
}

.incDecButton:focus, .incDecButton:active {
  outline: none;
}

.disabled {
  background-color: #313131;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hotels/hotel-reservations-list/hotel-reservations-list.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;AACJ;;AAGA;EACE,uBAAA;AAAF;;AAGA;EACI,eAAA;AAAJ;;AAGA;EACE,4BAAA;AAAF;;AAGA;EACE,qBAAA;EACA,SAAA;EACA,uBAAA;AAAF;;AAGA;EACE,wBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;AAAF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;AACF","sourcesContent":["th, td {\r\n    text-align: center;\r\n    white-space: nowrap\r\n}\r\n\r\n\r\ntd {\r\n  vertical-align: inherit;\r\n}\r\n\r\nth:last-child {\r\n    border-right: 0;\r\n}\r\n\r\n.table-responsive{\r\n  overflow-x: unset!important;\r\n}\r\n\r\nul {\r\n  list-style-type: none;\r\n  margin: 0;\r\n  padding-inline-start: 0;\r\n}\r\n\r\ntd li {\r\n  border-bottom: 1px solid ;\r\n}\r\n\r\ntd li:last-child {\r\n  border-bottom: none;\r\n}\r\n\r\n.incDecButton{\r\n  background: none;\r\n  border:none;\r\n  margin: 0 5px;\r\n}\r\n.incDecButton:focus, .incDecButton:active{\r\n  outline: none;\r\n}\r\n\r\n.disabled {\r\n  background-color: #313131;\r\n  color: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
