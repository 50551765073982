import { IntToPercent } from 'src/app/utils/utils';
import { Pipe, PipeTransform } from '@angular/core';

import { EventFeatures } from '../models/api/entities/event-features';
import { HotelRoomReservationInfo } from '../models/api/entities/hotel-room-reservation-info';
import { RoomType } from '../models/api/enums/room-type';
import { toMoment } from '../shared/utils/utils';

@Pipe({
  name: 'hotelSubtotal'
})
export class HotelSubtotalPipe implements PipeTransform {

  transform(reservation: HotelRoomReservationInfo, eventFeatures?: EventFeatures, isLateBookingStarted: boolean = false): number {
    let subtotal = 0;

    if (reservation === null || reservation.room === null) {
      return subtotal;
    }

    const totalNight = this.nightsCount(reservation.checkInDate, reservation.checkOutDate);

    let price = reservation.room.standartPrice;

    if (reservation.isLateRegistration) {
      price = reservation.room.lateRegistrationPricePerNight;
    }

    switch (reservation.room.roomType) {
      case RoomType.QD:
        price *= 4;
        break;
      case RoomType.TR:
        price *= 3;
        break;
      case RoomType.TW:
        price *= 2;
        break;
      case RoomType.TW_SEP_B:
        price *= 2;
        break;
      case RoomType.TW_SH_B:
        price *= 2;
        break;
      case RoomType.Twin_BB:
        price *= 2;
        break;
      case RoomType.Twin_HB:
        price *= 2;
        break;
      case RoomType.Twin_FB:
        price *= 2;
        break;
      case RoomType.Triple_BB:
        price *= 3;
        break;
      case RoomType.Triple_HB:
        price *= 3;
        break;
      case RoomType.Triple_FB:
        price *= 3;
        break;
      case RoomType.Multiple_4_beds_BB:
        price *= 4;
        break;
      case RoomType.Multiple_4_beds_HB:
        price *= 4;
        break;
      case RoomType.Multiple_4_beds_FB:
        price *= 4;
        break;
      case RoomType.Multiple_6_beds_BB:
        price *= 6;
        break;
      case RoomType.Multiple_6_beds_HB:
        price *= 6;
        break;
      case RoomType.Multiple_6_beds_FB:
        price *= 6;
        break;
    }

    if (reservation.discount > 0) {
      price -= reservation.discount;
    }
    subtotal = reservation.quantity * price * totalNight;

    if (eventFeatures?.isHotelRoomEarlyCheckInAddPriceEnabled && reservation.room.earlyCheckInAddPrice !== 0) {
      if (reservation.isEarlyCheckIn) {
        subtotal += (reservation.room.earlyCheckInAddPrice * reservation.quantity);
      }
    }

    if (eventFeatures?.isHotelRoomLateCheckOutAddPriceEnabled && reservation.room.lateCheckOutAddPrice !== 0) {
      if (reservation.isLateCheckOut) {
        subtotal += (reservation.room.lateCheckOutAddPrice * reservation.quantity);
      }
    }

    if (eventFeatures?.isPenaltyFunctionEnabled && reservation.isDisabled == true) {
      subtotal = (IntToPercent(reservation.percentageOfPenaltyImposed) * subtotal) / 100;
    }
    return subtotal;
  }

  nightsCount(checkInDate, checkOutDate): number {
    if (checkOutDate === null && checkInDate === null) {
      return null;
    }
    const diff = toMoment(checkOutDate).diff(checkInDate, 'days');
    return diff;
  }

}
