import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../../models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { Router } from '@angular/router';
import { stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

const maxBreadcrumbValueLength = 36;

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {



  private _breadcrumbs: Breadcrumb[] = [];
  private _title: string = '';
  constructor(private breadcrumbsService: BreadcrumbsService, private router: Router) {
    this.breadcrumbsService.breadcrumbsChanged.subscribe((breadcrumbs: Breadcrumb[]) => {
      this._breadcrumbs = breadcrumbs;
    })
    this.breadcrumbsService.titleChanged.subscribe((title: string) =>{
      this._title = title;
    })
  }

  ngOnInit() {
  }

  get isVisible(): boolean {
    return this.breadcrumbs.length > 0;
  }

  get breadcrumbs(): Breadcrumb[] {
    return this._breadcrumbs;
  }

  get title(): string{
    return this._title;
  }

  navigateTo(breadcrumb: Breadcrumb) {
    //use '/' if you want to navigate to the base url
    if(breadcrumb.link != ''){
      this.router.navigate([breadcrumb.link]);
    }
  }

  breadcrumbHasValue(breadcrumb: Breadcrumb) {
    return !stringIsNullOrEmpty(breadcrumb.value);
  }

  getBreadcrumbValue(breadcrumb: Breadcrumb) {
    const value = breadcrumb.value;
    if (stringIsNullOrEmpty(value)) {
      return null;
    }

    let finalValue = value.substring(0, Math.min(maxBreadcrumbValueLength, value.length));

    if (finalValue != value) {
      finalValue += '...';
    }

    return finalValue;
  }

}
