import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { arraysEqual, isArray } from '../../utils/utils';

import { NgForOfContext } from '@angular/common';

@Component({
	selector: 'sac-check-list',
	templateUrl: './check-list.component.html',
	styleUrls: ['./check-list.component.css'],
})
export class CheckListComponent implements OnInit, OnChanges {

	@Input() public allItems: any[];

	private checkedItems_: any[];
	@Input() public set checkedItems(value: any[]) {
		this.checkedItems_ = value;
	}
	@Output() public checkedItemsChange = new EventEmitter<any[]>();
	@Output() public change = new EventEmitter<any[]>();

	@ContentChild(TemplateRef) itemTemplate: TemplateRef<NgForOfContext<any>>;

	_allItems: any[] = [];
	_checkedItems: any[] = [];

	ngOnInit() {
		this.cleanup();
	}

	ngOnChanges(changes: SimpleChanges) {
		for (const change in changes) {
			if (change === 'allItems') {
				this.cleanup();
			} else if (change === 'checkedItems') {
				this.cleanup();
			}
		}
	}

	private cleanup() {
		let allItems = this.allItems;
		let checkedItems = this.checkedItems_;
		if (!isArray(allItems)) {
			allItems = [];
		}
		if (!isArray(checkedItems)) {
			checkedItems = [];
		}

		const cleanCheckedItems = [];
		for (const item of checkedItems) {
			if (allItems.some(t => t === item)) {
				cleanCheckedItems.push(item);
			}
		}
		this._allItems = allItems;
		this._checkedItems = cleanCheckedItems;
		if (arraysEqual(cleanCheckedItems, checkedItems)) {
			this.checkedItems = cleanCheckedItems;
		}
	}

	public isItemChecked(item: any) {
		return this._checkedItems.some(t => t === item);
	}

	public onItemClick(item: any) {
		const allItems = this._allItems;
		let checkedItems = this._checkedItems;

		if (item === null || !allItems.some(t => t === item)) {
			return;
		}

		if (checkedItems.some(t => t === item)) {
			checkedItems = checkedItems.filter(t => t !== item);
		} else {
			checkedItems.push(item);
		}

		this.checkedItemsChange.emit(checkedItems);
		this.change.emit(checkedItems);
		this.cleanup();
	}

}
