import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from 'src/app/services/api.service';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { ChangeSubjectType } from 'src/app/models/api/enums/change-subject-type';
import { Country } from 'src/app/models/api/entities/country';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { PersonRequest } from 'src/app/models/api/protocol/person-request';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { VisaTypeInfo } from 'src/app/models/api/entities/visa-type-info';
import { VisaType } from 'src/app/models/api/enums/visa-type';
import { MessageService } from 'src/app/shared/services/message.service';
import { deepClone, toDate, toDateString } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() country: Country;

  @Input() personId: number = 0;

  @Input() clubShortName: string = null;

  @Output() saved = new EventEmitter<PersonInfo>();
  @Output() closed = new EventEmitter<PersonInfo>();

  @ViewChild('documentModal', { static: true }) private documentModal: TemplateRef<any>;
  private documentModalRef: NgbModalRef;

  allPersons: PersonInfo[];
  allVisaTypes: VisaTypeInfo[];

  person: PersonInfo;

  visaDepartureDate: Date;
  visaArrivalDate: Date;
  passportExpiryDate: Date;

  personRequest: PersonRequest;

  isNew: boolean;

  isVisiblle: boolean;
  isReadOnly: boolean;

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private changeNotificationsService: ChangeNotificationsService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);



    if (!canViewDelegation) {
      this.close();
      return;
    }

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.country.code);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }

    this.allVisaTypes = this.entityMapService.getAllVisaTypes();

    this.loadPerson();
  }

  private async loadPerson() {
    if (this.isVisiblle) {
      this.close();
    }

    this.isNew = false;

    this.personRequest = {
      personId: this.personId,
      withPhotos: false
    }

    const person = await this.apiService.getPerson(this.personRequest);

    if (person == null) {
      this.close();
      return;
    }

    this.visaArrivalDate = toDate(person.visaArrivalDate);
    this.visaDepartureDate = toDate(person.visaDepartureDate);
    this.passportExpiryDate = toDate(person.passportExpiryDate);

    this.person = person;

    this.changeNotificationsService.removeNotificationsByQuery(ChangeSubjectType.Person, [person.id.toString()]);

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.documentModalRef != null) {
      this.documentModalRef.close();
      this.documentModalRef = null;
    }
    this.documentModalRef = this.modalService.open(this.documentModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.documentModalRef != null) {
      this.documentModalRef.close();
      this.documentModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.person);
  }

  private async save() {

    this.person.fromDocuments = true;
    this.person.visaArrivalDate = toDateString(this.visaArrivalDate);
    this.person.visaDepartureDate = toDateString(this.visaDepartureDate);
    this.person.passportExpiryDate = toDateString(this.passportExpiryDate);


    const person: PersonInfo = deepClone(this.person);

    if(person.visaType === VisaType.NotSelected){
      this.messageService.setToastMessage(false, 'Please select visa type.');
      return;
    }


    const request: UpdateRequest<PersonInfo> = {
      isNew: this.isNew,
      subject: person
    };

    const result = await this.apiService.updatePerson(request);

    if (result.isSuccess) {
      this.saved.emit(person);
      this.messageService.setToastMessage(true, `${person.name} documents updated`, 10);
      this.onClose();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage, 10);
    }


  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }


  //#endregion


}
