import * as moment from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Country } from 'src/app/models/api/entities/country';
import { CurrencyTypeInfo } from 'src/app/models/api/entities/currency-type-info';
import { DisciplineType } from 'src/app/models/api/enums/discipline-type';
import { DisciplineTypeInfo } from 'src/app/models/api/entities/discipline-type-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { EventStage } from 'src/app/models/api/enums/event-stage';
import { List } from 'lodash';
import { Router } from '@angular/router';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { toDateTimeString } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-event-new',
  templateUrl: './event-new.component.html',
  styleUrls: ['./event-new.component.scss']
})
export class EventNewComponent implements OnInit {

  public DisciplineType = DisciplineType;

  public headerItems: PageHeaderItem[];

  public allCountries: Country[];
  // public allCities: List<string>;
  public allCurrencies: CurrencyTypeInfo[];
  public allDisciplines: DisciplineTypeInfo[];

  today?: Date;
  minDate?: Date;
  maxDate?: Date;

  public name = '';
  public country: Country = null;
  public location = '';
  public discipline: DisciplineTypeInfo = null;
  public currency: CurrencyTypeInfo = null;
  public startDate: Date;
  public endDate: Date;
  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private router: Router,
  ) {
    this.messageService = new MessageService();
  }

  async ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }
    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    if (!canManageSystem)
    {
      this.close();
      return;
    }

    this.headerItems = [
      { title: 'Save', iconClass: 'fa-check', callback: () => this.onSave() },
      { title: 'Close', iconClass: 'fa-times', callback: () => this.onClose() }
    ]

    this.allCountries = this.entityMapService.allCountries;
    this.allDisciplines = this.entityMapService.getAllDisciplineTypes();
    this.allCurrencies = this.entityMapService.getAllCurrencies();

    this.country = this.allCountries[0];
    // this.allCities = this.entityMapService.getCitiesByCountryCode(this.country.iocCode);
    this.currency = this.allCurrencies[0];
    this.discipline = this.allDisciplines[0];

    this.today = moment().set('hour', 0).set('minute', 0).toDate();
    this.minDate = moment().add(1, 'days').set('hour', 0).set('minute', 0).toDate();
    this.maxDate = moment().add(10, 'years').set('hour', 0).set('minute', 0).toDate();
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canViewEvents = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canViewEvents) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private async save(): Promise<string> {
    return new Promise<string>(async resolve => {

      const evt: EventInfo = {
        id: 0,
        code: null,
        eventStage: EventStage.PreRegistrationStart,
        name: this.name,
        countryCode: this.country.code,
        country: null,
        currencyType: this.currency.currencyType,
        timeZone: null,
        location: this.location,
        bankInfo: null,
        disciplineType: this.discipline.disciplineType,
        groupTypes: [],
        categoryTypes: [],
        apparatusTypes: [],
        unitTypeCodes: [],
        unitTypes: [],

        startDate: toDateTimeString(this.startDate),
        endDate: toDateTimeString(this.endDate),

        deadlines: null,
        features: null,

        delegationsCount: 0,
        isDisabled: false,

        updatedOn: null,
        hexColor:null,
        diplomaId:null
      }

      var result = await this.apiService.createEvent(evt);

      if (result.isSuccess)
      {
        resolve(null);
      }
      else
      {
        resolve(result.errorMessage);
      }


    });
  }

  //#endregion

  //#region Actions

  async onSave() {
    const error = await this.save();
    if (error != null) {
      this.messageService.setToastMessage(false, error, 7);
    } else {
      this.messageService.setToastMessage(true, 'Event created', 7);
      this.close();
    }
  }

  onClose() {
    this.close();
  }

  //#endregion

  // onCountryChange() {
  //   this.allCities = null;
  //   this.location = null;
  //   this.allCities = this.entityMapService.getCitiesByCountryCode(this.country.code);
  // }

  //#endregion

}
