import { Pipe, PipeTransform } from '@angular/core';

import { EntityMapService } from '../services/entity-map.service';
import { Gender } from '../models/api/enums/gender';

@Pipe({
  name: 'genderType'
})
export class GenderTypePipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  async transform(gender: Gender): Promise<string> {
    if (gender === null) {
      return null;
    }
    await this.entityMapService.ready();

    return this.entityMapService.getGenderByType(gender).name;
  }

}
