import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dateZeroPrefix'
})
export class DateZeroPrefixPipe implements PipeTransform {

	transform(input: number, places: number): string {
		let output = '';
		if (input !== null) {
			output += input;
		}
		while (output.length < places) {
			output = '0' + output;
		}
		return output;
	}

}
