import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MimeTypes } from 'src/app/utils/mime-types';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { PersonsListRequest } from 'src/app/models/api/protocol/persons-list-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserService } from 'src/app/services/user.service';
import { isConvertableToNumber, stringIsNullOrEmpty, toDateTimeString } from 'src/app/shared/utils/utils';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-persons-list',
  templateUrl: './persons-list.component.html',
  styleUrls: ['./persons-list.component.scss']
})
export class PersonsListComponent implements OnInit {

  public toDateString = toDateTimeString;

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public persons: ListResponse<PersonInfo> = new LocalListResponse<PersonInfo>();
  public personsListRequest: PersonsListRequest;


  public currentPersonId: number;
  public viewingPerson = false;

  public currentGroupId: number;
  public viewingGroup = false;

  public isObserver: boolean = false;

  public canManageDelegation = false;

  public messageService: MessageService;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })
    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      if ( curUser.role === 3 ){
        this.isObserver = true;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;

      const breadcrumbs: Breadcrumb[] = [];

      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }

      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      if(this.isObserver){
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      // eslint-disable-next-line max-len
      if(evt.features.isClubExist === false)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` });
        breadcrumbs.push({ key: 'Persons', value: '', link:''})
      }

      if(evt.features.isClubExist === true)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}` });
        breadcrumbs.push({ key: 'Persons', value: '', link:''})
      }


      this.breadcrumbsService.breadcrumbs = breadcrumbs;

      this.updateHeaderItems();

      if (params.has('PersonId')) {

        const personIdStr = params.get('PersonId');

        if (personIdStr == null || !isConvertableToNumber(personIdStr)) {
          this.close();
          return;
        }

        const personId = Number(personIdStr);

        this.currentPersonId = personId;

        if (personId !== 0) {
          this.viewPerson(personId);
        }else{
          this.addPerson();
        }

      };

      if (params.has('groupId')) {

        const groupIdStr = params.get('groupId');

        if (groupIdStr == null || !isConvertableToNumber(groupIdStr)) {
          this.close();
          return;
        }

        const groupId = Number(groupIdStr);

        this.currentGroupId = groupId;

        if (groupId !== 0) {
          this.viewGroup(groupId);
        }else{
          this.addGroup();
        }

      };

      this.reloadList();

    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }


  private updateHeaderItems() {
    const headerItems: PageHeaderItem[] = [];
    if (this.canManageDelegation) {
      // headerItems.push({ title: 'Download photos', iconClass: 'fa-file-zip-o', callback: () => this.onDownloadPhotos() });
      // headerItems.push({ title: 'Download persons', iconClass: 'fa-file-excel-o', callback: () => this.onDownloadPersons() });
      // if(this.evt.features.isEntryFeeEnabled){
      //   headerItems.push({ title: 'Add group', iconClass: 'fa-plus-circle', callback: () => this.onAddGroup() });
      //   headerItems.push({ title: 'Manage groups', iconClass: 'fa-object-group', callback: () => this.onManageGroup() });
      // }
      headerItems.push({ title: 'Add person', iconClass: 'fa-user-plus', callback: () => this.onAddPerson() });
    }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    // eslint-disable-next-line max-len
    this.persons = await this.apiService.getPersonsList(true, this.itemsPerPage, this.persons.page, null, this.delegation.eventId, this.delegation.countryCode, this.clubShortName);
  }

  private viewPerson(personId: number) {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/persons/${personId}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
     path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.clubShortName}/persons/${personId}`;

    this.location.go(path);
    this.currentPersonId = personId;
    this.viewingPerson = true;
  }

  private viewGroup(groupId: number) {
    this.location.go(`events/${this.evt.id}/delegations/${this.delegation.countryCode}/persons/group/${groupId}`);
    this.currentGroupId = groupId;
    this.viewingGroup = true;
  }

  private closePerson() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/persons`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/persons`;

    this.location.go(path);
    this.viewingPerson = false;
    this.currentPersonId = 0;
  }

  private closePersonGroup() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/persons`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/persons`;

    this.location.go(path);
    this.viewingGroup = false;
    this.currentGroupId = 0;
  }

  private addPerson() {
    this.viewPerson(0);
  }

  private addGroup() {
    this.viewGroup(0);
  }

  private manageGroup() {
    this.router.navigate([`events/${this.evt.id}/delegations/${this.delegation.countryCode}/persons/manage/group`]);
  }

  private downloadPersons() {
    let path = `/Export/PersonInfo?eventId=${this.evt.id}&isDetailed=false&country=${this.delegation.countryCode}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `/Export/PersonInfo?eventId=${this.evt.id}&isDetailed=false&country=${this.delegation.countryCode}&club=${this.delegation.clubShortName}`;

    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private downloadPhotos() {
    let path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=true&detailType=photos&country=${this.delegation.countryCode}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `/Export/PersonInfo?eventId=${this.evt.id}&isZip=true&isDetailed=true&detailType=photos&country=${this.delegation.countryCode}&club=${this.delegation.clubShortName}`;

    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private deletePerson(person: PersonInfo) {
    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        this.apiService.deletePerson(person.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            this.messageService.setToastMessage(true, `Person '${person.name} has successfully deleted`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  //#endregion

  //#region Actions

  onPageChanged(page: number) {
    this.persons.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onDownloadPersons() {
    this.downloadPersons();
  }

  onDownloadPhotos() {
    this.downloadPhotos();
  }

  onAddGroup() {
    this.addGroup();
  }

  onManageGroup() {
    this.manageGroup();
  }

  onAddPerson() {
    this.addPerson();
  }

  onViewPerson(person: PersonInfo) {
    this.viewPerson(person.id);
  }

  onDeletePerson(person: PersonInfo) {
    this.deletePerson(person);
  }

  onPersonUpdated() {
    this.reloadList();
  }

  onPersonClosed() {
    this.closePerson();
  }

  onPersonGroupUpdated() {
    this.reloadList();
  }

  onPersonGroupClosed() {
    this.closePersonGroup();
  }

  onDownloadDiploma(person: PersonInfo) {
    if (person == null) {
      return;
    }
    this.downloadDiploma(person.countryCode, person.id);
  }

  private downloadDiploma(countryCode: string, personId: number) {
    let path = `/Export/DelegationPersonDiploma?eventId=${this.evt.id}&country=${countryCode}&personId=${personId}`;

    if(this.evt.features.isClubExist === true && this.clubShortName !== null)
      path = `/Export/DelegationPersonDiploma?eventId=${this.evt.id}&country=${countryCode}&country=${countryCode}&club=${this.clubShortName}&personId=${personId}`;

    const mimeType = MimeTypes.pdf;
    this.downloadFile(path, mimeType);
  }

  private downloadFile(path: string, mimeType: string) {
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  //#endregion


}
