import { MealSubtotalPipe } from './../../../pipes/meal-subtotal.pipe';
import { size, groupBy } from 'lodash';
import { InstalmentsInfo } from './../../../models/api/entities/payment-tracker/instalments-info';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { FlightReservationInfo } from 'src/app/models/api/entities/flight-reservation-info';
import { FlightType } from 'src/app/models/api/enums/flight-type';
import { HotelRoomReservationInfo } from 'src/app/models/api/entities/hotel-room-reservation-info';
import { HotelSubtotalPipe } from 'src/app/pipes/hotel-subtotal.pipe';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { MealReservationInfo } from 'src/app/models/api/entities/meal-reservation-info';
import { MimeTypes } from 'src/app/utils/mime-types';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { UserInfo } from 'src/app/models/api/entities/user-info';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { isConvertableToNumber } from 'src/app/utils/utils';
import { FunctionType } from 'src/app/models/api/enums/function-type';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { deepClone, stringIsNullOrEmpty, toDate } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-delegation-overview',
  templateUrl: './delegation-overview.component.html',
  styleUrls: ['./delegation-overview.component.scss']
})
export class DelegationOverviewComponent implements OnInit {

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public hotels: ListResponse<HotelRoomReservationInfo> = new LocalListResponse<HotelRoomReservationInfo>();
  public meals: ListResponse<MealReservationInfo> = new LocalListResponse<MealReservationInfo>();
  public persons: ListResponse<PersonInfo> = new LocalListResponse<PersonInfo>();
  public rooms: ListResponse<HotelRoomReservationInfo> = new LocalListResponse<HotelRoomReservationInfo>();
  public flightArrivals: ListResponse<FlightReservationInfo> = new LocalListResponse<FlightReservationInfo>();
  public flightDepartures: ListResponse<FlightReservationInfo> = new LocalListResponse<FlightReservationInfo>();

  public installmentsInfo: InstalmentsInfo[];

  public nfUser: UserInfo;
  public canManageDelegation = false;
  public isNfUser = false;
  public isLateBookingStarted: boolean = false;

  public totalInstallments = 0;

  public covidTotal = 0;

  public messageService: MessageService;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
  ) {
    this.messageService = new MessageService();
  }

  async ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      if (curUser.role === 4) {
        this.isNfUser = true;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const nfUser = await this.apiService.getUser(delegation.nfUsername);

      if (nfUser == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;
      this.nfUser = nfUser;


      if (evt.features.isHotelRoomLatePerNightPriceEnabled) {

        const lateStartDate = toDate(evt.deadlines.accommodationLateBookingStartDate);

        const today = new Date();

        if (today > lateStartDate) {
          this.isLateBookingStarted = true;
        }

      }

      const breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }
      if (canViewDelegation) {
        let country = this.delegation.countryCode;

        if(this.delegation.clubShortName){
          country = country + '/' + this.delegation.clubShortName
        }

        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });

        if (this.evt.features.isClubExist === true) {
          breadcrumbs.push({
            key: "",
            value: `[${delegation.country.code}] ${delegation.country.name}`,
            link: `/events/${evt.id}/delegations/${country}`,
          });
          breadcrumbs.push({
            key: "Overview",
            link: ``,
          });
        }

        if (this.evt.features.isClubExist === false) {
          breadcrumbs.push({
            key: "",
            value: `[${delegation.country.code}] ${delegation.country.name}`,
            link: `/events/${evt.id}/delegations/${country}/${delegation.clubShortName}`,
          });
          breadcrumbs.push({
            key: "Overview",
            link: ``,
          });
        }

      }
      this.breadcrumbsService.breadcrumbs = breadcrumbs;
      this.breadcrumbsService.title = evt.name;

      if (canManageThisDelegation) {
        this.headerItems = [{ title: 'Close', iconClass: 'fa-close', callback: () => this.onClose() }];
      }

      this.reloadHotelsList();
      this.reloadMealsList();
      this.reloadRoomsList();
      this.reloadPersonList();
      this.reloadFlightArrivalsList();
      this.reloadFlightDeparturesList();
      this.reloadInstallments();

      await this.setCovidTotal();
    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    if (this.evt != null) {
      this.router.navigate([`/events/${this.evt.id}`]);
    }
  }

  private downloadFile(path: string, mimeType: string) {
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private downloadOverview() {
    let country = this.delegation.countryCode;

    if(this.delegation.clubShortName){
      country = country + '&club=' + this.delegation.clubShortName
    }

    const path = `/Export/DelegationInvoice?eventId=${this.delegation.eventId}&country=${country}`;
    const mimeType = MimeTypes.pdf;
    this.downloadFile(path, mimeType);
  }

  private downloadSinglePhoto(type: string, item: PersonInfo) {
    const path = `/Persons/DownloadPhoto?eventId=${this.delegation.eventId}&person=${item.id}&type=${type}`;
    const mimeType = MimeTypes.jpeg;
    this.downloadFile(path, mimeType);
  }

  private async reloadHotelsList() {
    // eslint-disable-next-line max-len
    this.hotels = await this.apiService.getHotelRoomReservations(this.evt.features.isPenaltyFunctionEnabled, -1, this.hotels.page, null, this.evt.id, this.delegation.countryCode, this.delegation.clubShortName);
  }

  private async reloadMealsList() {
    // eslint-disable-next-line max-len
    this.meals = await this.apiService.getMealReservations(this.evt.features.isPenaltyFunctionEnabled, -1, this.meals.page, null, this.evt.id, this.delegation.countryCode, this.delegation.clubShortName);
  }

  private async reloadPersonList() {
    // eslint-disable-next-line max-len
    this.persons = await this.apiService.getPersonsList(false, -1, this.persons.page, null, this.evt.id, this.delegation.countryCode, this.delegation.clubShortName);
  }

  private async reloadRoomsList() {
    // eslint-disable-next-line max-len
    this.rooms = await this.apiService.getHotelRoomReservations(false, -1, this.rooms.page, null, this.evt.id, this.delegation.countryCode, this.delegation.clubShortName);
  }

  private async reloadFlightArrivalsList() {
    // eslint-disable-next-line max-len
    this.flightArrivals = await this.apiService.getFlightReservationsList(false, -1, this.flightArrivals.page, null, this.evt.id, this.delegation.countryCode, 'A', this.delegation.clubShortName);
  }

  private async reloadFlightDeparturesList() {
    let country = this.delegation.countryCode;

    if(this.delegation.clubShortName){
      country = country + '/' + this.delegation.clubShortName
    }

    // eslint-disable-next-line max-len
    this.flightDepartures = await this.apiService.getFlightReservationsList(false, -1, this.flightArrivals.page, null, this.evt.id, this.delegation.countryCode, 'D', this.delegation.clubShortName);
  }

  private async reloadInstallments() {
    // eslint-disable-next-line max-len
    this.installmentsInfo = await this.apiService.getPayments(this.evt.id, this.delegation.countryCode,this.delegation.clubShortName);
    const totalInstallments: InstalmentsInfo[] = deepClone(this.installmentsInfo);

    if (totalInstallments.length === 0)
      this.totalInstallments = 0;

    if (totalInstallments.length !== 0)
      this.totalInstallments = totalInstallments.map(t => t.amount).reduce((a, b) => { return a + b; })
  }


  //#region Actions

  onClose() {
    this.close();
  }

  onDownloadOverview() {
    this.downloadOverview();
  }

  onDownloadSinglePhoto(type: string, item: PersonInfo) {
    this.downloadSinglePhoto(type, item);
  }

  gymnastEntryFee(): number {
    const gymnastLength = this.delegation.entryFeePersonsCount;
    if (gymnastLength !== 0) {
      return gymnastLength * this.evt.features.entryFeePerGymnasts;
    }
    return 0;
  }

  gymnastGroupEntryFee(): number {
    const forGymnast: PersonInfo[] = deepClone(this.persons.items);

    const groups = forGymnast.filter(t => t.function === FunctionType.Gymnast && t.groupId !== 0);

    let groupped = groupBy(groups, t => t.groupId);

    const groupLength = size(groupped);
    if (groupLength !== 0) {
      return groupLength * this.evt.features.entryFeePerGroup;
    }
    return 0;
  }

  async setCovidTotal(): Promise<void> {

    const pcrCount = this.delegation.covid19PcrPersonsArrivalCount + this.delegation.covid19PcrPersonsDepartureCount;
    const quickCount = this.delegation.covid19QuickPersonsArrivalCount + this.delegation.covid19QuickPersonsDepartureCount;

    if (this.evt.features.quickTestFeePerPerson !== 0) {
      this.covidTotal += this.evt.features.quickTestFeePerPerson * quickCount;
    }

    if (this.evt.features.pcrFeePerPerson !== 0) {
      this.covidTotal += this.evt.features.pcrFeePerPerson * pcrCount;
    }

  }

  hotelTotal(): number {
    const subTotalPipe = new HotelSubtotalPipe();
    let total = 0;
    let subTotal = 0;
    if (this.evt.features.isAccomodationEnabled) {
      this.hotels.items.forEach(hotel => {
        total = subTotalPipe.transform(hotel, this.evt?.features, this.isLateBookingStarted);
        subTotal += total;
      });
    }
    return subTotal;
  }

  mealTotal(): number {
    const mealSubtotalPipe = new MealSubtotalPipe();
    let total = 0;
    let subTotal = 0;
    if (this.evt.features.isMealsEnabled) {
      this.meals.items.forEach(meal => {
        total = mealSubtotalPipe.transform(meal, this.evt);
        subTotal += total;
      });
    }
    return subTotal;
  }

  //#endregion


}
