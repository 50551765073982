// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs-container {
  margin: 0;
  margin-bottom: 30px;
  margin-top: 56px;
  border-bottom: 1px solid;
}

.breadcrumb {
  display: inline-block;
  padding: 0;
  border-radius: 20pt;
  margin: 5pt;
  background: linear-gradient(90deg, rgba(23, 162, 184, 0.5), rgba(23, 162, 184, 0));
  cursor: pointer;
}

.breadcrumb > .icon-container {
  display: inline-block;
  border-radius: 20pt 0 0 20pt;
  padding: 2pt 5pt;
  background: #17a2b8;
}

.breadcrumb > .text-container {
  display: inline-block;
  margin: 0 0 0 10pt;
  box-sizing: border-box;
  min-width: 70px;
}

.breadcrumb .breadcrumb-key {
  font-size: 14px;
  font-family: "Source Sans Pro";
}

.breadcrumb .breadcrumb-value {
  font-size: 14px;
  font-family: "Source Sans Pro";
  font-weight: 700;
  color: #1e4e6e;
}`, "",{"version":3,"sources":["webpack://./src/app/components/breadcrumbs/breadcrumbs.component.scss"],"names":[],"mappings":"AAAA;EAEI,SAAA;EACA,mBAAA;EACA,gBAAA;EACA,wBAAA;AAAJ;;AAEA;EAEI,qBAAA;EACA,UAAA;EACA,mBAAA;EACA,WAAA;EACA,kFAAA;EACA,eAAA;AAAJ;;AAGA;EAEI,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,mBAAA;AADJ;;AAIA;EAEI,qBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;AAFJ;;AAKA;EAEI,eAAA;EACA,8BAAA;AAHJ;;AAMA;EAEI,eAAA;EACA,8BAAA;EACA,gBAAA;EACA,cAAA;AAJJ","sourcesContent":[".breadcrumbs-container\r\n{\r\n    margin: 0;\r\n    margin-bottom: 30px;\r\n    margin-top: 56px;\r\n    border-bottom: 1px solid;\r\n}\r\n.breadcrumb\r\n{\r\n    display: inline-block;\r\n    padding: 0;\r\n    border-radius: 20pt;\r\n    margin: 5pt;\r\n    background: linear-gradient(90deg, rgb(23, 162, 184, 0.5), rgb(23, 162, 184, 0));\r\n    cursor: pointer;\r\n}\r\n\r\n.breadcrumb > .icon-container\r\n{\r\n    display: inline-block;\r\n    border-radius: 20pt 0 0 20pt;\r\n    padding: 2pt 5pt;\r\n    background: #17a2b8;\r\n}\r\n\r\n.breadcrumb > .text-container\r\n{\r\n    display: inline-block;\r\n    margin: 0 0 0 10pt;\r\n    box-sizing: border-box;\r\n    min-width: 70px;\r\n}\r\n\r\n.breadcrumb .breadcrumb-key\r\n{\r\n    font-size: 14px;\r\n    font-family: 'Source Sans Pro';\r\n}\r\n\r\n.breadcrumb .breadcrumb-value\r\n{\r\n    font-size: 14px;\r\n    font-family: 'Source Sans Pro';\r\n    font-weight: 700;\r\n    color: #1e4e6e;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
