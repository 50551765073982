// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.flag {
  margin: 0 0 2px 5px;
  height: 16px;
  width: 21px;
  outline: 1px solid #919191;
  background: #595959;
}

a:hover {
  cursor: pointer;
}

.dropdown button, .dropdown a {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.dropdown .dropdown-area {
  border-radius: 0 0 12px 12px;
  border: 1px solid #ddddde;
  width: 160px;
  padding: 8px;
}
.dropdown .dropdown-area button {
  border: none;
  justify-content: start;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,WAAA;EACA,0BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAGE;EACE,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAEE;EACE,4BAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;AAAJ;AACI;EACE,YAAA;EACA,sBAAA;AACN","sourcesContent":["img.flag {\r\n    margin: 0 0 2px 5px;\r\n    height: 16px;\r\n    width: 21px;\r\n    outline: 1px solid #919191;\r\n    background: #595959\r\n}\r\n\r\na:hover {\r\n    cursor: pointer;\r\n}\r\n\r\n.dropdown {\r\n  button,a{\r\n    font-weight: 500;\r\n    font-size: 1.4rem;\r\n    line-height: 2rem;\r\n    display: flex;\r\n    gap: 8px;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  .dropdown-area {\r\n    border-radius: 0 0 12px 12px;\r\n    border:1px solid  #ddddde;\r\n    width: 160px;\r\n    padding: 8px;\r\n    button {\r\n      border: none;\r\n      justify-content: start;\r\n\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
