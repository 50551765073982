import { isNull, isUndefined } from 'lodash';
import { HotelInfo } from './../../../../models/api/entities/hotel-info';
import { EventInfo } from './../../../../models/api/entities/event-info';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpecialExportInfo } from 'src/app/models/api/entities/special-export/special-export-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { ActivatedRoute, Router } from '@angular/router';
import { AccommodationDetailsInfo } from 'src/app/models/api/entities/special-export/accommodation/accommodation-details-info';

@Component({
  selector: 'app-accomodations-list',
  templateUrl: './accomodations-list.component.html',
  styleUrls: ['./accomodations-list.component.scss']
})
export class AccomodationsListComponent implements OnInit {

  @Input() evt: EventInfo;
  @Input() hotels: ListResponse<HotelInfo> = new LocalListResponse<HotelInfo>();
  @Input() detailInfo: SpecialExportInfo;
  public typeDetailId: number;

  public isAccommodationDetail: boolean = false;

  public selectedAccommodation: AccommodationDetailsInfo;

  @Output() updates = new EventEmitter<string>();

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.load();
  }

  private async load() {
    this.route.paramMap.subscribe(async params => {

      const typeDetailIdStr = params.get('typeDetail');

      const typeDetailId = Number(typeDetailIdStr);

      if (typeDetailId !== 0) {
        this.isAccommodationDetail = true;
      }
      this.typeDetailId = typeDetailId;

      if (!isNull(this.detailInfo.accomondationDetails)) {
        const accommondationDetail = this.detailInfo.accomondationDetails.filter(t => t.hotelId === typeDetailId);
        this.selectedAccommodation = accommondationDetail[0];
      }

    });
  }



  onDetail(TypeDetailId: number) {
    this.router.navigate([`/events/${this.evt.id}/special-export/${this.detailInfo.id}/detail/${TypeDetailId}`]);
  }


}
