import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CallingCode } from '../../models/calling-code';
import * as countries from 'i18n-iso-countries';
import { getCountryCallingCode, CountryCode, isSupportedCountry, parsePhoneNumberFromString } from 'libphonenumber-js';
import { stringIsNullOrEmpty } from '../../utils/utils';

@Component({
	selector: 'sac-phone-number-input',
	templateUrl: './phone-number-input.component.html',
	styleUrls: ['./phone-number-input.component.css']
})
export class PhoneNumberInputComponent implements OnInit {

	//#region Fields

	phoneNumber_ = '';

	@Input() defaultCountryCode: string;
	@Input() public set value(phoneNumber: string) {
		this.phoneNumber_ = phoneNumber;
	}
	@Input() isReadOnly: boolean;
	@Output() private numberChange = new EventEmitter<string>();
	@Output() private change = new EventEmitter<string>();

	public code: CallingCode;
	public codeList: CallingCode[];
	public number: string;
	countries = countries;

	// #endregion

	constructor() { }

	ngOnInit() {
		this.loadCountries();
		if (!stringIsNullOrEmpty(this.phoneNumber_)) {
			this.parsePhoneNumber(this.phoneNumber_);
		}
	}

	parsePhoneNumber(number: string) {
		const parsedNumber = parsePhoneNumberFromString(number);

		if (parsedNumber == null) {
			return;
		}

		const country = this.codeList.find(t => t.countryCode === parsedNumber.country);
		if (country == null) {
			this.number = number;
			return;
		}
		this.code = country;
		this.number = parsedNumber.nationalNumber.toString();
	}

	loadCountries() {
		const countryList = this.countries.getAlpha2Codes();
		this.codeList = Object.keys(countryList).map((t) => {
			const callingCode = isSupportedCountry(t) ? getCountryCallingCode(<CountryCode>t) : null;
			return {
				countryCode: t,
				callingCode: callingCode?.toString()
			};
		}).filter(t => t.callingCode != null);
		if (stringIsNullOrEmpty(this.phoneNumber_)) {
			this.code = this.codeList.find(t => t.countryCode === countries.alpha3ToAlpha2(this.defaultCountryCode));
		}
	}

	onChange() {
		let newValue: String = '';

		if (this.code == null) {
			newValue = '';
		} else if (stringIsNullOrEmpty(this.number)) {
			newValue = '';
		} else {
			newValue = '+' + this.code.callingCode + this.number;
		}

		this.phoneNumber_ = newValue.toString();
		this.numberChange.emit(this.phoneNumber_);
		this.change.emit(this.phoneNumber_);
	}
}
