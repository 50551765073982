import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserService } from 'src/app/services/user.service';
import { UserRole } from 'src/app/models/api/enums/user-role';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo
  public canManageEvent = false;
  public canManageSystem = false;

  public messageService: MessageService;
  isObserver: boolean = false;
  isAccountant: boolean = false;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private alertService: AlertService
  ) {
    this.messageService = new MessageService();
  }

  async ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();
    await this.userService.isAgreed();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);
    if (!canViewEvent) {
      if(canViewDelegation){
        return;
      }
      else
      {
        this.close();
        return;
      }
    }

    this.route.paramMap.subscribe(async params => {
      const evtIdStr = params.get('eventId');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }
      const isObserver = curUser.role === UserRole.Observer;
      this.isObserver = isObserver;

      const isAccountant = curUser.role === UserRole.Accountant;
      this.isAccountant = isAccountant;

      this.canManageEvent = canManageEvent;
      this.canManageSystem = canManageSystem;
      this.evt = evt;

      this.breadcrumbsService.breadcrumbs = [];

      if (canManageSystem) {
        this.breadcrumbsService.breadcrumbs = [
          { key: 'Events', link: `/events` },
          { key: `${this.evt.name}`, link: ''},
          {key: 'Delegations', link:''}
        ];

        this.headerItems = [
          ...this.headerItems,
          { title: 'Delete', iconClass: 'fa-trash', callback: () => this.onDelete() }
        ];

        if (this.evt.isDisabled !== true) {

          this.headerItems = [
            ...this.headerItems,
            { title: 'Disable', iconClass: 'fa-lock', callback: () => this.onDisable() }
          ];

        }

        if (this.evt.isDisabled) {

          this.headerItems = [
            ...this.headerItems,
            { title: 'Enable', iconClass: 'fa-unlock', callback: () => this.onClose() }
          ];

        }



      }

      this.headerItems = [
        ...this.headerItems,
        { title: 'Close', iconClass: 'fa-close', callback: () => this.onClose() }
      ];

    });
  }


  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);
    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  //#endregion

  //#region Actions

  onClose() {
    this.close();
  }

  onSettings() {
    this.router.navigate([`/events/${this.evt.id}/settings`]);
  }

  onUsers() {
    this.router.navigate([`/events/${this.evt.id}/users`]);
  }

  onHotels() {
    this.router.navigate([`/events/${this.evt.id}/hotels`]);
  }

  onMealServices() {
    this.router.navigate([`/events/${this.evt.id}/meal-venues`]);
  }

  onDelegations() {
    this.router.navigate([`/events/${this.evt.id}/delegations`]);
  }

  onOverview() {
    this.router.navigate([`/events/${this.evt.id}/overview`]);
  }

  onEnable() {

    if (!this.canManageSystem)
      this.messageService.setToastMessage(true, `You can't enable event!`);

    const message = 'Are you sure?';

    this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        this.apiService.enableEvent(this.evt.id).then(result => {
          if (result.isSuccess) {
            this.router.navigate([`/events`]);
            this.messageService.setToastMessage(true, `Event successfully enabled!`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });

      }

    });

  }

  onDisable() {

    if (!this.canManageSystem)
      this.messageService.setToastMessage(true, `You can't disable event!`);

    const message = 'Are you sure?';

    this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        this.apiService.disableEvent(this.evt.id).then(result => {
          if (result.isSuccess) {
            this.router.navigate([`/events`]);
            this.messageService.setToastMessage(true, `Event successfully disabled!`);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });

      }

    });



  }

  onDelete() {

    if (!this.canManageSystem){
      this.messageService.setToastMessage(false, `You can't delete event!`);
      return;
    }

    const message = 'Are you sure?';

    this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {

        this.apiService.deleteEvent(this.evt.id).then(result => {
          if (result.isSuccess) {
            this.messageService.setToastMessage(true, `Event successfully deleted!`);
            this.router.navigate([`/events`]);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });

      }

    });

  }

  onExport() {
    this.router.navigate([`/events/${this.evt.id}/export`]);
  }

  onSpecialExport() {
    this.router.navigate([`/events/${this.evt.id}/special-export`]);
  }

  onPaymentTracker() {
    this.router.navigate([`/events/${this.evt.id}/payment-tracker`]);
  }

  onPenalties() {
    this.router.navigate([`/events/${this.evt.id}/penalties`]);
  }

  onMissedInformations() {
    this.router.navigate([`/events/${this.evt.id}/missed-informations`]);
  }

  onInvoice() {
    this.router.navigate([`/events/${this.evt.id}/invoices`]);
  }

  onMail() {
    this.router.navigate([`/events/${this.evt.id}/mailbox`]);
  }

  //#endregion

}
