import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { ListRequest } from 'src/app/models/api/protocol/list-request';

@Component({
  selector: 'event-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent {

  @Output() filterOptions:EventEmitter<ListRequest>=new EventEmitter<ListRequest>();
  @Input()Events!:EventInfo

}
