import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Airport } from 'src/app/models/api/entities/airport';
import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { Country } from 'src/app/models/api/entities/country';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { FlightReservationInfo } from 'src/app/models/api/entities/flight-reservation-info';
import { FlightType } from 'src/app/models/api/enums/flight-type';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-flight-reservations',
  templateUrl: './flight-reservations.component.html',
  styleUrls: ['./flight-reservations.component.scss']
})
export class FlightReservationsComponent implements OnInit {

  public isNavbarCollapsed = true;

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public flight: FlightReservationInfo;
  public flightId: number;
  public country: Country;
  public airport: Airport;

  public canManageDelegation = false;

  public isObserver: boolean = false;

  public headerItems: PageHeaderItem[];

  public messageService: MessageService;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async (params) => {
      this.route.data.subscribe(async (_data) => {
        const countryCode = params.get('countryCode');
        const flightIdStr = params.get('flightId');
        if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
          this.close();
          return;
        }

        const evtId = Number(evtIdStr);
        const evt = await this.apiService.getEvent(evtId);
        if (evt == null) {
          this.close();
          return;
        }

        if(evt.features.isClubExist){
          this.clubShortName = params.get('clubShortName');
        }

        const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);
        if (delegation == null) {
          this.close();
          return;
        }

        const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
        if (!canViewThisEvent) {
          this.close();
          return;
        }

        if ( curUser.role === 3 ){
          this.isObserver = true;
        }

        const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
        this.canManageDelegation = canManageThisDelegation;

        this.evt = evt;
        this.delegation = delegation;

        this.flightId = 0;

        if (isConvertableToNumber(flightIdStr)) {
          this.flightId = Number(flightIdStr);
        }

        let getFlight: FlightReservationInfo;

        getFlight = await this.apiService.getFlightReservation(this.flightId);
        if ( getFlight === null ) {
          this.close();
          return;
        }


        this.country = this.entityMapService.getCountryByCode(getFlight.flightCountryCode);
        this.airport = this.entityMapService.getAirportByIataCode(getFlight.airport);
        this.flight = getFlight;

        let breadcrumbs: Breadcrumb[] = [];

        if (canManageSystem) {
          breadcrumbs = [...breadcrumbs, { key: 'Events', link: `/events` }];

          breadcrumbs = [...breadcrumbs, { key: '', value: `${evt.name}`, link: `/events/${evt.id}` }];

          breadcrumbs = [...breadcrumbs, { key: 'Delegations', link: `/events/${evt.id}/delegations` }];
        }

        if (this.isObserver) {
          breadcrumbs = [...breadcrumbs, { key: '', value: `${evt.name}`, link: `/events/${evt.id}` }];

          breadcrumbs = [...breadcrumbs, { key: 'Delegations', link: `/events/${evt.id}/delegations` }];
        }

        // eslint-disable-next-line max-len
        if(this.evt.features.isClubExist === false) {
          breadcrumbs = [...breadcrumbs, { key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` }];
          // eslint-disable-next-line max-len
          breadcrumbs = [...breadcrumbs, { key: 'Travel details', link: `/events/${evt.id}/delegations/${delegation.country.code}/flight-reservations` }];
          breadcrumbs = [...breadcrumbs, { key: `${this.flight.flightNumber}`, link: `` }];

        }

        if(this.evt.features.isClubExist === true) {
          breadcrumbs = [...breadcrumbs, { key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}` }];
          // eslint-disable-next-line max-len
          breadcrumbs = [...breadcrumbs, { key: 'Travel details', link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}/flight-reservations` }];
          breadcrumbs = [...breadcrumbs, { key: `${this.flight.flightNumber}`, link: `` }];
        }

        this.breadcrumbsService.breadcrumbs = breadcrumbs;
        this.breadcrumbsService.title = this.flight.flightNumber;
        this.headerItems = [
          { title: 'Close', iconClass: 'fa-times', callback: () => this.onClose() },
        ];

      });

    });
  }

  private close() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/flight-reservations`;

    if(this.evt.features.isClubExist)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/flight-reservations`;


    this.router.navigate([path]);
  }

  //#endregion

  //#region Actions

  onClose() {
    this.close();
  }

  async onPassengerUpdated(_event) {
        let getFlight: FlightReservationInfo;

        getFlight = await this.apiService.getFlightReservation(this.flightId);

        if ( getFlight === null ) {
          this.close();
          return;
        }

        this.country = this.entityMapService.getCountryByCode(getFlight.flightCountryCode);
        this.airport = this.entityMapService.getAirportByIataCode(getFlight.airport);
        this.flight = getFlight;
  }

  //#endregion

  //#region Data

  getFlightTypeName(flightType: FlightType): string{
    return this.entityMapService.getFlightByType(flightType).name;
  }

  //#endregion

}
