// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  white-space: nowrap;
}

.table-responsive {
  overflow-x: unset !important;
}

.td-gray {
  color: #C5C5C5;
}`, "",{"version":3,"sources":["webpack://./src/app/components/delegations/delegations-list/delegations-list.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACE,cAAA;AACF","sourcesContent":["th, td {\r\n    white-space: nowrap\r\n}\r\n\r\n.table-responsive{\r\n    overflow-x: unset!important;\r\n}\r\n\r\n.td-gray{\r\n  color: #C5C5C5\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
