import { HotelRoomReservationInfo } from 'src/app/models/api/entities/hotel-room-reservation-info';
import { Pipe, PipeTransform } from '@angular/core';

import { EventFeatures } from '../models/api/entities/event-features';
import { HotelRoomInfo } from '../models/api/entities/hotel-room-info';
import { RoomType } from '../models/api/enums/room-type';
import { IntToPercent } from '../utils/utils';
import { toMoment } from '../shared/utils/utils';

@Pipe({
  pure: true,
  name: 'roomSubtotal'
})
export class RoomSubtotalPipe implements PipeTransform {

  // eslint-disable-next-line max-len
  transform(room: HotelRoomInfo, reservation: HotelRoomReservationInfo, checkInDate: string, checkOutDate: string, eventFeatures?: EventFeatures, isLateBookingStarted: boolean = false): number {
    let subtotal = 0;

    if (room === null) {
      return subtotal;
    }

    const totalNight = this.nightsCount(checkInDate, checkOutDate);

    let price = room.standartPrice;

    if (isLateBookingStarted && reservation.isLateRegistration) {
      price = room.lateRegistrationPricePerNight;
    }

    var quantity: number = reservation.quantity;

    switch (room.roomType) {
      case RoomType.QD:
        price *= 4;
        break;
      case RoomType.TR:
        price *= 3;
        break;
      case RoomType.TW:
        price *= 2;
        break;
      case RoomType.TW_SEP_B:
        price *= 2;
        break;
      case RoomType.TW_SH_B:
        price *= 2;
        break;
      case RoomType.Twin_BB:
        price *= 2;
        break;
      case RoomType.Twin_HB:
        price *= 2;
        break;
      case RoomType.Twin_FB:
        price *= 2;
        break;
      case RoomType.Triple_BB:
        price *= 3;
        break;
      case RoomType.Triple_HB:
        price *= 3;
        break;
      case RoomType.Triple_FB:
        price *= 3;
        break;
      case RoomType.Multiple_4_beds_BB:
        price *= 4;
        break;
      case RoomType.Multiple_4_beds_HB:
        price *= 4;
        break;
      case RoomType.Multiple_4_beds_FB:
        price *= 4;
        break;
      case RoomType.Multiple_6_beds_BB:
        price *= 6;
        break;
      case RoomType.Multiple_6_beds_HB:
        price *= 6;
        break;
      case RoomType.Multiple_6_beds_FB:
        price *= 6;
        break;
    }

    if (reservation.discount > 0)
      price -= reservation.discount;

    subtotal = quantity * price * totalNight;

    if (eventFeatures?.isHotelRoomEarlyCheckInAddPriceEnabled && room.earlyCheckInAddPrice !== 0) {
      if (reservation.isEarlyCheckIn) {
        subtotal += (room.earlyCheckInAddPrice * quantity);
      }
    }

    if (eventFeatures?.isHotelRoomLateCheckOutAddPriceEnabled && room.lateCheckOutAddPrice !== 0) {
      if (reservation.isLateCheckOut) {
        subtotal += (room.lateCheckOutAddPrice * quantity);
      }
    }

    if(eventFeatures?.isPenaltyFunctionEnabled && reservation.isDisabled){
      subtotal = (IntToPercent(reservation.percentageOfPenaltyImposed) * subtotal) / 100;
    }


    return subtotal;
  }

  nightsCount(checkInDate, checkOutDate): number {
    if (checkOutDate === null && checkInDate === null) {
      return null;
    }
    const diff = toMoment(checkOutDate).diff(checkInDate, 'days');
    return diff;
  }

}
