import { PenaltyType } from './../models/api/enums/penalty-type';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityMapService } from '../services/entity-map.service';

@Pipe({
  name: 'penaltyType'
})
export class PenaltyTypePipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  async transform(penaltyType: PenaltyType ): Promise<string> {

    if (penaltyType === null) {
      return null;
    }

    await this.entityMapService.ready();

    return this.entityMapService.getPenaltyTypeName(penaltyType);

  }

}
