import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toPercent'
})
export class ToPercentPipe implements PipeTransform {

  transform(number: number, withPercent: boolean = false): string | number {
    if(!withPercent)
      return `${number / 1000}%`;

    return number / 1000;
  }

}
