import { isNullOrUndefined } from 'src/app/utils/utils';
import { Component, Input, OnInit } from '@angular/core';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { MealDetailsInfo } from 'src/app/models/api/entities/special-export/meal/meal-details-info';

const moment = extendMoment(Moment);
@Component({
  selector: 'app-special-export-detail-meal',
  templateUrl: './special-export-detail-meal.component.html',
  styleUrls: ['./special-export-detail-meal.component.scss']
})
export class SpecialExportDetailMealComponent implements OnInit {

  @Input() eventId: number;
  @Input() venueDetail: MealDetailsInfo;

  public datesCol: number = 1;
  public dateRange: Array<string>;
  public mealTypesCol: number = 1;
  public mealTypesFor = [];
  public venueId: number;

  constructor() { }

  ngOnInit(): void {

    if(!isNullOrUndefined(this.venueDetail)){
      this.datesCol = this.venueDetail.delegations[0].venueDetailsDelegationsDetailByServeDate.length;
    }else{
      this.datesCol = null;
    }

    const minServeDate = moment(this.venueDetail.minServeDate);
    const maxServeDate = moment(this.venueDetail.maxServeDate);
    const dateRangeMoment = moment.range(minServeDate, maxServeDate);
    const dateRange = Array.from(dateRangeMoment.by('days')).map(m => m.format('DD MMM'));

    this.dateRange = dateRange;

    this.mealTypesCol = this.venueDetail.mealTypes.length;

    for (let index = 0; index < this.datesCol / this.mealTypesCol; index++) {
      this.mealTypesFor[index] = index;
    }

  }

}
