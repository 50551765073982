import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from 'src/app/services/api.service';
import { Country } from 'src/app/models/api/entities/country';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelRoomReservationInfo } from 'src/app/models/api/entities/hotel-room-reservation-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { PersonShortInfo } from 'src/app/models/api/entities/person-short-info';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { isEmpty } from 'lodash';
import { MessageService } from 'src/app/shared/services/message.service';
import { deepClone, toDateString } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-hotel-room-reservation',
  templateUrl: './hotel-room-reservation.component.html',
  styleUrls: ['./hotel-room-reservation.component.scss']
})
export class HotelRoomReservationComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() country: Country;

  @Input() reservationId: 0;

  @Input() clubShortName: string = null;

  @Output() saved = new EventEmitter<HotelRoomReservationInfo>();
  @Output() closed = new EventEmitter<HotelRoomReservationInfo>();

  @ViewChild('reservationPersonModal', { static: true }) private reservationPersonModal: TemplateRef<any>;
  private reservationPersonModalRef: NgbModalRef;

  isNew: boolean;
  isVisiblle: boolean;
  isReadOnly: boolean;

  messageService: MessageService;

  allPersonList: ListResponse<PersonShortInfo> = new LocalListResponse<PersonShortInfo>();
  personDropdown: PersonShortInfo[];
  selectedPersons: PersonShortInfo[];
  reservation: HotelRoomReservationInfo;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private modalService: NgbModal
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.country.code);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }

    if (this.reservationId !== 0 || this.reservationId !== null) {
      this.loadRoomMates();
    } else {
      this.close();
    }

  }

  private async loadRoomMates() {
    if (this.isVisiblle) {
      this.close();
    }

    this.isNew = false;

    const reservations = await this.apiService.getHotelRoomReservations(false, -1, 0, null, this.evt.id, this.country.code, this.clubShortName);

    let usedPersons = [];

    reservations.items.forEach(person => {
      if (person.personIds !== null) {
        person.personIds.forEach(p => {
          usedPersons.push(p);
        });
      }
    });




    const reservation = await this.apiService.getHotelRoomReservation(this.reservationId);
    if (reservation == null) {
      this.close();
      return;
    }

    const hotels = await this.apiService.getHotels(false, 0, 1, null, this.evt.id);
    let allHotels = hotels.items.filter(t => t.availableRoomCount > 0 && t.id !== reservation.room.hotel.id);
    allHotels = [reservation.room.hotel, ...allHotels];

    const rooms = await this.apiService.getHotelRooms(false, 0, 1, null, reservation.room.hotel.id);
    let allRooms = rooms.items.filter(t => t.availableQuantity > 0 && t.id !== reservation.room.id);
    allRooms = [reservation.room, ...allRooms];


    this.reservation = reservation;

    this.allPersonList = await this.apiService.getShortPersons(false, -1, 0, null, this.evt.id, this.country.iocCode,this.clubShortName);

    this.personDropdown = this.allPersonList.items.filter(f => !usedPersons.includes(f.id));

    this.selectedPersons = reservation.persons;

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.reservationPersonModalRef != null) {
      this.reservationPersonModalRef.close();
      this.reservationPersonModalRef = null;
    }

    const ngnModalOptions: NgbModalOptions = { size: 'lg', keyboard: false, backdrop: 'static' };

    this.reservationPersonModalRef = this.modalService.open(this.reservationPersonModal, ngnModalOptions);
    this.isVisiblle = true;
  }

  private close() {
    if (this.reservationPersonModalRef != null) {
      this.reservationPersonModalRef.close();
      this.reservationPersonModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.reservation);
  }

  private async save() {

    if (this.reservation.persons.length === 0 && isEmpty(this.selectedPersons)) {
      this.messageService.setToastMessage(false, 'Please select person.');
      return;
    }

    this.reservation.persons = this.selectedPersons;
    this.reservation.isRoomReservation = true;

    this.reservation.checkInDate = toDateString(this.reservation.checkInDate);
    this.reservation.checkOutDate = toDateString(this.reservation.checkOutDate);

    const reservation = deepClone(this.reservation);

    const request: UpdateRequest<HotelRoomReservationInfo> = {
      isNew: this.isNew,
      subject: reservation
    };

    const result = await this.apiService.updateHotelRoomReservation(request);
    if (result.isSuccess) {
      this.saved.emit(this.reservation);
      this.messageService.setToastMessage(true, this.isNew ? 'Room reserved' : 'Room reservation updated', 7);
      this.close();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage);
    }

  }

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  onRemovePerson() {

  }

}
