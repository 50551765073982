import { EventInfo } from 'src/app/models/api/entities/event-info';
import { Pipe, PipeTransform } from '@angular/core';
import { MealReservationInfo } from '../models/api/entities/meal-reservation-info';
import { IntToPercent } from '../utils/utils';

@Pipe({
  name: 'mealSubtotal'
})
export class MealSubtotalPipe implements PipeTransform {

  transform(reservations: MealReservationInfo, evt: EventInfo, inputPrice: number = 1000000 ): number {

    let subTotal = 0;

    if(inputPrice !== 1000000)
    subTotal = inputPrice * reservations.quantity;

    subTotal = reservations.price * reservations.quantity;

    if(evt.features.isPenaltyFunctionEnabled && reservations.isDisabled)
      subTotal = (IntToPercent(reservations.percentageOfPenaltyImposed) * subTotal) / 100;

    return subTotal;
  }

}
