import { Component, OnInit } from '@angular/core';
import { DisciplineTypeInfo } from '../../../models/api/entities/discipline-type-info';
import { EntityMapService } from '../../../services/entity-map.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { ApiService } from 'src/app/services/api.service';
import { DisciplineType } from 'src/app/models/api/enums/discipline-type';
@Component({
  selector: 'app-discipline',
  templateUrl: './discipline.component.html',
  styleUrls: ['./discipline.component.scss']
})
export class DisciplineComponent implements OnInit {

  public disciplineTypeInfo: DisciplineTypeInfo={
    disciplineType: DisciplineType.ACR,
    code:'',
    name: "",
    groupTypes:[],
    categoryTypes: [],
    unitTypes: [],
    apparatusTypes: []
  };

  constructor(
    public entityMapService: EntityMapService,
    private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService
  ) { 
  }

  ngOnInit() {
    this.load();
  }

  private async load(){
    await this.apiService.ready();
    await this.entityMapService.ready();
    this.route.paramMap.subscribe(async (params) => {
      const disciplineType = params.get('disciplineType');
      if (disciplineType == null) {
        this.router.navigate(['/disciplines']);
        return;
      }
      const disciplineEnumValue = parseInt(disciplineType, 10);
      this.disciplineTypeInfo = await this.entityMapService.getDisciplineByType(disciplineEnumValue);
      let breadcrumbs: Breadcrumb[] = [];
      breadcrumbs.push({ key: 'Disciplines', link: `/disciplines` });
      breadcrumbs.push({ key: '', value:`${this.disciplineTypeInfo.name}`, link: `` });
      this.breadcrumbsService.breadcrumbs = breadcrumbs;
    });

  }

}

