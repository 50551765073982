// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  text-align: center;
  white-space: nowrap;
}

ul {
  list-style-type: none;
}

.disabled {
  background-color: #313131;
  color: #fff;
}

.table-container {
  overflow: auto;
  max-height: 600px;
}

.scrollable {
  overflow: auto;
  max-height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/delegations/delegation-overview/delegation-overview.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;AACF","sourcesContent":["th, td {\r\n  text-align: center;\r\n  white-space: nowrap\r\n}\r\n\r\nul {\r\n  list-style-type: none;\r\n}\r\n\r\n.disabled {\r\n  background-color: #313131;\r\n  color: #fff;\r\n}\r\n\r\n.table-container{\r\n  overflow: auto;\r\n  max-height: 600px;\r\n}\r\n\r\n.scrollable {\r\n  overflow: auto;\r\n  max-height: 200px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
