// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incDecButton {
  background: none;
  border: none;
  margin: 0 5px;
}

.incDecButton:focus, .incDecButton:active {
  outline: none;
}

.pcrCount {
  font-weight: 600;
}

th, td {
  text-align: center;
  white-space: nowrap;
}

ul {
  list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/covid19/covid19.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;AACF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,gBAAA;AAEF;;AACA;EACE,kBAAA;EACA,mBAAA;AAEF;;AAEA;EACE,qBAAA;AACF","sourcesContent":[".incDecButton{\r\n  background: none;\r\n  border:none;\r\n  margin: 0 5px;\r\n}\r\n.incDecButton:focus, .incDecButton:active{\r\n  outline: none;\r\n}\r\n\r\n.pcrCount{\r\n  font-weight: 600;\r\n}\r\n\r\nth, td {\r\n  text-align: center;\r\n  white-space: nowrap\r\n}\r\n\r\n\r\nul {\r\n  list-style-type: none;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
