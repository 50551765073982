// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-group-item {
    padding: .5rem 1rem;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/check-list/check-list.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".list-group-item {\r\n    padding: .5rem 1rem;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
