import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from 'src/app/services/api.service';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { ChangeSubjectType } from 'src/app/models/api/enums/change-subject-type';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelInfo } from 'src/app/models/api/entities/hotel-info';
import { HotelRoomInfo } from 'src/app/models/api/entities/hotel-room-info';
import { RoomType } from 'src/app/models/api/enums/room-type';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { MessageService } from 'src/app/shared/services/message.service';
import { deepClone, sleep } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-hotel-room',
  templateUrl: './hotel-room.component.html',
  styleUrls: ['./hotel-room.component.scss']
})
export class HotelRoomComponent implements AfterViewInit {

  RoomType = RoomType;

  @Input() evt: EventInfo;
  @Input() hotel: HotelInfo;
  @Input() roomId: number;

  @Output() saved = new EventEmitter<HotelRoomInfo>();
  @Output() closed = new EventEmitter<HotelRoomInfo>();

  @ViewChild('roomModal', { static: true }) private roomModal: TemplateRef<any>;
  private roomModalRef: NgbModalRef;

  room: HotelRoomInfo;
  minQuantity: number;
  isNew: boolean;

  isVisiblle: boolean;
  isReadOnly: boolean;

  roomTypes: RoomType[];

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private changeNotificationsService: ChangeNotificationsService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);

    if (!canManageEvent) {
      this.close();
      return;
    }

    const canManageThisEvent = canManageSystem || curUser.eventId == this.evt.id;
    if (!canManageThisEvent) {
      this.close();
      return;
    }

    this.roomTypes = this.entityMapService.allRoomTypes;

    if (this.roomId === 0) {
      this.newRoom();
    } else {
      this.loadRoom();
    }
  }

  private async newRoom() {
    await sleep(50);
    this.isNew = true;
    this.room = {
      id: 0,
      hotelId: this.hotel.id,
      roomType: RoomType.S,
      standartPrice: 0,
      pricePerNight: 0,
      lateRegistrationPricePerNight: 0,
      earlyCheckInAddPrice: 0,
      lateCheckOutAddPrice: 0,
      totalQuantity: 0,
      availableQuantity: 0,
      isDisabled: false
    };
    this.minQuantity = 0;
    this.open();
  }

  private async loadRoom() {
    if (this.isVisiblle) {
      this.onClose();
    }
    this.isNew = false;
    const room = await this.apiService.getHotelRoom(this.roomId);
    if (room == null) {
      return;
    }

    this.room = room;

    let reservedQuantity = this.room.totalQuantity - this.room.availableQuantity;
    reservedQuantity = Math.max(0, reservedQuantity);
    this.minQuantity = reservedQuantity;

    this.changeNotificationsService.removeNotificationsByQuery(ChangeSubjectType.HotelRoom, [room.id.toString()]);

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.roomModalRef != null) {
      this.roomModalRef.close();
      this.roomModalRef = null;
    }
    this.roomModalRef = this.modalService.open(this.roomModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.roomModalRef != null) {
      this.roomModalRef.close();
      this.roomModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.room);
  }

  private async save() {

    const room: HotelRoomInfo = deepClone(this.room);

    if(room.standartPrice === 0){
      this.messageService.setToastMessage(false, 'Please add room standart price.');
      return ;
    }

    if(room.pricePerNight === 0 && this.evt.features.isHotelRoomEarlyPerNightPriceEnabled){
      this.messageService.setToastMessage(false, 'Please add early booking price per night.');
      return ;
    }

    if(room.lateRegistrationPricePerNight === 0 && this.evt.features.isHotelRoomLatePerNightPriceEnabled){
      this.messageService.setToastMessage(false, 'Please add late booking price per night.');
      return ;
    }

    if(room.earlyCheckInAddPrice === 0 && this.evt.features.isHotelRoomEarlyCheckInAddPriceEnabled){
      this.messageService.setToastMessage(false, 'Please add early check in.');
      return ;
    }

    if(room.lateCheckOutAddPrice === 0 && this.evt.features.isHotelRoomLateCheckOutAddPriceEnabled){
      this.messageService.setToastMessage(false, 'Please add late check out.');
      return ;
    }

    // if(room.totalQuantity === 0){
    //   this.messageService.setToastMessage(false, 'Please add total quantity.');
    //   return ;
    // }

    room.hotelId = this.room.hotelId;

    const request: UpdateRequest<HotelRoomInfo> = {
      isNew: this.isNew,
      subject: room
    };

    const result = await this.apiService.updateHotelRoom(request);
    if (result.isSuccess) {
      this.saved.emit(this.room);
      this.messageService.setToastMessage(true, this.isNew ? 'Room created' : 'Room updated', 7);
      this.close();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage);
    }
  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  //#endregion

}
