import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { CurrencyType } from 'src/app/models/api/enums/currency-type';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelRoomReservationInfo } from 'src/app/models/api/entities/hotel-room-reservation-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MimeTypes } from 'src/app/utils/mime-types';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserService } from 'src/app/services/user.service';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber, stringIsNullOrEmpty } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-hotel-room-reservations-list',
  templateUrl: './hotel-room-reservations-list.component.html',
  styleUrls: ['./hotel-room-reservations-list.component.scss']
})
export class HotelRoomReservationsListComponent implements OnInit {

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public reservations: ListResponse<HotelRoomReservationInfo> = new LocalListResponse<HotelRoomReservationInfo>();

  public currentReservationId: number;
  public viewingAddEditRoomMate = false;

  public canManageDelegation = false;

  public isObserver: boolean = false;

  public messageService: MessageService;

  public clubShortName: string = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');

      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      if ( curUser.role === 3 ){
        this.isObserver = true;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.evt = evt;
      this.delegation = delegation;

      const breadcrumbs: Breadcrumb[] = [];

      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }

      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      if(this.isObserver){
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      if(evt.features.isClubExist === false)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` });
        breadcrumbs.push({ key: 'Rooming List', value: '', link:''})
      }

      if(evt.features.isClubExist === true)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}` });
        breadcrumbs.push({ key: 'Rooming List', value: '', link:''})
      }

      this.updateHeaderItems();

      this.breadcrumbsService.breadcrumbs = breadcrumbs;

      if (params.has('roomReservationId')) {

        const roomReservationIdStr = params.get('roomReservationId');

        if (roomReservationIdStr == null || !isConvertableToNumber(roomReservationIdStr)) {
          this.close();
          return;
        }

        const reservationId = Number(roomReservationIdStr);

        this.currentReservationId = reservationId;

        if(reservationId !== 0){
          this.viewReservation(reservationId);
        }else{
          this.addReservation();
        }

        this.viewReservation(reservationId);
      };

      this.reloadList();

    });

  }


  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private updateHeaderItems() {
    let headerItems: PageHeaderItem[] = [];
    if (this.canManageDelegation) {
      headerItems.push({ title: 'Download reservations', iconClass: 'fa-file-excel-o', callback: () => this.onDownloadReservations() });
    }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    this.reservations = await this.apiService.getHotelRoomReservations(false, this.itemsPerPage, this.reservations.page, null, this.delegation.eventId, this.delegation.countryCode, this.delegation.clubShortName);
  }

  private viewReservation(reservationId: number) {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/room-reservations/${reservationId}`;

    if(this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/room-reservations/${reservationId}`;

    this.location.go(path);
    this.currentReservationId = reservationId;
    this.viewingAddEditRoomMate = true;
  }

  private closeReservation() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/room-reservations`;

    if(this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/room-reservations`;

    this.location.go(path);
    this.viewingAddEditRoomMate = false;
    this.currentReservationId = 0;
  }

  private addReservation() {
    this.viewReservation(0);
  }

  private downloadReservations() {
    let path = `/Export/HotelReservations?eventId=${this.evt.id}&country=${this.delegation.countryCode}`;

    if(this.clubShortName !== null)
      path = `/Export/HotelReservations?eventId=${this.evt.id}&country=${this.delegation.countryCode}&country=${this.delegation.clubShortName}`;

    const mimeType = MimeTypes.xls;
    this.apiService.downloadFile(path, mimeType).then(error => {
      if (error != null) {
        this.messageService.setToastMessage(false, error, 7);
      }
    });
  }

  private deleteReservation(reservation: HotelRoomReservationInfo) {
    this.apiService.deleteHotelRoomReservation(reservation.id).then(result => {
      if (result.isSuccess) {
        this.reloadList();
        const toastMessage = `Hotel room reservation '${reservation.room.hotel.name} deleted`;
        this.messageService.setToastMessage(true, toastMessage);
      } else {
        this.messageService.setToastMessage(false, result.errorMessage);
      }
    });
  }

  //#endregion

  //#region Actions

  onPageChanged(page: number) {
    this.reservations.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onAddRoomMate() {
    this.addReservation();
  }

  onViewReservation(reservation: HotelRoomReservationInfo) {
    this.viewReservation(reservation.id);
  }

  onDeleteReservation(reservation: HotelRoomReservationInfo) {
    this.deleteReservation(reservation);
  }

  onReservationPersonUpdated() {
    this.reloadList();
  }

  onReservationPersonClosed() {
    this.closeReservation();
  }

  onDownloadReservations() {
    this.downloadReservations();
  }

  //#endregion


}
