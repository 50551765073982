import { stringIsNullOrEmpty } from "src/app/utils/utils";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { ApiService } from "src/app/services/api.service";
import { ChangeNotificationsService } from "src/app/services/change-notifications.service";
import { ChangeSubjectType } from "src/app/models/api/enums/change-subject-type";
import { EntityMapService } from "src/app/services/entity-map.service";
import { MealServiceInfo } from "src/app/models/api/entities/meal-service-info";
import { MealVenueInfo } from "src/app/models/api/entities/meal-venue-info";
import { ShortEventInfo } from "src/app/models/api/entities/short-event-info";
import { UpdateRequest } from "src/app/models/api/protocol/update-request";
import { UserInfo } from "src/app/models/api/entities/user-info";
import { UserPrivilege } from "src/app/models/api/enums/user-privilege";
import { MessageService } from "src/app/shared/services/message.service";
import { deepClone, sleep } from "src/app/shared/utils/utils";

@Component({
  selector: "app-meal-venue",
  templateUrl: "./meal-venue.component.html",
  styleUrls: ["./meal-venue.component.scss"],
})
export class MealVenueComponent implements AfterViewInit {
  @Input() evt: ShortEventInfo;
  @Input() venueId: number;

  @Output() saved = new EventEmitter<MealServiceInfo>();
  @Output() closed = new EventEmitter<MealServiceInfo>();

  @ViewChild("venueModal", { static: true })
  private venueModal: TemplateRef<any>;
  private venueModalRef: NgbModalRef;

  venue: MealVenueInfo;
  isNew: boolean;

  isVisiblle: boolean;

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private changeNotificationsService: ChangeNotificationsService,
    private modalService: NgbModal
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageSystem
    );
    const canManageEvent = this.entityMapService.userCan(
      curUser,
      UserPrivilege.ManageEvent
    );

    if (!canManageEvent) {
      this.close();
      return;
    }

    const canManageThisEvent =
      canManageSystem || curUser.eventId == this.evt.id;
    if (!canManageThisEvent) {
      this.close();
      return;
    }

    if (this.venueId === 0) {
      this.newVenue();
    } else {
      this.loadVenue();
    }
  }

  private async newVenue() {
    await sleep(50);
    this.isNew = true;
    this.venue = {
      eventId: this.evt.id,
      name: null,
      breakfastServicePrice: null,
      lunchServicePrice: null,
      dinnerServicePrice: null,
      isDisabled: false,
    };
    this.open();
  }

  private async loadVenue() {
    if (this.isVisiblle) {
      this.onClose();
    }
    this.isNew = false;
    const venue = await this.apiService.getMealServiceVenue(this.venueId);
    if (venue === null) {
      return;
    }

    this.venue = venue;
    this.changeNotificationsService.removeNotificationsByQuery(
      ChangeSubjectType.MealVenue,
      [venue.name]
    );

    this.open();
  }

  private async open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.venueModalRef != null) {
      this.venueModalRef.close();
      this.venueModalRef = null;
    }

    this.venueModalRef = this.modalService.open(this.venueModal, {
      size: "lg",
      keyboard: false,
      backdrop: "static",
    });
    this.isVisiblle = true;
  }

  private close() {
    if (this.venueModalRef != null) {
      this.venueModalRef.close();
      this.venueModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.venue);
  }

  private async save() {
    const venue: MealVenueInfo = deepClone(this.venue);

    if (stringIsNullOrEmpty(venue.name)) {
      this.messageService.setToastMessage(false, "Please add name.");
      return;
    }

    venue.eventId = this.evt.id;

    const request: UpdateRequest<MealVenueInfo> = {
      isNew: this.isNew,
      subject: venue,
    };

    const result = await this.apiService.updateMealServiceVenue(request);

    if (result.isSuccess) {
      this.saved.emit(this.venue);
      this.messageService.setToastMessage(
        true,
        this.isNew ? "Meal venue created" : "Meal venue updated",
        7
      );
      this.onClose();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage);
    }
  }

  onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }
}
