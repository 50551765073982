import { UserInfo } from './../../models/api/entities/user-info';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'
import { UserRole } from '../../models/api/enums/user-role';
import { UserPrivilege } from '../../models/api/enums/user-privilege';
import { UpdateRequest } from '../../models/api/protocol/update-request';
import { EntityMapService } from '../../services/entity-map.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public user: UserInfo;

  public UserRole = UserRole;
  public UserPrivilege = UserPrivilege;

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private router: Router) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.apiService.currentUser$.subscribe(user => {
      this.user = user;
      this.loadUser(user);
    })
  }

  private loadUser(userInfo: UserInfo) {
    let user = userInfo;
    if (user != null) {
      user = {
        eventId: user.eventId,
        username: user.username,
        password: user.password,
        name: user.name,
        surname: user.surname,
        email: user.email,
        phone: null,
        role: user.role,
        isDisabled: user.isDisabled,
        countryCode: user.countryCode,
        country: user.country,
      };
    }
    if (user == null) {
      this.router.navigate(['/auth']);
      return;
    }
    this.user = user;
  }

  public async onSave() {
    if (this.user == null) {
      return;
    }

    const request: UpdateRequest<UserInfo> = {
      isNew: false,
      subject: this.user
    };

    const result = await this.apiService.updateUser(request);

    if (result.isSuccess) {
      this.messageService.setToastMessage(true, "Account updated", 5);
      this.apiService.reloadCurrentUser();
    }
    else
    {
      this.messageService.setToastMessage(false, result.errorMessage, 5);
    }
  }

  public onCancel() {
    this.router.navigate(['/']);
  }

  public getFlagAddress(): string {
    if (this.user == null || this.user.country == null) {
      return null;
    }
    return this.entityMapService.getCountryFlagAddress(this.user.country.code);
  }

  public roleHasCountry(): boolean {
    if (this.user == null || !this.entityMapService.roleCan(this.user.role, UserPrivilege.BoundToCountry)) {
      return false;
    }
    return true;
  }

  public roleHasEvent(): boolean {
    if (this.user == null || !this.entityMapService.roleCan(this.user.role, UserPrivilege.BoundToEvent)) {
      return false;
    }
    return true;
  }
}
