import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../../services/api.service';
import { ChangeNotificationsService } from 'src/app/services/change-notifications.service';
import { ChangeSubjectType } from 'src/app/models/api/enums/change-subject-type';
import { Country } from '../../../models/api/entities/country';
import { EntityMapService } from '../../../services/entity-map.service';
import { PasswordGenerator } from 'src/app/utils/password-generator';
import { ShortEventInfo } from 'src/app/models/api/entities/short-event-info';
import { UpdateRequest } from '../../../models/api/protocol/update-request';
import { UserInfo } from '../../../models/api/entities/user-info';
import { UserPrivilege } from '../../../models/api/enums/user-privilege';
import { UserRole } from '../../../models/api/enums/user-role';
import { sleep } from '../../../utils/utils';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { MessageService } from 'src/app/shared/services/message.service';
import { deepClone } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements AfterViewInit {

  UserRole = UserRole;
  UserPrivilege = UserPrivilege;

  @Input() evt: EventInfo;
  @Input() username: string;

  @Output() saved = new EventEmitter<UserInfo>();
  @Output() closed = new EventEmitter<UserInfo>();

  @ViewChild('userModal', { static: true }) private userModal: TemplateRef<any>;
  private userModalRef: NgbModalRef;

  user: UserInfo;
  clubShortName: string;
  isNew: boolean;

  isVisiblle: boolean;
  isReadOnly: boolean;
  isPasswordVisible: boolean;

  roles: UserRole[];
  countries: Country[];

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private changeNotificationsService: ChangeNotificationsService,
    private modalService: NgbModal,
  ) {
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    if (!canManageEvent) {
      this.close();
      return;
    }

    let canManageThisEvent = curUser.eventId == this.evt.id ;

    if(curUser.role === UserRole.Admin)
        canManageThisEvent = true;

    if(curUser.role === UserRole.LOC)
        canManageThisEvent = true;

    if (!canManageThisEvent) {
      this.close();
      return;
    }

    this.countries = this.entityMapService.allCountries;

    let roles = this.entityMapService.allUserRoles.filter(role => role !== UserRole.Undefined && role !== UserRole.Admin);

    if (!canManageThisEvent) {
      roles = [UserRole.NF,UserRole.Accountant,UserRole.Observer];
    }
    this.roles = roles;

    if (this.username == null) {
      this.newUser();
    } else {
      this.loadUser();
    }
  }

  private async newUser() {
    await sleep(50);
    this.isNew = true;
    this.user = {
      eventId: this.evt.id,
      username: null,
      password: null,
      email: null,
      phone: null,
      role: UserRole.NF,
      isDisabled: false,
      countryCode: null,
      country: null,
      name: null,
      surname: null,
    };
    this.generatePassword();
    this.updateRole();
    this.open();
  }

  private async loadUser() {

    if (this.isVisiblle) {
      this.onClose();
    }
    this.isNew = false;
    const user = await this.apiService.getUser(this.username);
    if (user == null) {
      return;
    }

    this.user = user;
    if(this.evt.features.isClubExist)
    this.clubShortName = user.clubShortName;

    this.changeNotificationsService.removeNotificationsByQuery(ChangeSubjectType.User, [user.username]);

    this.updateRole();
    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (!this.isNew) {
      if (this.user.role !== UserRole.NF && !this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent)) {
        return;
      }
    }
    if (this.userModalRef != null) {
      this.userModalRef.close();
      this.userModalRef = null;
    }
    this.userModalRef = this.modalService.open(this.userModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private updateRole() {
    this.updateCountry();
  }

  private updateCountry() {
    if (this.entityMapService.userCan(this.user, UserPrivilege.BoundToCountry)) {
      if (this.isNew) {
        this.user.countryCode = this.evt.countryCode;
        this.user.country = this.entityMapService.getCountryByCode(this.user.countryCode);
      }
    }
    else {
      this.user.countryCode = null;
      this.user.country = null;
    }
    this.upodateUsername();
  }

  private upodateUsername() {
    if (this.user == null || this.evt == null) {
      return;
    }

    if (!this.isUserenameGenerated) {
      if (this.isNew) {
        this.user.username = null;
      }
      return;
    }
    if(this.isNew)
      {
        let parts: string[] = [];
        parts = [...parts, UserRole[this.user.role].toString()];
        if (this.entityMapService.userCan(this.user, UserPrivilege.BoundToEvent)) {
          parts = [...parts, this.evt.code]
        }
        if (this.entityMapService.userCan(this.user, UserPrivilege.BoundToCountry) && this.user.country.code != null) {
          parts = [...parts, this.user.country.code];
        }
        if(this.evt.features.isClubExist && this.user.club?.length > 1){
          const sub = this.user.club?.substring(0,3);
          parts = [...parts, sub.toUpperCase()];

          this.clubShortName = sub.toUpperCase();
        }
        this.user.username = parts.join('_');
      }
  }

  private generatePassword() {
    this.user.password = PasswordGenerator.generate();
  }


  private close() {
    if (this.userModalRef != null) {
      this.userModalRef.close();
      this.userModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.user);
  }

  private async save() {

    let user: UserInfo = deepClone(this.user);

    if (user.country != null) {
      user.countryCode = user.country.code;
    }
    user.eventId = this.evt.id;

    user.country = null;

    if(this.evt.features.isClubExist === true && this.clubShortName === null && user.role === UserRole.NF){
      this.messageService.setToastMessage(false, 'Please enter club name.', 5);
      return;
    }


    if(this.evt.features.isClubExist)
    user.clubShortName = this.clubShortName;

    const request: UpdateRequest<UserInfo> = {
      isNew: this.isNew,
      subject: user
    };

    const result = await this.apiService.updateUser(request);
    if (result.isSuccess) {
      this.saved.emit(this.user);
      this.messageService.setToastMessage(true, this.isNew ? 'User created' : 'User updated', 7);
      this.onClose();
    } else {
      this.messageService.setToastMessage(false, result.errorMessage);
    }
  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  onTogglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  onGeneratePassword() {
    this.generatePassword();
  }

  onChangeRole() {
    this.updateRole();
  }

  onChangeCountry() {
    this.upodateUsername();
  }

  onClubChange() {
    this.upodateUsername();
  }


  //#endregion

  //#region Data

  getFlagAddress(): string {
    if (this.user == null || this.user.country == null) {
      return null;
    }
    return this.entityMapService.getCountryFlagAddress(this.user.country.code);
  }

  getDisciplineTypeCode(): string {
    const disciplineType = this.entityMapService.getDisciplineByType(this.evt.disciplineType);
    if (disciplineType == null) {
      return null;
    }

    return disciplineType.code;
  }

  get isCountryVisible(): boolean {
    return this.entityMapService.userCan(this.user, UserPrivilege.BoundToCountry);
  }

  get isCountryLocked(): boolean {
    return !this.isNew;
  }

  get isUsernameLocked(): boolean {
    return !this.isNew || this.isUserenameGenerated;
  }

  get isUserenameGenerated(): boolean {
    return this.user.role == UserRole.NF || this.user.role == UserRole.LOC;
  }

  //#endregion

}
