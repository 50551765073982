import { MealVenueInfo } from './../../../../models/api/entities/meal-venue-info';
import { ApiResponse } from './../../../../models/api/protocol/api-response';
import { SpecialExportInfo } from './../../../../models/api/entities/special-export/special-export-info';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelInfo } from 'src/app/models/api/entities/hotel-info';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { ExportType } from 'src/app/models/api/enums/export-type';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-special-export-detail',
  templateUrl: './special-export-detail.component.html',
  styleUrls: ['./special-export-detail.component.scss']
})
export class SpecialExportDetailComponent implements OnInit {

  public headerItems: PageHeaderItem[] = [];

  public evt: EventInfo;
  public canManageEvent = false;

  public itemsPerPage = 10;
  public filter = '';

  public hotels: ListResponse<HotelInfo> = new LocalListResponse<HotelInfo>();
  public venues: ListResponse<MealVenueInfo> = new LocalListResponse<MealVenueInfo>();

  public detailInfo: SpecialExportInfo;

  public messageService: MessageService;

  public detailId: number;

  public eventId: number;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
  ) {
    this.messageService = new MessageService();

  }

  ngOnInit(): void {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);

    if (!canViewEvent) {
      this.close();
      return;
    }
    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {

      const detailIdStr = params.get('detailId');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (detailIdStr == null || !isConvertableToNumber(detailIdStr)) {
        this.close();
        return;
      }


      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const detailId = Number(detailIdStr);

      this.detailId = detailId;
      this.eventId = evtId;

      const detailInfo = await this.reloadDetail();

      if (detailInfo == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      this.evt = evt;

      this.detailInfo = detailInfo.data;
      this.canManageEvent = canManageEvent;

      if (curUser.role === 1) {
        this.breadcrumbsService.breadcrumbs = [
          { key: 'Events', link: `/events` },
        ];
      }

      if (canManageEvent) {
        this.breadcrumbsService.breadcrumbs.push({ key: '', value: `[${evt.code}] ${evt.name}`, link: `/events/${evtId}` });
        this.breadcrumbsService.breadcrumbs.push({ key: 'Special exports', value: ``, link: `/events/${evtId}/special-export` });
        this.breadcrumbsService.title = evt.name;

        this.headerItems = [
          ...this.headerItems,
          { title: 'Close', iconClass: 'fa-close', callback: () => this.onClose() }
        ];
      }


      if(this.detailInfo.exportType === ExportType.Accommodation){
        this.reloadHotelsList();
        this.breadcrumbsService.breadcrumbs.push({ key: 'Accomodations', link: `` });
      }

      if(this.detailInfo.exportType === ExportType.Rooming){
        this.breadcrumbsService.breadcrumbs.push({ key: 'Roomings', link: `` });
        this.reloadHotelsList();
      }

      if(this.detailInfo.exportType === ExportType.Meal){
        this.breadcrumbsService.breadcrumbs.push({ key: 'Meals', link: `` });
        this.reloadMealVenuessList();
      }


    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate([`/events/${this.evt.id}/special-export`]);
    } else {
      this.router.navigate(['/']);
    }
  }

  public async reloadHotelsList() {
    this.hotels = await this.apiService.getHotels(false, this.itemsPerPage, this.hotels.page, this.filter, this.evt.id);
  }

  public async reloadMealVenuessList(){
    this.venues = await this.apiService.getMealServiceVenues(false, this.itemsPerPage, this.venues.page, this.filter, this.evt.id);
  }

  public async reloadDetail(){
    return await this.apiService.getReportDetail(this.eventId, this.detailId);
  }

  //#region Actions

  onClose() {
    this.close();
  }

  async onUpdated(){
    const detailInfo = await this.reloadDetail();
    this.detailInfo = detailInfo.data;
  }

  //#endregion

}
