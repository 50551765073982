import { ApparatusType } from './../models/api/enums/apparatus-type';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityMapService } from '../services/entity-map.service';
import { ApparatusTypeInfo } from '../models/api/entities/apparatus-type-info';

@Pipe({
  name: 'apparatusType'
})
export class ApparatusTypePipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  async transform(apparatusType: ApparatusType, withShortCode: boolean = false): Promise<string> {
    if (apparatusType === null) {
      return null;
    }
    await this.entityMapService.ready();

    let getTypeInfo: ApparatusTypeInfo = this.entityMapService.getApparatusByType(apparatusType);
    
    let returnName: string = getTypeInfo.name;

    if(withShortCode){
      returnName = `[${getTypeInfo.code}] ${getTypeInfo.name}`;
    }
    
    return returnName
  }

}
