// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox {
  position: relative;
  cursor: pointer;
  appearance: none;
  width: 50px;
  height: 25px;
  border-radius: 25px;
  border: 2px solid #f90000;
  outline: none;
  transition: 0.3s;
}

.checkbox::before {
  content: " ";
  position: absolute;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  background: #bf0000;
  top: 1px;
  left: 1px;
  transition: 0.3s ease-in-out;
}

.checkbox:checked::before {
  transform: translateX(25px);
  background: #28a745;
}

.checkbox:checked {
  border-color: #28a745;
}`, "",{"version":3,"sources":["webpack://./src/app/components/meals/meal-reservations/meal-reservations.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EAGA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;AACF;;AACA;EACE,YAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,QAAA;EACA,SAAA;EACA,4BAAA;AAEF;;AAAA;EACE,2BAAA;EACA,mBAAA;AAGF;;AADA;EACE,qBAAA;AAIF","sourcesContent":[".checkbox{\r\n  position: relative;\r\n  cursor: pointer;\r\n  -webkit-appearance: none;\r\n  -moz-appearance: none;\r\n  appearance: none;\r\n  width: 50px;\r\n  height: 25px;\r\n  border-radius: 25px;\r\n  border: 2px solid #f90000;\r\n  outline: none;\r\n  transition: 0.3s;\r\n}\r\n.checkbox::before{\r\n  content: \" \";\r\n  position: absolute;\r\n  height: 19px;\r\n  width: 19px;\r\n  border-radius: 50%;\r\n  background: #bf0000;\r\n  top: 1px;\r\n  left: 1px;\r\n  transition: 0.3s ease-in-out;\r\n}\r\n.checkbox:checked::before{\r\n  transform: translateX(25px);\r\n  background: #28a745;\r\n}\r\n.checkbox:checked{\r\n  border-color: #28a745;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
