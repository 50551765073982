import { Pipe, PipeTransform } from '@angular/core';

import { EntityMapService } from '../services/entity-map.service';
import { FunctionType } from '../models/api/enums/function-type';

@Pipe({
  name: 'functionType'
})
export class FunctionTypePipe implements PipeTransform {

  constructor(private entityMapService: EntityMapService) { }

  async transform(functionType: FunctionType): Promise<string> {
    if (functionType === null) {
      return null;
    }
    await this.entityMapService.ready();

    return this.entityMapService.getFunctionTypeName(functionType);
  }

}
