import { Inject, Injectable, inject } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { stringIsNullOrEmpty } from '../utils/utils';

@Injectable({
	providedIn: 'root',
})
export class MessageService {

	public message: string;
	public isSuccess: boolean;
	public isVisible: boolean;

	private messageObservers: Observer<void>[] = [];
	public onMessageChanged = new Observable<void>(observer => {
		this.messageObservers.push(observer);
	});

	private cancelCallback: Function;
	private delaySeconds = 10;
	private toastr: ToastrService = inject(ToastrService);
	constructor(
	) { 
		
	}

	// Required MessageComponent
	public setMessage(isSuccess: boolean, message: string, delaySeconds?: number): Promise<void> {
		if (delaySeconds == null) {
			delaySeconds = this.delaySeconds;
		}
		if (this.cancelCallback != null) {
			this.cancelCallback();
			this.cancelCallback = null;
		}
		if (stringIsNullOrEmpty(message)) {
			this.isVisible = false;
			this.message = null;
			this.isSuccess = false;
			return new Promise((resolve) => {
				resolve();
			});
		} else {
			this.isVisible = true;
			this.isSuccess = isSuccess;
			this.message = message;
			return new Promise((resolve) => {
				let shouldCancel = false;
				let isCancelling = false;

				this.cancelCallback = () => {
					shouldCancel = true;
				};
				setTimeout(() => {
					if (!shouldCancel && !isCancelling) {
						isCancelling = true;
						this.isVisible = false;
						this.message = null;
						this.isSuccess = false;
						this.cancelCallback = null;
						resolve();
					}
				}, delaySeconds * 1000);
			});
		}
	}

	public setToastMessage(isSuccess: boolean, message: string, delaySeconds?: number) {
		if (delaySeconds == null) {
			delaySeconds = this.delaySeconds;
		}

		const title = isSuccess ? 'Success' : 'Error'
		const delay = delaySeconds * 1000;
		
		if(isSuccess) {
			
			this.toastr.success(message, title, {timeOut: delay});
		}
		else {
			this.toastr.error(message, title, {timeOut: delay});
		}
	}
}
