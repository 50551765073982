export enum UserPrivilege {
  ManageSystem = 0,

  ManageEvent,
  ViewEvent,

  ManageDelegation,
  ViewDelegation,

  ManageLog,
  ViewLog,

  ReceiveUpdateNotifications,

  SendMail,

  BoundToEvent,
  BoundToCountry,

  HavePhoneNumber,

  ManagePayment,
  ViewPayment
}
