import { EntityMapService } from './entity-map.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Injectable } from '@angular/core';
import { UserRole } from '../models/api/enums/user-role';
import { UserPrivilege } from '../models/api/enums/user-privilege';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService,
    private router: Router,
    private entityMapService: EntityMapService
  ) { }

  async isAgreed(): Promise<void> {
    await this.apiService.ready();


    const user = this.apiService.currentUser;

    if(!user.isAgreeWithTerms && Number(sessionStorage.getItem("aggree")) === 0){
      this.apiService.reloadCurrentUser();
      sessionStorage.setItem("aggree", "1");
    }else{
      sessionStorage.setItem("aggree", "0");
    }

    if(!user.isAgreeWithTerms && user.role !== UserRole.Admin)
      {
        this.router.navigate(['/terms-of-use']);
      }
  }

  async isUserAuthenticated(): Promise<void> {

    const user = this.apiService.currentUser;

    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }
}
