import { DelegationInfo } from './../../../models/api/entities/delegation-info';
import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { DeadlinesStatusInfo } from 'src/app/models/api/entities/deadlines-status-info';
import { MomentInput } from 'moment';
import { DateStatus, DateStatuses } from 'src/app/utils/date-status';
import { EventFeatures } from 'src/app/models/api/entities/event-features';
import { EventDeadlines } from 'src/app/models/api/entities/event-deadlines';
import { MessageService } from 'src/app/shared/services/message.service';
import { toDate, toDateTimeString } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-special-deadlines',
  templateUrl: './special-deadlines.component.html',
  styleUrls: ['./special-deadlines.component.scss']
})
export class SpecialDeadlinesComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() delegationInfo: DelegationInfo;

  @Output() saved = new EventEmitter<DelegationInfo>();
  @Output() closed = new EventEmitter<DelegationInfo>();

  @ViewChild('specialDeadlinesModal', { static: true }) private specialDeadlinesModal: TemplateRef<any>;

  private specialDeadlinesModalRef: NgbModalRef;

  public deadlinesStatus: DeadlinesStatusInfo;

  isVisiblle: boolean;

  public isAccommodationFillingEnabled: boolean = false;
  public accommodationFillingStartDate: Date;
  public accommodationFillingEndDate: Date;

  public isAccommodationRoomFillingEnabled: boolean = false;
  public accommodationRoomFillingStartDate: Date;
  public accommodationRoomFillingEndDate: Date;

  public isMealsFillingEnabled: boolean = false;
  public mealsFillingStartDate: Date;
  public mealsFillingEndDate: Date;

  public isVisaFillingEnabled: boolean = false;
  public visaFillingStartDate: Date;
  public visaFillingEndDate: Date;

  public isTravelFillingEnabled: boolean = false;
  public travelFillingStartDate: Date;
  public travelFillingEndDate: Date;

  public isMusicFillingEnabled: boolean = false;
  public musicFillingStartDate: Date;
  public musicFillingEndDate: Date;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private modalService: NgbModal,
    private messageService: MessageService
  ) { }

  ngAfterViewInit() {
    this.load();
  }

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.delegationInfo.countryCode);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }

    this.deadlinesStatus = this.setDeadlinesStatus();

    if (this.delegationInfo.countryCode === null) {
      this.close();
    } else {
      this.loadDelegation();
    }

  }

  private async loadDelegation() {
    if (this.isVisiblle) {
      this.close();
    }

    if (this.delegationInfo === null) {
      this.close();
      return;
    }

    this.isAccommodationFillingEnabled = this.delegationInfo.isAccommodationFillingEnabled;
    this.accommodationFillingStartDate = toDate(this.delegationInfo.accommodationFillingStartDate);
    this.accommodationFillingEndDate = toDate(this.delegationInfo.accommodationFillingEndDate);


    this.isAccommodationRoomFillingEnabled = this.delegationInfo.isAccommodationRoomFillingEnabled;
    this.accommodationRoomFillingStartDate = toDate(this.delegationInfo.accommodationRoomFillingStartDate);
    this.accommodationRoomFillingEndDate = toDate(this.delegationInfo.accommodationRoomFillingEndDate);


    this.isMealsFillingEnabled = this.delegationInfo.isMealsFillingEnabled;
    this.mealsFillingStartDate = toDate(this.delegationInfo.mealsFillingStartDate);
    this.mealsFillingEndDate = toDate(this.delegationInfo.mealsFillingEndDate);


    this.isVisaFillingEnabled = this.delegationInfo.isVisaFillingEnabled;
    this.visaFillingStartDate = toDate(this.delegationInfo.visaFillingStartDate);
    this.visaFillingEndDate = toDate(this.delegationInfo.visaFillingEndDate);


    this.isTravelFillingEnabled = this.delegationInfo.isTravelFillingEnabled;
    this.travelFillingStartDate = toDate(this.delegationInfo.travelFillingStartDate);
    this.travelFillingEndDate = toDate(this.delegationInfo.travelFillingEndDate);


    this.isMusicFillingEnabled = this.delegationInfo.isMusicFillingEnabled;
    this.musicFillingStartDate = toDate(this.delegationInfo.musicFillingStartDate);
    this.musicFillingEndDate = toDate(this.delegationInfo.musicFillingEndDate);

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.specialDeadlinesModalRef != null) {
      this.specialDeadlinesModalRef.close();
      this.specialDeadlinesModalRef = null;
    }

    this.specialDeadlinesModalRef = this.modalService.open(this.specialDeadlinesModal, { size: 'xl', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;

  }

  private close() {
    if (this.specialDeadlinesModalRef != null) {
      this.specialDeadlinesModalRef.close();
      this.specialDeadlinesModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.delegationInfo);
  }

  private async save() {

    this.delegationInfo.isAccommodationFillingEnabled = this.isAccommodationFillingEnabled;
    this.delegationInfo.accommodationFillingStartDate = toDateTimeString(this.accommodationFillingStartDate);
    this.delegationInfo.accommodationFillingEndDate = toDateTimeString(this.accommodationFillingEndDate);

    this.delegationInfo.isAccommodationRoomFillingEnabled = this.isAccommodationRoomFillingEnabled;
    this.delegationInfo.accommodationRoomFillingStartDate = toDateTimeString(this.accommodationRoomFillingStartDate);
    this.delegationInfo.accommodationRoomFillingEndDate = toDateTimeString(this.accommodationRoomFillingEndDate);

    this.delegationInfo.isMealsFillingEnabled = this.isMealsFillingEnabled;
    this.delegationInfo.mealsFillingStartDate = toDateTimeString(this.mealsFillingStartDate);
    this.delegationInfo.mealsFillingEndDate = toDateTimeString(this.mealsFillingEndDate);

    this.delegationInfo.isVisaFillingEnabled = this.isVisaFillingEnabled;
    this.delegationInfo.visaFillingStartDate = toDateTimeString(this.visaFillingStartDate);
    this.delegationInfo.visaFillingEndDate = toDateTimeString(this.visaFillingEndDate);

    this.delegationInfo.isTravelFillingEnabled = this.isTravelFillingEnabled;
    this.delegationInfo.travelFillingStartDate = toDateTimeString(this.travelFillingStartDate);
    this.delegationInfo.travelFillingEndDate = toDateTimeString(this.travelFillingEndDate);

    this.delegationInfo.isMusicFillingEnabled = this.isMusicFillingEnabled;
    this.delegationInfo.musicFillingStartDate = toDateTimeString(this.musicFillingStartDate);
    this.delegationInfo.musicFillingEndDate = toDateTimeString(this.musicFillingEndDate);

    const request = await this.apiService.updateDelegation(this.delegationInfo);

    if (request.isSuccess) {
      this.messageService.setToastMessage(true, `Special deadlines are set to ${this.delegationInfo.country.name}`);
    }

    this.saved.emit(this.delegationInfo);

    this.onClose();
  }

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  isDateEnded(start: MomentInput, end: MomentInput): boolean {
    if (start == null || end == null) {
      return false;
    }
    let dateStatus = new DateStatus();
    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = false;

    switch (dateStatus.getStatus()) {
      case DateStatuses.Ended:
      case DateStatuses.FeatureEnded:
        return true;
    }
  }

  setDeadlinesStatus(): DeadlinesStatusInfo {
    const eventFeatures: EventFeatures = this.evt?.features;
    const eventDeadlines: EventDeadlines = this.evt?.deadlines;
    let deadlinesStatus: DeadlinesStatusInfo = {
      isAccommodationFillingEnded: false,
      isAccommodationRoomFillingEnded: false,
      isMealsFillingEnded: false,
      isVisaFillingEnded: false,
      isTravelFillingEnded: false,
      isMusicFillingEnded: false
    };

    if (eventFeatures.isAccomodationEnabled && this.isDateEnded(eventDeadlines.accommodationFillingStartDate, eventDeadlines.accommodationFillingEndDate))
      deadlinesStatus.isAccommodationFillingEnded = true;

    if (eventFeatures.isAccomodationEnabled && this.isDateEnded(eventDeadlines.accommodationRoomFillingStartDate, eventDeadlines.accommodationRoomFillingEndDate))
      deadlinesStatus.isAccommodationRoomFillingEnded = true;

    if (eventFeatures.isMealsEnabled && this.isDateEnded(eventDeadlines.mealsFillingStartDate, eventDeadlines.mealsFillingEndDate))
      deadlinesStatus.isMealsFillingEnded = true;

    if (this.isDateEnded(eventDeadlines.visaFillingStartDate, eventDeadlines.visaFillingEndDate))
      deadlinesStatus.isVisaFillingEnded = true;

    if (this.isDateEnded(eventDeadlines.travelFillingStartDate, eventDeadlines.travelFillingEndDate))
      deadlinesStatus.isTravelFillingEnded = true;

    if (eventFeatures.isMusicEnabled && this.isDateEnded(eventDeadlines.musicFillingStartDate, eventDeadlines.musicFillingEndDate))
      deadlinesStatus.isMusicFillingEnded = true;

    return deadlinesStatus;
  }

  toggleAccommodationFillingMode() {
    this.isAccommodationFillingEnabled = !this.isAccommodationFillingEnabled;
  }

  toggleRoomFillingMode() {
    this.isAccommodationRoomFillingEnabled = !this.isAccommodationRoomFillingEnabled;
  }

  toggleMealsFillingMode() {
    this.isMealsFillingEnabled = !this.isMealsFillingEnabled;
  }

  toggleVisaFilling() {
    this.isVisaFillingEnabled = !this.isVisaFillingEnabled;
  }

  toggleTravelFilling() {
    this.isTravelFillingEnabled = !this.isTravelFillingEnabled;
  }

  toggleMusicFilling() {
    this.isMusicFillingEnabled = !this.isMusicFillingEnabled;
  }

}
