// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note {
  border: 2px solid rgb(196, 0, 0);
  padding: 5px;
  font-size: 13px;
  text-align: left;
}

.card {
  width: 60%;
  justify-content: center;
}

.card-body {
  padding: 1px;
}

p {
  margin: 0;
}

.alert-card {
  margin: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/events/penalties/agreement/agreement.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,UAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".note {\r\n  border: 2px solid rgb(196, 0, 0);\r\n  padding: 5px;\r\n  font-size: 13px;\r\n  text-align: left;\r\n}\r\n\r\n.card {\r\n  width: 60%;\r\n  justify-content: center;\r\n}\r\n\r\n.card-body{\r\n  padding: 1px;\r\n}\r\n\r\np {\r\n  margin: 0;\r\n}\r\n\r\n.alert-card{\r\n  margin: 25px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
