import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'sac-check-box',
	templateUrl: './check-box.component.html',
	styleUrls: ['./check-box.component.css']
})
export class CheckBoxComponent implements OnInit {

	@Input() checkedTitile = 'Included';
	@Input() uncheckedTitile = 'Not included';
	@Input() isReadOnly: boolean;

	value_ = false;
	@Input() public set value(value: boolean) {
		this.value_ = value === true;
	}
	@Output() public valueChange = new EventEmitter<boolean>();
	@Output() public change = new EventEmitter<boolean>();

	constructor() { }

	ngOnInit() {
	}

	onClick() {
		if (!this.isReadOnly) {
			this.value_ = !this.value_;
			this.valueChange.emit(this.value_);
			this.change.emit(this.value_);
		}
	}

}
