// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container {
  font-size: 10rem !important;
  max-width: 500px;
  padding: 50px;
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.error-icon {
  font-size: 5rem;
}

h1 {
  margin-top: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/page-not-found/page-not-found.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,4BAAA;EACA,6BAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EAEA,gCAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".error-container {\r\n  font-size: 10rem !important;\r\n  max-width: 500px;\r\n  padding: 50px;\r\n  text-align: center;\r\n  margin-left: auto!important;\r\n  margin-right: auto!important;\r\n  position: absolute;\r\n  left: 50%;\r\n  top: 50%;\r\n  -webkit-transform: translate(-50%, -50%);\r\n  transform: translate(-50%, -50%);\r\n}\r\n\r\n.error-icon {\r\n  font-size: 5rem\r\n}\r\n\r\nh1 {\r\n  margin-top: .5rem\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
