export enum CategoryType {
    // RG
    I = 0, // Individual
    G, // Group
    // TUM, DMT, AG
    M, // Man
    W, // Woman
    // TRA IND, AER
    IM, // Individual Man
    IW, // Individual Woman
    // TRA SYN, ACR
    MP, // Men's Pair
    WP, // Women's Pair
    // ACR, AER
    MXP, // Mixed Pair
    // ACR, TG
    WG, // Women's Group
    MG, // Men's Four
    MXG, // Miged Group
    // AER
    TR, // Trio
    GR, // Group
    AD, // Aero Dance
    AS, // Aero Step
}
