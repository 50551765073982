import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UserRole } from 'src/app/models/api/enums/user-role';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor(
  ) { }
}
