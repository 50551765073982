// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  text-align: center;
  white-space: nowrap;
}

th:last-child {
  border-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/events/payment-tracker/payment-tracker.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":["th, td {\r\n    text-align: center;\r\n    white-space: nowrap\r\n}\r\n\r\nth:last-child {\r\n    border-right: 0;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
