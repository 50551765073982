import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { CurrencyType } from 'src/app/models/api/enums/currency-type';
import { DelegationInfo } from 'src/app/models/api/entities/delegation-info';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { HotelRoomReservationInfo } from 'src/app/models/api/entities/hotel-room-reservation-info';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { Location } from '@angular/common';
import { MimeTypes } from 'src/app/utils/mime-types';
import { RoomType } from 'src/app/models/api/enums/room-type';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { MomentInput } from 'moment';
import { DateStatus, DateStatuses } from 'src/app/utils/date-status';
import { UserService } from 'src/app/services/user.service';
import { isConvertableToNumber, stringIsNullOrEmpty, toDate, toDateString } from 'src/app/shared/utils/utils';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/services/alert.service';
export interface HotelColumns {
  hotelName: boolean,
  category: boolean,
  type: boolean,
  checkIn: boolean,
  checkOut: boolean,
  earlyCheckIn: boolean,
  lateCheckOut: boolean,
  price: boolean,
  discount: boolean,
  subTotal: boolean
}

@Component({
  selector: 'app-hotel-reservations-list',
  templateUrl: './hotel-reservations-list.component.html',
  styleUrls: ['./hotel-reservations-list.component.scss']
})
export class HotelReservationsListComponent implements OnInit {

  public toDateString = toDateString;

  public itemsPerPage = 10;

  public headerItems: PageHeaderItem[] = [];
  public headerTitle: string = 'Hotel reservations';

  public evt: EventInfo;
  public delegation: DelegationInfo;
  public reservations: ListResponse<HotelRoomReservationInfo> = new LocalListResponse<HotelRoomReservationInfo>();

  public isLateBookingStarted: boolean = false;

  public isLocOrAdmin: boolean = false;

  public isObserver: boolean = false;

  public currentReservationId: number;

  public viewingReservation = false;

  public viewingAddEditRoomMate = false;

  public canManageDelegation = false;

  public priceCoumnsCount: number;

  public hotelClassName: string;

  public messageService: MessageService;

  public showRoomingList: boolean;

  public roomingIsActive: boolean = false;

  public isPenaltyFunctionEnabled: boolean = false;

  public clubShortName: string = null;

  public showColumns: HotelColumns = {
    hotelName: true,
    category: true,
    type: true,
    checkIn: true,
    checkOut: true,
    earlyCheckIn: true,
    lateCheckOut: true,
    price: true,
    discount: true,
    subTotal: true,
  };

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location,
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit() {
    this.load();
  }

  //#region Routines

  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canManageDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ManageDelegation);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }


    if (curUser.role === 1 || curUser.role === 2) {
      this.isLocOrAdmin = true;
    }

    if(sessionStorage.getItem("showRoomingList")){
      this.showRoomingList = Boolean(sessionStorage.getItem("showRoomingList"));
    }

    let evtIdStr;
    this.route.parent.paramMap.subscribe(async params => {
      evtIdStr = params.get('eventId');
    })

    this.route.paramMap.subscribe(async params => {
      const countryCode = params.get('countryCode');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      if (stringIsNullOrEmpty(countryCode)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);

      if (evt == null) {
        this.close();
        return;
      }

      if(evt.features.isClubExist){
        this.clubShortName = params.get('clubShortName');
      }

      const delegation = await this.apiService.getDelegation(evtId, countryCode, this.clubShortName);

      if (delegation == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      if ( curUser.role === 3 ){
        this.isObserver = true;
      }

      const canManageThisDelegation = canManageEvent || (canManageDelegation && curUser.eventId === evt.id);
      this.canManageDelegation = canManageThisDelegation;

      this.isPenaltyFunctionEnabled = evt.features.isPenaltyFunctionEnabled;

      this.evt = evt;
      this.delegation = delegation;

      let breadcrumbs: Breadcrumb[] = [];
      if (canManageSystem) {
        breadcrumbs.push({ key: 'Events', link: `/events` });
      }

      if (canManageEvent) {
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      if(this.isObserver){
        breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        breadcrumbs.push({ key: 'Delegations', link: `/events/${evt.id}/delegations` });
      }

      if(evt.features.isClubExist === false)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}` });
        breadcrumbs.push({ key: 'Hotel Reservations', value: '', link:''})
      }

      if(evt.features.isClubExist === true)
      {
        breadcrumbs.push({ key: '', value: `[${delegation.country.code}] ${delegation.country.name}`, link: `/events/${evt.id}/delegations/${delegation.country.code}/${delegation.clubShortName}` });
        breadcrumbs.push({ key: 'Hotel Reservations', value: '', link:''})
      }


      this.breadcrumbsService.breadcrumbs = breadcrumbs;

      if(this.evt.features.isHotelRoomLatePerNightPriceEnabled){

        const lateStartDate = toDate(this.evt.deadlines.accommodationLateBookingStartDate);

        const today = new Date();

        if (today > lateStartDate) {
          this.headerTitle = "Late Hotel Reservations";

          this.isLateBookingStarted = true;
        }

      }

      this.roomingIsActive = this.RoomingIsActive(
          this.evt.deadlines.accommodationRoomFillingStartDate,
          this.evt.deadlines.accommodationRoomFillingEndDate,
          this.evt.features.isHotelRoomLatePerNightPriceEnabled,
          this.evt.deadlines.accommodationLateBookingStartDate,
          this.evt.deadlines.accommodationLateBookingEndDate
        );

      this.updateHeaderItems();

      if (params.has('hotelReservationId')) {

        const reservationIdStr = params.get('hotelReservationId');

        if (reservationIdStr == null || !isConvertableToNumber(reservationIdStr)) {
          this.close();
          return;
        }

        const reservationId = Number(reservationIdStr);

        this.currentReservationId = reservationId;

        if(reservationId !== 0){
          this.viewReservation(reservationId);
        }else{
          this.addReservation();
        }

        this.viewReservation(reservationId);
      };

      this.reloadList();

    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }

    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/']);
    }
  }

  private updateHeaderItems() {
    let headerItems: PageHeaderItem[] = [];
    if (this.canManageDelegation) {

      if(this.roomingIsActive){
        headerItems.push({
          title: `${this.showRoomingList ? 'Hide' : 'Show'} rooming list`,
          iconClass: `${this.showRoomingList ? 'fa-eye-slash' : 'fa-eye'}`,
          callback: () => this.onShowRoomingList()
        });
      }

      headerItems.push({ title: 'Add reservation', iconClass: 'fa-plus', callback: () => this.onAddReservation() });
    }
    this.headerItems = headerItems;
  }

  private async reloadList() {
    this.reservations = await this.apiService.getHotelRoomReservations(this.evt.features.isPenaltyFunctionEnabled, this.itemsPerPage, this.reservations.page, null, this.delegation.eventId, this.delegation.countryCode, this.delegation.clubShortName);
  }

  private viewReservation(reservationId: number) {

    if(!this.isLocOrAdmin && this.isLateBookingStarted && reservationId !== 0){
      let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/hotel-reservations`;

      if(this.clubShortName !== null)
        path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/hotel-reservations`;

      this.location.go(path);
      this.messageService.setToastMessage(false, "The time is over. You can`t edit this reservation.");
      return;
    }

    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/hotel-reservations/${reservationId}`;

      if(this.clubShortName !== null)
        path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/hotel-reservations/${reservationId}`;

    this.location.go(path);
    this.currentReservationId = reservationId;
    this.viewingReservation = true;
  }

  private addRoomingToReservation(reservationId: number) {

    if(!this.isLocOrAdmin && !this.roomingIsActive && reservationId !== 0){
      let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/hotel-reservations`;

        if(this.clubShortName !== null)
          path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/hotel-reservations`;

      this.location.go(path);
      this.messageService.setToastMessage(false, "The time is over. You can`t edit rooming list in this reservation.");
      return;
    }

    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/hotel-reservations/rooming/${reservationId}`;

    if(this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/hotel-reservations/rooming/${reservationId}`;

    this.location.go(path);
    this.currentReservationId = reservationId;
    this.viewingAddEditRoomMate = true;
  }

  private closeReservation() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/hotel-reservations`;

    if(this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/hotel-reservations`;

    this.location.go(path);
    this.viewingReservation = false;
    this.currentReservationId = 0;
  }

  private closeRooming() {
    let path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/hotel-reservations`;

    if(this.clubShortName !== null)
      path = `events/${this.evt.id}/delegations/${this.delegation.countryCode}/${this.delegation.clubShortName}/hotel-reservations`;

    this.location.go(path);
    this.viewingAddEditRoomMate = false;
    this.currentReservationId = 0;
  }

  private addReservation() {
    this.viewReservation(0);
  }

  private deleteReservation(reservation: HotelRoomReservationInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        this.apiService.deleteHotelRoomReservation(reservation.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            const toastMessage = `Hotel room reservation '${reservation.room.hotel.name} deleted`;
            this.messageService.setToastMessage(true, toastMessage);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  private disableReservation(reservation: HotelRoomReservationInfo) {

    const message = 'Are you sure? ';
    const alert = this.alertService.showConfirmation(message, confirmed => {
      if (confirmed) {
        this.apiService.disableHotelRoomReservation(reservation.id).then(result => {
          if (result.isSuccess) {
            this.reloadList();
            const toastMessage = `Hotel room reservation '${reservation.room.hotel.name} disabled`;
            this.messageService.setToastMessage(true, toastMessage);
          } else {
            this.messageService.setToastMessage(false, result.errorMessage);
          }
        });
      }
    });
  }

  //#endregion

  //#region Actions

  onPageChanged(page: number) {
    this.reservations.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onShowRoomingList() {
    this.showRoomingList = !this.showRoomingList;
    sessionStorage.setItem("showRoomingList", this.showRoomingList.toString());
    this.updateHeaderItems();
  }

  onAddReservation() {
    this.addReservation();
  }

  onViewReservation(reservation: HotelRoomReservationInfo) {
    if(reservation.isDisabled){
      this.messageService.setToastMessage(false, "You can not change disabled reservation!");
      return;
    }

    this.viewReservation(reservation.id);
  }

  onAddRoomingToReservation(reservation: HotelRoomReservationInfo) {
    this.addRoomingToReservation(reservation.id);
  }

  onDeleteReservation(reservation: HotelRoomReservationInfo) {
    this.deleteReservation(reservation);
  }

  onDisableReservation(reservation: HotelRoomReservationInfo) {
    this.disableReservation(reservation);
  }

  onReservationUpdated() {
    this.reloadList();
  }

  onReservationClosed() {
    this.closeReservation();
  }

  onRoomingClosed() {
    this.closeRooming();
  }

  RoomingIsActive(start: MomentInput, end: MomentInput, isLateFeatureEnabled: boolean, lateStart: MomentInput, lateEnd: MomentInput): boolean {

    let roomingIsActive: boolean = false;

    if(this.isLocOrAdmin){
      roomingIsActive = true;
    }

    let dateStatus = new DateStatus();

    dateStatus.startDate = start;
    dateStatus.endDate = end;
    dateStatus.isFeatureEnabled = isLateFeatureEnabled;

    if (isLateFeatureEnabled) {
      dateStatus.featureStartDate = lateStart;
      dateStatus.featureEndDate = lateEnd;
    }

    switch (dateStatus.getStatus()) {
      case DateStatuses.OnGoing:
      case DateStatuses.EndsSoon:
      case DateStatuses.FeatureEndsSoon:
      case DateStatuses.FeatureOnGoing:
        roomingIsActive = true;
        break;
    }

    return roomingIsActive;
  }

  //#endregion

}
