import { Router } from '@angular/router';
import { Component, AfterViewInit } from '@angular/core';
import { DisciplineTypeInfo } from '../../../models/api/entities/discipline-type-info';
import { EntityMapService } from '../../../services/entity-map.service';

@Component({
  selector: 'app-disciplines-list',
  templateUrl: './disciplines-list.component.html',
  styleUrls: ['./disciplines-list.component.scss']
})
export class DisciplinesListComponent implements AfterViewInit {

  public disciplines: DisciplineTypeInfo[];

  public selectedDiscipline: DisciplineTypeInfo;

  constructor(
    public entityMapService: EntityMapService,
    public router: Router
  ) {
  }

  ngAfterViewInit() {
    this.entityMapService.ready().then(() => {
      this.disciplines = this.entityMapService.getAllDisciplineTypes();
    });
  }

  public viewDiscipline(discipline: DisciplineTypeInfo) {
    this.selectedDiscipline = discipline;
  }

  navigateToDiscipline(discipline: DisciplineTypeInfo){
    this.router.navigate([`/disciplines/${discipline.disciplineType}`])
  }

}
