import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private titleService: Title) {}

  async ngOnInit() {
    await this.RedirectToHttpsIfNeeded();
    await this.Load();
  }

  private async RedirectToHttpsIfNeeded() {
    return;
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

  private async Load() {
    const title = `[delegation.${environment.production ? '' : 'DEV.'}GYMDATA.online]`;
    this.titleService.setTitle(title);
  }


}
