import { MissedInformationsInfo } from './../../../models/api/entities/missed-informations/missed-informations-info';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { UserService } from 'src/app/services/user.service';
import { MealVenueInfo } from 'src/app/models/api/entities/meal-venue-info';
import { PageHeaderItem } from 'src/app/shared/models/page-header-item';
import { MessageService } from 'src/app/shared/services/message.service';
import { isConvertableToNumber } from 'src/app/shared/utils/utils';

export interface MealTypeAvailable {
  IsBreakfastNotNull: boolean,
  IsLunchNotNull: boolean,
  IsDinnerNotNull: boolean
}

@Component({
  selector: 'app-missed-informations',
  templateUrl: './missed-informations.component.html',
  styleUrls: ['./missed-informations.component.scss']
})
export class MissedInformationsComponent implements OnInit {

  public headerItems: PageHeaderItem[] = [];

  public itemsPerPage = 10;
  public filter = '';

  public evt: EventInfo;
  public informations: ListResponse<MissedInformationsInfo> = new LocalListResponse<MissedInformationsInfo>();

  public mealTypeAvailable: MealTypeAvailable = {
    IsBreakfastNotNull: false,
    IsLunchNotNull: false,
    IsDinnerNotNull: false
  };

  public mealsColSpan: number = 0;

  public canManageEvent = false;

  public messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private entityMapService: EntityMapService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
  ) {
    this.messageService = new MessageService();
  }

  ngOnInit(): void {
    this.load();
  }

  private async load() {

    await this.apiService.ready();
    await this.entityMapService.ready();


    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canManageEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ViewEvent);

    if (!canViewEvent) {
      this.close();
      return;
    }

    this.route.parent.paramMap.subscribe(async params => {
      const evtIdStr = params.get('eventId');
      if (evtIdStr == null || !isConvertableToNumber(evtIdStr)) {
        this.close();
        return;
      }

      const evtId = Number(evtIdStr);
      const evt = await this.apiService.getEvent(evtId);
      if (evt == null) {
        this.close();
        return;
      }

      const canViewThisEvent = canManageSystem || curUser.eventId === evt.id;
      if (!canViewThisEvent) {
        this.close();
        return;
      }

      this.canManageEvent = canManageEvent;

      this.evt = evt;


      if (this.evt.features.isMealsEnabled) {
        this.reloadMealVenues();
      }

      if (curUser.role === 1) {
        this.breadcrumbsService.breadcrumbs = [
          { key: 'Events', link: `/events` },
        ];
      }

      if (canViewEvent) {
        this.breadcrumbsService.breadcrumbs.push({ key: '', value: `${evt.name}`, link: `/events/${evt.id}` });
        this.breadcrumbsService.breadcrumbs.push({ key: 'Missed informations', link: `` });
        this.breadcrumbsService.title = evt.name;
      }
      if (canManageEvent) {

        this.headerItems = [
          ...this.headerItems,
          { title: 'Export', iconClass: 'fa-file-excel-o', callback: () => this.onExport() },
          { title: 'Close', iconClass: 'fa-close', callback: () => this.onClose() }
        ];
      }


      this.reloadList();
    });
  }

  private close() {
    const user = this.apiService.currentUser;
    if (user === null) {
      this.router.navigate(['/auth']);
      return;
    }



    const canManageSystem = this.entityMapService.userCan(user, UserPrivilege.ManageSystem);

    if (canManageSystem) {
      this.router.navigate([`/events/${this.evt.id}`]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private export() {

  }

  private async reloadList() {
    const informations = await this.apiService.getMissedInformations(false, this.itemsPerPage, this.informations.page, this.filter, this.evt.id);
    this.informations = informations;
  }

  private async reloadMealVenues() {

    const mealVenues: MealVenueInfo[] = await (await this.apiService.getMealServiceVenues(false, -1, -1, null, this.evt.id)).items;

    const isBreakfastNotNull: boolean = mealVenues.filter(t => t.breakfastServicePrice !== null).length !== 0;
    const isDinnerNotNull: boolean = mealVenues.filter(t => t.dinnerServicePrice !== null).length !== 0;
    const isLunchNotNull: boolean = mealVenues.filter(t => t.lunchServicePrice !== null).length !== 0;

    if (isBreakfastNotNull) {
      this.mealTypeAvailable.IsBreakfastNotNull = isBreakfastNotNull;
      this.mealsColSpan++;
    }

    if (isDinnerNotNull) {
      this.mealTypeAvailable.IsDinnerNotNull = isDinnerNotNull;
      this.mealsColSpan++;
    }

    if (isLunchNotNull) {
      this.mealTypeAvailable.IsLunchNotNull = isLunchNotNull;
      this.mealsColSpan++;
    }


  }

  //#region Actions
  onFilterChange() {
    this.reloadList();
  }

  onPageChanged(page: number) {
    this.informations.page = page;
    this.reloadList();
  }

  onItemPerPageChanged(item: number) {
    this.itemsPerPage = item;
    this.reloadList();
  }

  onClose() {
    this.close();
  }


  onExport() {
    this.export();
  }

  onFilterKeyUp(key: any) {
		if (key.which === 13) {
			this.onFilterChange();
		}
	}
}
