import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit-member',
  templateUrl: './unit-member.component.html',
  styleUrls: ['./unit-member.component.scss']
})
export class UnitMemberComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
