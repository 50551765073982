// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileUpload {
  background: #00bcbe;
  border-radius: 15px;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin: 1.25em auto; /*20px/16px 0*/
  overflow: hidden;
  padding: 0.875em; /*14px/16px*/
  position: relative;
  text-align: center;
  width: 120px;
  cursor: pointer;
}

.fileUpload:hover, .fileUpload:active, .fileUpload:focus {
  background: #00a2a4;
  cursor: pointer;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 148px;
  height: 46px;
  cursor: pointer;
}

input[type=file] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/documents/photo-upload/photo-upload.component.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EAGA,mBAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA,EAAA,cAAA;EACA,gBAAA;EACA,gBAAA,EAAA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACE,eAAA;AACH;;AACA;EACC,mBAAA;EACC,eAAA;AAEF;;AAAA;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;EACA,eAAA;EACA,UAAA;EACA,wBAAA;EACA,YAAA;EACA,YAAA;EACF,eAAA;AAGF;;AAAA;EACI,eAAA;EACA,WAAA;EACA,YAAA;AAGJ","sourcesContent":[".fileUpload {\r\n\tbackground: #00bcbe;\r\n\t-webkit-border-radius: 15px;\r\n\t-moz-border-radius: 15px;\r\n\tborder-radius: 15px;\r\n\tcolor: #fff;\r\n\tfont-size: 1em;\r\n\tfont-weight: bold;\r\n\tmargin: 1.25em auto;/*20px/16px 0*/\r\n\toverflow: hidden;\r\n\tpadding: 0.875em;/*14px/16px*/\r\n\tposition: relative;\r\n\ttext-align: center;\r\n\twidth: 120px;\r\n   cursor: pointer;\r\n}\r\n.fileUpload:hover, .fileUpload:active, .fileUpload:focus {\r\n\tbackground: #00a2a4;\r\n  cursor: pointer;\r\n}\r\n.fileUpload input.upload {\r\n    position: absolute;\r\n    top: 0;\r\n    right: 0;\r\n    margin: 0;\r\n    padding: 0;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n    opacity: 0;\r\n    filter: alpha(opacity=0);\r\n    width: 148px;\r\n    height: 46px;\r\n  cursor: pointer;\r\n}\r\n\r\ninput[type=\"file\"] {\r\n    position: fixed;\r\n    right: 100%;\r\n    bottom: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
