import { GroupTypeInfo } from './../../../models/api/entities/group-type-info';
import { UnitType } from './../../../models/api/entities/unit-type';
import { MusicInfo } from './../../../models/api/entities/music-info';
import { Component, EventEmitter, Input, AfterViewInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { Country } from 'src/app/models/api/entities/country';
import { EventInfo } from 'src/app/models/api/entities/event-info';
import { ApiService } from 'src/app/services/api.service';
import { EntityMapService } from 'src/app/services/entity-map.service';
import { UserPrivilege } from 'src/app/models/api/enums/user-privilege';
import { UpdateRequest } from 'src/app/models/api/protocol/update-request';
import { ListResponse } from 'src/app/models/api/protocol/list-response';
import { LocalListResponse } from 'src/app/models/api/protocol/local-list-response';
import { PersonInfo } from 'src/app/models/api/entities/person-info';
import { DisciplineTypeInfo } from 'src/app/models/api/entities/discipline-type-info';
import { CategoryTypeInfo } from 'src/app/models/api/entities/category-type-info';
import { ApparatusTypeInfo } from 'src/app/models/api/entities/apparatus-type-info';
import { DisciplineType } from 'src/app/models/api/enums/discipline-type';
import { Gender } from 'src/app/models/api/enums/gender';
import { FunctionType } from 'src/app/models/api/enums/function-type';
import { isUndefined } from 'lodash';
import { HttpEventType } from '@angular/common/http';
import { ApparatusType } from 'src/app/models/api/enums/apparatus-type';
import { isNullOrUndefined } from 'src/app/utils/utils';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss']
})
export class MusicComponent implements AfterViewInit {

  @Input() evt: EventInfo;
  @Input() country: Country;

  @Input() musicId: 0;

  @Output() saved = new EventEmitter<MusicInfo>();
  @Output() closed = new EventEmitter<MusicInfo>();

  @ViewChild('musicModal', { static: true }) private musicModal: TemplateRef<any>;

  @Input() clubShortName: string = null;

  private musicModalRef: NgbModalRef;

  music: MusicInfo;

  disciplineTypeInfo: DisciplineTypeInfo[];

  groupTypes: GroupTypeInfo[];

  categoryTypes: CategoryTypeInfo[];

  unitTypes: UnitType[];

  apparatusTypes: ApparatusTypeInfo[];

  allPersons: ListResponse<PersonInfo> = new LocalListResponse<PersonInfo>();

  personDropdown: PersonInfo[];

  selectedPersons: PersonInfo[] = [];

  maxMember: number;
  minMember: number;

  isNew: boolean;
  isMusicChanged: boolean = false;

  isVisiblle: boolean;
  isReadOnly: boolean;
  isDisabledGroupType: boolean = false;
  isDisabledCategoryType: boolean = false;
  isDisabledApparatusType: boolean = false;
  blobUrl: string;
  fileToUpload: File;
  public progress: number = 0;
  public isUploadProgress: boolean = false;

  musicLength: string = null;

  messageService: MessageService;

  constructor(
    private apiService: ApiService,
    private entityMapService: EntityMapService,
    private modalService: NgbModal,
    progressBar: NgbProgressbarConfig
  ) {
    progressBar.max = 100;
    progressBar.striped = true;
    progressBar.animated = true;
    progressBar.type = 'info';
    progressBar.height = '23px';
    this.messageService = new MessageService();
  }

  ngAfterViewInit() {
    this.load();
  }


  private async load() {
    await this.apiService.ready();
    await this.entityMapService.ready();

    const curUser = this.apiService.currentUser;

    if (curUser == null) {
      this.close();
      return;
    }

    const canManageSystem = this.entityMapService.userCan(curUser, UserPrivilege.ManageSystem);
    const canViewEvent = this.entityMapService.userCan(curUser, UserPrivilege.ManageEvent);
    const canViewDelegation = this.entityMapService.userCan(curUser, UserPrivilege.ViewDelegation);

    if (!canViewDelegation) {
      this.close();
      return;
    }

    const canViewThisDelegation = canManageSystem ||
      (canViewEvent && curUser.eventId === this.evt.id) ||
      (canViewDelegation && curUser.eventId === this.evt.id && curUser.countryCode === this.country.code);

    if (!canViewThisDelegation) {
      this.close();
      return;
    }


    this.allPersons = await this.apiService.getPersonsList(false, -1, 0, null, this.evt.id, this.country.code, this.clubShortName);

    this.personDropdown = this.allPersons.items;

    if (this.evt.disciplineType === DisciplineType.AG) {
      this.isDisabledCategoryType = true;
      this.isDisabledApparatusType = true;

    }

    if (this.evt.disciplineType === DisciplineType.AG || this.evt.disciplineType === DisciplineType.RG) {
      this.personDropdown = this.personDropdown.filter(p => p.gender === Gender.F && p.function === FunctionType.Gymnast);
    }

    let disciplineTypeInfo = this.entityMapService.getAllDisciplineTypes();

    let disciplineTypeInfoFiltered = disciplineTypeInfo.filter(t => t.disciplineType === this.evt.disciplineType);

    this.categoryTypes = disciplineTypeInfoFiltered[0].categoryTypes;

    this.groupTypes = disciplineTypeInfoFiltered[0].groupTypes;

    this.apparatusTypes = disciplineTypeInfoFiltered[0].apparatusTypes;

    this.unitTypes = disciplineTypeInfoFiltered[0].unitTypes;


    if (this.musicId === 0) {
      this.newMusic();
    } else {
      this.loadMusic();
    }
  }

  private async newMusic() {

    this.isNew = true;

    this.music = {
      id: 0,
      eventId: this.evt.id,
      countryCode: this.country.code,
      groupType: null,
      categoryType: null,
      apparatusType: null,
      groupNumber: null,
      members: [],
      clubShortName: this.clubShortName
    };

    if (this.evt.disciplineType === DisciplineType.AG) {

      this.music.categoryType = this.categoryTypes.filter(t => t.code === "IW")[0].categoryType;
      this.music.apparatusType = this.apparatusTypes.filter(t => t.code === "FX")[0].apparatusType;

    }

    this.open();
  }

  private async loadMusic() {
    if (this.isVisiblle) {
      this.close();
    }

    this.isNew = false;

    const music = await this.apiService.getMusic(this.musicId);

    if (music === null) {
      this.close();
      return;
    }

    this.selectedPersons = music.members;

    if (music.groupType !== null && music.categoryType !== null) {
      this.maxMember = this.unitTypes.filter(t => t.categoryType === music.categoryType && t.groupType === music.groupType)[0].maxGymnasts;
      this.minMember = this.unitTypes.filter(t => t.categoryType === music.categoryType && t.groupType === music.groupType)[0].minGymnasts;
    }

    this.music = music;

    this.open();
  }

  private open() {
    const curUser = this.apiService.currentUser;
    if (curUser == null) {
      return;
    }

    if (this.musicModalRef != null) {
      this.musicModalRef.close();
      this.musicModalRef = null;
    }
    this.musicModalRef = this.modalService.open(this.musicModal, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.isVisiblle = true;
  }

  private close() {
    if (this.musicModalRef != null) {
      this.musicModalRef.close();
      this.musicModalRef = null;
    }
    this.isVisiblle = false;
    this.closed.emit(this.music);
  }

  private async save() {

    if (isNullOrUndefined(this.music.groupType)) {
      this.messageService.setToastMessage(false, 'Please select group type.');
      return;
    }

    if (isNullOrUndefined(this.music.categoryType)) {
      this.messageService.setToastMessage(false, 'Please select category type.');
      return;
    }

    if (this.evt.disciplineType === DisciplineType.AG || 
      this.evt.disciplineType === DisciplineType.RG || 
      this.evt.disciplineType === DisciplineType.ACR || 
      this.evt.disciplineType === DisciplineType.TG) {

      if (isNullOrUndefined(this.music.apparatusType)) {
        this.messageService.setToastMessage(false, 'Please select apparatus type.');
        return;
      }

    }

    if (this.isNew && isUndefined(this.blobUrl)) {
      this.messageService.setToastMessage(false, `Please select music file.`);
      return;
    }

    if (this.selectedPersons.length < this.minMember && this.evt.disciplineType !== DisciplineType.TG) {
      this.messageService.setToastMessage(false, `Please select minimum ${this.minMember} and maximum ${this.maxMember} person. You've chosen to ${this.selectedPersons.length} persons`);
      return;
    }


    this.music.members = this.selectedPersons;

    this.music.musicLength = this.musicLength;

    const request: UpdateRequest<MusicInfo> = {
      isNew: this.isNew,
      subject: this.music
    };

    const result = await this.apiService.updateMusic(request);

    if (result.isSuccess) {

      if (!isUndefined(this.fileToUpload)) {

        const formData = new FormData();

        formData.append('file', this.fileToUpload, this.fileToUpload.name);

        this.apiService.uploadMusic(formData, this.evt.id, this.country, result.data, this.clubShortName).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
            this.isUploadProgress = true;
          } else if (event.type === HttpEventType.Response) {
            if (event.body.isSuccess) {
              this.messageService.setToastMessage(true, this.isNew ? 'Music created' : 'Music updated', 5);
              this.saved.emit(this.music);
              this.onClose();
            } else {
              this.messageService.setToastMessage(false, `Music not uploaded!`);
            }
            this.progress = 0;
            this.isUploadProgress = false;
          }
        });
      }else{
        this.messageService.setToastMessage(true, this.isNew ? 'Music created' : 'Music updated', 5);
        this.saved.emit(this.music);
        this.onClose();
      }
    }
  }

  //#endregion

  //#region Actions

  async onSave() {
    this.save();
  }

  onCancel() {
    this.onClose();
  }

  onClose() {
    this.close();
  }

  onTypesChange() {
    if (this.music.groupType !== null && this.music.categoryType !== null) {
      this.maxMember = this.unitTypes.filter(t => t.categoryType === this.music.categoryType && t.groupType === this.music.groupType)[0].maxGymnasts;
      this.minMember = this.unitTypes.filter(t => t.categoryType === this.music.categoryType && t.groupType === this.music.groupType)[0].minGymnasts;
    }
  }

  changedMusic(files) {
    if (files.length === 0) {
      return;
    }

    this.isMusicChanged = true;

    let fileToUpload = <File>files[0];

    this.fileToUpload = fileToUpload;

    const objectURL = URL.createObjectURL(fileToUpload);

    this.blobUrl = objectURL;

  }

  musicDuration(duration: string){
    this.musicLength = duration;
  }


}
