export enum ChangeSubjectType {
  User = 0,

  Event,

  Hotel,
  HotelRoom,
  MealVenue,

  HotelRoomReservation,
  MealReservation,
  Person,
  Unit,
  Flight,
  FlightDetail
}
